import React from 'react';
import { connect } from 'react-redux';
import Lesson from 'app/containers/lesson';
import { importSchoolTemplates } from '../bundles/MainApp/components/helpers/school';

export default function multiLayout(layoutProps = { school: {}, lesson: {} }) {
  return function hoc(Component) {
    function mapStateToProps(state) {
      return {
        isExplicitSchool: state.$$school.get('explicit'),
        templateName: state.$$school.get('template_name'),
      };
    }

    return connect(mapStateToProps)(class MultiLayout extends React.Component {
      render() {
        const { isExplicitSchool, templateName } = this.props;
        const School = importSchoolTemplates(templateName);

        if (isExplicitSchool) {
          return (
            <School {...layoutProps.school}>
              <Component />
            </School>
          );
        } else {
          return (
            <Lesson {...layoutProps.lesson}>
              <Component />
            </Lesson>
          );
        }
      }
    });
  };
}
