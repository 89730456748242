import cachedResponse from "lib/storage/cache";

const FETCH_QUEUE = [];
let FETCH_QUEUE_RUNNING = false;

function generateRequestUrl(parts = []) {
  let fullUrl = "";

  if (parts.length === 0) {
    return fullUrl;
  }

  if (typeof parts[0] === "string" && parts[0].startsWith("http")) {
    fullUrl = parts.shift();
  }

  if (parts.length) {
    const queryParams = parts.map(encodeURIComponent).join("/");
    const PLACEMENT_REGEX = /\/?$/;

    fullUrl = fullUrl.replace(PLACEMENT_REGEX, `/${queryParams}`);
  }

  return fullUrl;
}

function generateRequestOptions(method = "GET", data = null) {
  const requestOptions = {
    method,
    cache: "no-cache",
    headers: {
      Accept: "application/json",
      "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').content
    }
  };

  if (data && data instanceof File) {
    const formData = new FormData();
    formData.append("files[]", data);

    requestOptions.body = formData;
  }

  if (data && requestOptions.body === undefined) {
    requestOptions.body = new URLSearchParams(data).toString();
    requestOptions.headers["Content-Type"] = "application/x-www-form-urlencoded; charset=UTF-8";
  }

  return requestOptions;
}

function storageProccessFetchQueue() {
  if (FETCH_QUEUE_RUNNING || !FETCH_QUEUE.length) return;

  FETCH_QUEUE_RUNNING = true;
  const { url, options, resolve, reject } = FETCH_QUEUE.shift();
  let cachedKey = null;

  if (options.method === "GET") {
    cachedKey = cachedResponse.key(url);

    if (cachedResponse.has(cachedKey)) {
      resolve(cachedResponse.get(cachedKey));
      FETCH_QUEUE_RUNNING = false;
      storageProccessFetchQueue();
      return;
    }
  }

  fetch(url, options)
    .then((response) => response.json())
    .then((response) => {
      if (Array.isArray(response) || response.status === "success" || response.success || response.name.length) {
        const successfulResponse = response.data || response;

        if (options.method === "GET") {
          cachedResponse.set(cachedKey, successfulResponse);
        }

        resolve(successfulResponse);
      } else {
        throw new Error("Response is not successful");
      }
    })
    .catch((error) =>
      reject({
        status: "error",
        error
      })
    )
    .finally(() => {
      if (options.method !== "GET") {
        cachedResponse.clear();
      }

      FETCH_QUEUE_RUNNING = false;
      storageProccessFetchQueue();
    });
}

export function createFileObject(file = {}, { url = "", source = "browser", teacherId = "", lessonId = "" }) {
  // [
  //   // Browser
  //   {
  //     preview: "blob:http://dev.schooler.biz:4100/89ea084d-1ace-4d2f-b091-c7c544586093",
  //     lastModified: 1715868415493,
  //     lastModifiedDate: "Thu May 16 2024 17:06:55 GMT+0300 (Israel Daylight Time)",
  //     name: "נספח_שיעור_2_new_3.pdf",
  //     size: 808256,
  //     type: "application/pdf",
  //     webkitRelativePath: ""
  //   },
  //   // Server
  //   {
  //     name: "2/2/נספח_שיעור_2_new_3.pdf",
  //     size: 808256,
  //     date: "2024-05-20 08:54:16 UTC",
  //     url: "/file?id=2%2F2%2F%D7%A0%D7%A1%D7%A4%D7%97_%D7%A9%D7%99%D7%A2%D7%95%D7%A8_2_new_3%2Epdf",
  //   }
  // ];

  const fileDetails = {
    basename: undefined,
    extension: undefined,
    dotExt: undefined,
    name: undefined,
    raw: file,
    url: undefined
    // date: new Date(???)
  };

  switch (source) {
    case "browser":
      fileDetails.name = file.name;
      fileDetails.url = file.preview;
      break;
    case "server":
      fileDetails.name = file.name.replace(new RegExp(`^${teacherId}/${lessonId}/`), "");
      fileDetails.url = `${url}${file.url}`;
      break;
  }

  const [basename, extension] = fileDetails.name.split(/(?=\.\w+$)/);
  fileDetails.basename = basename;
  fileDetails.dotExt = extension;
  fileDetails.extension = extension.replace(/^\./, "");

  return fileDetails;
}

export function storageFetch(urlParts = [], method = "GET", data = null, cache = false) {
  const url = generateRequestUrl(urlParts);
  const options = generateRequestOptions(method, data);

  return new Promise((resolve, reject) => {
    FETCH_QUEUE.push({ url, options, cache, resolve, reject });
    storageProccessFetchQueue();
  });
}
