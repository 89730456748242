import React from 'react';

export default function Clock() {
  return (
    <svg className="clock-svg" width="19" height="18.5" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="9.861" cy="9.485" r="8.0" stroke="currentColor" />
      <path d="M9.861 4.05v5.507l3.74 3.064" stroke="currentColor" strokeLinecap="round" />
    </svg>
  );
}
