import { symbols } from '../actions/reseller';
import { extend } from 'lodash';
import groupReducer from './group';

export const initialState = {
  activity_log: {
    limit: 16,
    visits: [],
  },
  all_users: {},
  group: {
    quicklinks: [],
    addQuicklinkUrl: '',
    error: null,
  },
};

export default function reseller (state = initialState, action) {
  let next;
  let group = { group: groupReducer(state.group, action) };

  switch (action.type) {
    case symbols.RECEIVED_LIST:
      next = extend({}, state.activity_log, { visits: action.payload });
      return extend({}, state, { activity_log: next }, group);

    case symbols.SET_LIMIT:
      next = extend({}, state.activity_log, { limit: action.payload });
      return extend({}, state, { activity_log: next }, group);

    case symbols.SET_ACTIVE_NAVITEM:
      next = extend({}, state.nav, { activeItem: action.payload });
      return extend({}, state, { nav: next }, group);

    case symbols.RECEIVED_ALL_USERS:
      next = extend({}, state.all_users, { users: action.payload });
      return extend({}, state, { all_users: next }, group);

    case symbols.RECEIVED_ACTIVE_USERS:
      return extend({}, state, { active_users: action.payload.res }, group);

    default:
      next = extend({}, state, group);
      return next;
  }
}
