import React from 'react';
import { connect } from 'react-redux';

import Container from './container';
import LoginForm from '../components/forms/login_form';
import SchoolLoginForm from '../components/forms/school_login_form';
import Lesson from '../containers/lesson';
import { StudentTracker } from '../services/StudentTracker';
import { importSchoolTemplates } from '../../MainApp/components/helpers/school';

class Login extends Container {
  static subheader = 'simple';

  componentDidMount(prevProps) {
    StudentTracker.pageView( this.props.eventProps, this.props.ga4DisabledEventsCodes);
  }

  render() {
    const School = importSchoolTemplates(this.props.templateName);

    if (this.props.isExplicitSchool) {
      return (
        <School>
          <SchoolLoginForm />
        </School>
      );
    } else {
      return (
        <Lesson internalScroll={false} hideBottomPanel={true}>
          <LoginForm />
        </Lesson>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    isExplicitSchool: state.$$school.get('explicit'),
    templateName: state.$$school.get('template_name'),
    ga4DisabledEventsCodes: state.$$layout.get('ga4_disabled_events_codes'),
    eventProps: state.$$layout.get('event_props').toJS(),
  };
}

export default connect(mapStateToProps)(Login);
