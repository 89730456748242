import React from 'react';
import { connect } from 'react-redux';
import { StudentTracker } from '../services/StudentTracker';
import LocalStorageManager from '../../../lib/localStorageManager';
import SniperLinks from '../components/sniper_links';
import { AfterJoinLogo, AfterJoinBackgroundImage } from '../components/svg/join_bundle';

class AfterPay extends React.Component {

  componentDidMount() {
    if(this.props.purchaseDone === true){
      this.purchaseEvent(this.props);
    }
  }

  purchaseEvent = (props) => {
    if(LocalStorageManager.get('finalPrice') === null){
      return
    }
    StudentTracker.track('purchase', {
      'transaction_id': props.paymentId,
      'value': LocalStorageManager.get('finalPrice') || props.bundlePrice,
      'currency': 'ILS',
      'items': this.itemProps(props)
    });    
    LocalStorageManager.remove('couponName');
    LocalStorageManager.remove('couponDiscount');
    LocalStorageManager.remove('finalPrice');
  }

  itemProps = (props) => {
    let couponName = LocalStorageManager.get('couponName');
    let couponDiscount = LocalStorageManager.get('couponDiscount');
    let finalPrice = LocalStorageManager.get('finalPrice');
    let items = [
      {
        'item_id': `${props.bundleId}`,
        'item_name': props.bundleName,
        'item_brand': props.teacherName,
        'item_brand_id': props.teacherId,
        'item_category': props.schoolName,
        'item_category2': props.bundlePolicy,
        'coupon': couponName || props.couponName,
        'discount': couponDiscount || props.couponDiscount,   
        'price': finalPrice || props.bundlePrice,
        'currency': "ILS",
        'quantity': 1
      }
    ];
    return items;
  }

  render() {
    const { bundleName, i18n } = this.props;

    return (
      <>
        <div>
          <div className="lg:block hidden text-center">
            <AfterJoinBackgroundImage className="max-w-full" />
          </div>
          <div className="flex items-center justify-center flex-col text-center rtl lg:py-0 py-32 px-4 lg:w-1/2 lg:mx-auto">
            <div className="lg:block hidden"><AfterJoinLogo /></div>
            <div className="lg:hidden"><AfterJoinLogo width={79} height={74}
            /></div>
            <span className="text-3xl text-[28px] lg:text-4xl lg:text-[40px] font-bold mt-3 text-[#333]" dangerouslySetInnerHTML={{
              __html: i18n.coursePayment.title
            }}></span>
            <span className="mt-2 text-2xl text-[23px] lg:text-3xl lg:text-[27px] text-[#333] font-bold">{bundleName}</span>
            <span className="text-lg leading-[23px] sm:leading-7 font-normal mt-[3px] lg:mt-2 text-[#333]" dangerouslySetInnerHTML={{
              __html: i18n.schoolPayment.content
            }}></span>
            <SniperLinks {...this.props} />
          </div>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    bundleId: state.$$afterjoin.get('bundle_id'),
    bundleName: state.$$afterjoin.get('bundle_name'),
    bundlePrice: state.$$afterjoin.get('price'),
    i18n: state.$$i18n.getIn(['students', 'thankYou']).toJS(),
    bundleId: state.$$afterjoin.get('bundle_id'),
    bundlePolicy: state.$$afterjoin.get('bundle_policy'),
    teacherId: state.$$afterjoin.get('teacher_id'),
    teacherName: state.$$afterjoin.get('teacher_name'),
    couponName: state.$$afterjoin.get('coupon_name'),
    couponDiscount: state.$$afterjoin.get('coupon_discount'),
    purchaseDone: state.$$afterjoin.get('purchase_done'),
    paymentId: state.$$afterjoin.get('payment_id'),
    gmail_link: state.$$afterjoin.getIn(['sniper_links', 'gmail']),
    outlook_link: state.$$afterjoin.getIn(['sniper_links', 'outlook']),
  };
}

export default connect(mapStateToProps)(AfterPay);
