import { symbols as navSymbols } from './ajax';
import { reportError } from './layout';
import $ from 'jquery';

export const symbols = {
  ADD_PRODUCT_OK: '@@cart--add-product-ok',
  ERROR: '@@cart--ajax-eror',
  ADD_PRODUCT_START: '@@cart--add-product-start',
  REMOVE_PRODUCT_START: '@@cart--remove-product-start',
  AJAX_START: '@@cart--ajax-start',
  AJAX_END: '@@cart--ajax-end',
  POLICY_CHANGE: '@@cart--policy-change',
};

export default {
  removeFromCart,
  addBundleToCartAndNavigate,
  addTermToCartAndNavigate,
  updateCouponOrder,
  processOrder,
  checkOrderExists,
  checkOrderValid,
};

function processOrder(orderId) {
  return async (dispatch, getstate) => {
    var url = getstate().$$layout.getIn(['urls', 'process_order']);
    url += (url.split('?')[1] ? '&':'?') + `order_id=${orderId}`;
    const res = await($.get(url));
    return res;
  };
}

function checkOrderExists(orderId) {
  return async (dispatch, getstate) => {
    var url = getstate().$$layout.getIn(['urls', 'check_order_exists']);
    url += (url.split('?')[1] ? '&':'?') + `order_id=${orderId}`;
    const res = await($.get(url));
    return res;
  };
}

function checkOrderValid(order_id, final_price) {
  return async (dispatch, getstate) => {
    var url = getstate().$$layout.getIn(['urls', 'check_order_valid']);
    url += (url.split('?')[1] ? '&':'?') + `order_id=${order_id}&amount=${final_price}`;
    const res = await($.get(url));
    return res;
  };
}

function updateCouponOrder() {
  return async (dispatch, getstate) => {
    const url = getstate().$$layout.getIn(['urls', 'update_coupon_order']);
    try {
      const { location } = await $.post(url);
      window.navigateTo( location );
    } catch (err) {
      dispatch(reportError(getstate().$$i18n.getIn(['system', 'notifications', 'invalid_coupon'])));
    }
  };
}

function addBundleToCartAndNavigate(bundleId, options = {}) {
  return (dispatch, getstate) => {
    const url = getstate().$$layout.getIn(['urls', 'add_to_cart']);
    dispatch(addToCartAndNavigate(url, Object.assign({}, options, {
      bundle_id: bundleId,
    })));
  };
}

function addTermToCartAndNavigate(termId, plan) {
  return (dispatch, getstate) => {
    const url = getstate().$$layout.getIn(['urls', 'add_term_to_cart']);
    dispatch(addToCartAndNavigate(url, {
      virtualclass_id: termId,
      plan: plan,
    }));
  };
}

function addToCartAndNavigate(url, data) {
  return (dispatch, getstate) => {
    const shoppingcartUrl = getstate().$$layout.getIn(['urls', 'shoppingcart']);

    const token = getstate().$$layout.get('authenticityToken');
    const xhr = new XMLHttpRequest();

    xhr.open('POST', url);
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.setRequestHeader('Accept', 'application/json');
    xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
    xhr.send(JSON.stringify(Object.assign({}, {
      authenticity_token: token,
    }, data)));

    dispatch({
      type: symbols.ADD_PRODUCT_START,
    });
    dispatch({
      type: symbols.AJAX_START,
    });

    xhr.addEventListener('load', () => {
      if (xhr.status === 200) {
        const resultData = JSON.parse(xhr.responseText);
        dispatch({
          type: navSymbols.APPSTATE_MERGE,
          payload: resultData,
        });
        dispatch({
          type: symbols.AJAX_END,
          payload: resultData,
        });
        const nextUrl = resultData.layout.lastLoadedUrl === url ? shoppingcartUrl : resultData.layout.lastLoadedUrl;

        window.navigateTo(nextUrl);
      } else {
        dispatch({
          type: symbols.ERROR,
          payload: xhr.statusText
        });
      }
    });

    xhr.addEventListener('error', () => {
      dispatch({
        type: symbols.ERROR,
        payload: xhr.statusText
      });
    });
  };
}

function removeFromCart(productId) {
  return (dispatch, getstate) => {
    const url = getstate().$$layout.getIn(['urls', 'remove_from_cart']);
    const xhr = new XMLHttpRequest();
    const token = getstate().$$layout.get('authenticityToken');
    xhr.open('POST', url);
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.setRequestHeader('Accept', 'application/json');
    xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
    xhr.send(JSON.stringify({
      id: productId,
      authenticity_token: token
    }));
    dispatch({
      type: symbols.REMOVE_PRODUCT_START
    });
    dispatch({
      type: symbols.AJAX_START
    });

    xhr.addEventListener('load', () => {
      if (xhr.status === 200) {
        const data = JSON.parse(xhr.responseText);
        dispatch({
          type: symbols.AJAX_END,
          payload: data
        });
      } else {
        dispatch({
          type: symbols.ERROR,
          payload: xhr.statusText
        });
      }
    });

    xhr.addEventListener('error', () => {
      dispatch({
        type: symbols.ERROR,
        payload: xhr.statusText
      });
    });
  };
}
