import React from "react";

export default function arrowToCourseMobile({circleColor}) {
  return (
    <svg
      width="45"
      height="46"
      viewBox="0 0 45 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="22.5" cy="23.3901" r="22.5" fill={circleColor} />
      <path
        d="M26.8572 22.9852L22.6925 27.1499L18.5279 22.9852C18.4089 22.8703 18.2495 22.8067 18.084 22.8081C17.9186 22.8096 17.7603 22.8759 17.6433 22.9929C17.5263 23.1099 17.46 23.2682 17.4585 23.4336C17.4571 23.5991 17.5207 23.7585 17.6356 23.8775L21.9177 28.1601C22.1227 28.3647 22.4004 28.4795 22.69 28.4795C22.9796 28.4795 23.2574 28.3647 23.4624 28.1601L27.7444 23.8775C27.8481 23.7571 27.9026 23.6019 27.8969 23.4431C27.8912 23.2843 27.8258 23.1334 27.7138 23.0207C27.6017 22.908 27.4512 22.8418 27.2924 22.8352C27.1336 22.8286 26.9782 22.8822 26.8572 22.9852Z"
        fill="white"
      />
      <path
        d="M21.9201 24.0467C22.1251 24.2513 22.4028 24.3661 22.6924 24.3661C22.982 24.3661 23.2598 24.2513 23.4648 24.0467L27.7468 19.7641C27.8544 19.644 27.912 19.4873 27.9078 19.3261C27.9035 19.1649 27.8376 19.0115 27.7238 18.8973C27.6099 18.7831 27.4567 18.7169 27.2955 18.7121C27.1343 18.7074 26.9775 18.7645 26.8571 18.8718L22.6924 23.0365L18.5278 18.8718C18.4695 18.8116 18.3999 18.7635 18.3229 18.7304C18.2459 18.6974 18.1631 18.68 18.0794 18.6792C17.9956 18.6785 17.9125 18.6945 17.8349 18.7262C17.7574 18.7579 17.6869 18.8048 17.6277 18.864C17.5684 18.9233 17.5216 18.9937 17.4899 19.0713C17.4581 19.1488 17.4422 19.2319 17.4429 19.3157C17.4436 19.3995 17.461 19.4823 17.4941 19.5593C17.5272 19.6362 17.5752 19.7059 17.6355 19.7641L21.9201 24.0467Z"
        fill="white"
      />
    </svg>
  );
}
