import { useSelector } from "react-redux";
import Dropzone from "react-dropzone";
import React from "react";

import EditableLabel from "app/components/helpers/editable_label";
import FileIcon from "app/components/lesson/attached_files/file_icon";
import ActionIcon from "app/components/lesson/attached_files/action-icon";

const ALLOWED_MIME_TYPES = [
  ".doc",
  ".docx",
  ".flac",
  ".hex",
  ".mp3",
  ".pdf",
  ".ppt",
  ".pptx",
  ".rb",
  ".txt",
  ".wav",
  ".xls",
  ".xlsx",
  "image/jpeg",
  "image/png",
];

export default function EditableFilesList({
  files = [],
  onDelete = () => {},
  onDrop = () => {},
  onRename = () => {},
  isProcessing = false,
}) {
  const [i18n] = useSelector((state) => [
    state.$$i18n.getIn(["system", "lesson", "attachedFiles"]).toJS(),
  ]);

  return (
    <Dropzone
      accept={ALLOWED_MIME_TYPES.join(",")}
      onDrop={onDrop}
      className="attached-files--dropzone"
    >
      <label dangerouslySetInnerHTML={{ __html: i18n.dropzone.caption }} />
      {isProcessing && <div className="attached-files--dropzone-loader" />}
      <ul className="attached-files--list">
        {files.map((file, index) => (
          <li
            key={`editable-file-${index}`}
            className="attached-files--list-item"
            onClick={(event) => {
              event.stopPropagation();
              event.preventDefault();
            }}
          >
            <FileIcon
              className="attached-files--list-item-icon"
              extension={file.extension}
              type="font"
            />
            <EditableLabel
              displayValue={file.basename}
              editClassName="attached-files--list-item-basename-editable-input lesson--link_lock bright teacher__edit-lesson-in-toc"
              displayClassName="attached-files--list-item-basename lesson--link_lock lesson active"
              onCommit={(newBasename) => onRename(file, newBasename)}
            />
            <ActionIcon
              className="attached-files--list-item-edit"
              name="edit"
              onClick={() => {
                const editableLabelSelector = [
                  `.attached-files--list-item:nth-child(${index + 1})`,
                  ".attached-files--list-item-basename",
                ].join(" ");

                document.querySelector(editableLabelSelector).click();
              }}
              tooltipText={i18n.tooltips.rename}
            />
            <ActionIcon
              className="attached-files--list-item-remove"
              name="trash"
              onClick={() => onDelete(file)}
              tooltipText={i18n.tooltips.remove}
            />
          </li>
        ))}
      </ul>
    </Dropzone>
  );
}
