import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { Form, Input, CheckBoxWithLabel } from '../components/forms/form_for';
import JoinCourse from './join_course';
import {ConditionalComponent} from '../components/common';
import withJoin from '../components/HOC/withJoin';
import { StudentTracker } from '../services/StudentTracker';
import { importSchoolTemplates } from '../../MainApp/components/helpers/school';
import SignupFormLogo from '../../MainApp/components/svg/signup_form_logo';

class JoinSchool extends React.Component {
  componentDidMount(prevProps) {
    StudentTracker.pageView( this.props.eventProps, this.props.ga4DisabledEventsCodes);
  }
  
  render(){
    const props = this.props;

    if (props.bundle) {
      return <JoinCourse bundle={props.bundle} />;
    }

    const School = importSchoolTemplates(props.templateName);

    return (
      <School>
        <div className="content-inner">

          <div className="iconed-block !p-0">
            <div className="iconed-block-inner">

              <div className="col-set col-set-bordered school-signup-form">
                <div className="col">
                  <div className="inline-block">
                    <SignupFormLogo fillColor={props.altColor} />
                  </div>

                  <h3 className="new-user-form-header"
                    dangerouslySetInnerHTML={{ __html: props.i18n.title}}>
                  </h3>

                  <Form
                    for="user"
                    action="new_school_user_registration"
                    method="POST" className='form-simple'
                  >
                    <ConditionalComponent isRender={props.showNameField}>
                      <Input
                        className="nm-input input-user"
                        name='name'
                        type="text"
                        aria-label={props.i18n.form.fullNameAriaLabel}
                        placeholder={props.i18n.form.fullName}
                        defaultValue={props.name}
                        required={props.nameRequired}
                        customValidity={props.i18n.form.fullNameError}
                      />
                    </ConditionalComponent>

                    <Input
                      className="nm-input input-email"
                      name='email'
                      required={true}
                      type="email"
                      aria-label={props.i18n.form.emailAriaLabel}
                      placeholder={props.i18n.form.email}
                      defaultValue={props.email}
                      customValidity={props.i18n.form.emailError}
                    />

                    <ConditionalComponent isRender={props.showPhoneField}>
                      <Input
                        className="nm-input input-phone"
                        name='phone'
                        type="tel"
                        aria-label={props.i18n.form.phoneAriaLabel}
                        placeholder={props.i18n.form.phone}
                        defaultValue={props.phone}
                        pattern={props.i18n.form.phonePattern}
                        required={props.phoneRequired}
                        customValidity={props.i18n.form.phoneError}
                      />
                    </ConditionalComponent>

                    {
                      props.bundle &&
                      <input type='hidden' name='initial_bundle_id' value={props.bundle} />
                    }

                    {
                      props.couponId &&
                      <input type='hidden' name='coupon_id' value={props.couponId} />
                    }

                    <ConditionalComponent isRender={!isEmpty(props.terms)}>
                      <CheckBoxWithLabel className="terms-course-wrapper" name="is_terms_approved" label={props.terms} onClick={props.termLinkClick} testName="terms" ariaLabel={props.i18n.termsAndConditionAriaLabel} />
                    </ConditionalComponent>

                    <ConditionalComponent isRender={props.isTermsNotApproved}>
                      <div className="feedback-invalid" dangerouslySetInnerHTML={{__html: props.i18n.form.termsError }}></div>
                    </ConditionalComponent>

                    <ConditionalComponent isRender={props.getFlushContainerErrors() !== ''}>
                      <div className="feedback-invalid">{props.getFlushContainerErrors().split(',').map(error=>(<div>{error}</div>))}</div>
                    </ConditionalComponent>

                    <button className="button button-primary"
                      onClick={props.validateBeforeSubmit}
                      aria-label={props.i18n.form.registerButtonAriaLabel}
                      dangerouslySetInnerHTML={{__html: props.i18n.form.registerButton }}
                      style={{color: props.textColor, backgroundColor: props.altColor}}>
                    </button>

                    <div className="form-actions">
                      <div className='link-to-signin'
                        dangerouslySetInnerHTML={{__html: props.i18n.form.loginLink.replace('%{target_link}', props.loginPath) }}>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </School>
    );
  }
}


JoinSchool.propTypes = {
  email: PropTypes.string,
};

function mapStateToProps(state) {
  let prefillData = {};
  try {
    prefillData = JSON.parse(state.$$account.get('prefill_data'));
  } catch (err) {
    // pass
  }

  return {
    loginPath: state.$$layout.getIn(['urls', 'login']),
    email: prefillData.email ? decodeURIComponent(prefillData.email) : '',
    phone: prefillData.phone ? decodeURIComponent(prefillData.phone) : '',
    name: prefillData.name ? decodeURIComponent(prefillData.name) : '',
    bundle: state.$$layout.get('add_bundle'),
    schoolName: state.$$school.getIn(['name']),
    terms : state.$$layout.get('terms'),
    termsAttachmentUrl: state.$$layout.getIn(['urls', 'terms_attachments']),
    customDomain: state.$$layout.get('customDomain'),
    phoneRequired: state.$$layout.getIn(['fields', 'phone']) === 'required',
    nameRequired: state.$$layout.getIn(['fields', 'name']) === 'required',
    showPhoneField: state.$$layout.getIn(['fields', 'phone']) !== 'hidden',
    showNameField:  state.$$layout.getIn(['fields', 'name']) !== 'hidden',
    couponId: state.$$layout.getIn(['coupon_id']),
    ga4DisabledEventsCodes: state.$$layout.get('ga4_disabled_events_codes'),
    eventProps: state.$$layout.get('event_props').toJS(),
    templateName: state.$$school.get('template_name'),
    textColor: state.$$school.getIn(['template_values', 'textColor', 'value']),
    altColor: state.$$school.getIn(['template_values', 'secondaryColor', 'value']),
    i18n: {
      ...state.$$i18n.getIn(['students', 'joinSchool']).toJS(),
      form: state.$$i18n.getIn(['students', 'form']).toJS()
    }
  };
}

export default connect(mapStateToProps)(withJoin(JoinSchool));

