import React from 'react';
import PropTypes from 'prop-types';
import { isFunction } from 'lodash';

export default function ConditionalComponent({ isRender, children }) {
  const decision = isFunction(isRender) ? !!isRender() : !!isRender

  return (
    <React.Fragment>
      { decision ? children : null }
    </React.Fragment>
  );
}

ConditionalComponent.propTypes = {
  isRender: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]).isRequired
};

ConditionalComponent.defaultProps = {
  isRender: false
};
