import React, { PropTypes } from 'react';
import { connect } from 'react-redux';

import MultiLayout from '../components/multi_layout';
import SchoolPasswordReset from '../components/forms/school_password_reset';
import PasswordFormLogo from '../components/svg/password_form_logo';
import { Form, Label, PasswordField, HiddenField } from '../components/forms/form_for';


function ResetPassword(props) {
  const { isExplicitSchool, resetPasswordToken, i18n } = props;

  return (
    <MultiLayout internalScroll={false} hideBottomPanel={true}>
      {isExplicitSchool ? (
        <SchoolPasswordReset {...props} />
      ) : (
        <div className="centered-container flex justify-center items-center md:pt-[165px] pt-[90px]">
          <div className="edit-password-form">
            <div className="col">
              <div className="flex justify-center">
                <PasswordFormLogo fillColor={'#03D6F3'} />
              </div>
              <div className="md:mt-[10px] mt-[30px]">
                <h3 className='text-center md:!text-[30px] !text-[28px] font-bold text-[#333333] leading-[15px]' arial-label={i18n.resetPassword.updateTitleAriaLabel} dangerouslySetInnerHTML={{ __html: i18n.resetPassword.updateTitle }}></h3>
              </div>
              <div className="md:!mt-[24px] !mt-[30px]">
                <Form for="user" action="user_password_path" method="PUT" className='clear w-full ml-0 !pt-0'>
                  <HiddenField name="reset_password_token" value={resetPasswordToken} />

                  <div dangerouslySetInnerHTML={{ __html: props.deviseerrors }} />

                  <div className='nm-form-row !mb-[10px]'>
                    <div className='nm-left'>
                      <PasswordField id="password" name="password" className='input-field !px-[18px] text-base font-normal placeholder-[#333] !text-justify directional' placeholder={i18n.resetPassword.updatePassword} />
                    </div>
                  </div>

                  <div className='nm-form-row !mb-0'>
                    <div className='nm-left'>
                      <PasswordField id="password_confirmation" name="password_confirmation" className='input-field !px-[18px] text-base font-normal placeholder-[#333] !text-justify directional' placeholder={i18n.resetPassword.updatePasswordConfirmation} />
                    </div>
                  </div>

                  <input
                    type="submit"
                    arial-label={i18n.resetPassword.updateButtonAriaLabel}
                    className='reset-password-btn school-reset-password cta-btn w-full mt-[20px]'
                    value={i18n.resetPassword.updateButton}
                  />
                </Form>
              </div>
            </div>
          </div>
        </div>
      )}
    </MultiLayout>
  );
}

ResetPassword.propTypes = {
  resetPasswordToken: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  return {
    deviseerrors: state.$$layout.get('deviseerror'),
    i18n: {
      ...state.$$i18n.getIn(['students', 'form']).toJS(),
      resetPassword: state.$$i18n.getIn(['students', 'resetPassword']).toJS()
    },
    resetPasswordToken: state.$$account.get('reset_password_token'),
    isExplicitSchool: state.$$school.get('explicit')
  };
}

export default connect(mapStateToProps)(ResetPassword);
