import React from 'react';
import { clearError } from '../actions/layout';

export default class Container extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
    if (this.props.dispatch) {
      this.props.dispatch(clearError());
    }
  }
}
