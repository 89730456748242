import { connect } from 'react-redux';
import { isNumber } from 'lodash';
import { durationString } from 'app/components/helpers/school';
import { StudentTracker } from 'app/services/StudentTracker';
import $ from 'jquery';
import Immutable from 'immutable';
import React from 'react';
import TinyMceEditorContent from 'app/containers/schoolTemplates/common/TinyMceEditorContent';

function priceTags(props) {
  var currencySign = isNumber(props.price) ? props.i18n.general.currencySign : ''
  var currencyKlass = currencySign !== '' ? 'currency-sign' : ''

  if (props.priceAfterDiscount !== null && props.priceAfterDiscount !== props.price) {
    return (
      <div style={{ display: 'inline-block' }}>
        <span
          className={`currency before-discount`}
          style={{ textDecoration: 'line-through', marginRight: '5px' }}
        >
          {props.currencyType}{props.price}
        </span>
        <span className={`currency after-discount`}>{props.currencyType}{props.priceAfterDiscount}</span>
      </div>
    );
  } else {
    return <span className={`currency normal-currency`}>{props.price} {props.currencyType}</span>;
  }
}

function CourseBox(props) {
  const thumbnailStyle = {backgroundImage: `url(${props.thumbnail})`}
  const priceLabel = props.i18n.free
  const durationValue = props.courseDurationDisplay ? durationString(props.duration, props.i18n.duration) : null;
  if (props.policy === 'external_signup' && props.price === 0) {
    priceLabel = props.i18n.general.externalSignup
  }
  return (
    <div className={`course-box ${props.draft ? 'draft' : ''} ${props.owned ? 'owned' : ''} `}>
      { props.draft &&
        <div className="draft-label">
          <span className='sch-draft'></span>
          <span dangerouslySetInnerHTML={{ __html: props.i18n.draft }}></span>
        </div>
      }

      <div
        className="thumbnail"
        style={{ backgroundImage: `url(${props.thumbnail})` }}
      ></div>

      <div className='course-info'>
        <h2>{props.name}</h2>
        <div className='side-by-side'>
          <div className='course-tiny-details'>
            <span className='author'>{props.author}</span>
            <span className='duration' dangerouslySetInnerHTML={{ __html: durationValue }}></span>
          </div>
          <div className='course-price'>
            <span
              style={{
                backgroundColor: props.altColor,
                color: props.textColor,
              }}
              className={(props.owned || (props.price === 0 || isNaN(Number(props.price)))) ? 'free' : props.priceAfterDiscount !== null && props.priceAfterDiscount !== props.price ? 'paid-with-discount' :'paid'}
            >
              {
                props.owned ? (props.isActive ? props.i18n.joined : priceTags(props)) :
                props.price === 0 ? priceLabel : priceTags(props)
              }
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

class BundlesContainer extends React.Component {
  componentDidMount() {
    this.equalizeCourseNamesHeights();
    StudentTracker.pageView(
      this.props.eventProps,
      this.props.ga4DisabledEventsCodes
    );
  }

  componentDidUpdate() {
    this.equalizeCourseNamesHeights();
  }

  equalizeCourseNamesHeights() {
    let maxHeight = 0;

    $('.course-box h2').each(function(){
      if ($(this).height() > maxHeight) { maxHeight = $(this).height(); }
    });

    $('.course-box h2').css('min-height', maxHeight);
  }

  createBundleProps(bundle, i18n) {
    return {
      thumbnail: bundle.get('img'),
      name: bundle.get('name'),
      href: bundle.get('href'),
      author: bundle.get('author'),
      duration: bundle.get('duration'),
      price: bundle.get('price'),
      currencyType: bundle.get('currency_type') ? bundle.get('currency_type').match(/[^\s()]+/)[0] : '₪',
      language_name: bundle.get('language_name'),
      priceAfterDiscount: bundle.get('price_after_discount'),
      altColor: this.props.altColor,
      textColor: this.props.textColor,
      draft: bundle.get('draft'),
      owned: bundle.get('owned'),
      isActive: bundle.get('is_active'),
      policy: bundle.get('policy'),
      courseDurationDisplay: bundle.get('is_course_duration_display'),
      i18n: i18n
    };
  }

  redirectTo (url) {
    window.location.href = url
  }

  trackClick(bundle){
    StudentTracker.track('choose_course', {
      'item_id': bundle.get('id'),
      'item_name': bundle.get('name'),
      'item_brand': bundle.get('author'),
      'item_brand_id': bundle.get('author_id'),
      'item_category': this.props.schoolName,
      'item_category2': bundle.get('bundle_policy'),
    });
  }

  render() {
    const { bundles, schoolDescription, selectCourseText, i18n } = this.props;
    return (
      <>
        <div className='school-grid row school-intro-text'>
          <p dangerouslySetInnerHTML={{ __html: schoolDescription }} ></p>
          <TinyMceEditorContent className='cta' text={selectCourseText} />
        </div>
        <div className='bundles-container'>
          {
            bundles.map(bundle => (
              <a
                className='course-link'
                href='#'
                id={bundle.get('href')}
                onClick={(e) => { e.preventDefault(); this.trackClick(bundle); window.location.href = bundle.get('href'); }}
              >
                <CourseBox
                  {...this.createBundleProps(bundle, i18n)}
                  headerRefs={this.courseHeaderRefs}
                />
              </a>
            ))
          }
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    bundles: state.$$school.get('bundles') || Immutable.Map(),
    schoolName: state.$$school.get('name'),
    altColor: state.$$school.getIn(['template_values', 'secondaryColor', 'value']),
    textColor: state.$$school.getIn(['template_values', 'textColor', 'value']),
    schoolDescription: state.$$school.getIn(['template_values', 'description','value']),
    selectCourseText: state.$$school.getIn(['template_values', 'selectCourseText','value']),
    ga4DisabledEventsCodes: state.$$layout.get('ga4_disabled_events_codes'),
    eventProps: state.$$layout.get('event_props').toJS(),
    i18n: {
      ...state.$$i18n.getIn(['students', 'course']).toJS(),
      draft: state.$$i18n.getIn(['system', 'draft']),
      general: state.$$i18n.getIn(['students', 'general']).toJS()
    },
  };
}


export default connect(mapStateToProps)(BundlesContainer);
