export default class Undo {
  constructor(entityIndex, oldValue, newValue, entity, undoType = Undo.UNDO_TYPES.LESSON) {
    this.entityIndex = entityIndex
    this.oldValue = oldValue
    this.newValue = newValue
    this.entity = entity
    this.undoType = undoType
    this.history = []
  }

  static UNDO_TYPES ={
    LESSON : 1,
    SECTION : 2
  }

  get EntityIndex() {
    return this.entityIndex
  }
  
  get OldValue() {
    return this.oldValue
  }
  get NewValue() {
    return this.newValue
  }
  
  get Entity() {
    return this.entity
  }

  get UndoType() {
    return this.undoType
  }
  create(entityId, oldValue, newValue, entity, sourceActionName){
    const isProdEnv = process.env.NODE_ENV === 'production'
    if(!isProdEnv) {
      this.history.push({
        entityIndex: this.entityIndex,
        oldValue: this.oldValue,
        newValue: this.newValue,
        entity: this.entity,
        sourceActionName
      })
    }
   
    const newUndo = new Undo(entityIndex, oldValue, newValue, entity, this.undoType)
    if(!isProdEnv) {
      newUndo.history = this.history.concat()
    }

    return newUndo
  }
}