import React, { Component } from 'react';
import $ from 'jquery';
import PropTypes from 'prop-types';
import { isWhistPlayer, WHIST_PLAYER_URL } from '../helpers/video_helper';
import { callPlayer, getSDK, randomString } from 'react-player/lib/utils';

const SDK_GLOBAL = 'whistAPI';
const PLAYER_ID_PREFIX = 'whist-player-';

export default class WhistPlayer extends Component {
    static displayName = 'WhistPlayer'
    static canPlay = (url) => isWhistPlayer(url);
    static loopOnEnded = true;
    callPlayer = callPlayer
    playerID = this.props.config.playerId || `${PLAYER_ID_PREFIX}${randomString()}`;
    static propTypes = {
      config: PropTypes.object,
      playing: PropTypes.bool,
      onError: PropTypes.func,
      onEnded: PropTypes.func,
      onProgress: PropTypes.func,
      onMount: PropTypes.func,
      url: PropTypes.string,
    };
    componentDidMount() {
      if (this.props.onMount) {
        this.props.onMount(this);
      }
    }
    componentWillUnmount() {
      if(window.hasOwnProperty('lessonId')) {
        delete window['lessonId'];
      }
    }
    load(url, isReady) {
      const { playing, config, onError, onEnded, onProgress, lessonId } = this.props;
      window.lessonId = lessonId;
      getSDK(WHIST_PLAYER_URL, SDK_GLOBAL).then(() => {
        const videoCdnInstance = window.videocdn.instance();
        videoCdnInstance && videoCdnInstance.listen('finish', (data) => {
          if (playing) {
            // Create play action without user click
            videoCdnInstance.call('clip');
          }
          if (data.fid) {
            onProgress ( {'lessonId': window.lessonId} );
            onEnded(data);
          }
        });
      }, onError);
    }
    unbind() {
    }

    play() {
      this.callPlayer('play');
    }

    pause() {
      this.callPlayer('pause');
    }

    stop() {
      this.unbind();
      this.callPlayer('remove');
    }

    seekTo(seconds) {
      this.callPlayer('time', seconds);
    }

    setVolume(fraction) {
      this.callPlayer('volume', fraction);
    }

    mute = () => {
      this.callPlayer('mute');
    }

    unmute = () => {
      this.callPlayer('unmute');
    }

    setPlaybackRate(rate) {
      this.callPlayer('playbackRate', rate);
    }

    getDuration() {
      return this.callPlayer('duration');
    }

    getCurrentTime() {
      return this.callPlayer('time');
    }

    getSecondsLoaded() {
      return null;
    }
    render() {
      const { url, config, playing } = this.props;

      return (
          <iframe
            allow='autoplay'
            src={playing ? `${url}?autoplay=true` : url}
            webkitallowfullscreen='true'
            mozallowfullscreen='true'
            allowFullScreen
            frameBorder='0'
            scrolling='no'
          >
          </iframe>
      );
    }
}
