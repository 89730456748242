import React from 'react';
import { useSelector } from 'react-redux';
import {RefreshForbiddenIcon} from '../icons/index';

const CLASS_NAMES ={
  CONTAINER : 'undo-redo-splash-container',
  SPLASH_CONTENT_CONTAINER : 'undo-redo-splash-content-container',
  PLACEHOLDER : 'undo-redo-splash-placeholder',
  HEADER : 'undo-redo-splash-header',
  SUB_HEADER : 'undo-redo-splash-sub-header',
  SVG_CONTAINER : 'undo-redo-splash-svg-container',
}

const UndoRedoSplash = () => {
  const i18n = useSelector((state) => state.$$i18n.getIn(['system', 'undoRedo', 'splash']).toJS());

  return (
    <div data-testname="undoRedoSplashContainer" className={CLASS_NAMES.CONTAINER}>
      <div className={CLASS_NAMES.PLACEHOLDER}>&nbsp;</div>
      <div className={CLASS_NAMES.SPLASH_CONTENT_CONTAINER}>
        <div className={CLASS_NAMES.SVG_CONTAINER}>
          <RefreshForbiddenIcon/>
        </div>
        <div className={CLASS_NAMES.HEADER}>
          {i18n.header}
        </div>
        <div className={CLASS_NAMES.SUB_HEADER}>
          {i18n.subHeader}
        </div>
      ֿ</div>
    </div>
  )
}
export default UndoRedoSplash
