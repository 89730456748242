import React, { PropTypes } from 'react';

export default function withJoin(WrappedComponent) {
    return class extends React.Component {
      constructor(props) {
        super(props);
        this.state ={
            isTermsNotApproved: false
          }
      }

      getFlushContainerErrors(){
        const flushContainer = document.querySelector('.my-notification-error .text')
        let errorsText = ''

        if (flushContainer) {
          errorsText = flushContainer.outerText
          document.querySelector('.my-notification-error').style.display = 'none'
        }

        return errorsText
      }

      validateBeforeSubmit = (e) => {
        const form = document.forms[0]
        const prefix = this.props.customDomain ? 'd_school__user' : 'r_school_user'
        const inputName = `${prefix}[is_terms_approved]`
        if(form[inputName] && !form[inputName].checked){
          this.setState({isTermsNotApproved: true})
          e.preventDefault();
          return false;
        }
        this.setState({isTermsNotApproved: false})
      }

      termLinkClick = (e) => {
        const { termsAttachmentUrl } = this.props;
        const { tagName, dataset } = e.target;
        if (tagName === 'A' && dataset.blobId !== '-1') {
          e.preventDefault();
          // Open a blank tab first
          const newWindow = window.open('about:blank', '_blank');

          // Perform the AJAX request
          const querySign = termsAttachmentUrl.indexOf('?') > 0 ? '&' : '?'
          fetch(`${termsAttachmentUrl}${querySign}blob_id=${dataset.blobId}`, {
            method: 'GET',
            headers: new Headers({ 'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content }),
          })
            .then(response => response.json())
            .then(res => {
              if (res.status === 'success') {
                // Update the URL of the opened tab
                if (newWindow) {
                  newWindow.location.href = res.url;
                }
              } else {
                console.error(res.error)
              }
            })
            .catch(e => console.error(`Can not get attachment url the reason is:${e}`))
        }
      }

      render() {
          return <WrappedComponent {...this.props}
                                   isTermsNotApproved={this.state.isTermsNotApproved}
                                   termLinkClick={this.termLinkClick}
                                   validateBeforeSubmit={this.validateBeforeSubmit}
                                   getFlushContainerErrors={this.getFlushContainerErrors}
          />;
      }
    };
  }
