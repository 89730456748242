import { storageFetch, createFileObject } from "lib/storage/helpers";
import { reportError } from "app/actions/layout";
import { STATUS_TYPES } from "app/reducers/lesson/attached_files";
import { intersectionWith } from "lodash";

export const symbols = {
  DELETE_FILE: "@@lesson.attached-files.delete",
  LIST_FILES_ERROR: "@@lesson.attached-files.list.error",
  LIST_FILES: "@@lesson.attached-files.list",
  RENAME_FILE: "@@lesson.attached-files.rename",
  STATUS_CHANGE: "@@lesson.attached-files.status-change",
  UPLOAD_FILE: "@@lesson.attached-files.upload",
};

const dispatchStatusChange = (dispatch, status = STATUS_TYPES.IDLE) => {
  dispatch({
    type: symbols.STATUS_CHANGE,
    payload: { status }
  });
};

const dispatchActionError = (dispatch, getState, errorKey) => {
  const errorMessage = getState().$$i18n.getIn(["system", "lesson", "attachedFiles", "errorMessages", errorKey]);
  dispatch(reportError(errorMessage));
};

export const listFiles = () => (dispatch, getState) => {
  const lessonId = getState().$$lesson.get("id");
  const teacherId = getState().$$layout.getIn(["storageapi_id"]);
  const url = getState().$$layout.getIn(["urls", "attached-files-api"]);

  dispatchStatusChange(dispatch, STATUS_TYPES.BUSY);

  storageFetch([url, "ls", teacherId, lessonId], "GET")
    .then((serverFiles) => {
      const files = serverFiles.map((file) =>
        createFileObject(file, {
          url,
          source: "server",
          lessonId,
          teacherId
        })
      );

      dispatch({
        type: symbols.LIST_FILES,
        payload: { files }
      });
    })
    .catch(() => {
      // dispatchActionError(dispatch, getState, "list");
      dispatch({
        type: symbols.LIST_FILES_ERROR,
        payload: { files: [] }
      });
    })
    .finally(() => dispatchStatusChange(dispatch, STATUS_TYPES.IDLE));
};

export const deleteFile =
  (file = {}) =>
  (dispatch, getState) => {
    const lessonId = getState().$$lesson.get("id");
    const teacherId = getState().$$layout.getIn(["storageapi_id"]);
    const url = getState().$$layout.getIn(["urls", "attached-files-api"]);

    dispatchStatusChange(dispatch, STATUS_TYPES.BUSY);

    storageFetch([url, teacherId, lessonId, file.name], "DELETE")
      .then(() => {
        dispatch({
          type: symbols.DELETE_FILE,
          payload: { file }
        });
      })
      .catch(() => dispatchActionError(dispatch, getState, "delete"))
      .finally(() => dispatchStatusChange(dispatch, STATUS_TYPES.IDLE));
  };

export const renameFile =
  (file = {}, newBasename = "") =>
  (dispatch, getState) => {
    const url = getState().$$layout.getIn(["urls", "rename-attached-file"]);
    const newFilename = `${newBasename}.${file.extension}`;

    dispatchStatusChange(dispatch, STATUS_TYPES.BUSY);

    const sameNameFileFound =
      getState()
        .$$attachedFiles.get("files")
        .findIndex((f) => f.name === newFilename) > -1;

    if (sameNameFileFound) {
      dispatchActionError(dispatch, getState, "exists");
      return dispatchStatusChange(dispatch, STATUS_TYPES.IDLE);
    }

    const options = {
      old_name: file.name,
      new_name: newFilename
    };

    storageFetch([url], "POST", options)
      .then(() => {
        dispatch({
          type: symbols.RENAME_FILE,
          payload: { file, name: newFilename, basename: newBasename }
        });
      })
      .catch(() => dispatchActionError(dispatch, getState, "rename"))
      .finally(() => dispatchStatusChange(dispatch, STATUS_TYPES.IDLE));
  };

export const uploadFiles =
  (filesToUpload = []) =>
  (dispatch, getState) => {
    const lessonId = getState().$$lesson.get("id");
    const teacherId = getState().$$layout.getIn(["storageapi_id"]);
    const url = getState().$$layout.getIn(["urls", "attached-files-api"]);

    dispatchStatusChange(dispatch, STATUS_TYPES.BUSY);

    const storedFiles = getState().$$attachedFiles.get("files");
    const browserFiles = filesToUpload.map((file) =>
      createFileObject(file, { source: "browser", lessonId, teacherId })
    );
    const sameNameFilesFound = intersectionWith(storedFiles, browserFiles, (a, b) => a.name === b.name).length > 0;

    if (sameNameFilesFound) {
      dispatchActionError(dispatch, getState, "exists");
      return dispatchStatusChange(dispatch, STATUS_TYPES.IDLE);
    }

    Promise.all(
      filesToUpload.map((fileToUpload) => storageFetch([url, "upload", teacherId, lessonId], "PUT", fileToUpload))
    )
      .then(() => {
        dispatch({
          type: symbols.UPLOAD_FILE,
          payload: { files: browserFiles }
        });
      })
      .catch(() => dispatchActionError(dispatch, getState, "upload"))
      .finally(() => dispatchStatusChange(dispatch, STATUS_TYPES.IDLE));
  };

export default {
  listFiles,
  deleteFile,
  renameFile,
  uploadFiles
};
