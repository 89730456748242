import React from "react";
import PropTypes from "prop-types";

import {
  JpgIcon,
  ExcelIcon,
  Mp3Icon,
  PdfIcon,
  PptIcon,
  WaveIcon,
  WordIcon,
} from "app/components/icons";

export default function FileIcon({
  className = "",
  extension = "",
  type = "font",
}) {
  let IconComponent = <SvgFileIcon extension={extension} />;

  if (type === "font") {
    IconComponent = <FontFileIcon extension={extension} />;
  }

  return (
    <span className={`file-icon ${className}`}>
      {IconComponent}
    </span>
  );
}

FileIcon.propTypes = {
  className: PropTypes.string,
  extension: PropTypes.string,
  type: PropTypes.oneOf(["font", "svg"]),
};

FileIcon.defaultProps = {
  className: "",
  extension: "",
  type: "font",
};

const FontFileIcon = ({ extension = "" }) => {
  let icon;

  switch (extension) {
    case "gif":
    case "jpeg":
    case "jpg":
    case "png":
      icon = "image-o";
      break;

    case "pdf":
      icon = "pdf-o";
      break;

    case "doc":
    case "docx":
      icon = "word-o";
      break;

    case "xls":
    case "xlsx":
      icon = "excel-o";
      break;

    case "ppt":
    case "pptx":
      icon = "powerpoint-o";
      break;

    case "flac":
    case "mp3":
    case "wav":
      icon = "audio-o";
      break;

    default:
      icon = "o";
      break;
  }

  return <i className={`fa fa-file-${icon}`} aria-hidden="true"></i>;
};

const SvgFileIcon = ({ extension = "" }) => {
  switch (extension) {
    case "gif":
    case "jpeg":
    case "jpg":
    case "png":
      return <JpgIcon />;

    case "pdf":
      return <PdfIcon />;

    case "doc":
    case "docx":
      return <WordIcon />;

    case "xls":
    case "xlsx":
      return <ExcelIcon />;

    case "ppt":
    case "pptx":
      return <PptIcon />;

    case "wav":
      return <WaveIcon />;

    case "flac":
    case "mp3":
      return <Mp3Icon />;

    default:
      return <JpgIcon />;
  }
};
