import $ from 'jquery';

export const symbols = {
  SUBMIT_START: '@@registration.submit-start',
  HANDLE_RESULT: '@@registration.submit-end',
  HANDLE_ERROR: '@@registration.submit-error',
  SET_SIGNUP_REASON: '@@registration.form-set-signup-reason',
};

export function submitForm(aUrl, key, data, trybundle) {
  return (dispatch, getstate) => {
    const url = (typeof aUrl === 'function') ? aUrl(getstate) : aUrl;

    dispatch({ type: symbols.SUBMIT_START });

    return new Promise((resolve, reject) => {
      $.ajax({
        method: 'POST',
        url: url,
        data: { [key]: data, trybundle: trybundle },
        dataType: 'json',
        success: (result) => {
          dispatch({ type: symbols.HANDLE_RESULT, payload: result });
          resolve();
        },
        error: (xhr, err) => {
          dispatch({ type: symbols.HANDLE_ERROR, payload: err });
          if (xhr.responseJSON) {
            reject(xhr.responseJSON.errors);
          } else {
            reject(xhr.responseText);
          }
        },
      });
    });
  };
}


