import * as React from "react"

const UndoIcon = ({ width = 22, height = 18, opacity = 1, color = '#354751', ...rest }) => {
  return (
    <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg" {...rest} viewBox="0 0 14 17">
      <defs>
      <filter colorInterpolationFilters="auto" id="a">
        <feColorMatrix
          in="SourceGraphic"
          values="0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.000000 0"
        />
      </filter>
    </defs>
    <g
      transform="translate(-191 -20)"
      filter="url(#a)"
      fill="none"
      fillRule="evenodd"
      opacity={opacity}
      strokeLinecap="round"
    >
      <g stroke={color} strokeWidth={1.5}>
        <path
          d="m192 24.398 3.498 3.398M192 24.398 195.498 21"
          strokeLinejoin="round"
        />
        <path d="M199.923 36a6 6 0 0 0 2.319-1.43 5.927 5.927 0 0 0 1.3-6.494 5.95 5.95 0 0 0-5.506-3.678H192" />
      </g>
    </g>
    </svg>
  )
}
UndoIcon.propTypes = {
  width: React.PropTypes.number,
  height: React.PropTypes.number,
};

export default UndoIcon;