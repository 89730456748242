import React from 'react';
import { connect } from 'react-redux';
import { NotificationContainer } from 'react-notifications';
import {UndoRedoModalNotification, UndoRedoSplash} from '../components/undo_redo';
import {ConditionalComponent} from '../components/common';

function getContrastYIQ(hexcolorWithHash) {
  const hexcolor = hexcolorWithHash.substr(1);
  const r = parseInt(hexcolor.substr(0, 2), 16);
  const g = parseInt(hexcolor.substr(2, 2), 16);
  const b = parseInt(hexcolor.substr(4, 2), 16);
  const yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
  return (yiq >= 128) ? 'bg-white' : 'bg-black';
}

function shouldLoadComponent(props) {
  if (props && props.component) {
    if (typeof props.component.then === 'function') {
      return true;
    }
  }
  return false;
}

class UIView extends React.Component {
  constructor(props) {
    super(props);
    this.state = { cls: shouldLoadComponent(props) ? null : props.component };
  }

  componentDidMount() {
    this.loadComponent();
  }

  componentDidUpdate() {
    if (this.state.cls === null) {
      this.loadComponent();
    }
  }

  loadComponent() {
    if (this.props && this.props.component) {
      if (typeof this.props.component.then === 'function') {
        this.props.component.then((module) => {
          this.setState({cls: module.default});
        });
      }
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ cls: shouldLoadComponent(nextProps) ? null : nextProps.component });
  }
  render() {
    const props = this.props;
    const component = this.state.cls;

    return (
      <div className="school-root">
        <ConditionalComponent isRender={props.isDisplaySplash}>
          <UndoRedoSplash/>
        </ConditionalComponent>

        <ConditionalComponent isRender={props.isCourseUpdatedByOtherSession && props.isUndoModalDisplay && props.isAdmin}>
          <UndoRedoModalNotification />
        </ConditionalComponent>

        {component && React.createElement(component)}

        <NotificationContainer />
      </div>
    );
  }
}

function mapStateToProps(state, own) {
  return {
    component: state.$$nav.get('activeView') || own.component,
    bgColor: state.$$layout.get('headerBgColor'),
    isCourseUpdatedByOtherSession: state.undoRedoCableCarChannel.get('isCourseUpdatedByOtherSession'),
    isUndoModalDisplay: state.undoRedoCableCarChannel.get('isModalDisplay'),
    isDisplaySplash: state.undoRedoSplash.get('isDisplaySplash'),
    isAdmin: state.$$account.get('admin')
  };
}

UIView.propTypes = {
  component: React.PropTypes.func,
};

export default connect(mapStateToProps)(UIView);
