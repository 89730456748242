import React from 'react';
import PropTypes from 'prop-types';

const ChevronIcon = ({ className, fillColor = '#333333' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" className={className} width="12" height="6" viewBox="0 0 12 6" fill={fillColor}>
    <path d="M1.70712 5.74895C1.31659 6.08368 0.683422 6.08368 0.292895 5.74895C-0.0976319 5.41421 -0.0976319 4.8715 0.292895 4.53677L5.29293 0.251051C5.68346 -0.0836845 6.31662 -0.0836846 6.70715 0.25105L11.7072 4.53676C12.0977 4.8715 12.0977 5.41421 11.7072 5.74895C11.3167 6.08368 10.6835 6.08368 10.293 5.74895L6.00004 2.06932L1.70712 5.74895Z" fill="#333333"/>
  </svg>
);

ChevronIcon.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string,
};

export default ChevronIcon;
