import React, { useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import Player from '@vimeo/player';

const VimeoPlayer = forwardRef(({ url, ...videoProps }, ref) => {
  const iframeRef = useRef(null);
  const playerRef = useRef(null);
  const { onEnded, onProgress, lessonId, onPause, seekStart, playing } = videoProps;

  useEffect(() => {
    if (iframeRef.current) {
      playerRef.current = new Player(iframeRef.current);

      playerRef.current.on('loaded', () => {
        playerRef.current.setCurrentTime(parseInt(seekStart)).then(() => {
          if (playing) {
            playerRef.current.play();
          } else {
            playerRef.current.pause();
          }
        });

        playerRef.current.on('timeupdate', (data) => {
          onProgress({ lessonId, playedSeconds: data.seconds });
        });

        playerRef.current.on('pause', () => {
          onPause();
        });

        playerRef.current.on('ended', () => {
          onEnded();
        });
      });
    }

    return () => {
      if (playerRef.current) {
        playerRef.current.off('loaded');
        playerRef.current.off('timeupdate');
        playerRef.current.off('pause');
        playerRef.current.off('ended');
      }
    };
  }, [onEnded, onProgress, onPause, lessonId, seekStart]);

  useEffect(() => {
    if (playerRef.current) {
      if (playing) {
        playerRef.current.play();
      } else {
        playerRef.current.pause();
      }
    }
  }, [playing]);

  useImperativeHandle(ref, () => ({
    seekTo: (seconds) => {
      if (playerRef.current) {
        playerRef.current.setCurrentTime(seconds);
      }
    },
  }));

  if (!url) {
    console.error('VimeoPlayer: url is undefined');
    return null;
  }

  const embedUrl = url.includes('player.vimeo.com') ? url : `https://player.vimeo.com/video/${url.split('/').pop()}`;

  return (
    <iframe
      ref={iframeRef}
      src={embedUrl}
      width="640"
      height="360"
      allowFullScreen
      frameBorder="0"
      scrolling="no"
      {...videoProps}
    ></iframe>
  );
});

export default VimeoPlayer;