import Immutable from 'immutable';
import { symbols } from '../../actions/undo_redo/undo_redo_splash';

export const initialState = Immutable.Map({
  isDisplaySplash: false
})

export default function undoRedoSplashReducer(state = initialState, action) {
  switch (action.type) {
    case symbols.UNDO_REDO_SPLASH:
      return state.set('isDisplaySplash', action.payload)
    default:
      return state;
  }
}