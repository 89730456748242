import React from 'react';
import { connect } from 'react-redux';
import { parseISO, format as dateFormat, isValid as dateIsValid } from 'date-fns';

import SiteLogo from '../components/svg/site_logo';

class DripWait extends React.Component {

  static propTypes = {
    drip: React.PropTypes.object.isRequired
  };

  chooseMessage(type, days) {
    let messageKey = 'willBeOpenInADay';

    if (type === 'day' && days > 1) {
      messageKey = 'willBeOpenInFewDays';
    } else if (type === 'date') {
      messageKey = 'willBeOpenOnDate';
    }

    return this.props.i18n[messageKey];
  }

  formatDate(date) {
    let textDate = '00/00/0000';
    let textTime = '00:00';

    const parsedDate = parseISO(date);
    if (dateIsValid(parsedDate)) {
      textDate = dateFormat(parsedDate, 'dd/MM/yyyy');
      textTime = dateFormat(parsedDate, 'HH:mm');
    }

    return { textDate, textTime };
  }

  message() {
    const days = this.props.drip.get('days');
    const dripMessage = this.chooseMessage(this.props.drip.get('type'), days);
    const { textDate, textTime } = this.formatDate(this.props.drip.get('date'));

    return dripMessage
      .replace('%{number_of_days}', days)
      .replace('%{date}', textDate)
      .replace('%{time}', textTime);
  }

  render() {
    return (
      <div className="general-message">
        <SiteLogo />
        <p dangerouslySetInnerHTML={{ __html: this.message() }}></p>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    i18n: state.$$i18n.getIn(['students', 'lesson', 'drip']).toJS()
  };
}

export default connect(mapStateToProps)(DripWait);
