import React from 'react';

export default function PasswordFormLogo({ fillColor }) {
  return (
    <svg
      width="124"
      height="97"
      viewBox="0 0 124 97"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse
        opacity="0.5"
        cx="82.5173"
        cy="40.8932"
        rx="41.1255"
        ry="40.8932"
        fill={fillColor}
        fillOpacity="0.5"
      />
      <path
        d="M45.4268 50.5547H5.89268C3.46667 50.5547 1.5 48.5648 1.5 46.1102V14.9991C1.5 12.5445 3.46667 10.5547 5.89268 10.5547H98.1391C100.565 10.5547 102.532 12.5445 102.532 14.9991V37.3147"
        stroke="#333333"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.0706 21.6641V39.4418"
        stroke="#333333"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.4196 25.6797L11.7261 35.4308"
        stroke="#333333"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.3803 35.4878L11.7614 25.6211"
        stroke="#333333"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M45.4267 21.6641V39.4418"
        stroke="#333333"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M52.7757 25.6797L38.0822 35.4308"
        stroke="#333333"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M52.7364 35.4878L38.1176 25.6211"
        stroke="#333333"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="58.605"
        y="59.4492"
        width="39.5342"
        height="35.5511"
        rx="2.92426"
        stroke="#333333"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <ellipse
        cx="78.3719"
        cy="77.5009"
        rx="5.49086"
        ry="5.55556"
        stroke="#333333"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M89.3536 52.7752C89.3536 46.6387 84.4369 41.6641 78.3718 41.6641C72.3068 41.6641 67.3901 46.6387 67.3901 52.7752V59.4418H89.3536V52.7752Z"
        stroke="#333333"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

