import Immutable from 'immutable';
import { symbols } from '../../actions/lesson/video_player';

export const initialState = Immutable.Map({
  replacingVideo: false,
  currentVideoMark: -1,
  wistia: null,
  playingIntro: false,
});

export default function VideoPlayerReducer(state = initialState, action) {
  switch (action.type) {
    case symbols.REPLACE_VIDEO:
      return state.set('replacingVideo', true);

    case symbols.REPLACE_VIDEO_END:
      return state.merge({ replacingVideo: false, currentVideoMark: -1 });

    case symbols.SET_ACTIVE_MARK:
      return state.set('currentVideoMark', action.payload);

    case symbols.SET_INTERVALS:
      return setNextStateFromIntervals(state, action.payload);

    case symbols.SET_WISTIA_API:
      return state.merge({ 'wistia': action.payload, intervals: [] });

    case symbols.PLAYING_INTRO:
      return state.set('playingIntro', action.payload);

    default:
      return state;
  }
}

function setNextStateFromIntervals(state, payload) {
  const { intervals, watched } = payload;

  let res = state.set('intervals', intervals);

  if (watched.size > 0) {
    let nextPart = 0;

    for (let i = 0; i < intervals.length; i++) {
      const id = intervals[i].id;
      if (watched.get(String(id))) {
        nextPart += 1;
      } else {
        break;
      }
    }

    if ((nextPart > 0) && (nextPart < intervals.length)) {
      res = res.set('currentVideoMark', intervals[nextPart].id);
      const api = state.get('wistia');
      if (api) {
        const second = intervals[nextPart].start;
        api.time(second);
      }
    }
  }

  return res;
}
