import ReactOnRails from 'react-on-rails';
import MainApp from './MainApp';

import Router from 'nighthawk';
import ES6Promises from 'es6-promise';
import { compose, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import modalMiddleware from 'lib/middlewares/modalMiddleware';
import cablecar from 'redux-cablecar';

import { unstable_batchedUpdates as batchedUpdates } from 'react-dom';
import { batchedSubscribe } from 'redux-batched-subscribe';
import jQuery from 'jquery';

window.$ = window.jQuery = jQuery;

jQuery.ajaxSetup({
  headers: { 'X-CSRF-Token': jQuery('meta[name="csrf-token"]').attr('content') },
});

jQuery(document).on("click", ".my-notification .close", (event) => {
  jQuery(event.target).closest(".my-notification").hide();
});

let enhancer;
if (window.livepreview) {
  enhancer = applyMiddleware(thunkMiddleware, modalMiddleware, cablecar);
} else {
  enhancer = applyMiddleware(thunkMiddleware, modalMiddleware);
}

const composedStore = compose(
  enhancer,
  batchedSubscribe(batchedUpdates),
);

ES6Promises.polyfill();
const router = new Router();
const view = MainApp(router, composedStore);

window.navigateTo = router.changeRoute.bind(router);
window.pushRouterHistory = function (url) {
  router.currentLocation = url;
};

router.listen({ dispatch: false });
ReactOnRails.register({ RouterApp: view });
