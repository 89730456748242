import Immutable from 'immutable';
import { symbols } from '../actions/ajax';

export const initialState = Immutable.Map({
  loading: null,
  showSpinner: false,
  inTransition: false,
});

export default function navReducer(state = initialState, action) {
  switch (action.type) {
    case symbols.AJAX_START:
      return state.set('loading', action.payload);

    case symbols.APPSTATE_MERGE:
      return state.set('loading', null).
        set('showSpinner', false).
        set('inTransition', false).
        update('activeView', (view) => action.payload.nextView || view);

    case symbols.SHOW_SPINNER:
      return state.set('showSpinner', true);

    case symbols.PAGE_TRANSITION_START:
      const transitionValue = action.payload && action.payload.transitionValue === true;
      return state.set('inTransition', transitionValue || false);

    case symbols.UPDATE_VIEW:
      return state.set('activeView', action.payload.component);

    default:
      return state;
  }
}
