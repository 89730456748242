import tinycolor from 'tinycolor2';

export function getFgColorFor(color) {
  const t = tinycolor(color);
  if (t.isDark()) {
    return t.complement().lighten(60).toHexString();
  } else {
    return t.complement().darken(60).toHexString();
  }
}

export function getSeparatorColors(bgColor) {
  return getFgColorWithAlpha(0.6);
}

export function getFgColorWithAlpha(bgColor, alpha) {
  return tinycolor(getFgColorFor(bgColor)).setAlpha(alpha).toString();
}

export function getColorWithAlpha(color, alpha) {
  return tinycolor(getFgColorFor(color)).setAlpha(alpha).toString();
}

export function getDarkerBgColor(bgColor) {
  return tinycolor(bgColor).darken().toHexString();
}

export function getLighterBgColor(bgColor) {
  return tinycolor(bgColor).lighten().toHexString();
}

export function getRgbColorWithAlpha(color, alpha) {
  const tColor = tinycolor(color);
  return tColor.setAlpha(alpha).toString();
}

export function getDarkenRgbColor(color, amount) {
  const tColor = tinycolor(color);
  return tColor.setAlpha(amount).darken().toString();
}
