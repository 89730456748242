import React from 'react';
import { isNumber } from 'lodash';

import ConditionalComponent from '../../../components/common/ConditionalComponent';


export default function CoursePrice({
  backgroundColor,
  color,
  i18n,
  isActive,
  isOwned,
  policy,
  price,
  currencyType,
  priceAfterDiscount,
}) {

  let priceBeforeDiscount = 0;
  let priceLabel = price;
  let dataCurrency = '';
  const generalI18n = i18n.getIn(['students', 'general']).toJS();
  const currencySign = currencyType ? currencyType.match(/[^\s()]+/)[0] : '₪';

  if (isOwned && isActive) {
    priceLabel = generalI18n.joined;
  } else if (['free_for_all', 'registration_required'].includes(policy)) {
    priceLabel = generalI18n.freeCourse;
  } else if (policy === 'external_signup') {
    if (priceLabel === 0) {
      priceLabel = generalI18n.externalSignup;
    }
  } else if (isNumber(priceAfterDiscount) && isNumber(price)) {
    if (priceAfterDiscount !== price) {
      priceBeforeDiscount = price;
    }
    priceLabel = priceAfterDiscount;
  }

  if (priceLabel === 0 || priceAfterDiscount === 0) {
    priceLabel = generalI18n.freeCourse;
    dataCurrency = '';
  } else {
    dataCurrency = isNumber(priceLabel) ? currencySign : '';
  }

  return (
    <div className='school-course-price'>
      <ConditionalComponent isRender={priceBeforeDiscount !== 0}>
        <span
          className="school-course-price-before-discount"
          data-currency={currencySign}
          style={{backgroundColor, color}}
        >
          {priceBeforeDiscount}
        </span>
      </ConditionalComponent>

      <span
        className="school-course-price-label"
        data-currency={dataCurrency}
        style={{backgroundColor, color}}>
          {priceLabel}
      </span>
    </div>
  );
}
