import React from "react"

const RefreshIForbiddenIcon = ({ width = 24, height = 24, ...rest }) => {
  return (
     <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}  viewBox="0 0 24 24">
     <g clipPath="url(#a)">
       <path
         d="M4.29 14.142a8 8 0 1 1 2.61 4.024m-2.61-4.024 2.13-1.807m-2.13 1.807-2.517-.856"
         stroke="#fff"
         strokeWidth={2}
         strokeLinecap="round"
         strokeLinejoin="round"
       />
       <path
         fillRule="evenodd"
         clipRule="evenodd"
         d="M13.293 14.707a1 1 0 0 0 1.414-1.414L13.414 12l1.293-1.293a1 1 0 0 0-1.414-1.414L12 10.586l-1.293-1.293a1 1 0 0 0-1.414 1.414L10.586 12l-1.293 1.293a1 1 0 1 0 1.414 1.414L12 13.414l1.293 1.293Z"
         fill="red"
       />
     </g>
     <defs>
       <clipPath id="a">
         <path fill="#fff" d="M0 0h24v24H0z" />
       </clipPath>
     </defs>
   </svg>
  )
}
RefreshIForbiddenIcon.propTypes = {
  width: React.PropTypes.number,
  height: React.PropTypes.number,
};

export default RefreshIForbiddenIcon;