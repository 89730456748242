// a11y tab navigation
const handleFirstTab = ({ key }) => {
  if (key && key.toLowerCase() === "tab") {
    document.body.classList.add("using-tab");
    window.removeEventListener("keydown", handleFirstTab);
    window.addEventListener("mousedown", handleMouseDown);
  }
};

const handleMouseDown = () => {
  document.body.classList.remove("using-tab");
  window.removeEventListener("mousedown", handleMouseDown);
  window.addEventListener("keydown", handleFirstTab);
};

window.addEventListener("keydown", handleFirstTab);
