import React from 'react';
import Slider from 'react-rangeslider';

class VolumeControl extends React.Component {
  constructor (props, context) {
    super(props, context)
    this.state = {
      value: this.props.value,
    }
  }

  handleVolume = (value) => {
    this.setState({
      value: value
    });
    this.props.sendVolumePosition(value);
  };

  render () {
    return (
      <div className='slider-horizontal'>
        <Slider
          min={0}
          max={this.props.max}
          orientation='horizontal'
          tooltip={false}
          value={(this.props.value * this.props.max ) }
          onChange={this.handleVolume }
        />
      </div>
    )
  }
};

export default VolumeControl;

