
import { createSelector } from 'reselect'
import Immutable from 'immutable';

function newLessonData() {
  return Immutable.Map({
    lesson_id: '-1',
  });
}
export const getLessonId = (_, lessonId) => lessonId
export const getLessonName = (_, lessonName) => lessonName
export const getLessonIndex = (_, lessonIndex) => lessonIndex
export const getLessonSectionName = (_, sectionName) => sectionName
export const getLessons = state => state.$$bundletoc.get('lessons');
export const getStore = state => state

export const getLessonById = createSelector(
  getLessons,
  getLessonId,
  (lessons, lessonId) => {
    return lessons.find((lesson) => lesson.get('lesson_id') === lessonId) || newLessonData()
  }
);
export const getLessonByName = createSelector(
  getLessons,
  getLessonName,
  (lessons, lessonName) => {
    return lessons.find((lesson) => lesson.get('lesson_name') === lessonName) || newLessonData()
  }
);
export const getLessonBySectionName = createSelector(
  getLessons,
  getLessonSectionName,
  (lessons, sectionName) => {
    return lessons.find((lesson) => lesson.get('section_name') === sectionName) || newLessonData()
  }
);
export const getLessonIndexById = createSelector(
  getLessons,
  getLessonId,
  (lessons, lessonId) => {
    return lessonId ? lessons.findIndex(lesson => lesson.get('lesson_id') === lessonId) : newLessonData();
  }
);
export const getLessonByIndex = createSelector(
  getLessons,
  getLessonIndex,
  (lessons, lessonIndex) => {
    return lessons.get(lessonIndex) || newLessonData()
  }
);
export const getLessonMetaData = createSelector(
  getStore,
  getLessonById,
  getLessonIndexById,
  ({$$lesson}, lesson, lessonIndex) => {
    const extendedLesson = lesson.merge({
      notes: $$lesson.get('notes'),
      index: lessonIndex,
      isFBFeedDisabled: $$lesson.get('comments_disabled'),
      drip: $$lesson.get('drip'),
    })
    return extendedLesson
  }
);
export const getLessonData = createSelector(
  getStore,
  getLessonById,
  getLessonMetaData,
  ({$$lesson, $$bundletoc, $$theme}, myDataFromToc, lessonMetaData) => {
    return {
      myLessonId: myDataFromToc.get('lesson_id'),
      sectionId: myDataFromToc.get('section_id'),
      lessonMetaData,
      lessonIndex: lessonMetaData.get('index'),
      bundleToc: $$bundletoc,
      skipTabs: !$$lesson.get('showSidebar'),
      lessonName: myDataFromToc.get('lesson_name'),
      duration: myDataFromToc.get('duration'),
      path: myDataFromToc.get('path'),
      open: myDataFromToc.get('open'),
      freeForAll: $$bundletoc.get('free_for_all'),
      isActive: myDataFromToc.get('lesson_id') === $$lesson.get('id'),
      lessonSequence: myDataFromToc.get('lesson_seq'),
      fgColor: $$theme.getIn(['settings', 'text_color'])
   }
  }
);
