import React from "react";

const SkipLink = ({ targetId, label, onClick }) => (
  <a
    href={`#${targetId}`}
    className="skip-link"
    onClick={(event) => onClick(event, targetId)}
  >
    {label}
  </a>
);

const SkipTo = ({ links }) => {
  const handleSkipLinkClick = (event, targetId) => {
    event.preventDefault();
    const targetElement = document.getElementById(targetId);

    if (targetElement) {
      targetElement.tabIndex = 0; // To start tab navigation from here
      targetElement.focus();
    }
  };

  return (
    <nav>
      {Object.keys(links).map((key) => (
        <SkipLink
          key={key}
          targetId={key}
          label={links[key]}
          onClick={handleSkipLinkClick}
        />
      ))}
    </nav>
  );
};

export default SkipTo;
