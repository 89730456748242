import React from "react";

export default function arrowToCourses() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="33"
      viewBox="0 0 32 33"
      fill="none"
    >
      <path
        d="M4.29006 16.708L15.2901 27.708C15.383 27.8017 15.4936 27.8761 15.6155 27.9269C15.7373 27.9777 15.868 28.0038 16.0001 28.0038C16.1321 28.0038 16.2628 27.9777 16.3846 27.9269C16.5065 27.8761 16.6171 27.8017 16.7101 27.708L27.7101 16.708C27.8963 16.5206 28.0009 16.2672 28.0009 16.003C28.0009 15.7388 27.8963 15.4854 27.7101 15.298C27.6171 15.2043 27.5065 15.1299 27.3846 15.0791C27.2628 15.0283 27.1321 15.0022 27.0001 15.0022C26.868 15.0022 26.7373 15.0283 26.6155 15.0791C26.4936 15.1299 26.383 15.2043 26.2901 15.298L16.0001 25.588L5.71006 15.298C5.6171 15.2043 5.5065 15.1299 5.38464 15.0791C5.26278 15.0283 5.13207 15.0022 5.00006 15.0022C4.86805 15.0022 4.73734 15.0283 4.61548 15.0791C4.49362 15.1299 4.38302 15.2043 4.29006 15.298C4.10381 15.4854 3.99927 15.7388 3.99927 16.003C3.99927 16.2672 4.10381 16.5206 4.29006 16.708Z"
        fill="white"
      />
      <path
        d="M15.2901 18.458C15.383 18.5517 15.4936 18.6261 15.6155 18.6769C15.7373 18.7277 15.868 18.7538 16.0001 18.7538C16.1321 18.7538 16.2628 18.7277 16.3846 18.6769C16.5065 18.6261 16.6171 18.5517 16.7101 18.458L27.7101 7.458C27.8963 7.27063 28.0009 7.01718 28.0009 6.753C28.0009 6.48881 27.8963 6.23536 27.7101 6.048C27.6171 5.95427 27.5065 5.87987 27.3846 5.8291C27.2628 5.77834 27.1321 5.7522 27.0001 5.7522C26.868 5.7522 26.7373 5.77834 26.6155 5.8291C26.4936 5.87987 26.383 5.95427 26.2901 6.048L16.0001 16.338L5.71006 6.048C5.6171 5.95427 5.5065 5.87987 5.38464 5.8291C5.26278 5.77834 5.13207 5.7522 5.00006 5.7522C4.86805 5.7522 4.73734 5.77834 4.61548 5.8291C4.49362 5.87987 4.38302 5.95427 4.29006 6.048C4.10381 6.23536 3.99927 6.48881 3.99927 6.753C3.99927 7.01718 4.10381 7.27063 4.29006 7.458L15.2901 18.458Z"
        fill="white"
      />
    </svg>
  );
}
