import * as React from 'react';

const WaveIcon = ({ width = 26, height = 23, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 1000 1000" {...rest}>
      <path d="M193.108 277.718v429.064c0 17.121-13.879 31-31 31s-31-13.879-31-31V277.718c0-17.121 13.879-31 31-31s31 13.879 31 31zm104.112 65.12c-17.121 0-31 13.879-31 31v236.824c0 17.121 13.879 31 31 31s31-13.879 31-31V373.838c0-17.121-13.879-31-31-31zm540.452 0c-17.121 0-31 13.879-31 31v236.824c0 17.121 13.879 31 31 31s31-13.879 31-31V373.838c0-17.121-13.879-31-31-31zM432.333 33.5c-17.121 0-31 13.879-31 31V920c0 17.121 13.879 31 31 31s31-13.879 31-31V64.5c0-17.121-13.879-31-31-31zm135.114 80.001c-17.121 0-31 13.879-31 31V840c0 17.121 13.879 31 31 31s31-13.879 31-31V144.501c0-17.121-13.879-31-31-31zm135.112 81.779c-17.121 0-31 13.879-31 31v531.94c0 17.121 13.879 31 31 31s31-13.879 31-31V226.28c0-17.12-13.879-31-31-31z" />
    </svg>
  );
};

WaveIcon.propTypes = {
  width: React.PropTypes.number,
  height: React.PropTypes.number,
};
export default WaveIcon;
