"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _YouTube = require("./YouTube");

var _SoundCloud = require("./SoundCloud");

var _Vimeo = require("./Vimeo");

var _Facebook = require("./Facebook");

var _Streamable = require("./Streamable");

var _Wistia = require("./Wistia");

var _Twitch = require("./Twitch");

var _DailyMotion = require("./DailyMotion");

var _Mixcloud = require("./Mixcloud");

var _FilePlayer = require("./FilePlayer");

var _default = [_YouTube.YouTube, _SoundCloud.SoundCloud, _Vimeo.Vimeo, _Facebook.Facebook, _Streamable.Streamable, _Wistia.Wistia, _Twitch.Twitch, _DailyMotion.DailyMotion, _Mixcloud.Mixcloud, _FilePlayer.FilePlayer];
exports["default"] = _default;