import React, { PropTypes } from 'react';
import $ from 'jquery';

export default class EditableLabel extends React.Component {
  static propTypes = {
    onCommit: PropTypes.func.isRequired,

    displayValue: PropTypes.string.isRequired,

    // optional
    inputElement: PropTypes.string,
    displayElement: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),

    displayClassName: PropTypes.string,
    editClassName: PropTypes.string,

    editStyle: PropTypes.object,
    displayStyle: PropTypes.object,
  };

  static defaultProps = {
    inputElement: "input",
    displayElement: "span",
    onStartEdit: () => null,
    displayValue: undefined,
    displayClassName: "",
    editClassName: "",
    editStyle: {},
    displayStyle: {},
  };

  constructor(props, ctx) {
    super(props, ctx);
    this.state = {
      isEditing: false,
      value: props.displayValue,
    };
  }

  componentDidUpdate() {
    if (this.refs.input) {
      this.refs.input.focus();
      if (this.props.inputElement === "textarea") {
        $(this.refs.input).height(0).height(this.refs.input.scrollHeight);
      }
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.displayValue !== nextProps.displayValue) {
      this.setState({ value: nextProps.displayValue });
    }
  }

  setValue = (e) => {
    // this.props.onEdit(e.target.value);
    this.setState({ value: e.target.value });
  };

  commitValue = () => {
    this.props.onCommit(this.state.value);
    this.setState({ isEditing: false });
  };

  _handleKeypress = (e) => {
    if (e.key === "Enter") {
      this.commitValue();
    }
  };

  startEditing = (e) => {
    this.setState({ isEditing: true });
    e.stopPropagation();
  };

  selectText = (e) => {
    e.target.select();
  };

  stop = (e) => {
    e.stopPropagation();
  };

  renderEditingWidget() {
    const { inputElement, editClassName, editStyle } = this.props;
    const { value } = this.state;

    return React.createElement(
      inputElement,
      {
        type: "text",
        ref: "input",
        value,
        onChange: this.setValue,
        className: editClassName,
        onBlur: this.commitValue,
        onFocus: this.selectText,
        onKeyPress: this._handleKeypress,
        onClick: this.stop,
        style: editStyle,
      },
      null
    );
  }

  renderDisplayWidget() {
    const { displayValue, displayElement, displayClassName, displayStyle } =
      this.props;

    return React.createElement(
      displayElement,
      {
        className: displayClassName,
        onClick: this.startEditing,
        style: displayStyle,
      },
      displayValue
    );
  }

  render() {
    const { isEditing } = this.state;

    if (isEditing) {
      return this.renderEditingWidget();
    } else {
      return this.renderDisplayWidget();
    }
  }
}
