import Immutable from 'immutable';

export const initialState = Immutable.fromJS({
  name: '',
  teacher_name: '',
});

export default function SchoolReducer(state = initialState, action) {
  switch (action.type) {
    default:
      return state;
  }
}

