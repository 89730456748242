import Immutable from "immutable";
import { symbols } from "app/actions/lesson/attached_files";
import { symbols as navSymbols } from "app/actions/ajax";

export const STATUS_TYPES = {
  IDLE: 0,
  BUSY: 1,
  ERROR: 2
};

export const initialState = Immutable.Map({
  status: STATUS_TYPES.IDLE,
  files: []
});

export default function attachedFilesReducer(state = initialState, action) {
  switch (action.type) {
    case symbols.STATUS_CHANGE:
      return state.set("status", action.payload.status);

    case symbols.DELETE_FILE:
      window.MixpanelService.track("Editor update", { Action: "Delete file" });

      return state.update("files", (files) => files.filter((file) => file.name !== action.payload.file.name));

    case symbols.LIST_FILES:
      return state.set("files", action.payload.files);

    case symbols.LIST_FILES_ERROR:
      return state.set("files", []);

    case symbols.RENAME_FILE:
      window.MixpanelService.track("Editor update", { Action: "Rename file" });

      return state.update("files", (files) =>
        files.map((file) =>
          file.url === action.payload.file.url
            ? {
                ...file,
                name: action.payload.name,
                basename: action.payload.basename
              }
            : file
        )
      );

    case symbols.UPLOAD_FILE:
      window.MixpanelService.track("Editor update", {
        Action: "Upload file" + (action.payload.files.length > 1 && "s")
      });

      return state.update("files", (files) => files.concat(action.payload.files));

    case navSymbols.PAGE_TRANSITION_START:
      return initialState;

    default:
      return state;
  }
}
