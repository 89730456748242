import Immutable from 'immutable';
import { symbols } from '../actions/lesson';
import { symbols as videoPlayerSymbols } from '../actions/lesson/video_player';
import { symbols as navSymbols } from '../actions/ajax';
import { symbols as registrationSymbols } from '../actions/registrations';
import { symbols as teacher } from '../actions/teacher/edit_lesson';
import { symbols as layoutSymbols } from '../actions/layout';
import PlayerReducer from './lesson/video_player';

export const initialState = Immutable.fromJS({
  selectedTab: 2,
  showSidebar: false,
  $$player: Immutable.Map(),
  branches: Immutable.Map(),
  currentContentType: 'video',
  contentTypes: Immutable.Map(),
  drip: Immutable.Map({
    active: false,
    date: '',
    day: '',
    type: 'day',
  }),
  /*
   * enum:
   *  0 - ready to type
   *  1 - sending
   *  2 - sent OK
   *  3 - error
   */
  question: 0,
  lastPostIssueError: '',
});

export default function LessonReducer(state = initialState, action) {
  const nextState = state.set('$$player', PlayerReducer(state.get('$$player'), action));

  switch (action.type) {

    case symbols.SELECT_TAB:
      return nextState.set('selectedTab', action.payload);

    case symbols.SHOW_SIDEBAR:
      return nextState.set('showSidebar', action.payload);

    case navSymbols.PAGE_TRANSITION_START:
      const oldPlayer = state.get('$$player');
      return initialState.merge({ selectedTab: state.get('selectedTab'), '$$player': oldPlayer });

    case layoutSymbols.SHOW_FILES_PANEL:
      return state.set('filesPanel', true);

    case videoPlayerSymbols.WATCH_VIDEO_PART:
      return nextState.setIn(['watched_markers', String(action.payload)], true);

    case registrationSymbols.HANDLE_RESULT:
      return nextState.set('video', Immutable.fromJS(action.payload.intro_video));

    case teacher.START_EDIT:
      return startEdit(nextState, action);

    case teacher.SELECT_VIDEO:
      return nextState.update('video',
        (v) => (v
          ? v
            .set('videoId', action.payload.videoId)
            .set('type', action.payload.videoType)
            .set('duration', action.payload.duration)
          : Immutable.Map({
            videoId: action.payload.videoId,
            type: action.payload.videoType,
          })));

    case teacher.SET_VIDEO_AUTOPLAY:
      return setVideoAutoplay(state, action);

    case teacher.SET_AUDIO_AUTOPLAY:
      return setAudioAutoplay(state, action);

    case teacher.DESTROY_LESSON:
      return action.payload.stayInLesson ? state : initialState;

    case teacher.TOGGLE_FREE:
      return action.payload === state.get('id') ? nextState.update('free', (val) => !val) : nextState;

    case teacher.SET_DRIP:
      return setDrip(nextState, action.payload);

    case teacher.SET_CONTENT_TYPE:
      return setContentType(nextState, action);

    case teacher.COMMIT_IFRAME_SRC:
      return commitIframeSrc(nextState, action);

    case teacher.COMMIT_QUIZ:
      return commitQuiz(nextState, action);

    case teacher.RECEIVED_UPLOADED_FILE_URL:
      return receiveUploadedFileUrl(nextState, action);

    case teacher.RECEIVED_UPLOADED_THUMBNAIL_URL:
      return receiveUploadedThumbnailUrl(nextState, action);

    case teacher.RECEIVED_SAVED_PDF:
      return receiveSavedPDF(nextState, action);

    case teacher.RECEIVED_SAVED_AUDIO:
      return receiveSavedAudio(nextState, action);

    case teacher.RECEIVED_SAVED_THUMBNAIL:
      return receiveSavedThumbnail(nextState, action);

    case teacher.SAVE_LESSON_NOTES_START:
      return startSaveLessonNotes(state, action);

    case teacher.SAVE_LESSON_NOTES_FAIL:
      return tryRevertLessonNotes(state, action);

    case teacher.COMMENTS_STATUS_CHANGED:
      return commentsStatusChanged(state, action);

    default:
      return nextState;
  }
}

function setContentType(state, action) {
  const nextContentType       = action.payload.contentType;
  const currentContentType    = state.get('currentContentType');
  const availableContentTypes = state.get('contentTypes');

  return state.
    set('currentContentType', nextContentType).
    set('contentTypes', availableContentTypes.
      set(currentContentType, false).
      set(nextContentType, true));
}

function startEdit(state, action) {
  const currentContentType = state.get('currentContentType');
  return state.setIn(['contentTypes', currentContentType], true);
}

function commitIframeSrc(state, action) {
  return state.set('iframesrc', action.payload.src);
}

function commitQuiz(state, { payload: { quiz, detailedQuizResults } }) {
  let s = state;
  if (quiz) {
    const selectedIndex = state.get('quizzes').findIndex(theQuiz => theQuiz.get('id') === quiz.get('id'));

    s = s.update(
      'quizzes',
      quizzes => quizzes.map((value, index) => (
        value.set('selected', index === selectedIndex)
      )));
  }

  return s.setIn(['lesson_quiz', 'detailed'], detailedQuizResults);
}

function receiveUploadedFileUrl(state, action) {
  return state.set('pdfurl', action.payload.pdfurl);
}

function receiveUploadedThumbnailUrl(state, action){
  return state.set('audioThumbnail', action.payload.audioThumbnail);
}

function receiveSavedPDF(state, action) {
  const oldLesson = state.toJS();
  const newLesson = Object.assign({}, oldLesson, action.payload);

  return Immutable.fromJS(newLesson);
}

function receiveSavedAudio(state, action) {
  const oldLesson = state.toJS();
  const newLesson = Object.assign({}, oldLesson, action.payload);

  return Immutable.fromJS(newLesson);
}

function receiveSavedThumbnail(state, action) {
  const oldLesson = state.toJS();
  const newLesson = Object.assign({}, oldLesson, action.payload);

  return Immutable.fromJS(newLesson);
}

function startSaveLessonNotes(state, action) {
  return state.set('notes', action.payload);
}

function tryRevertLessonNotes(state, action) {
  return state;
}

function setVideoAutoplay(state, action) {
  if (state.get('video')) {
    return state.setIn(['video', 'autoplay'], action.payload);
  } else {
    return state.set('video', Immutable.fromJS({ 'autoplay': action.payload }));
  }
}

function setAudioAutoplay(state, action) {
  if (state.get('audio')) {
    return state.setIn(['audio', 'autoplay'], action.payload);
  } else {
    return state.set('audio', Immutable.fromJS({ 'autoplay': action.payload }));
  }
}

function setDrip(state, payload) {
  const previousDrip = state.get('drip').toJSON();
  const nextDrip     = Object.assign({}, previousDrip, payload);

  return state.set('drip', Immutable.fromJS(nextDrip));
}

function commentsStatusChanged(state, action) {
  return state.set('comments_disabled', action.payload);
}
