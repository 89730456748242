import React, { PropTypes } from 'react';
import { connect } from 'react-redux';
import Immutable from 'immutable';
import CKEditor from 'lib/components/ckeditor';
import actions from '../../actions/teacher/edit_lesson';
import { eventActions as undoEventActions } from '../../actions/undo_redo/undo_redo';
import Undo from '../../services/undo';
import { getLessonIndexById } from '../../components/bundletoc/selectors/lesson'

class VideoNotes extends React.Component {
  constructor(props, ctx) {
    super(props, ctx);
    this.state = {
      editing: false,
      oldNotes: this.props.notes,
    };
  }

  static propTypes = {
    editable: PropTypes.bool,
    dispatch: PropTypes.func.isRequired,
    notes: PropTypes.string,
    userColors: PropTypes.object,
  }

  startEdit = (ev) => {
    if (!this.props.editable) return;

    this.setState({ editing: true, oldNotes: this.props.notes });
  }

  stopEdit =  (ev) => {
    const {dispatch, lessonData, lessonIndex} = this.props
    const newNotes = this.refs.notes.getData();
    dispatch(actions.saveLessonNotes(newNotes));
    if(newNotes !== this.state.oldNotes){
      window.MixpanelService.track('Editor update', {'Action': 'Lesson text'});
    }
    dispatch(undoEventActions.saveLessonNotes(
      new Undo(lessonIndex, 
               Object.assign({}, lessonData , {notes: this.state.oldNotes}), 
               Object.assign({}, lessonData , {notes: newNotes}))));
    this.setState({ editing: false, oldNotes: newNotes });
  }

  renderContent() {
    const { notes, editable } = this.props;

    if (this.state.editing) {
      return <div className='lesson--content_text'><CKEditor onBlur={this.stopEdit} ref='notes' text={notes} /></div>;
    } else if (notes) {
      return (
        <div
          onClick={this.startEdit}
          dangerouslySetInnerHTML={{ __html: notes }}
          className='lesson--content_text'
        >
        </div>
      );
    } else if (editable) {
      return <p onClick={this.startEdit} className='lesson--content_text'>שיעור זה אינו כולל טקסט מלווה. לחצו כדי לכתוב כאן טקסט חופשי שיופיע מתחת לשיעור</p>;
    } else {
      return false;
    }
  }

  render() {
    return (
      <div className='lesson__video-notes-wrapper'>
        {this.renderContent()}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    notes: state.$$lesson.get('notes'),
    lessonId: state.$$lesson.get('id'),
    lessonIndex: state.$$lesson.get('id') && getLessonIndexById(state, state.$$lesson.get('id')),
  };
}

export default connect(mapStateToProps)(VideoNotes);
