import React, { PropTypes } from 'react';
import ViewLesson from '../lesson';
import { connect } from 'react-redux';
import OfferToBuy from '../../components/offer_to_buy';
import DripWait from '../../components/drip_wait';
import VideoTab from '../../components/lesson/video_tab';
import PdfTab from '../../components/lesson/pdf_tab';
import AudioTab from '../../components/lesson/audio_tab';
import IFrameTab from '../../components/lesson/iframe_tab';
import QuizTab from '../../components/lesson/quiz_tab';
import JoinCourse from '../join_course';
import Login from '../login';

function lessonContent({ contentTypes, isEditing, video, pdfurl, pdfdownloadable, pdfpasswordprotected, pdfprintable, iframesrc, quiz, audioThumbnail }, wrapperProps) {
  if (contentTypes.get('video')) {
    wrapperProps.selectedContentType = 'video';
    return (
      <VideoTab
        item={video}
        isTeacher={isEditing}
      />
    );
  }

  if (contentTypes.get('attachment')) {
    wrapperProps.selectedContentType = 'attachment';
    return (
      <PdfTab
        isTeacher={isEditing}
        pdfdownloadable={pdfdownloadable}
        pdfpasswordprotected={pdfpasswordprotected}
        pdfprintable={pdfprintable}
        pdfurl={pdfurl}
      />
    );
  }

  if (contentTypes.get('audio')) {
    wrapperProps.selectedContentType = 'audioAttachment';
    return (
      <AudioTab
        pdfurl={pdfurl}
        audioThumbnail={audioThumbnail}
        isTeacher={isEditing}
        key={Math.random()}
      />
    );
  }

  if (contentTypes.get('iframe')) {
    wrapperProps.mobileNotes = 'top';
    wrapperProps.internalScroll = false;
    wrapperProps.selectedContentType = 'iframe';

    return (
      <IFrameTab
        iframesrc={iframesrc}
        isTeacher={isEditing}
      />
    );
  }

  if (contentTypes.get('quiz')) {
    wrapperProps.selectedContentType = 'quiz';
    return (
      <QuizTab quiz={quiz} isTeacher={isEditing} />
    );
  }

  return false;
}

class LazyEditLesson extends React.Component {
  constructor(props) {
    super(props);
    this.state = { cls: null };
  }

  componentDidMount() {
    import('app/containers/teacher/edit_lesson.jsx').then((module) => {
      this.setState({ cls: module.default });
    });
  }

  render() {
    return this.state.cls && React.createElement(this.state.cls, this.props, this.props.children);
  }

}

/**
 * Untested refactored for readability version of Lesson component.
 * which is acctually a routing component within a lesson page.
 */
// function LessonSimplified(props) {
//   if (props.accessDenied && props.signedIn && props.drip.get("active")) {
//     return (
//       <ViewLesson>
//         <DripWait drip={props.drip} />
//       </ViewLesson>
//     );
//   }

//   if (props.accessDenied && props.signedIn) {
//     return (
//       <ViewLesson>
//         <OfferToBuy
//           bundleId={props.bundleId}
//           bundleName={props.bundleName}
//           dispatch={props.dispatch}
//           error={props.error}
//           price={props.price}
//         />
//       </ViewLesson>
//     );
//   }

//   if (props.accessDenied && props.canbuy) {
//     return <JoinCourse />;
//   }

//   if (props.accessDenied) {
//     return <Login />;
//   }

//   if (props.isEditing) {
//     return (
//       <LazyEditLesson
//         allowMobile
//         mobileNotes="bottom">
//         {lessonContent(props, { allowMobile: true, mobileNotes: "bottom" })}
//       </LazyEditLesson>
//     );
//   }

//   return (
//     <ViewLesson
//       allowMobile
//       mobileNotes="bottom"
//       lessonContentAvailable>
//       {lessonContent(props, { allowMobile: true, mobileNotes: "bottom" })}
//     </ViewLesson>
//   );
// }

function Lesson(props) {
  const { signedIn, error, price, bundleName, bundleId, dispatch, drip, canbuy } = props;

  if (props.isEditing) {
    const wrapperProps = { allowMobile: true, mobileNotes: 'bottom'};
    const Content = lessonContent(props, wrapperProps);
    return (
      <LazyEditLesson {...wrapperProps}>
        {Content}
      </LazyEditLesson>
    );
  } else if (props.accessDenied) {
    if (signedIn) {
      if (drip.get('active')) {
        return (
          <ViewLesson>
            <DripWait
              drip={drip}
            />
          </ViewLesson>
        );
      } else {
        return (
          <ViewLesson>
            <OfferToBuy
              price={price}
              error={error}
              bundleName={bundleName}
              bundleId={bundleId}
              dispatch={dispatch}
            />
          </ViewLesson>
        );
      }
    } else {
      return (
        canbuy ?  <JoinCourse /> : <Login />
      );
    }
  } else {
    const wrapperProps = { allowMobile: true, mobileNotes: 'bottom', lessonContentAvailable: true};
    const Content = lessonContent(props, wrapperProps);
    return (
      <ViewLesson {...wrapperProps}>
        {Content}
      </ViewLesson>
    );
  }
}

function mapStateToProps(state) {
  return {
    isEditing: state.$$layout.get('edit'),
    signedIn: state.$$account.get('signedIn'),
    accessDenied: state.$$lesson.get('access_denied'),
    error: state.$$layout.get('error'),
    price: state.$$afterjoin.get('bundle_price'),
    bundleName: state.$$afterjoin.get('bundle_name'),
    bundleId: state.$$afterjoin.get('bundle_id'),
    video: state.$$lesson.get('video'),
    contentTypes: state.$$lesson.get('contentTypes'),
    pdfurl: state.$$lesson.get('pdfurl'),
    pdfdownloadable: state.$$lesson.get('pdfdownloadable'),
    pdfpasswordprotected: state.$$lesson.get('pdfpasswordprotected'),
    pdfprintable: state.$$lesson.get('pdfprintable'),
    audioThumbnail: state.$$lesson.get('audioThumbnail'),
    iframesrc: state.$$lesson.get('iframesrc'),
    drip: state.$$lesson.get('drip'),
    quiz: state.teacher.editLesson.get('quizPreview') || state.$$lesson.get('quiz'),
    canbuy: state.$$account.get('canbuy'),
    audioDuration: state.teacher.editLesson.get('audioDuration'),
  };
}

Lesson.propTypes = {
  isEditing: PropTypes.bool,
  accessDenied: PropTypes.bool,
  signedIn: PropTypes.bool,
};

export default connect(mapStateToProps)(Lesson);
