import React, { PropTypes } from 'react';
import cx from 'classnames';
import { assign } from 'lodash';

import { getRgbColorWithAlpha, getDarkenRgbColor } from '../components/helpers/colors';
import ColoredSpan from '../components/helpers/text';
import { isLayoutLTR } from 'lib/helpers';

export default function MobileHeader(props) {
  const { userColors, mobileNavOpen, toggleMobileNav, buyNow, buyNowIcon, signinPath, prevLesson, vimeoOnIosDevice,
    nextLesson, teacherName, videopdf, courseName, signoutPath, bundlesIndexPath, i18n } = props;

  const { color, backgroundColor } = userColors;
  const actionBackgroundColor = getDarkenRgbColor(backgroundColor, 30);

  const getArrowAction = (lessonRoute, actionClassName) => {
    return lessonRoute ?
      (<div className='active-action' style={{ backgroundColor: actionBackgroundColor }}>
        <a href={lessonRoute} className='active-action' style={{ color: actionBackgroundColor }}>
          <i className={`sch-${actionClassName}`} style={{ color }} />
        </a>
      </div>)
      : (<div className='active-action' disabled ><i className={`sch-${actionClassName}`} style={{ color: getRgbColorWithAlpha(color, 0.3) }} /></div>);
  };

  const borderColorWithOpacity = { borderColor: getRgbColorWithAlpha(color, 0.2) };
  const registrationOrBuyStyle = assign({}, borderColorWithOpacity, { backgroundColor: borderColorWithOpacity.borderColor });
  const topPanelHeadingStyle = assign({}, userColors, { borderTopColor: color });
  const buyNowButtonText = buyNowIcon === 'registration' ? i18n.general.registration : i18n.general.purchase;

  return (
    <div className={`hide-desktop header-mobile ${vimeoOnIosDevice ? 'navbar-pos' : ''}`} style={userColors} >

      <div className="header-mobile-top">

        <div className={`nav-opener ${mobileNavOpen ? 'open' : ''}`}>
          <div className={`icon ${mobileNavOpen ? 'sch-close' : 'sch-menu'}`} style={{ color }} onClick={toggleMobileNav}></div>
        </div>

        <ul className={cx('top-panel-menu !justify-end', { 'buy-now-display': !!buyNow })}>
          {!!buyNow &&
            <li className='with-text-icon !w-auto !mx-1'>
              <a href="#" onClick={buyNow} style={registrationOrBuyStyle}>
                <span style={{ color }} className={`icon sch-${buyNowIcon}`} data-testname="mobile-new-user-button"></span>
                <span style={{ color }} dangerouslySetInnerHTML={{ __html: buyNowButtonText }}></span>
              </a>
            </li>
          }
          {bundlesIndexPath &&
            <li className='with-text-icon !w-auto !mx-1'>
              <a href={bundlesIndexPath} style={borderColorWithOpacity}>
                <ColoredSpan fgColor={{ color }} dangerouslySetInnerHTML={{
                  __html: i18n.school.allCourses
                }}></ColoredSpan>
              </a>
            </li>
          }
          {signinPath.length ?
            <li className='!mx-1'>
              <a href={signinPath} style={borderColorWithOpacity}>
                <span style={{ color }}
                  data-testname="mobile-signin-button"
                  dangerouslySetInnerHTML={{ __html: i18n.general.login }}></span>
              </a>
            </li>
            :
            <li className='!mx-1'>
              <a href={signoutPath} style={borderColorWithOpacity}>
                <span style={{ color }}
                  data-testname="mobile-signout-button"
                  dangerouslySetInnerHTML={{ __html: i18n.general.logout }}></span>
              </a>
            </li>
          }
        </ul>
      </div>

      <div className="top-panel-heading" style={topPanelHeadingStyle}>
        {getArrowAction(isLayoutLTR() ? nextLesson : prevLesson, 'arrow-right')}
        <div className="text-wrapper !text-center" >
          <div className="text-ellipsis" style={{ color }}>
            <strong>{courseName}</strong>
          </div>
          <div className="subheading text-ellipsis"
            dangerouslySetInnerHTML={{
              __html: i18n.lesson.misc.by.replace('%{teacher_name}', teacherName)
            }}></div>
        </div>
        {getArrowAction(isLayoutLTR() ? prevLesson : nextLesson, 'arrow-left')}
      </div>

      {videopdf &&
        <a href={videopdf} target="_blank" className="button-circle button-hanging" style={userColors}>
          <span className="icon icon-sheet"></span>
        </a>
      }
    </div>
  );
}
MobileHeader.propTypes = {
  userColors: PropTypes.object,
  mobileNavOpen: PropTypes.bool,
  toggleMobileNav: PropTypes.func,
  buyNow: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.func,
  ]),
  buyNowIcon: PropTypes.string,
  signinPath: PropTypes.string,
  prevLesson: PropTypes.string,
  nextLesson: PropTypes.string,
  teacherName: PropTypes.string,
  videopdf: PropTypes.string,
  courseName: PropTypes.string,
  signoutPath: PropTypes.string,
  bundlesIndexPath: PropTypes.string,
  i18n: PropTypes.object,
};
