import $ from 'jquery';

export const symbols = {
  START_EDIT: '@@theme.start-edit',
  COMMIT: '@@theme.commit',
  CANCEL: '@@theme.cancel-edit',
  SET: '@@theme.set',
};

const actions = {
  startEdit,
  save,
  cancel,
  commit,
  set,
};

export default actions;

function set(options) {
  return { type: symbols.SET, payload: { options } };
}

function startEdit() {
  return {
    type: symbols.START_EDIT,
    meta: {
      modal: {
        cancel: actions.cancel(),
        closedWithTypes: [symbols.CANCEL, symbols.COMMIT],
      },
    },
  };
}

function commit() {
  return { type: symbols.COMMIT };
}

function save() {
  return async (dispatch, getstate) => {
    const url = getstate().$$layout.getIn(['urls', 'update-school-color']);
    try {
      await $.ajax({
        url,
        data: { settings: getstate().$$theme.get('settings').toJS() },
        dataType: 'json',
        type: 'POST',
      });
      dispatch(commit());
    } catch (err) {
      dispatch(cancel());
    }
  };
}

function cancel() {
  return { type: symbols.CANCEL };
}

