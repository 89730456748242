import React, { Component, PropTypes } from 'react';
import { connect } from 'react-redux';

class ColoredSpan extends Component {
  static propTypes = {
    'aria-hidden': PropTypes.bool,
    className: PropTypes.string,
    fgColor: PropTypes.string.isRequired,
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node
    ]),
  };

  static defaultProps = {
    'aria-hidden': false,
    className: '',
    children: null,
  };

  render() {
    const { className, fgColor, children, dispatch, style, ...props } = this.props;
    const styleProps = typeof style === "object" && style !== null ? style : {};
    const cssStyle = { color: fgColor, ...styleProps };

    return (
      <span
        className={`colored-item ${className}`}
        style={cssStyle}
        {...props}
      >
        {children}
      </span>
    );
  }
}

const mapStateToProps = (state) => ({
  fgColor: state.$$theme.getIn(['settings', 'text_color']),
});

export default connect(mapStateToProps)(ColoredSpan);
