import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useState } from "react";

import { getRgbColorWithAlpha } from "app/components/helpers/colors";
import FileIcon from "app/components/lesson/attached_files/file_icon";
import { layoutDirectionSuffix } from "lib/helpers";

function ViewableFilesList({
  enableDropdown = false,
  files = [],
  userColors = {},
}) {
  if (files.length === 0) {
    return null;
  }

  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const onDropdownToggle = () => {
    isDropdownOpen = setDropdownOpen(!isDropdownOpen);
  };
  const componentClassNames = classNames("attached-files--viewable", {
    "attached-files--dropdown": enableDropdown,
    "attached-files--dropdown-open": enableDropdown && isDropdownOpen,
  });

  return (
    <div className={componentClassNames}>
      {enableDropdown && (
        <i
          className="attached-files--dropdown-toggle fa fa-paperclip"
          style={{ color: getRgbColorWithAlpha(userColors.color, 0.5) }}
          onClick={onDropdownToggle}
        />
      )}
      <ul className="attached-files--list">
        {files.map((file, index) => (
          <li key={`file-${index}`} className="attached-files--list-item">
            <a
              className="attached-files--list-item-link"
              href={file.url}
              rel="noopener noreferrer"
              target="_blank"
            >
              <FileIcon
                extension={file.extension}
                className="attached-files--list-item-icon"
                type={enableDropdown ? "svg" : "font"}
              />
              <span className="attached-files--list-item-basename">
                {file.basename}
              </span>
              <i className={`attached-files--list-item-download ${layoutDirectionSuffix('sch-arrow', true)}`}></i>
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
}

ViewableFilesList.propTypes = {
  editable: PropTypes.bool,
  enableDropdown: PropTypes.bool,
  userColors: PropTypes.object,
};

ViewableFilesList.defaultProps = {
  editable: false,
  enableDropdown: false,
  userColors: { color: "" },
};

export default ViewableFilesList;
