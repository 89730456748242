'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _Rangeslider = require('./Rangeslider');

var _Rangeslider2 = _interopRequireDefault(_Rangeslider);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _Rangeslider2.default;