import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';

// import { SearchResultProps, LessonProps, QuickLinkProps } from '../constants/bundletoc.js';

export const SearchResultsProps = ImmutablePropTypes.contains({
});

export const LessonProps = ImmutablePropTypes.contains({
  duration: React.PropTypes.number,
  lesson_id: React.PropTypes.number.isRequired,
  lesson_name: React.PropTypes.string.isRequired,
  open: React.PropTypes.bool,
  path: React.PropTypes.oneOfType([React.PropTypes.string, React.PropTypes.bool]),
  section_id: React.PropTypes.oneOfType([React.PropTypes.string, React.PropTypes.number]).isRequired,
  section_name: React.PropTypes.string,
  watched: React.PropTypes.bool,
});

export const LinkProps = ImmutablePropTypes.contains({
  group_id: React.PropTypes.number.isRequired,
  href: React.PropTypes.string,
  id: React.PropTypes.number,
  link_text: React.PropTypes.string.isRequired,
  text: React.PropTypes.string.isRequired,
});

export const QuickLinkProps = ImmutablePropTypes.contains({
  group: React.PropTypes.string,
  links: ImmutablePropTypes.listOf(LinkProps).isRequired,
});
