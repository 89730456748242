import React from 'react';
import PropTypes from 'prop-types';

const FlashSuccessIcon = ({ width = 19, height = 18, fill = '#32902F' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 19 18" fill="none">
    <path d="M9.03229 0C4.04011 0 0 4.02528 0 9C0 13.9744 4.03973 18 9.03229 18C14.0245 18 18.0646 13.9747 18.0646 9C18.0646 4.02564 14.0248 0 9.03229 0Z" fill={fill} />
  </svg>
);

FlashSuccessIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string,
};

export default FlashSuccessIcon;
