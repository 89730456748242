import React, { PropTypes } from 'react';
import actions from '../../actions/bundletoc';
import { connect } from 'react-redux';
import lessonActions from '../../actions/lesson';
import teacherActions from '../../actions/teacher/edit_lesson';
import ColoredSpan from '../helpers/text';
import Icon from '../helpers/icon';
import classNames from 'classnames';
import { eventActions as undoEventActions } from '../../actions/undo_redo/undo_redo';
import Undo from '../../services/undo';
import { getLessonIndexById } from './selectors/lesson'

function padded(num) {
  return num > 9 ? String(num) : '0' + num;
}

class Lesson extends React.Component {

  static propTypes = {
    dispatch: React.PropTypes.func,
    idx: React.PropTypes.number,

    activeLessonId: PropTypes.number.isRequired,
    myLessonId: PropTypes.number.isRequired,
    lessonName: PropTypes.string.isRequired,
    duration: PropTypes.number,
    path: PropTypes.string,
    open: PropTypes.bool,
    query: PropTypes.object,
    itemClasses: PropTypes.string,
    skipTabs: PropTypes.bool,
    purchased: PropTypes.bool,
    watched: PropTypes.bool,
    isActive: PropTypes.bool.isRequired,
    lessonType: PropTypes.string,
  }

  static defaultProps() {
    return {
      duration: true,
      open: false,
      query: {},
      itemClasses: '',
      skipTabs: true,
      watched: false,
      purchased: false,
    };
  }

  closeSidebar = () => {
    this.props.dispatch(lessonActions.showSidebar(false));
  }

  renderDuration() {
    const { duration } = this.props;
    const hours = Math.floor(duration / 3600).toString().padStart(2, '0');
    const minutes = Math.floor((duration % 3600) / 60).toString().padStart(2, '0');
    const seconds = Math.floor(duration % 60).toString().padStart(2, '0');

    return (
      <span className='duration'>({hours}:{minutes}:{seconds})</span>
    );
  }

  commitLessonName = (val) => {
    const {dispatch, myLessonId, sectionId, lessonSequence, lessonName, lessonIndex} = this.props
    dispatch(teacherActions.commitLessonName(myLessonId, val));
    if(val !== lessonName){
      dispatch(undoEventActions.commitLessonName(
        new Undo(lessonIndex,
          {name: lessonName, lesson_name: val, section_id: sectionId, lesson_seq: lessonSequence},
          {name: val, lesson_name:val, section_id: sectionId, lesson_seq: lessonSequence})));
    }
  }

  markUnread = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.props.dispatch(actions.markUnread(this.props.myLessonId));
  }

  clickLesson = (event) => {
    const targetElement = document.getElementById('main');
    if (targetElement) {
      targetElement.tabIndex = 0;
      targetElement.focus();
    }
  };

  renderLessonItem(isActive) {
    const {
      duration,
      freeForAll,
      lessonName,
      open,
      path,
      preview,
      purchased,
      watched,
      lessonType,
      i18n
    } = this.props;

    let result;
    const showPreviewIcon = open && !freeForAll && !purchased;
    const showWatchedIcon = !showPreviewIcon && watched && !preview && !freeForAll;
    const lessonAriaLabel = i18n.lessonTypeAriaLabel[lessonType]
      ? `${i18n.lessonTypeAriaLabel[lessonType]} ${lessonName}`
      : lessonName;

    if (path) {
      result = (
        <a
          href={path}
          onClick={this.clickLesson}
          className={classNames({
            bright: open,
            'closed-lesson': !showPreviewIcon,
            active: isActive,
            'unpadded-right': showPreviewIcon || showWatchedIcon,
          })}
          aria-label={lessonAriaLabel}
        >
          {showPreviewIcon && <Icon name='preview' className='tablecell' />}
          {showWatchedIcon &&
            <Icon
              name='check'
              className='tablecell icon-watched-lesson'
              onClick={(e) => this.markUnread(e)}
            />
          }
          <ColoredSpan className='lesson caption tablecell'>
            {lessonName}
            {duration && duration ? this.renderDuration() : false}
          </ColoredSpan>
        </a>
      );
    } else {
      result = <ColoredSpan>{lessonName}</ColoredSpan>;
    }
    return result;
  }

  render() {
    const { isActive } = this.props;

    return (
      <li>
        {/* lesson name or path */}
        {this.renderLessonItem(isActive)}
      </li>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const myDataFromToc = state.$$bundletoc.get('lessons').find((l) => l.get('lesson_id') === ownProps.id);

  return {
    activeLessonId: state.$$lesson.get('id') || -1,
    myLessonId: myDataFromToc.get('lesson_id'),
    skipTabs: !state.$$lesson.get('showSidebar'),
    preview: state.$$layout.get('preview'),
    lessonIndex: getLessonIndexById(state, myDataFromToc.get('lesson_id')),
    lessonName: myDataFromToc.get('lesson_name'),
    draftLessonName: state.teacher.editLesson.getIn(['lessonNameData', 'name']),
    duration: myDataFromToc.get('duration'),
    path: myDataFromToc.get('path'),
    open: myDataFromToc.get('open'),
    watched: myDataFromToc.get('watched'),
    isActive: myDataFromToc.get('lesson_id') === state.$$lesson.get('id'),
    sectionId: myDataFromToc.get('section_id'),
    lessonSequence: myDataFromToc.get('lesson_seq'),
    freeForAll: state.$$bundletoc.get('free_for_all'),
    purchased: state.$$layout.getIn(['default_bundle_props', 'owned']),
    lessonType: myDataFromToc.get('type'),
    i18n: state.$$i18n.getIn(['students', 'lesson', 'misc']).toJS()
  };
}

export default connect(mapStateToProps)(Lesson);
