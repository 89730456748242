var zoomLevelDetector = function (matchMedia, currentLevel, minimalLevel, stepDivisor) {
    while (currentLevel >= minimalLevel &&
        !matchMedia("(min-resolution: " + currentLevel / stepDivisor + "dppx)").matches) {
        currentLevel--;
    }
    return currentLevel;
};
var calculatePageZoomLevel = function (win) {
    var mm = win.matchMedia;
    var startLevel = 10;
    var minLevel = 0.1;
    var stepDivisor = 1;
    var level;
    for (var i = 0; i < 4; i++) {
        level = 10 * zoomLevelDetector(mm, startLevel, minLevel, stepDivisor);
        startLevel = level + 9;
        minLevel = level;
        stepDivisor *= 10;
    }
    return level / stepDivisor;
};
function zoomLevel(win) {
    win = win || global.window;
    if (!win) {
        return 1;
    }
    if (typeof win.devicePixelRatio !== "undefined") {
        return win.devicePixelRatio;
    }
    var frames = win.document.frames;
    if (typeof frames !== "undefined") {
        if (typeof frames.devicePixelRatio !== "undefined") {
            return frames.devicePixelRatio;
        }
        return frames.screen.deviceXDPI / frames.screen.systemXDPI;
    }
    if (typeof win.matchMedia !== "undefined") {
        return calculatePageZoomLevel(win);
    }
    return 1;
}
function elementZoomLevel(element, elementStyles, win) {
    elementStyles = elementStyles || getComputedStyle(element);
    // @ts-ignore
    return zoomLevel(win) * (parseFloat(elementStyles.zoom) || 1);
}

export default zoomLevel;
export { elementZoomLevel, zoomLevel };
