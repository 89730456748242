import Immutable from 'immutable';
import { uniqueId } from 'lodash';

import { symbols } from '../actions/bundletoc';
import { symbols as teacher } from '../actions/teacher/edit_lesson';

export const initialState = Immutable.Map({});

let _savedBundleToc;

export default function bundleTocReducer(state = initialState, action) {
  switch (action.type) {
    case symbols.MARK_UNREAD:
      return stateWithLessonWatched(state, action.payload, false);

    case symbols.MARK_READ:
      return markRead(state, action);

    case symbols.RECEIVED_SEARCH_RESULTS:
      return state.set('searchResults', Immutable.fromJS(action.payload));

    case symbols.CLEAR_SEARCH:
      return state.set('searchResults', null);

    case symbols.CREATE_NEW_MODULE:
      return createNewModule(state);

    case symbols.SAVE_MODULE:
      return state;

    case symbols.CANCEL_NEW_MODULE:
      return state;

    case symbols.START_DRAG_CHAPTER:
      return startDragChapter(state, action);

    case symbols.END_DRAG_CHAPTER:
      return endEditBundleToc(state, action);

    case symbols.MARK_COMPLETE:
      return markComplete(state, action);

    case symbols.PARTIAL_COMPLETE:
      return partialComplete(state, action);

    case teacher.COMMIT_LESSON_NAME:
      return renameLesson(state, action);

    case teacher.DESTROY_LESSON:
      return destroyLesson(state, action);

    case teacher.DELETE_SECTION:
      return deleteSection(state, action);

    case teacher.RENAME_SECTION:
      return renameSection(state, action);

    case teacher.CREATE_SECTION:
      return createSection(state, action);

    case teacher.RECEIVED_NEW_BUNDLETOC:
      return Immutable.fromJS(action.payload);

    case teacher.NEW_BUNDLE_IMG:
      return newBundleImage(state, action);

    case teacher.SET_BUNDLE_NAME:
      return setBundleName(state, action);

    case teacher.SET_INSTRUCTOR_DETAILS:
      return setInstructorDetails(state, action);

    case teacher.MOVE_LESSON:
      return moveLesson(state, action);

    case teacher.MOVE_CHAPTER:
      return moveChapter(state, action);

    case teacher.BEGIN_EDIT_BUNDLE_TOC:
      return beginEditBundleToc(state, action);

    case teacher.CANCEL_EDIT_BUNDLE_TOC:
      return cancelEditBundleToc(state, action);

    case teacher.CREATE:
      return createNewLesson(state, action);

    case teacher.PUBLISH_BUNDLE:
      return publishBundle(state, action);

    case teacher.SET_PUBLISHED_ONCE:
      return setPublishedOnce(state, action);

    case teacher.INCREMENT_PUBLISHED_COUNTER:
      return incrementPublishedCounter(state, action);

    default:
      return state;
  }
}

function stateWithLessonWatched(state, lessonId, val) {
  const lessons = state.get('lessons');
  const idx = lessons.findIndex((item) => item.get('lesson_id') === lessonId);
  let s1 = state.setIn(['lessons', idx, 'watched'], val);

  const term = state.get('term');
  if (term) {
    const activeLessons = term.get('lessons');
    const activeLessonIdx = activeLessons.findIndex((item) => item.get('lesson_id') === lessonId);
    s1 = s1.setIn(['term', 'lessons', activeLessonIdx, 'watched'], val);
  }

  return s1;
}

function markComplete(state, params)
{
  return state.set('lesson_logs',params);
}

function partialComplete(state, params)
{
  return state.set('lesson_logs',params);
}

function nextVal(list, key, gap = 1) {
  return Number(list.maxBy((item) => item.get(key)).get(key)) + gap;
}

function createNewModule(state) {
  const lessons = state.get('lessons');
  const nextLessonId  = nextVal(lessons, 'lesson_id');
  const nextSectionId = nextVal(lessons, 'section_id');
  const nextLessonSeq = nextVal(lessons, 'lesson_seq');

  const newState = state.set('lessons', lessons.push(Immutable.fromJS({
    lesson_id: nextLessonId,
    section_id: nextSectionId,
    lesson_name: 'שם השיעור הראשון',
    open: true,
    watched: false,
    section_name: 'מודול חדש',
    duration: 0,
    deliverable: false,
    lesson_seq: nextLessonSeq,
    new: true,
  })));
  return newState;
}

function renameLesson(state, { payload: { id, name } }) {
  const lessons = state.get('lessons');
  const idx = lessons.findIndex((l) => l.get('lesson_id') === id);
  const newState = state.updateIn(['lessons', idx], (lesson) => lesson.set('lesson_name', name));
  return newState;
}

function destroyLesson(state, action) {
  const lessons = state.get('lessons');
  const idx = lessons.findIndex((l) => l.get('lesson_id') === action.payload.id);
  const newState = state.setIn(['lessons', idx, 'DELETE'], true);
  return newState;
}

function renameSection(state, action) {
  return state.update('lessons', (lessons) => (
    lessons.map((lesson) => (
      lesson.get('section_id') === action.payload.sectionId
        ? lesson.set('section_name', action.payload.sectionNewName)
        : lesson
    ))));
}

function createSection(state, action) {
  return state.update('lessons', (lessons) => (
    lessons.map((lesson) => (
      lesson.get('lesson_id') === action.payload.lessonId
        ? lesson.set('section_id', uniqueId('c')).set('section_name', action.payload.sectionName)
        : lesson
    ))));
}

function newBundleImage(state, action) {
  return state.set('image', action.payload);
}

function setBundleName(state, action) {
  return state.set('name', action.payload);
}

function setInstructorDetails(state, { payload: { nameForDisplay } }) {
  return state.set('teacher_name', nameForDisplay);
}

function moveChapter(state, { payload: { sectionId, toLessonIndex: toSectionOrLessonIndex, isUndoRedo } }) {
  const lessons = state.get('lessons');
  const fromStartIdx = lessons.findIndex(lesson => lesson.get('section_id') === sectionId);
  const fromEndIdx = lessons.findLastIndex(lesson => lesson.get('section_id') === sectionId);
  if (fromStartIdx === -1) return state;
  if (fromEndIdx === -1) return state;

  let targetIndex;
  if(!isUndoRedo) {
    if (toSectionOrLessonIndex < fromStartIdx) {
      // move back
      targetIndex = toSectionOrLessonIndex;
    } else if (toSectionOrLessonIndex > fromStartIdx) {
      // move forward
      const targetSectionId = lessons.getIn([toSectionOrLessonIndex, 'section_id']);
      const lastLessonInTargetSection = lessons.findLastIndex(lesson => lesson.get('section_id') === targetSectionId);
      targetIndex = lastLessonInTargetSection + 1;
    } else {
      // stay put
      return state;
    }
  } else {
    let sectionIndex = 0
    let lessonIndex = 0
    let prevSectionId = -1
    lessons.forEach((item, index) => {
      lessonIndex = index
      sectionIndex = item.get('section_id') === prevSectionId ? sectionIndex : ++sectionIndex
      return sectionIndex === toSectionOrLessonIndex ? false : (prevSectionId = item.get('section_id'))
    });
    targetIndex = lessonIndex <= fromStartIdx ? lessonIndex : lessonIndex + 1
  }

  const before = lessons.slice(0, targetIndex).filter(lesson => lesson.get('section_id') !== sectionId);
  const middle = lessons.slice(fromStartIdx, fromEndIdx + 1);
  const after  = lessons.slice(targetIndex).filter(lesson => lesson.get('section_id') !== sectionId);

  let newLessons = before.concat(middle).concat(after);

  newLessons = newLessons.map((lesson, index) => lesson.set('lesson_seq', index + 1));
  return state.set('lessons', newLessons);
}

function moveLesson(state, { payload: { fromId, toIndex, sectionData } }) {
  const lessons = state.get('lessons');
  if (toIndex < 0) return state;
  if (toIndex >= lessons.size) return state;

  const destinationChapterInfo = state.getIn(['lessons', toIndex]) || state.get('lessons').last();

  const fromIndex = lessons.findIndex(lesson => lesson.get('lesson_id') === fromId);
  const item = state.getIn(['lessons', fromIndex]).
    set('lesson_seq', toIndex + 1).
    set('section_name', sectionData ? sectionData.sectionName : destinationChapterInfo.get('section_name')).
    set('section_id', sectionData ? sectionData.sectionId :destinationChapterInfo.get('section_id')).
    //NOTE:UNDO_REDO_LESSON_ORDER_ACTION need for case when reorder lesson to deleted section
    //in that way client mark that lesson should new section.
    //section created on server
    set('UNDO_REDO_LESSON_ORDER_ACTION', sectionData
    && !lessons.some(lesson => lesson.get('section_id') === sectionData.sectionId))
  const newList = state.get('lessons').delete(fromIndex).insert(toIndex, item);
  const newLessons = newList.slice(0, toIndex + 1).
    concat(newList.slice(toIndex + 1).update('lesson_seq', seq => seq + 1));

  return state.set('lessons', newLessons);
}

function beginEditBundleToc(state, action) {
  _savedBundleToc = state;
  return state;
}

function endEditBundleToc(state, action) {
  return state.set('isDraggingChapter', false);
}

function cancelEditBundleToc(state, action) {
  return _savedBundleToc;
}

function startDragChapter(state, action) {
  return state.set('isDraggingChapter', true);
}

function deleteSection(state, { payload: { id } }) {
  return state.update('lessons',
    lessons => lessons.map(
      lesson => (
        (lesson.get('section_id') === id) ? lesson.set('DELETE', true) : lesson
      )
    )
  );
}

function createNewLesson(state, { payload: { sectionId, name } }) {
  const lessons = state.get('lessons');

  const index = 1 + lessons.findLastIndex(lesson => lesson.get('section_id') === sectionId);
  const newLessonData = Immutable.Map({
    name,
    section_id: sectionId
  });
  return state.set('lessons', lessons.insert(index, newLessonData));
}

function publishBundle(state, { payload: { val } }) {
  return state.set('hidden', val);
}

function incrementPublishedCounter(state, { payload: { val } }){
  return state.set('published_counter', val);
}

function setPublishedOnce(state, action) {
  return state.set('published_once', action.payload.val);
}

function markRead(state, action) {
  const lessons = state.get('lessons');

  const lessonIndex = lessons.findIndex(lsn => lsn.get('lesson_id') === action.payload);
  return state.setIn(['lessons', lessonIndex, 'watched'], true)
}
