export default class TOCBuilder {

  lessonsWithSections(lessons) {
    if (this.lessons === lessons) {
      return this.result;
    }
    this.lessons = lessons;
    this.result = [];
    let first = true;
    let prevSectionId = -1;
    let item;
    let sectionIndex = 1;

    for (let i = 0; i < lessons.size; i++) {
      item = lessons.get(i);

      if (item.get('section_id') !== prevSectionId) {
        // start a new section
        this.result.push({
          id: item.get('section_id'),
          initialName: item.get('section_name'),
          first: first,
          index: sectionIndex++,
          firstLessonHref: item.get('path'),
          firstLessonIndex: i,
          lessons: [],
        });
      }
      this.result[this.result.length - 1].lessons.push({
        idx: i,
        id: item.get('lesson_id'),
      });
      prevSectionId = item.get('section_id');
      first = false;
    }
    return this.result;
  }
}
