import React from 'react';
import PropTypes from 'prop-types';

const FlashWarningIcon = ({ width = 19, height = 18, fill = '#F3A120' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 19 18" fill="none">
    <path d="M9.03229 0C4.04011 0 0 4.02528 0 9C0 13.9744 4.03973 18 9.03229 18C14.0245 18 18.0646 13.9747 18.0646 9C18.0646 4.02564 14.0248 0 9.03229 0Z" fill={fill}/>
    <path d="M9.03075 4.58105C9.55 4.58105 9.96692 5.00947 9.95281 5.52853L9.83359 9.91204C9.82178 10.3461 9.46651 10.6919 9.03228 10.6919C8.59827 10.6919 8.24309 10.3464 8.23099 9.9126L8.10871 5.52917C8.09423 5.00987 8.51125 4.58105 9.03075 4.58105ZM9.03255 13.4191C8.7513 13.4191 8.50982 13.3197 8.30812 13.1208C8.10642 12.9191 8.00698 12.6776 8.00982 12.3964C8.00698 12.118 8.10642 11.8794 8.30812 11.6805C8.50982 11.4816 8.7513 11.3822 9.03255 11.3822C9.30244 11.3822 9.53965 11.4816 9.7442 11.6805C9.94875 11.8794 10.0524 12.118 10.0553 12.3964C10.0524 12.5839 10.0027 12.7558 9.90613 12.912C9.81238 13.0654 9.6888 13.189 9.53539 13.2828C9.38198 13.3737 9.21437 13.4191 9.03255 13.4191Z" fill="white"/>
  </svg>
);

FlashWarningIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string,
};

export default FlashWarningIcon;
