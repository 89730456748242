import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';

import SchoolDescription from './SchoolDescription';
import { scrollToView } from '../../../components/helpers/school';
import { StudentTracker } from '../../../services/StudentTracker';
import { useSelector } from 'react-redux';
import CourseBox from '../../../containers/schoolTemplates/common/CourseBox';

export default function BundleContainer({ bundles, i18n, altColor, textColor, templateValues, schoolName }) {
  const [coursesList, setCoursesList] = useState([])
  const [searchTerm, setSearchTerm] = useState('')
  const courseText = templateValues.getIn(['selectCourseText', 'value'])
  const description = templateValues.getIn(['description', 'value'])
  const isSearchDisplay = templateValues.getIn(['isSearchDisplay', 'value']) === '1'
  const { eventProps, ga4DisabledEventsCodes, templateName, layoutLocale } = useSelector(state => ({
    eventProps: state.$$school.get('eventProps'),
    ga4DisabledEventsCodes: state.$$school.get('ga4DisabledEventsCodes'),
    templateName: state.$$school.get('template_name'),
    layoutLocale: state.$$layout.get('layoutLocale'),
  }));

  const getCoursesList = () => {
    const bundlesList = isEmpty(bundles.toJS()) ? [] : bundles.toJS();

    return searchTerm.length ? coursesList : bundlesList;
  }

  const onSearchResults = (term, courses) => {
    setCoursesList(courses)
    setSearchTerm(term)
  }

  useEffect(() => {
    StudentTracker.pageView(
      eventProps,
      ga4DisabledEventsCodes
    );
  }, []);

  return (
    <div className='school-grid' id='courseList'>
      <SchoolDescription
        altColor={altColor}
        courses={bundles.toJS()}
        description={description}
        i18n={i18n}
        isSearchDisplay={isSearchDisplay}
        onSearchResults={onSearchResults}
        selectCourseText={courseText}
        textColor={textColor}
      />

      <div className='school-bundles-container' id={`${templateName}-bundles-tab`}>
        {getCoursesList().map((course, index) => (
          <div className='school-bundles-column' key={`column-${index}`}>
            <CourseBox
              templateName={templateName}
              altColor={altColor}
              course={course}
              i18n={i18n}
              key={`course-box-${index}`}
              schoolName={schoolName}
              textColor={textColor}
              layoutLocale={layoutLocale}
            />
          </div>
        ))}
      </div>
    </div>
  )
}
