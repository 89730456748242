import cartActions from '../actions/cart';
import React, { PropTypes } from 'react';
import { connect } from 'react-redux';

class OfferToBuy extends React.Component {

  static propTypes = {
    bundleName: PropTypes.string.isRequired,
    bundleId: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    dispatch: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.props.dispatch(cartActions.addBundleToCartAndNavigate(this.props.bundleId));
  }

  render() {
    return (
      <p dangerouslySetInnerHTML={{__html: this.props.i18nWaiting}}></p>
    );
  }
}

function mapStateToProps(state) {
  return {
    i18nWaiting: state.$$i18n.getIn(['students', 'cart', 'waiting']),
  };
}

export default connect(mapStateToProps)(OfferToBuy);
