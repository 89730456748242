import $ from 'jquery';
import { debounce } from 'lodash';
import { reportError } from 'app/actions/layout';
import { symbols as navSymbols, enterPage, setLocationToActiveLesson } from 'app/actions/ajax';
import { Providers, getVideoProps } from 'app/components/helpers/video_providers';
import { NotificationManager } from 'react-notifications';
import { getFileApiFromUrl } from 'lib/helpers';
import { computeAudioLength } from 'lib/helpers';
import BaseFileValidator from 'lib/file-validation/base';

export const symbols = {
  START_EDIT: '@@actions-teacher-editlesson-start-edit',
  CANCEL_EDIT: '@@actions-teacher-editlesson-cancel-edit',
  SYNC_DONE: '@@actions-teacher-editlesson-sync',
  CREATE: '@@actions-teacher-editlesson-create',

  COMMIT_LESSON_NAME: '@@actions-teacher-editlesson-commitlessonname',

  SELECT_VIDEO: '@@actions-teacher-editlesson-changevideoid',
  SET_IFRAME_SRC: '@@actions-teacher-editlesson-setiframesrc',
  DESTROY_LESSON: '@@actions-teacher-editlesson-destroy',
  TOGGLE_FREE: '@@actions-teacher-editlesson-togglefree',
  RENAME_SECTION: '@@actions-teacher-editlesson-renamesection',
  CREATE_SECTION: '@@actions-teacher-editlesson-newsection',
  DELETE_SECTION: '@@actions-teacher-deletesection',

  TOGGLE_LINKS_PANEL: '@@actions-teacher-editlesson-togglelinkspanel',

  SET_CONTENT_TYPE: '@@actions-teacher-editlesson-setcontenttype',
  START_SELECT_CONTENT_TYPE: '@@actions-teacher-editlesson-startselectcontenttype',
  END_SELECT_CONTENT_TYPE: '@@actions-teacher-editlesson-endselectcontenttype',
  TRY_SAVE_CONTENT_TYPE: '@@actions-teacher-editlesson-trysavecontenttype',
  SET_VIDEO_URL: '@@actions-teacher-editlesson-setvideourl',
  SET_VIDEO_DURATION: '@@actions-teacher-editlesson-setvideoduration',
  SET_AUDIO_DURATION: '@@actions-teacher-editlesson-setaudioduration',
  SET_VIDEO_AUTOPLAY: '@@actions-teacher-editlesson-setvideoautoplay',
  SET_AUDIO_AUTOPLAY: '@@actions-teacher-editlesson-setaudioautoplay',
  SET_DOWNLOADABLE_PDF: '@@actions-teacher-editlesson-setdownloadablepdf',
  SET_PASSWORD_PROTECTED_PDF: '@@actions-teacher-editlesson-setpasswordprotectedpdf',
  SET_PRINTABLE_PDF: '@@actions-teacher-editlesson-setprintablepdf',
  SET_FILE_FOR_UPLOAD: '@@actions-teacher-editlesson-setfileforupload',
  SET_FILE_FOR_AUDIO_UPLOAD: '@@actions-teacher-editlesson-setfileforaudioupload',
  SET_FILE_FOR_AUDIO_THUMBNAIL_UPLOAD: '@@actions-teacher-editlesson-setfileforaudiothumbnailupload',
  REPORT_SELECT_CONTENT_ERROR: '@@actions-teacher-editlesson-reportselectcontenterror',
  CLEAR_SELECT_CONTENT_ERROR: '@@actions-teacher-editlesson-clearselectcontenterror',
  COMMIT_IFRAME_SRC: '@@actions-teacher-editlesson-commitiframesrc',

  RECEIVED_UPLOADED_FILE_URL: '@@actions-teacher-editlesson-receiveduploadedfileurl',
  RECEIVED_SAVED_PDF: '@@actions-teacher-editlesson-receivedsavedpdf',
  RECEIVED_SAVED_AUDIO: '@@actions-teacher-editlesson-receivedsavedaudio',
  RECEIVED_SAVED_THUMBNAIL: '@@actions-teacher-editlesson-receivedsavedthumbnail',
  RECEIVED_UPLOADED_THUMBNAIL_URL: '@@actions-teacher-editlesson-receiveduploadedthumbnailurl',
  RECEIVED_NEW_BUNDLETOC: '@@actions-teacher-editlesson-receivednewbundletoc',

  SAVE_LESSON_NOTES_START: '@@actions-teacher-editlesson-savelessonnotesstart',
  SAVE_LESSON_NOTES_END: '@@actions-teacher-editlesson-savelessonnotesend',
  SAVE_LESSON_NOTES_FAIL: '@@actions-teacher-editlesson-savelessonnotesfail',

  NEW_BUNDLE_IMG: '@@actions-teacher-editlesson-newbundleimg',
  NEW_SCHOOL_COLOR: '@@actions-teacher-editlesson-new-school-color',

  SET_BUNDLE_NAME: '@@actions-teacher-editlesson-setbundlename',
  SET_INSTRUCTOR_DETAILS: '@@actions-teacher-editlesson-setinstructordetails',
  SET_DRIP: '@@actions-teacher-editlesson-setdrip',

  SHOW_SPINNER: '@@actions-teacher-showspinner',
  FILE_UPLOAD_VALIDITY: '@@actions-teacher-fileuploadvalidity',
  MOVE_LESSON: '@@actions-teacher-editlesson-movelesson',
  MOVE_CHAPTER: '@@actions-teacher-editlesson-movechapter',
  BEGIN_EDIT_BUNDLE_TOC: '@@actions-teacher-editlesson-begineditbundletoc',
  END_EDIT_BUNDLE_TOC: '@@actions-teacher-editlesson-endeditbundletoc',
  CANCEL_EDIT_BUNDLE_TOC: '@@actions-teacher-editlesson-begineditbundletoc',

  SHOW_PUBLISH_BUNDLE_MODAL: '@@actions-teacher-editlesson-showpublishbundlemodal',
  HIDE_PUBLISH_BUNDLE_MODAL: '@@actions-teacher-editlesson-hidepublishbundlemodal',
  SET_PUBLISHED_ONCE: '@@actions-teacher-editlesson-setpublishedonce',
  PUBLISH_BUNDLE: '@@actions-teacher-editlesson-publishbundle',
  SHOW_PUBLISH_BUNDLE_POPUP: '@@actions-teacher-showpublishbundlepopup',
  HIDE_PUBLISH_BUNDLE_POPUP: '@@actions-teacher-hidepublishbundlepopup',
  SHOW_PUBLISH_ERRORS: '@@actions-teacher-showpublisherrors',

  SAVE_INSTRUCTOR_INFO_START: '@@actions-teacher-editlesson-saveinstructorinfo-start',
  SAVE_INSTRUCTOR_INFO_END: '@@actions-teacher-editlesson-saveinstructorinfo-end',
  SAVE_INSTRUCTOR_INFO_ERROR: '@@actions-teacher-editlesson-saveinstructorinfo-error',
  HIDE_EDIT_INSTRUCTOR: '@@actions-teacher-editlesson-canceleditinstructor',
  SHOW_EDIT_INSTRUCTOR: '@@actions-teacher-editinstructor-showeditinstructor',

  SELECT_QUIZ: '@@actions-teacher-selectquiz',
  COMMIT_QUIZ: '@@actions-teacher-commitquiz',
  TOGGLE_DETAILED_QUIZ_RESULTS: '@@actions-teacher-toggledetailedresults',
  RESET_DETAILED_QUIZ_RESULTS: '@@actions-teacher-resetquizresults',

  COMMENTS_STATUS_CHANGED: '@@actions-teacher-commentstatuschanged',
  INCREMENT_PUBLISHED_COUNTER: '@@actions-teacher-editlesson-incrementpublishedcounter',
};

const audioDuration = 7200;

const actions = {
  startEdit,
  cancelEdit: () => ({ type: symbols.CANCEL_EDIT }),
  sync,
  create,
  commitLessonName,
  selectVideo,
  destroy,
  toggleFree,
  renameSection,
  deleteSection,
  moveLesson,
  moveChapter,
  beginEditBundleToc,
  endEditBundleToc: () => ({ type: symbols.END_EDIT_BUNDLE_TOC }),
  saveBundleToc,
  cancelEditBundleToc,
  toggleLinksPanel: () => ({ type: symbols.TOGGLE_LINKS_PANEL }),
  createSection,
  setContentType,
  setIframeSrc: (src) => ({ type: symbols.SET_IFRAME_SRC, payload: { src } }),
  startSelectContentType: () => ({
    type: symbols.START_SELECT_CONTENT_TYPE,
    meta: {
      modal: {
        cancel: actions.endSelectContentType(),
        closedWithTypes: [symbols.END_SELECT_CONTENT_TYPE],
      },
    },
  }),
  endSelectContentType,
  showSpinner,
  fileUploadValidity: (isValid = true) => ({ type: symbols.FILE_UPLOAD_VALIDITY, payload: isValid }),
  trySaveContentType,
  setVideoUrl: (url) => ({ type: symbols.SET_VIDEO_URL, payload: { url } }),
  setVideoDuration: (duration) => ({ type: symbols.SET_VIDEO_DURATION, payload: { duration } }),
  setAudioDuration: (audioDuration) => ({ type: symbols.SET_AUDIO_DURATION, payload: { audioDuration } }),
  setAutoplayVideo: (val) => ({ type: symbols.SET_VIDEO_AUTOPLAY, payload: val }),
  setAutoplayAudio: (val) => ({ type: symbols.SET_AUDIO_AUTOPLAY, payload: val }),
  setDownloadablePDF: (val) => ({ type: symbols.SET_DOWNLOADABLE_PDF, payload: val }),
  setPasswordProtectedPDF: (val) => ({ type: symbols.SET_PASSWORD_PROTECTED_PDF, payload: val }),
  setPrintablePDF: (val) => ({ type: symbols.SET_PRINTABLE_PDF, payload: val }),
  setFileForUpload: (file) => ({ type: symbols.SET_FILE_FOR_UPLOAD, payload: { file } }),
  setFileForAudioUpload: (file) => ({ type: symbols.SET_FILE_FOR_AUDIO_UPLOAD, payload: { file } }),
  setFileForAudioThumbnailUpload: (file) => ({ type: symbols.SET_FILE_FOR_AUDIO_THUMBNAIL_UPLOAD, payload: { file } }),
  reportSelectContentError,
  clearSelectContentError: () => ({ type: symbols.CLEAR_SELECT_CONTENT_ERROR }),
  commitIframeSrc,
  commitSectionName,
  saveLessonNotes,
  setBundleImage,
  changeSchoolColor,
  commitBundleName,
  setDrip,
  publishBundle,
  showPublishBundleModal,
  hidePublishBundleModal,
  setPublishedOnce,
  showEditBundleStatusPopup,
  hideEditBundleStatusPopup,
  saveInstructorInfo,
  cancelEditInstructor,
  showEditInstructor,
  selectQuiz,
  toggleDetailedQuizResults,
  resetDetailedQuizResults,
  setCommentsDisabled,
  doSync,
  updateBundleToc,
  getSectionLessons,
  getLessonFullData,
  undoRedoLessonAttachment,
  undoRedoLessonAudioAttachment,
  incrementPublishBundleCounter,
};

export default actions;

function showSpinner() {
  return { type: symbols.SHOW_SPINNER };
}

function commitBundleName(val) {
  return (dispatch, getstate) => {
    const previousBundleName = getstate().$$bundletoc.getIn(['name']);
    dispatch({ type: symbols.SET_BUNDLE_NAME, payload: val });
    return $.ajax({
      url: getstate().$$layout.getIn(['urls', 'rename-bundle']),
      data: { name: val },
      type: 'POST',
    }).done(function (result) {
    }).fail(function (err) {
      dispatch({ type: symbols.SET_BUNDLE_NAME, payload: previousBundleName });
      dispatch(reportError('Error changing bundle name'));
    });
  };
}

function toggleFree(lessonId) {
  NotificationManager.removeAllNotifications();
  return (dispatch, getstate) => {
    dispatch({ type: symbols.TOGGLE_FREE, payload: lessonId });
    notifyToggleFree(getstate(), lessonId).
      done(function (res) {
        dispatch({ type: symbols.RECEIVED_NEW_BUNDLETOC, payload: res.bundletoc });
      });
  };
}

function setBundleImage(file) {
  return async (dispatch, getstate) => {

    return await new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.addEventListener('load', function () {
        uploadImage(dispatch, getstate, file);
        dispatch({ type: symbols.NEW_BUNDLE_IMG, payload: reader.result });
        resolve(reader.result)
      }, false);
      if (file) {
        reader.readAsDataURL(file);
      }
    })
  };
}

const debouncedAjaxSaveColor = debounce(function (dispatch, getstate, newColor, previousColor) {
  return $.ajax({
    url: getstate().$$layout.getIn(['urls', 'update-school-color']),
    data: { color: newColor },
    type: 'POST',
  }).done(function (result) {
  }).fail(function (err) {
    dispatch({ type: symbols.NEW_SCHOOL_COLOR, payload: previousColor });
    dispatch(reportError('Error changing background color'));
  });
}, 500);

function changeSchoolColor(newColor) {
  return (dispatch, getstate) => {
    const previousColor = getstate().$$layout.getIn(['headerBgColor']);
    dispatch({ type: symbols.NEW_SCHOOL_COLOR, payload: newColor });
    debouncedAjaxSaveColor(dispatch, getstate, newColor, previousColor);
  };
}

function uploadImage(dispatch, getstate, file) {
  const fd = new FormData();
  const previousImage = getstate().$$bundletoc.get('image');

  fd.append('img', file);
  $.ajax({
    url: getstate().$$layout.getIn(['urls', 'update-bundle-image']),
    data: fd,
    cache: false,
    contentType: false,
    processData: false,
    type: 'POST',
  }).done(function (result) {
  }).fail(function (err) {
    dispatch({ type: symbols.NEW_BUNDLE_IMG, payload: previousImage });
    dispatch(reportError('Failed to upload new image'));
  });
}

function notifyToggleFree(state, lessonId) {
  const lessons = state.$$bundletoc.get('lessons');
  const idx = lessons.findIndex((l) => l.get('lesson_id') === lessonId);
  const free = lessons.getIn([idx, 'open']);
  const url = lessons.getIn([idx, 'update_path']);
  return $.ajax({
    method: 'PUT',
    url: url,
    data: JSON.stringify({ free: !free }),
    contentType: 'application/json',
    dataType: 'json',
  });
}

function commitSectionName(sectionId, name) {
  return async (dispatch, getstate) => {
    if (name.length === 0) {
      dispatch(reportError(getstate().$$i18n.getIn(['errors', 'missing_chapter_name'])));
      return;
    }

    const url = getstate().$$layout.getIn(['urls', 'rename_bundle_section']);

    await new Promise((resolve, reject) => {
      $.ajax({
        url,
        type: 'POST',
        dataType: 'json',
        data: { section_id: sectionId, new_name: name },
      }).
        done(async res => {
          await dispatch({ type: symbols.RECEIVED_NEW_BUNDLETOC, payload: res.bundletoc });
          resolve()
        }).
        fail(async () => {
          await dispatch(reportError(getstate().$$i18n.getIn(['errors', 'rename_lesson_unknown_error'])));
          reject(getstate().$$i18n.getIn(['errors', 'rename_lesson_unknown_error']))
        });
    })
  };
}

function commitIframeSrc() {
  return (dispatch, getstate) => {
    const src = getstate().teacher.editLesson.get('iframesrc');
    dispatch({ type: symbols.COMMIT_IFRAME_SRC, payload: { src } });
  };
}

function reportSelectContentError(error) {
  return { type: symbols.REPORT_SELECT_CONTENT_ERROR, payload: { error } };
}

function setContentType(contentType) {
  return { type: symbols.SET_CONTENT_TYPE, payload: { contentType } };
}

function endSelectContentType() {
  return { type: symbols.END_SELECT_CONTENT_TYPE };
}

function trySaveContentType(contentType) {
  return async (dispatch, getstate) => {
    const $$lesson = getstate().$$lesson;
    const editLesson = getstate().teacher.editLesson;
    const lesson_id = $$lesson.get('id');
    const lessonFullData = getstate().$$bundletoc.get('lessons')
      .find(lesson => lesson.get('lesson_id') === lesson_id);

    const commonLessonData = {
      lesson_id,
      section_id: lessonFullData.get('section_id'),
      lesson_name: lessonFullData.get('lesson_name'),
      delete_missing: true,
    }
    if (contentType === 2) {
      const { lesson } = await saveVideoContent(dispatch, getstate);
      return Object.assign({}, commonLessonData, { video: lesson.video });
    } else if (contentType === 3) {
      const savedPDF = await savePDF(dispatch, getstate);

      if (savedPDF) {
        const attachment = editLesson.get('attachment')
          || await getFileApiFromUrl($$lesson.get('pdfurl'));

        return Object.assign({}, commonLessonData, savedPDF, { attachment });
      }

      return undefined;
    } else if (contentType === 4) {
      await saveIFrameContent(dispatch, getstate);
      return Object.assign({}, commonLessonData, { iframesrc: editLesson.get('iframesrc') })
    } else if (contentType === 5) {
      await saveQuiz(dispatch, getstate);
      return Object.assign({}, commonLessonData, { quiz: $$lesson.getIn(['quizzes', '0', 'id']) })
    } else if (contentType === 6) {
      const savedAudio = await uploadAudio(dispatch, getstate);
      if (savedAudio) {
        const audioAttachment = editLesson.get('audioAttachment')
          || await getFileApiFromUrl($$lesson.get('pdfurl'), $$lesson.get('audioContentType'));
        const audioThumbnail = editLesson.get('audioThumbnail')
          || await getFileApiFromUrl($$lesson.get('audioThumbnail'), $$lesson.get('audioThumbnailContentType'));
        const mp3Duration = await computeAudioLength(audioAttachment)

        let autoplayAudio = $$lesson.get('audioAutoplay');
        if (autoplayAudio == null && $$lesson.getIn(['audio', 'autoplay']) == undefined) {
          autoplayAudio = true
        } else if ($$lesson.getIn(['audio', 'autoplay']) !== undefined) {
          autoplayAudio = $$lesson.getIn(['audio', 'autoplay'])
        }

        return Object.assign({}, commonLessonData, savedAudio, { audioAttachment }, { audioThumbnail }, { duration: mp3Duration.duration }, { autoplayAudio: autoplayAudio })
      }

      return undefined;
    } else {
      dispatch(reportSelectContentError('unknown content type'));
    }
  };
}

function _addIfDefined(fd, key, value) {
  if (typeof value === 'undefined') {
    return;
  }

  fd.append(key, value);
}

function _addIfNotNull(fd, key, value) {
  if (value == null) {
    return;
  }
  fd.append(key, value);
}

async function saveVideoContent(dispatch, getstate) {
  const videoUrl = getstate().teacher.editLesson.get('videourl');
  const duration = getstate().teacher.editLesson.get('duration');
  const url = getstate().$$layout.getIn(['urls', 'update-lesson']);
  const { videoId, videoType } = getVideoProps(videoUrl);

  if ((videoType !== Providers.UNKNOWN) && !!duration) {
    dispatch(selectVideo(videoType, videoId, videoUrl, duration));
    dispatch(setContentType('video'));

    const state = getstate();
    const fd = new FormData();
    const currentLessonId = state.$$lesson.get('id');
    const toclessons = state.$$bundletoc.get('lessons');
    const currentLesson = toclessons.find(l => l.get('lesson_id') === currentLessonId);

    fd.append('delete_missing', true);

    _addIfNotNull(fd, 'display_name', currentLesson.get('lesson_name'));
    _addIfNotNull(fd, 'free', currentLesson.get('free'));
    _addIfNotNull(fd, 'drip[date] ', state.$$lesson.getIn(['drip', 'date']));
    _addIfNotNull(fd, 'drip[day] ', state.$$lesson.getIn(['drip', 'day']));
    _addIfNotNull(fd, 'drip[type] ', state.$$lesson.getIn(['drip', 'type']));
    _addIfNotNull(fd, 'notes', state.$$lesson.get('notes'));
    _addIfNotNull(fd, 'section[id]', currentLesson.get('section_id'));
    _addIfNotNull(fd, 'section[name]', currentLesson.get('section_name'));
    _addIfNotNull(fd, 'video[video]', state.$$lesson.getIn(['video', 'videoId']));
    _addIfNotNull(fd, 'video[video_type]', state.$$lesson.getIn(['video', 'type']));
    _addIfNotNull(fd, 'video[autoplay]', state.$$lesson.getIn(['video', 'autoplay']));

    _addIfNotNull(fd, 'video[video_url]', videoUrl);
    _addIfNotNull(fd, 'video[duration]', duration);
    dispatch(showSpinner());

    return await $.ajax({
      url: url,
      cache: false,
      contentType: false,
      processData: false,
      type: 'PUT',
      data: fd,
    }).done(function (appstate) {
      dispatch(endSelectContentType());
      dispatch({ type: navSymbols.APPSTATE_MERGE, payload: appstate });
    }).fail(function (xhr, err) {
      dispatch(reportSelectContentError(getstate().$$i18n.getIn(['errors', 'network_error']) + err));
    });
  } else if (videoType === Providers.UNKNOWN) {
    dispatch(reportSelectContentError(getstate().$$i18n.getIn(['errors', 'unsupported_video'])));
  } else if (!duration) {
    dispatch(reportSelectContentError(getstate().$$i18n.getIn(['errors', 'video_not_yet_detected'])));
  }
}

async function savePDF(dispatch, getstate) {
  const attachFileUrl = getstate().$$layout.getIn(['urls', 'attach_file']);
  const csrfToken = getstate().$$layout.get('authenticityToken');

  const file = getstate().teacher.editLesson.get('attachment');
  const pdfdownloadable = getstate().teacher.editLesson.get('pdfdownloadable');
  const pdfpasswordprotected = getstate().teacher.editLesson.get('pdfpasswordprotected');
  const pdfprintable = getstate().teacher.editLesson.get('pdfprintable');
  const pdfurl = getstate().$$lesson.get('pdfurl');
  const lessonId = getstate().$$lesson.get('id');

  const isPDFDetailsIncomplete = () => {
    let incomplete = true;

    if (
      pdfurl &&
      (typeof pdfdownloadable !== "undefined" ||
        typeof pdfpasswordprotected !== "undefined" ||
        typeof pdfprintable !== "undefined")
    ) {
      incomplete = false;
    }

    if (file) {
      incomplete = false;

      if (!file.type.match('application/pdf')) {
        incomplete = true;
        dispatch(reportSelectContentError(`invalid file type: ${file.type}`));
      }
    }

    return incomplete;
  };

  if (isPDFDetailsIncomplete()) {
    return dispatch(endSelectContentType());
  }

  dispatch(showSpinner());

  const formData = new FormData();
  formData.append('authenticity_token', csrfToken);

  if (lessonId !== undefined) {
    formData.append('lesson[id]', lessonId);
  }

  if (file) {
    formData.append('lesson[attachment]', file, BaseFileValidator.sanitizeFilename(file.name));
  }

  if (typeof pdfdownloadable !== 'undefined') {
    formData.append('lesson[pdf_downloadable]', pdfdownloadable);
  }

  if (typeof pdfpasswordprotected !== "undefined") {
    formData.append("lesson[pdf_passwordprotected]", pdfpasswordprotected);
  }

  if (typeof pdfprintable !== 'undefined') {
    formData.append('lesson[pdf_printable]', pdfprintable);
  }

  return await new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open('POST', attachFileUrl, true);

    xhr.onload = async function () {
      if (xhr.status === 200) {
        const pdfDetails = JSON.parse(xhr.responseText);
        dispatch({ type: symbols.RECEIVED_SAVED_PDF, payload: pdfDetails });
        dispatch(setContentType('attachment'));
        dispatch(endSelectContentType());
        resolve(pdfDetails);
      } else {
        reject(getstate().$$i18n.getIn(['errors', 'unknown_error_saving_file']));
        dispatch(
          reportSelectContentError(getstate().$$i18n.getIn(['errors', 'unknown_error_saving_file']))
        );
      }
    };

    xhr.send(formData);
  });
}

async function uploadAudio(dispatch, getstate) {
  const attachFileUrl = getstate().$$layout.getIn(['urls', 'attach_file']);
  const csrfToken = getstate().$$layout.get('authenticityToken');
  const file = getstate().teacher.editLesson.get('audioAttachment');
  const imageFile = getstate().teacher.editLesson.get('audioThumbnail');
  const pdfurl = getstate().$$lesson.get('pdfurl');
  const lessonId = getstate().$$lesson.get('id');
  const audioThumbnailUrl = getstate().$$lesson.get('audioThumbnail');
  let autoplay = getstate().$$lesson.get('audioAutoplay');

  const isAudioDetailsIncomplete = () => {
    let incomplete = false;

    if (pdfurl && audioThumbnailUrl) {
      incomplete = true;
    }

    if (pdfurl && pdfurl.includes(".pdf?") && audioThumbnailUrl) {
      incomplete = false;
    }

    if (imageFile) {
      incomplete = false;
    }

    if (file) {
      incomplete = false;
      if ((!file.type.match('audio/mpeg')) && (!file.type.match('audio/mp3'))) {
        incomplete = true;
      }
    }

    if (getstate().$$lesson.getIn(['audio', 'autoplay']) !== undefined) {
      incomplete = false;
    }
    return incomplete;
  };

  if (file) {
    if ((!file.type.match('audio/mpeg')) && (!file.type.match('audio/mp3'))) {
      return dispatch(reportSelectContentError(`ניתן להעלות רק קובץ מסוג MP3`));
    }
  };

  if (isAudioDetailsIncomplete()) {
    return dispatch(endSelectContentType());
  }

  dispatch(showSpinner());

  const formData = new FormData();
  formData.append("authenticity_token", csrfToken);

  if (lessonId !== undefined) {
    formData.append("lesson[id]", lessonId);
  }

  if (imageFile && file == undefined && pdfurl == undefined) {
    return dispatch(reportSelectContentError(` אי אפשר לשמור את השיעור ללא קובץ MP3 `));
  }

  if (audioThumbnailUrl && file == undefined && pdfurl == undefined) {
    return dispatch(reportSelectContentError(` אי אפשר לשמור את השיעור ללא קובץ MP3 `));
  }

  if (audioThumbnailUrl && file == undefined && pdfurl.includes(".pdf?")) {
    return dispatch(reportSelectContentError(` אי אפשר לשמור את השיעור ללא קובץ MP3 `));
  }

  // Add the file to the request.
  if (imageFile) {
    if ((!imageFile.type.match('image/gif'))
      && (!imageFile.type.match('image/jpeg'))
      && (!imageFile.type.match('image/png'))
      && (!imageFile.type.match('image/jpg'))) {
      return dispatch(reportSelectContentError(`invalid file type for thumbnail: ${imageFile.type}`));
    }
    else {
      formData.append('lesson[audio_thumbnail]', imageFile, BaseFileValidator.sanitizeFilename(imageFile.name));
    }
  }

  if (file) {
    const mp3Duration = await computeAudioLength(file);
    if (mp3Duration.duration > audioDuration) {
      return dispatch(reportSelectContentError(`ניתן להעלות קובץ באורך של עד שעתיים בלבד`));
    }
    dispatch(actions.setAudioDuration(mp3Duration.duration));
    formData.append('lesson[audio]', file, BaseFileValidator.sanitizeFilename(file.name));
    formData.append('audio_duration', mp3Duration.duration);
  }

  if (autoplay == null && getstate().$$lesson.getIn(['audio', 'autoplay']) == undefined) {
    autoplay = true
  } else if (getstate().$$lesson.getIn(['audio', 'autoplay']) !== undefined) {
    autoplay = getstate().$$lesson.getIn(['audio', 'autoplay'])
  }

  _addIfNotNull(formData, 'audio[autoplay]', autoplay);

  return await new Promise((resolve, reject) => {
    // Set up the request.
    const xhr = new XMLHttpRequest();
    // Open the connection.
    xhr.open('POST', attachFileUrl, true);

    // Set up a handler for when the request finishes.
    xhr.onload = async function () {
      if (xhr.status === 200) {
        // File(s) uploaded.
        const audioDetails = JSON.parse(xhr.responseText);
        if (file) {
          dispatch({ type: symbols.RECEIVED_SAVED_AUDIO, payload: audioDetails });
        }
        if (imageFile) {
          dispatch({ type: symbols.RECEIVED_SAVED_THUMBNAIL, payload: audioDetails });
        }
        dispatch(setContentType('audio'));
        dispatch(endSelectContentType());
        resolve(audioDetails)
      } else {
        reject(getstate().$$i18n.getIn(['errors', 'unknown_error_saving_file']));
        dispatch(
          reportSelectContentError(getstate().$$i18n.getIn(['errors', 'unknown_error_saving_file']))
        );
      }
    };

    // Send the Data.
    xhr.send(formData);
  })
}

async function saveIFrameContent(dispatch, getstate) {
  dispatch(commitIframeSrc());
  dispatch(setContentType('iframe'));
  return await doSync(getstate()).
    done(function (res) {
      dispatch(endSelectContentType());
    }).
    fail(function () {
      return dispatch(reportSelectContentError('network error saving iframe'));
    });
}

async function saveQuiz(dispatch, getstate) {
  dispatch(commitQuiz());
  dispatch(setContentType('quiz'));
  return await doSync(getstate()).
    done(function (res) {
      dispatch(endSelectContentType());
      dispatch({ type: navSymbols.APPSTATE_MERGE, payload: res });
    }).
    fail(function () {
      return dispatch(reportSelectContentError('network error saving iframe'));
    });
}

function selectVideo(videoType, videoId, videoUrl, duration) {
  return { type: symbols.SELECT_VIDEO, payload: { videoType, videoId, videoUrl, duration } };
}

function commitLessonName(id, name) {
  return async (dispatch, getstate) => {
    if (name.length === 0) {
      dispatch(reportError(getstate().$$i18n.getIn(['errors', 'missing_lesson_name'])));
      return;
    }
    await new Promise((resolve, reject) => {
      dispatch({ type: symbols.COMMIT_LESSON_NAME, payload: { id, name } });
      doSync(getstate())
        .then(function (res) {
          document.title = res.title;
          resolve()
        }).fail(function () {
          dispatch(reportError(getstate().$$i18n.getIn(['errors', 'save_lesson_unknown_error'])));
          reject(getstate().$$i18n.getIn(['errors', 'save_lesson_unknown_error']))
        });
    });
  };
}

function startEdit() {
  return { type: symbols.START_EDIT };
}

function create(sectionId, lessonName) {
  return async (dispatch, getstate) => {
    beginEditBundleToc();
    dispatch({
      type: symbols.CREATE,
      payload: {
        sectionId,
        name: lessonName || getNextLessonName(
          getstate().$$bundletoc.get('lessons'),
          getstate().$$i18n.getIn(['students', 'lesson', 'misc']).toJS()
        ),
      }
    });

    try {
      await doSaveBundleToc(dispatch, getstate);

      // find and move to newly created lesson
      const index = getstate().$$bundletoc.get('lessons').findLastIndex(lesson => lesson.get('section_id') === sectionId);

      if (index >= 0) {
        const path = getstate().$$bundletoc.getIn(['lessons', index, 'path'])
        const url = `${path}`;
        return await new Promise((resolve, reject) => {
          dispatch(enterPage(url, function () {
            setLocationToActiveLesson(getstate())
            const lesson = getstate().$$bundletoc.getIn(['lessons', index]);
            resolve({ lesson, index })
          }));
        })
      }
    } catch (err) {
      cancelEditBundleToc();
      dispatch(reportError(getstate().$$i18n.getIn(['errors', 'unknown'])));
    }
  };
}

function createSection(section_name, lessons) {
  return async (dispatch, getstate) => {
    const url = getstate().$$layout.getIn(['urls', 'new-module']);

    const newLessonName = getNextLessonName(
      getstate().$$bundletoc.get('lessons'),
      getstate().$$i18n.getIn(['students', 'lesson', 'misc']).toJS()
    );

    return await new Promise((resolve, reject) => {
      $.ajax({
        url,
        type: 'POST',
        dataType: 'json',
        data: section_name && lessons ? { section_name, lessons } : { lesson_name: newLessonName },
      }).done(async function (res) {
        dispatch({ type: navSymbols.APPSTATE_MERGE, payload: res });
        setLocationToActiveLesson(getstate())
        const lessonIndex = res.bundletoc.lessons.findLastIndex(lesson => lesson.lesson_id === res.lesson.id);
        const lesson = res.bundletoc.lessons.find(lesson => lesson.lesson_id == res.lesson.id);
        const sectionId = lesson.section_id;
        resolve({ sectionId, lessonIndex })
      }).
        fail(function () {
          dispatch(reportError('network error creating new module'));
          reject('network error creating new module')
        });
    })
  };
}

function renameSection(sectionId, sectionNewName) {
  return { type: symbols.RENAME_SECTION, payload: { sectionId, sectionNewName } };
}

function destroy(lessonId) {
  return async (dispatch, getstate) => {
    beginEditBundleToc();
    dispatch({ type: symbols.DESTROY_LESSON, payload: { id: lessonId } });
    const isLastLesson = getstate().$$bundletoc.get('lessons').size === 1;

    if (isLastLesson) {
      cancelEditBundleToc();
      dispatch(reportError(getstate().$$i18n.getIn(['errors', 'last_lesson_error'])));
    }

    try {
      await doSaveBundleToc(dispatch, getstate);
    } catch (err) {
      cancelEditBundleToc();
      dispatch(reportError(getstate().$$i18n.getIn(['client', 'errors',
        isLastLesson ? 'last_lesson_error' : 'reorder_lesson_unknown_error'])));
    }
  };
}

function sync() {
  return (dispatch, getstate) => {
    doSync(getstate()).then(function () {
      // dispatch({ type: symbols.SYNC_DONE });
    });
  };
}

function doSync(state, params = lessonParams(state)) {
  const url = state.$$layout.getIn(['urls', 'update-lesson']);
  return $.ajax({
    method: 'PUT',
    url: url,
    data: JSON.stringify(params),
    contentType: 'application/json',
    dataType: 'json',
  });
}

function lessonParams(state) {
  const currentLessonId = state.$$lesson.get('id');
  const toclessons = state.$$bundletoc.get('lessons');
  const currentLesson = toclessons.find((l) => l.get('lesson_id') === currentLessonId);
  const contentTypes = state.$$lesson.get('contentTypes');

  const res = {
    delete_missing: true,
    display_name: currentLesson.get('lesson_name'),
    free: state.$$lesson.get('free'),
    notes: state.$$lesson.get('notes'),
    drip: state.$$lesson.get('drip').toJSON(),
    section: {
      id: currentLesson.get('section_id'),
      name: currentLesson.get('section_name'),
    },
  };

  if (contentTypes.get('video')) {
    res.video = {
      video: state.$$lesson.getIn(['video', 'videoId']),
      video_type: state.$$lesson.getIn(['video', 'type']),
      duration: state.$$lesson.getIn(['video', 'duration']),
      autoplay: state.$$lesson.getIn(['video', 'autoplay']),
      video_url: state.$$lesson.getIn(['video', 'videoUrl']),
    };
  }

  if (contentTypes.get('iframe')) {
    res.iframesrc = state.$$lesson.get('iframesrc');
  }

  if (contentTypes.get('quiz')) {
    res.quiz = state.$$lesson.get('quizzes').find(quiz => !!quiz.get('selected')).get('id');
    res.detailedQuizResults = state.$$lesson.getIn(['lesson_quiz', 'detailed']);
  }

  return res;
}

function chaptersParams(chapters) {
  return chapters.map((chap, index) => ({
    title: chap.get('title'),
    text: chap.get('text'),
    order: index,
  })).toJS();
}

function saveLessonNotes(newNotes) {
  return (dispatch, getstate) => {
    const state = getstate();
    const params = {
      notes: newNotes,
    };
    dispatch({ type: symbols.SAVE_LESSON_NOTES_START, payload: newNotes });
    return doSync(state, params).
      then(function () {
        dispatch({ type: symbols.SAVE_LESSON_NOTES_END });
      }).
      fail(function (err) {
        reportError('Failed to update lesson notes');
        dispatch({ type: symbols.SAVE_LESSON_NOTES_FAIL, payload: err });
      });
  };
}

function setDrip(dripParams) {
  return async (dispatch, getstate) => {
    const state = getstate();
    const previousDrip = state.$$lesson.get('drip').toJSON();

    try {
      dispatch({ type: symbols.SET_DRIP, payload: dripParams });
      await doSync(state, { drip: dripParams });
    } catch (err) {
      reportError('Failed to update lesson drip');
      dispatch({ type: symbols.SET_DRIP, payload: previousDrip });
    }
  };
}

function moveLesson(fromId, toIndex, sectionData = 0) {
  return { type: symbols.MOVE_LESSON, payload: { fromId, toIndex, sectionData } };
}

function moveChapter(sectionId, toLessonIndex, isUndoRedo = false) {
  return { type: symbols.MOVE_CHAPTER, payload: { sectionId, toLessonIndex, isUndoRedo } };
}

function saveBundleToc() {
  return async (dispatch, getstate) => {
    try {
      await doSaveBundleToc(dispatch, getstate);
    } catch (err) {
      dispatch(reportError(getstate().$$i18n.getIn(['errors', 'reorder_lesson_unknown_error'])));
    }
  };
}

async function doSaveBundleToc(dispatch, getstate) {
  const appstate = await updateBundleToc(getstate());
  dispatch({ type: symbols.END_EDIT_BUNDLE_TOC });
  dispatch({ type: navSymbols.APPSTATE_MERGE, payload: appstate });
  setLocationToActiveLesson(getstate());
}

function updateBundleToc(state) {
  const url = state.$$layout.getIn(['urls', 'update-bundletoc']);
  const bundletoc = state.$$bundletoc.toJS();

  return $.ajax({
    method: 'POST',
    url: url,
    data: JSON.stringify({ bundletoc }),
    contentType: 'application/json',
    dataType: 'json',
  });
}


function getSectionLessons(section_id) {
  return async (dispatch, getstate) => {

    const url = getstate().$$layout.getIn(['urls', 'get_section_lessons']);

    try {
      const { lessons } = await $.get(url, { section_id })
      return await Promise.all(lessons.map(async lesson =>
        Object.assign(lesson, {
          ...(lesson.pdfurl && { attachment: await getFileApiFromUrl(lesson.pdfurl) })
        })))
    } catch (err) {
      console.error(err)
    }
  }
}

function getLessonFullData(lesson_id) {
  return async (dispatch, getstate) => {

    const url = getstate().$$layout.getIn(['urls', 'get_lesson_full_data']);
    return $.get(url, { lesson_id }, async ({ lesson }) => {
      if (lesson.pdfurl && lesson.pdfurl.includes(".pdf")) {
        return Object.assign(lesson, { ...(lesson.pdfurl && { attachment: await getFileApiFromUrl(lesson.pdfurl) }) })
      }
    })
  }
}

function beginEditBundleToc() {
  return { type: symbols.BEGIN_EDIT_BUNDLE_TOC };
}

function cancelEditBundleToc() {
  return { type: symbols.CANCEL_EDIT_BUNDLE_TOC };
}

function deleteSection(sectionId) {
  return async (dispatch, getstate) => {
    await dispatch({ type: symbols.DELETE_SECTION, payload: { id: sectionId } });
  }
}

function getNextLessonName(lessons, i18n) {
  const lessonNumberRegex = new RegExp(i18n.lessonName.replace('%{lesson_number}', '(\\d+)'));

  const maxLessonNumber = lessons.map(lesson => {
    const match = lesson.get('lesson_name').match(lessonNumberRegex);
    return match ? Number(match[1]) : 0;
  }).max();

  return i18n.lessonName.replace('%{lesson_number}', maxLessonNumber + 1);
}

function publishBundle(publish) {
  return async (dispatch, getstate) => {
    const url = getstate().$$layout.getIn(['urls', 'update_bundle']);
    try {
      await $.post(url, { hidden: !publish });
      dispatch({ type: symbols.PUBLISH_BUNDLE, payload: { val: !publish } });
    } catch (err) {
      const errors = convertErrorsToString(err.responseJSON.errors, getstate().$$i18n.getIn(['client', 'errors', 'attributes']));
      dispatch({ type: symbols.SHOW_PUBLISH_ERRORS, payload: { val: errors } });
    }
  };
}

function incrementPublishBundleCounter(counter) {
  return async (dispatch, getstate) => {
    dispatch({ type: symbols.INCREMENT_PUBLISHED_COUNTER, payload: { val: counter } });
  };
}

function showPublishBundleModal() {
  return async (dispatch, getstate) => {
    dispatch({ type: symbols.SHOW_PUBLISH_BUNDLE_MODAL });
  };
}

function hidePublishBundleModal() {
  return async (dispatch, getstate) => {
    dispatch({ type: symbols.HIDE_PUBLISH_BUNDLE_MODAL });
  };
}

function setPublishedOnce(val) {
  return async (dispatch, getstate) => {
    dispatch({ type: symbols.SET_PUBLISHED_ONCE, payload: { val: val } });
  };
}

function convertErrorsToString(errors, i18n) {
  let str = '';
  Object.keys(errors).forEach(function (key) {
    const key_heb = i18n.getIn([key, 'key']);
    const error_heb = i18n.getIn([key, 'error']);
    str += error_heb ? error_heb : ' ' + key_heb + ' ' + errors[key];
  })

  return str;
}

function showEditBundleStatusPopup() {
  return {
    type: symbols.SHOW_PUBLISH_BUNDLE_POPUP,
    meta: {
      modal: {
        cancel: hideEditBundleStatusPopup(),
        closedWithTypes: [symbols.HIDE_PUBLISH_BUNDLE_POPUP],
      },
    },
  };
}

function hideEditBundleStatusPopup() {
  return { type: symbols.HIDE_PUBLISH_BUNDLE_POPUP };
}

function saveInstructorInfo(data) {
  return async (dispatch, getstate) => {
    const url = getstate().$$layout.getIn(['urls', 'rename-teacher']);
    const params = {};

    if (data.nameForEmail) {
      data.nameForEmail = data.nameForEmail.replace('"', '״');
      params.name_for_email = data.nameForEmail;
    }

    if (data.nameForDisplay) params.name_for_display = data.nameForDisplay;
    if (data.email) params.email = data.email;

    try {
      await $.ajax({
        url,
        data: params,
        type: 'POST',
      });
      dispatch({ type: symbols.HIDE_EDIT_INSTRUCTOR });
      if (data.nameForDisplay) {
        dispatch({ type: symbols.SET_INSTRUCTOR_DETAILS, payload: data });
      }
    } catch (err) {
      dispatch(reportError(getstate().$$i18n.getIn(['errors', 'invalid_instructor_details'])));
    }
  };
}

function cancelEditInstructor() {
  return { type: symbols.HIDE_EDIT_INSTRUCTOR };
}

function showEditInstructor() {
  return {
    type: symbols.SHOW_EDIT_INSTRUCTOR,
    meta: {
      modal: {
        cancel: cancelEditInstructor(),
        closedWithTypes: [symbols.HIDE_EDIT_INSTRUCTOR],
      },
    },
  };
}

function selectQuiz(quiz) {
  return async (dispatch, getstate) => {
    const url = getstate().$$layout.getIn(['urls', 'quiz_for_user']);
    const quizForUser = await $.getJSON(url, { id: quiz.get('id') });
    dispatch({ type: symbols.SELECT_QUIZ, payload: { quiz, quizForUser } });
  };
}

function commitQuiz() {
  return (dispatch, getstate) => {
    const quiz = getstate().teacher.editLesson.get('quiz');
    const detailedQuizResults = getstate().teacher.editLesson.get('detailedQuizResults');
    dispatch({ type: symbols.COMMIT_QUIZ, payload: { quiz, detailedQuizResults } });
  };
}

function resetDetailedQuizResults() {
  return (dispatch, getstate) => {
    const val = getstate().$$lesson.getIn(['lesson_quiz', 'detailed']);
    dispatch({ type: symbols.RESET_DETAILED_QUIZ_RESULTS, payload: val });
  };
}

function toggleDetailedQuizResults() {
  return { type: symbols.TOGGLE_DETAILED_QUIZ_RESULTS };
}

function setCommentsDisabled(isDisabled) {
  return (dispatch, getstate) => {
    const updateUrl = getstate().$$layout.getIn(['urls', 'update-comments']);
    $.post(updateUrl, { disabled: isDisabled }).then((res) => {
      dispatch({ type: symbols.COMMENTS_STATUS_CHANGED, payload: res.disabled });
    });
  };
}
function undoRedoLessonAttachment(attachment, lessonId) {
  return async (dispatch, getstate) => {

    const attachFileUrl = getstate().$$layout.getIn(['urls', 'attach_file']);

    const formData = new FormData();
    formData.append('lesson[attachment]', attachment)
    formData.append('lesson[id]', lessonId || getstate().$$lesson.get('id'))
    formData.append('lesson[is_restore_mode]', 'create')
    formData.append('authenticity_token', getstate().$$layout.get('authenticityToken'));

    await fetch(attachFileUrl, { method: 'POST', body: formData })
      .then((response) => response.json())
      .then((result) => {
        dispatch({ type: symbols.RECEIVED_UPLOADED_FILE_URL, payload: result });
        dispatch(setContentType('attachment'));
        dispatch(actions.setFileForUpload(attachment))
        dispatch(endSelectContentType());
      })
      .catch(() => {
        return dispatch(reportSelectContentError(getstate().$$i18n.getIn(['errors', 'unknown_error_saving_file'])));
      })
  }
}

function undoRedoLessonAudioAttachment(attachment, lessonId, duration, imageFile, autoplay) {
  return async (dispatch, getstate) => {

    const attachFileUrl = getstate().$$layout.getIn(['urls', 'attach_file']);
    const formData = new FormData();
    formData.append('lesson[audio]', attachment)
    formData.append('audio_duration', duration);
    if (autoplay) {
      formData.append('audio[autoplay]', autoplay);
    }
    formData.append('lesson[id]', lessonId || getstate().$$lesson.get('id'))
    if (imageFile) {
      try {
        formData.append('lesson[audio_thumbnail]', imageFile, imageFile.name);
      }
      catch (err) {
        console.log(err)
      }
    }
    formData.append('lesson[is_restore_mode]', 'create')
    formData.append('authenticity_token', getstate().$$layout.get('authenticityToken'));

    await fetch(attachFileUrl, { method: 'POST', body: formData })
      .then((response) => response.json())
      .then((result) => {
        dispatch({ type: symbols.RECEIVED_UPLOADED_FILE_URL, payload: result });
        dispatch(setContentType('audio'));
        dispatch(actions.setFileForAudioUpload(attachment))
        dispatch(actions.setAudioDuration(duration));
        if (autoplay) {
          dispatch(actions.setAutoplayAudio(autoplay));
        }
        if (imageFile) {
          dispatch({ type: symbols.RECEIVED_UPLOADED_THUMBNAIL_URL, payload: result });
          dispatch(actions.setFileForAudioThumbnailUpload(imageFile))
        }
        dispatch(endSelectContentType());
      })
      .catch(() => {
        return dispatch(reportSelectContentError(getstate().$$i18n.getIn(['errors', 'unknown_error_saving_file'])));
      })
  }
}
