import React from 'react';

export default function siteLogo() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 124 62" className="site-logo">
      <path fill="#2e4b4f" d="M14.4 49.3H12c0-1.1-.2-1.8-.7-2.1-.5-.3-1.6-.4-3.5-.4-1.8 0-2.9.1-3.3.4-.5.3-.7 1-.7 2 0 1.6.8 2.5 2.4 2.6l1.9.1 2.4.1c2.9.1 4.4 1.7 4.4 4.5 0 1.8-.5 3-1.4 3.7s-2.7 1-5.3 1c-2.6 0-4.5-.3-5.5-.9-1-.6-1.5-1.8-1.5-3.5v-.9h2.4v.7c0 1 .3 1.7.8 2.1.5.3 1.6.5 3.2.5 1.9 0 3.2-.2 3.8-.5.6-.4.9-1.1.9-2.3 0-1.7-.8-2.5-2.3-2.5-3.6 0-5.9-.3-7.1-.9-1.1-.6-1.7-1.8-1.7-3.7 0-1.8.4-3 1.3-3.6.9-.6 2.7-.9 5.3-.9 4.3 0 6.4 1.3 6.4 3.8v.7zM29.3 55.3h2.4v.9c0 3.4-2.3 5.1-6.8 5.1-2.9 0-4.8-.5-5.7-1.6s-1.4-3.3-1.4-6.6c0-3.1.5-5.3 1.4-6.4.9-1.1 2.8-1.7 5.4-1.7 2.6 0 4.4.4 5.4 1.1 1 .8 1.4 2.2 1.4 4.3H29V50c0-1.2-.3-2.1-.9-2.5-.6-.4-1.8-.6-3.7-.6-1.8 0-2.9.4-3.4 1.2-.5.8-.8 2.5-.8 5.2 0 2.6.3 4.3.9 5 .6.7 1.9 1.1 4.1 1.1 1.8 0 2.9-.2 3.4-.7.5-.6.7-1.7.7-3.4zM37.4 38.3v8.8h.1c.6-1.5 2.3-2.2 5-2.2 4 0 6.1 1.8 6.1 5.3V61h-2.4V50.4c0-2.4-1.2-3.6-3.7-3.6-2 0-3.3.4-4 1.1-.7.7-1 2.1-1 4.2V61H35V38.3h2.4zM59.4 44.9c3 0 4.9.5 5.8 1.5.9 1 1.3 3.2 1.3 6.6 0 3.4-.4 5.6-1.3 6.6s-2.8 1.5-5.8 1.5-4.9-.5-5.8-1.5-1.3-3.2-1.3-6.6c0-3.4.4-5.6 1.3-6.6s2.8-1.5 5.8-1.5zm0 1.9c-2.1 0-3.4.3-3.9 1s-.8 2.4-.8 5.2.2 4.6.8 5.2 1.8 1 3.9 1c2.1 0 3.4-.3 3.9-1s.8-2.4.8-5.2-.2-4.6-.8-5.2c-.5-.7-1.8-1-3.9-1zM76.9 44.9c3 0 4.9.5 5.8 1.5S84 49.6 84 53c0 3.4-.4 5.6-1.3 6.6s-2.8 1.5-5.8 1.5-4.9-.5-5.8-1.5-1.3-3.2-1.3-6.6c0-3.4.4-5.6 1.3-6.6s2.8-1.5 5.8-1.5zm0 1.9c-2.1 0-3.4.3-3.9 1s-.8 2.4-.8 5.2.2 4.6.8 5.2 1.8 1 3.9 1c2.1 0 3.4-.3 3.9-1s.8-2.4.8-5.2-.2-4.6-.8-5.2c-.5-.7-1.8-1-3.9-1zM90 38.3V61h-2.4V38.3H90zM105.4 56.2h2.4v.6c0 1.7-.5 2.8-1.5 3.5-1 .7-2.8 1-5.4 1-3 0-4.9-.5-5.8-1.6-.9-1.1-1.4-3.3-1.4-6.7 0-3.2.5-5.3 1.4-6.4s2.8-1.6 5.5-1.6c3 0 4.9.5 5.8 1.4.9.9 1.4 2.9 1.4 6v1.2H96.2c0 2.5.3 4.1.8 4.8.5.7 1.8 1 3.8 1 1.9 0 3.1-.2 3.7-.5.6-.3.9-1 .9-2.1v-.6zm0-4.5V51c0-1.7-.3-2.8-.9-3.3s-1.8-.8-3.7-.8c-1.9 0-3.1.3-3.7.9-.6.6-.9 1.9-.9 4h9.2zM111.7 45.1h2.4l-.2 1.8h.1c.9-1.5 2.5-2.2 4.6-2.2 3 0 4.5 1.5 4.5 4.5v1.1h-2.3v-.4c0-.4.1-.7.1-.8 0-1.6-.9-2.4-2.7-2.4-2.6 0-3.9 1.6-3.9 4.8V61h-2.4V45.1z" />
      <path fill="#0cb8d0" d="m81 1-18.8 9.1.5 1.3 18.8-9.1L81 1zm1.1 2.9L63.2 13 72 36.7l17.6-12.5-7.5-20.3z" />
      <path fill="#03d6f3" d="m60.7 13.9-20.3 5L48 39.2l21.6-1.6-8.9-23.7zM59.6 11l-20.3 5 .5 1.3 20.3-5-.5-1.3z" />
    </svg>
  );
}
