export const symbols = {
  TOGGLE_BURGER_MENU: '@@layout.toggle-burger-menu',
  REPORT_ERROR: '@@layout.report-js-error',
  CLEAR_ERROR: '@@layout.clear-js-error',
  SHOW_FILES_PANEL: '@@layout.show-files-pane',
  REPORT_WARNING: '@@layout.report-js-warning',
};

export function toggleBurgerMenu() {
  return { type: symbols.TOGGLE_BURGER_MENU, payload: null };
}

export function reportError(err) {
  return { type: symbols.REPORT_ERROR, payload: err };
}

export function reportWarning(err) {
  return { type: symbols.REPORT_WARNING, payload: err };
}

export function clearError() {
  return { type: symbols.CLEAR_ERROR };
}

export function showFilesPanel() {
  return { type: symbols.SHOW_FILES_PANEL };
}
