import teacherActions from '../teacher/edit_lesson';
import teamActions from '../theme';
import { getLessonBySectionName, getLessons, getLessonByIndex} from '../../components/bundletoc/selectors'
import { symbols as navSymbols, enterPage, setLocationToActiveLesson } from '../ajax';
import { getFileApiFromUrl } from '../../../../lib/helpers';

export const symbols = {
	UNDO_CHANGE_SECTION_NAME: '@@actions-teacher-edit-lesson-received-new-bundletoc-undo',
	UNDO_CHANGE_LESSON_NAME: '@@actions-teacher-edit-lesson-commit-lesson-name-undo',
	UNDO_TOGGLE_FREE: '@@actions-teacher-edit-lesson-toggle-free-undo',
	UNDO_CHANGE_THEME: '@@theme.commit-undo',
	UNDO_CHANGE_BUNDLE_NAME: '@@actions-teacher-edit-lesson-set-bundle-name-undo',
	UNDO_CHANGE_LESSON_NOTES: '@@actions-teacher-edit-lesson-save-lesson-notes-start-undo',
	UNDO_CREATE_NEW_LESSON: '@@actions-teacher-edit-lesson-create-undo',
	UNDO_REMOVE_LESSON: '@@actions-teacher-edit-lesson-remove-undo',
	UNDO_REORDER_LESSON: '@@actions-teacher-edit-lesson-reorder-undo',
	UNDO_CREATE_NEW_SECTION: '@@actions-teacher-edit-lesson-create-new-section-undo',
  UNDO_REMOVE_SECTION: '@@actions-teacher-edit-lesson-remove-section-undo',
  UNDO_REORDER_SECTION: '@@actions-teacher-edit-lesson-reorder-section-undo',
	UNDO_DRIP_LESSON: '@@actions-teacher-edit-lesson-drip-lesson-undo',
	UNDO_TOGGLE_VIDEO_AUTOPLAY_LESSON: '@@actions-teacher-edit-lesson-toggle-video-autoplay-lesson-undo',
	UNDO_TOGGLE_AUDIO_AUTOPLAY_LESSON: '@@actions-teacher-edit-lesson-toggle-audio-autoplay-lesson-undo',
	UNDO_TOGGLE_DOWNLOADABLE_PDF: '@@actions-teacher-edit-lesson-toggle-downloadable-lesson-undo',
  UNDO_TOGGLE_PRINTABLE_PDF: '@@actions-teacher-edit-lesson-toggle-printable-lesson-undo',
  UNDO_FACEBOOK_FEED_LESSON: '@@actions-teacher-edit-lesson-facebook-feed-undo',
  UNDO_UPDATE_CONTENT_LESSON: '@@actions-teacher-edit-lesson-update-content-undo',
  UNDO_RENAME_BUNDLE: '@@actions-teacher-edit-lesson-rename-bundle-undo',
  UNDO_CHANGE_LOGO_BUNDLE: '@@actions-teacher-edit-lesson-change-logo-bundle-undo',
  UNDO_CHANGE_INSTRUCTOR_INFO_BUNDLE: '@@actions-teacher-edit-lesson-change-instructor-info-bundle-undo',
 }

export const undoName = {
  rename_section: 'renameSection',
  rename_lesson: 'renameLesson',
  change_free: 'lockStatus',
  lesson_notes: 'lessonNotes',
  change_theme: 'changeTheme',
  remove_lesson: 'removeLesson',
  add_lesson: 'addLesson',
  lessons_order_change: 'lessonsOrderChange',
	rename_bundle: 'renameBundle',
	remove_section: 'removeSection',
  sections_order_change: 'sectionsOrderChange',
	add_section: 'addSection',
	drip_lesson: 'dripLesson',
	toggle_video_autoplay_lesson: 'toggleVideoAutoplayLesson',
	toggle_audio_autoplay_lesson: 'toggleAudioAutoplayLesson',
	toggle_downloadable_pdf: 'toggleDownloadablePdf',
	toggle_printable_pdf: 'togglePrintablePdf',
	facebook_feed_lesson: 'facebookFeedLesson',
	change_logo_bundle: 'changeLogoBundle',
	change_instructor_info: 'changeInstructorInfo',
	update_lesson_content: 'updateLessonContent',
};

export const eventActions = {
	commitSectionName: (undoData) => ({ type: symbols.UNDO_CHANGE_SECTION_NAME, payload: undoData }),
	commitLessonName: (undoData) => ({ type: symbols.UNDO_CHANGE_LESSON_NAME, payload: undoData }),
	toggleFree: (undoData) => ({ type: symbols.UNDO_TOGGLE_FREE, payload: undoData }),
	themeSave: (undoData) => ({ type: symbols.UNDO_CHANGE_THEME, payload: undoData }),
	commitBundleName: (undoData) => ({ type: symbols.UNDO_CHANGE_BUNDLE_NAME, payload: undoData }),
	saveLessonNotes: (undoData) => ({ type: symbols.UNDO_CHANGE_LESSON_NOTES, payload: undoData }),
	createNewLesson: (undoData) => ({ type: symbols.UNDO_CREATE_NEW_LESSON, payload: undoData }),
	removeLesson: (undoData) => ({ type: symbols.UNDO_REMOVE_LESSON, payload: undoData }),
	reorderLessons: (undoData) => ({ type: symbols.UNDO_REORDER_LESSON, payload: undoData }),
	createSection: (undoData) => ({ type: symbols.UNDO_CREATE_NEW_SECTION, payload: undoData }),
	removeSection: (undoData) => ({ type: symbols.UNDO_REMOVE_SECTION, payload: undoData }),
	reorderSections: (undoData) => ({ type: symbols.UNDO_REORDER_SECTION, payload: undoData }),
	dripLesson: (undoData) => ({ type: symbols.UNDO_DRIP_LESSON, payload: undoData }),
	videoToggleAutoplayLesson: (undoData) => ({ type: symbols.UNDO_TOGGLE_VIDEO_AUTOPLAY_LESSON, payload: undoData }),
	audioToggleAutoplayLesson: (undoData) => ({ type: symbols.UNDO_TOGGLE_AUDIO_AUTOPLAY_LESSON, payload: undoData }),
	toggleDownloadablePDF: (undoData) => ({ type: symbols.UNDO_TOGGLE_DOWNLOADABLE_PDF, payload: undoData }),
	togglePrintablePDF: (undoData) => ({ type: symbols.UNDO_TOGGLE_PRINTABLE_PDF, payload: undoData }),
	facebookFeedLesson: (undoData) => ({ type: symbols.UNDO_FACEBOOK_FEED_LESSON, payload: undoData }),
	updateContentLesson: (undoData) => ({ type: symbols.UNDO_UPDATE_CONTENT_LESSON, payload: undoData }),
	renameBundle: (undoData) => ({ type: symbols.UNDO_RENAME_BUNDLE, payload: undoData }),
	logoBundle: (undoData) => ({ type: symbols.UNDO_CHANGE_LOGO_BUNDLE, payload: undoData }),
	changeInstructorInfoBundle: (undoData) => ({ type: symbols.UNDO_CHANGE_INSTRUCTOR_INFO_BUNDLE, payload: undoData }),
}

const actions = {
	handleOnUndoRedo
}

export default actions;


function handleOnUndoRedo(type, data, isUndoAction = true) {
  console.log({isUndoAction, type})
	switch (type) {
	case undoName.rename_section:
		return commitSectionName(data, isUndoAction);

	case undoName.rename_lesson:
		return commitLessonName(data, isUndoAction);

	case undoName.change_free:
		return toggleFreeLesson(data, isUndoAction);

	case undoName.change_theme:
		return changeThemeBundle(data, isUndoAction);

	case undoName.rename_bundle:
		return undoRedoCommitBundleName(data, isUndoAction);

	case undoName.lesson_notes:
		return saveNotesLesson(data, isUndoAction);

	case undoName.remove_lesson:
		return removeLesson(data, isUndoAction);

  case undoName.add_lesson:
		return addLesson(data, isUndoAction);

  case undoName.lessons_order_change:
		return reorderLessons(data, isUndoAction);

	case undoName.add_section:
		return createSection(data, isUndoAction);

  case undoName.remove_section:
		return removeSection(data, isUndoAction);

  case undoName.sections_order_change:
      return reorderSections(data, isUndoAction);

  case undoName.drip_lesson:
		return undoRedoDripLesson(data, isUndoAction);

  case undoName.toggle_video_autoplay_lesson:
		return undoRedoVideoToggleAutoplayLesson(data, isUndoAction);

  case undoName.toggle_audio_autoplay_lesson:
		return undoRedoAudioToggleAutoplayLesson(data, isUndoAction);

  case undoName.toggle_downloadable_pdf:
    return undoRedoToggleDownloadablePDF(data, isUndoAction);

  case undoName.toggle_passwordprotected_pdf:
    return undoRedoTogglePasswordProtectedPDF(data, isUndoAction);

  case undoName.toggle_printable_pdf:
    return undoRedoTogglePrintablePDF(data, isUndoAction);

  case undoName.facebook_feed_lesson:
		return undoRedoFacebookFeedLesson(data, isUndoAction);

  case undoName.update_lesson_content:
		return undoUpdateContentLesson(data, isUndoAction);

  case undoName.change_logo_bundle:
		return changeBundleLogo(data, isUndoAction);

  case undoName.change_instructor_info:
		return changeInstructorInfo(data, isUndoAction);

	default:
		'do nothing'
	}
}

function commitSectionName(redoData, isUndoAction = true) {
  return async (dispatch, getstate) => {
    const data = isUndoAction ? redoData.OldValue : redoData.NewValue
    const lesson = getLessonByIndex(getstate(), redoData.EntityIndex)

    await dispatch(teacherActions.commitSectionName(lesson.get('section_id'), data));
  }
}
function commitLessonName(redoData, isUndoAction = true) {
  return async (dispatch, getstate) => {
    const data = isUndoAction ? redoData.OldValue : redoData.NewValue
    const lesson = getLessonByIndex(getstate(), redoData.EntityIndex)
    await setActiveLesson(lesson.get('path'), dispatch, getstate)
    await dispatch(teacherActions.commitLessonName(lesson.get('lesson_id'), data.name));
  }
}

function toggleFreeLesson(redoData, isUndoAction = true) {
  return async (dispatch, getstate) => {
    const data = isUndoAction ? redoData.OldValue : redoData.NewValue
    const lesson = getLessonByIndex(getstate(), redoData.EntityIndex)
    await setActiveLesson(lesson.get('path'), dispatch, getstate)
    await dispatch(teacherActions.toggleFree(lesson.get('lesson_id'), data.isFree));
  }
}

function changeThemeBundle(redoData, isUndoAction = true) {
	return async (dispatch, getstate) => {
    const data = isUndoAction ? redoData.OldValue : redoData.NewValue

    dispatch(teamActions.set({
      background_color: data.background_color,
      text_color: data.text_color,
    }));
    await dispatch(teamActions.save())
  }
}

function undoRedoCommitBundleName(redoData, isUndoAction = true) {
	return async (dispatch, getstate) => {
    const data = isUndoAction ? redoData.OldValue : redoData.NewValue
    await dispatch(teacherActions.commitBundleName(data));
	}
};
function saveNotesLesson(redoData, isUndoAction = true) {
  return async (dispatch, getstate) => {
    const data = isUndoAction ? redoData.OldValue : redoData.NewValue
    const lesson = getLessonByIndex(getstate(), redoData.EntityIndex)
    await setActiveLesson(lesson.get('path'), dispatch, getstate)
    await dispatch(teacherActions.saveLessonNotes(data.notes));
  }
}
function removeLesson(redoData, isUndoAction = true) {
  return async (dispatch, getstate) => {
    if (isUndoAction) {
      await updateLesson(getstate, dispatch, redoData, isUndoAction, true, undoName.remove_lesson)
    }
    else {
      const lessonFromState = getLessonByIndex(getstate(), redoData.EntityIndex)
      await dispatch(teacherActions.destroy(lessonFromState.get('lesson_id')));
    }
  }
}

function addLesson(redoData, isUndoAction = true) {
	return async (dispatch, getstate) => {
    if (isUndoAction) {
      const lessonToDelete = getLessonByIndex(getstate(), redoData.EntityIndex)
      const updatedEntity = Object.assign({}, redoData.Entity,{
        section_id: lessonToDelete.get('section_id'),
        section_name: lessonToDelete.get('section_name')
      })
      await dispatch(teacherActions.destroy(lessonToDelete.get('lesson_id')));
    } else {
      //lesson
      const {lesson_name, section_id, section_name} = redoData.Entity;
      //find first lesson with same section_name
      //old section id could be deleted
      const lesson = getLessonBySectionName(getstate(), section_name)

      const {lesson: newEntity} = await dispatch(teacherActions.create(lesson.get('section_id') || section_id, lesson_name))
      await setActiveLesson(newEntity.get('path'), dispatch, getstate)
    }
	}
};
function reorderLessons(redoData, isUndoAction = true) {
	return  async (dispatch, getstate) =>  {
    const data = isUndoAction ? redoData.OldValue : redoData.NewValue
    const newLesson = getLessonByIndex(getstate(), data.position)
    let lessonId = newLesson.get('lesson_id')


    let sectionData = 0
    let index = data.index
    const {section_id: sectionId, section_name: sectionName} = data
    if (isUndoAction) {
      //get updated section id from state by section name
      const updatedLesson = getLessonBySectionName(getstate(), sectionName)
      sectionData = { sectionId: updatedLesson.get('section_id') || sectionId , sectionName }
    } else if (getLessons(getstate()).getIn([index, 'section_name']) != sectionName){
      // worst case scenario,when lesson back to the beginning of section
      //the lesson index is equal between old and new sections but the section names is different
      sectionData = { sectionId, sectionName }
      //case when 2 sections became to 1 section the lesson index is equal between old one
      if(sectionName !== redoData.OldValue.section_name){
        const firstLesson = getLessonBySectionName(getstate(), sectionName)
        sectionData = { sectionId: firstLesson.get('section_id'), sectionName }
      }
    }

    await dispatch(teacherActions.moveLesson(lessonId, index, sectionData));
    await dispatch(teacherActions.saveBundleToc())
  }
};

function createSection(redoData, isUndoAction = true) {
  return async (dispatch, getstate) => {
    if (isUndoAction) {
      //find first lesson of section
      const lesson = getLessonByIndex(getstate(), redoData.EntityIndex)
      await dispatch(teacherActions.deleteSection(lesson.get('section_id')));
      await dispatch(teacherActions.saveBundleToc())
    } else{
      await dispatch(teacherActions.createSection());
    }
  }
}
function reorderSections(redoData, isUndoAction = true) {
	return async(dispatch, getstate) => {
    const data = isUndoAction ? redoData.OldValue : redoData.NewValue
    const lesson = getLessonByIndex(getstate(), data.lessonIndex)
    await dispatch(teacherActions.moveChapter(lesson.get('section_id'), data.sectionIndex, true));
    await dispatch(teacherActions.saveBundleToc())
  }
}

function removeSection(redoData, isUndoAction = true) {
  return async (dispatch, getstate) => {
    const [lesson, _] = redoData.Entity;
    if (isUndoAction) {
       const lessons = redoData.Entity
       //pick old lesson data for future
       const attachments = lessons.reduce((acc, lesson, index) => {
         const {attachment, name} = lesson
         if (attachment) {
           Reflect.deleteProperty(lesson, 'attachment')
           acc.push({attachment, name, index})
         }
         return acc
       }, [])

		   const {sectionId: newSectionId} = await dispatch(teacherActions.createSection(lesson.section_name, redoData.Entity));
       const newLessons = getLessons(getstate())

    // lessons with attachment must update
    // with different request because of multipart data
    if(attachments.length) {
      const attachmentActions = attachments.map(async ({attachment, index}) =>{
        //get lesson id by lesson name from new created lessons
        const sectionLessons = newLessons.filter(lesson => newSectionId === lesson.get('section_id'))
        const lessonIdToUpdate = sectionLessons.getIn([index, 'lesson_id'])
        if (lessonIdToUpdate) {
          await dispatch(teacherActions.undoRedoLessonAttachment(attachment, lessonIdToUpdate))
          return await setActiveLesson(sectionLessons.getIn([index, 'path']), dispatch, getstate)
        }
        throw new Error('Can not find the lesson to update attachment')
      })
       await Promise.all(attachmentActions)
      }
    } else{
      const lessonByIndex = getLessonByIndex(getstate(), redoData.EntityIndex)

      await dispatch(teacherActions.deleteSection(lessonByIndex.get('section_id')));
      await dispatch(teacherActions.saveBundleToc())
    }
  }
}

function undoRedoDripLesson(redoData, isUndoAction = true) {
	return async (dispatch, getstate) => {
    const data = isUndoAction ? redoData.OldValue : redoData.NewValue
    const lesson = getLessonByIndex(getstate(), redoData.EntityIndex)
    await setActiveLesson(lesson.get('path'), dispatch, getstate)
		await dispatch(teacherActions.setDrip(data.drip));
	}
};

function undoRedoVideoToggleAutoplayLesson(undoRedoData, isUndoAction = true) {
	return async (dispatch, getstate) => {
    const data = isUndoAction ? undoRedoData.OldValue : undoRedoData.NewValue
		await dispatch(teacherActions.setAutoplayVideo(data));
	}
};

function undoRedoAudioToggleAutoplayLesson(undoRedoData, isUndoAction = true) {
	return async (dispatch, getstate) => {
    const data = isUndoAction ? undoRedoData.OldValue : undoRedoData.NewValue
		await dispatch(teacherActions.setAutoplayAudio(data));
	}
};

function undoRedoToggleDownloadablePDF(undoRedoData, isUndoAction = true) {
	return async (dispatch, getstate) => {
    const data = isUndoAction ? undoRedoData.OldValue : undoRedoData.NewValue
		await dispatch(teacherActions.setDownloadablePDF(data));
	}
};

function undoRedoTogglePasswordProtectedPDF(undoRedoData, isUndoAction = true) {
	return async (dispatch, getstate) => {
    const data = isUndoAction ? undoRedoData.OldValue : undoRedoData.NewValue
		await dispatch(teacherActions.setPasswordProtectedPDF(data));
	}
};

function undoRedoTogglePrintablePDF(undoRedoData, isUndoAction = true) {
	return async (dispatch, getstate) => {
    const data = isUndoAction ? undoRedoData.OldValue : undoRedoData.NewValue
		await dispatch(teacherActions.setPrintablePDF(data));
	}
};

function undoRedoFacebookFeedLesson(redoData, isUndoAction = true) {
	return async (dispatch, getstate) => {
    const data = isUndoAction ? redoData.OldValue : redoData.NewValue
    const lesson = getLessonByIndex(getstate(), redoData.EntityIndex)
    await setActiveLesson(lesson.get('path'), dispatch, getstate)
		await dispatch(teacherActions.setCommentsDisabled(data.isEnableFbComments));
	}
};


function undoUpdateContentLesson(undoRedoData, isUndoAction = true) {
	return async (dispatch, getstate) => {
    await updateLesson(getstate, dispatch, undoRedoData, isUndoAction, false, undoName.update_lesson_content)
	}
};

function changeBundleLogo(redoData, isUndoAction = true) {
	return async (dispatch, getstate) => {
    const data = isUndoAction ? redoData.OldValue : redoData.NewValue

    const [typeData, binaryData] = data.split(',')
    const [_, type] = typeData.match(/:(.*?);/)
    const binary = atob(binaryData);
    const array = []
    for (let i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    const blob = new Blob([new Uint8Array(array)], {type});

    await dispatch(teacherActions.setBundleImage(blob))
	}
};

function changeInstructorInfo(redoData, isUndoAction = true) {
	return async (dispatch, getstate) => {
		await dispatch(teacherActions.saveInstructorInfo(isUndoAction ? redoData.OldValue : redoData.NewValue));
	}
};

const updateLesson = async (getstate, dispatch, undoRedoData, isUndoAction, isCreate, undoName) => {
  const entity = isCreate ? undoRedoData.Entity
                          : isUndoAction ? undoRedoData.OldValue : undoRedoData.NewValue
  const lesson = Object.assign({}, entity, {
    is_restore_mode: isCreate ? 'create' : 'update',
    ...(entity.lesson_name && {display_name: entity.lesson_name})
  })

  let {attachment, audioAttachment, audioThumbnail} = lesson
  if (audioAttachment == undefined && lesson.pdfurl && lesson.pdfurl.includes(".mp3")){
    audioAttachment = await getFileApiFromUrl(lesson.pdfurl, lesson.audio_content_type)
    audioThumbnail = await getFileApiFromUrl(lesson.audioThumbnail,lesson.audio_thumbnail_content_type)
  }
  Reflect.deleteProperty(lesson, 'attachment')

  //update video properties
  if (lesson.video) {
    const { videoUrl: video_url, type: video_type, videoId: video } = lesson.video
    Object.assign(lesson.video, { video_url, video_type, video })
  }

  //update drip property
  if (lesson.drip_date || lesson.drip_day) {
    const { drip_date, drip_day, drip_type } = lesson
    Object.assign(lesson, {
      drip: {
        date: drip_date, day: drip_day, type: drip_type
      }
    })
  }

  const lessons = getLessons(getstate())

  if(lesson.section_name) {
    //for case when section is recreate and given lesson section doesn't exist anymore
    const lessonToc = lessons.find(lesson => lesson.get('section_name') === entity.section_name)
    lessonToc && Object.assign(lesson, { section_id: lessonToc.get('section_id') })
  }

  //handle to section lessons id irrelevant anymore
  if(lesson.section_id && lesson.lesson_name) {
    //for case when section is recreate and given lesson section doesn't exist anymore
    const lessonToc = lessons.find(les => les.get('section_id') === entity.section_id && les.get('lesson_name') === entity.lesson_name)
    lessonToc && Object.assign(lesson, { section_id: lessonToc.get('section_id'), lesson_id: lessonToc.get('lesson_id') })
  }
  const currentLesson = getLessonByIndex(getstate(), undoRedoData.EntityIndex)

  if (!lesson.section_name && currentLesson && currentLesson.get('lesson_id') !== '-1') {
    Object.assign(lesson, {
      section_id: currentLesson.get('section_id'),
      lesson_id: currentLesson.get('lesson_id')
    })
  }
  const $$layout = getstate().$$layout;

  await fetch($$layout.getIn(['urls', 'update-lesson']), {
      method: 'PUT',
      headers: new Headers({
        'X-CSRF-Token': $$layout.get('authenticityToken'),
        'Content-Type': 'application/json'
      }),
      body: JSON.stringify(lesson)
    })
    .then((response) => response.json())
    .then(async (result) => {
      await postLessonUpdateActions(getstate, dispatch, isCreate, undoRedoData, isUndoAction, result, undoName)
      // lesson with attachment must update
      // with different request because of multipart data
      if (attachment) {
        await dispatch(teacherActions.undoRedoLessonAttachment(attachment, result.lesson.id))
      }
      if (audioAttachment) {
        let duration = 0;
        let autoplay = "true"
        if (isUndoAction) {
          if(undoRedoData.oldValue){
            duration = undoRedoData.oldValue.duration
            audioThumbnail= undoRedoData.oldValue.audioThumbnail
            autoplay = undoRedoData.oldValue.autoplayAudio
          }else{
            duration = entity.details.audio_duration
            autoplay = entity.details.autoplayAudio
          }
        }else{
          if (undoRedoData.newValue){
            duration = undoRedoData.newValue.duration
            audioThumbnail = undoRedoData.newValue.audioThumbnail
            autoplay = undoRedoData.newValue.autoplayAudio
          }else{
            duration = entity.details.audio_duration
            autoplay = entity.details.autoplayAudio
          }
        };
        await dispatch(teacherActions.undoRedoLessonAudioAttachment(audioAttachment, result.lesson.id, duration, audioThumbnail, autoplay))
      }
    })
    .catch((result) => {
      dispatch(teacherActions.reportSelectContentError(getstate().$$i18n.getIn(['errors', 'network_error']) + result));
    })
};

const postLessonUpdateActions = async (getstate, dispatch, isCreate,
  undoRedoData, isUndoAction, result, undoName) =>{
  const {video, iframesrc, quiz} = result.lesson

  let contentType = ''
  if (video) {
    dispatch(teacherActions.selectVideo(video.type, video.videoId, video.videoUrl, video.duration));
    contentType = 'video'
  } else if (iframesrc) {
    contentType = 'iframe'
  } else if (quiz) {
    contentType = 'quiz'
  }

  dispatch(teacherActions.setContentType(contentType));
  dispatch(teacherActions.endSelectContentType())
  dispatch({type: navSymbols.APPSTATE_MERGE, payload: result});

  const updatedLesson = result.bundletoc.lessons.find(lesson => lesson.lesson_id === result.lesson.id);
  //update url for view
  if (updatedLesson) {
    await setActiveLesson(updatedLesson.path, dispatch, getstate)
  }
}

const setActiveLesson = async (path, dispatch, getstate) => {
  if(path) {
    return new Promise((resolve, reject) => {
      dispatch(enterPage(path, () => {
        if (getstate().$$lesson.get('id')) {
          setLocationToActiveLesson(getstate())
        }
        resolve()
      }));
    })
  } else{
    console.error('path is undefined for set active')
  }
}
