import * as React from 'react';

const JpgIcon = ({ width = 26, height = 23, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width || 26} height={height || 23} viewBox="0 0 1000 1000" {...rest}>
      <path d="M867.541 9.946H132.46C64.892 9.946 9.946 64.892 9.946 132.46v735.081c0 67.567 54.946 122.514 122.514 122.514h735.082c67.568 0 122.514-54.946 122.514-122.514V132.46C990.055 64.892 935.11 9.946 867.541 9.946zM132.46 91.622h735.082c22.522 0 40.838 18.315 40.838 40.838v289.374L773.901 287.356c-15.951-15.942-41.795-15.942-57.746 0l-287.621 287.62-103.849-103.849c-15.952-15.942-41.795-15.942-57.747 0L91.622 646.442V132.46c0-22.522 18.315-40.838 40.838-40.838zm735.081 816.757H132.46c-22.522 0-40.838-18.315-40.838-40.838V761.937l204.189-204.188L532.383 794.3c7.986 8.006 18.416 11.984 28.874 11.984 10.459 0 20.888-3.979 28.874-11.964 15.941-15.952 15.941-41.795 0-57.748l-103.85-103.849 258.746-258.727 163.352 163.352V867.54c0 22.523-18.315 40.839-40.838 40.839zM403.148 264.975c0 69.125-56.047 125.174-125.174 125.174-69.126 0-125.174-56.049-125.174-125.174 0-69.128 56.048-125.174 125.174-125.174 69.127 0 125.174 56.046 125.174 125.174z" />
    </svg>
  );
};

JpgIcon.propTypes = {
  width: React.PropTypes.number,
  height: React.PropTypes.number,
};

export default JpgIcon;
