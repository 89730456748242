class LocalStorageManager {
    save(key, item, options = { isPrimitive: false }) {
        if (options.isPrimitive) {
            localStorage.setItem(key, item);
        } else {
            try {
                const serializedItem = JSON.stringify(item);
                localStorage.setItem(key, serializedItem);
            } catch (err) {
                throw new Error(err);
            }
        }
    }
    get(key, options = { isPrimitive: false }) {
        let result;
        if (options.isPrimitive) {
            result = localStorage.getItem(key);
        }
        try {
            const serializedItem = localStorage.getItem(key);
            result = JSON.parse(serializedItem);
        } catch (err) {
            result = undefined;
        }
        return result;
    }
    remove(key) {
        localStorage.removeItem(key);
    }
}

export default new LocalStorageManager();
