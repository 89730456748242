import React, { useEffect } from 'react';
import { adjustFontSize, scrollToElement } from '../../../components/helpers/school';

const UseEffectInitializer = ({ preventScroll }) => {

  useEffect(() => {
    // Display small size header text in mobile.
    if (window.innerWidth < 576) {
      adjustFontSize('.school-grid.school-header-text-container .school-header *', 28);
      adjustFontSize('.school-grid.school-header-text-container .school-subheader *', 18);
      adjustFontSize('.school-container .row.header *', 28); // default template
      adjustFontSize('.school-container .row.subheader *', 28);
    }

     if (!preventScroll) {
      scrollToElement('.school-signup-form');
      scrollToElement('.school-login-form');
      scrollToElement('.school-new-pass-form');
    }
  }, [preventScroll]);

  return null;
};

export default UseEffectInitializer;
