import Immutable from 'immutable';

import { symbols } from '../../actions/undo_redo/undo_redo_cable';

export const initialState = Immutable.Map({
  undoRedoChannel: null,
  //unique id
  sessionId: +(new Date),
  isCourseUpdatedByOtherSession: false,
  isNotificationModalDisplay: true
})

export default function undoRedoCableReducer(state = initialState, action) {
  switch (action.type) {
    case symbols.UNDO_INIT_CABLE_CHANNEL:
      return cableChannelInit(state, action.payload) 
    case symbols.UNDO_CABLE_SEND:
      return cableChannelSend(state, action.payload)
    case symbols.UNDO_CABLE_RECEIVE:
      return cableChannelReceive(state, action.payload)
    case symbols.UNDO_MODAL_DISPLAY_STATE:
      return setNotificationModalDisplay(state, action.payload)    
    default:
      return state;
  }
}
const cableChannelReceive = (state, payload) => {
  const sessionId = state.get('sessionId')
  return payload.sessionId !== sessionId 
  ? state.set('isCourseUpdatedByOtherSession', true)
         .set('isModalDisplay', true) 
  : state
}

const cableChannelSend = (state) => {
  const channel = state.get('undoRedoChannel')
  const sessionId = state.get('sessionId')
  channel.perform('lesson_changed',{ sessionId })
  return state
}

const cableChannelInit = (state, channel) => {
  return state.set('undoRedoChannel', channel)
}
const setNotificationModalDisplay = (state, payload) => {
  return state.set('isModalDisplay', payload.isModalDisplay)
}