import React from 'react';
import { connect } from 'react-redux';
import Lesson from './lesson';

import Container from './container';
import userNameHelper from 'lib/form_helpers';

class EditAccount extends Container {
  static propTypes = {
    currentUsername: React.PropTypes.string,
    currentEmail: React.PropTypes.string,
    token: React.PropTypes.string,
  }

  static subheader = 'simple';

  render() {
    const _name = userNameHelper(this.props.customDomain);
    return (<Lesson>
      <div>
        <form role="form" lpformnum="1" action="/" method="POST" >
          <fieldset>
            <legend>עדכון פרטי חשבון</legend>
            <input type="hidden" name="_method" value="patch" />
            <input name="authenticity_token" type="hidden" value={this.props.token} />
            <div className="form-group">
              <label className="control-label" htmlFor="username-field">שם משתמש</label>
              <input name={_name('name')} type="text" className="form-control" id="username-field" placeholder={this.props.currentUsername} autoComplete="off" />
              <p className="help-block">בחר שם משתמש חדש או השאר ריק לשמור את הקיים</p>
            </div>

            <div className="form-group">
              <label className="control-label" htmlFor="email-field">כתובת מייל</label>
              <input name={_name('email')} type="email" className="form-control" id="email-field" placeholder={this.props.currentEmail} autoComplete="off" />
              <p className="help-block">בחר כתובת מייל חדשה או השאר ריק לשמור את הקיים</p>
            </div>

            <div className="form-group">
              <label className="control-label" htmlFor="password-field">סיסמא חדשה</label>
              <input name={_name('password')} type="password" className="form-control" id="password-field" autoComplete="off" />
              <p className="help-block">בחר סיסמא חדשה או השאר ריק אם אין ברצונך לשנות סיסמא</p>
            </div>

            <div className="form-group">
              <label className="control-label" htmlFor="password-validation-field">אימות סיסמא</label>
              <input name={_name('password_confirmation')} type="password" className="form-control" id="password-validation-field" autoComplete="off" />
              <p className="help-block">נא להזין שנית את הסיסמא החדשה</p>
            </div>

            <div className="form-group">
              <label className="control-label" htmlFor="current-password-field">סיסמא נוכחית</label>
              <input name={_name('current_password')} type="password" className="form-control" id="current-password-field" autoComplete="off" />
            </div>

            <button type="submit" className="btn btn-default button_fullwidth">עדכון פרטים</button>
          </fieldset>
        </form>
      </div>
    </Lesson>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentUsername: state.$$account.get('currentUsername'),
    currentEmail: state.$$account.get('currentEmail'),
    token: state.$$account.get('token'),
    customDomain: state.$$layout.get('customDomain'),
  };
}

export default connect(mapStateToProps)(EditAccount);
