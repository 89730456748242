import $ from 'jquery';
import { enterPage } from './ajax';

/**
 * Constants
 **/

export const symbols = {
  SET_LIMIT: '@@reseller:activitylog:setlimit',
  RECEIVED_LIST: '@@reseller:activitylog:receivedlist',
  SET_ACTIVE_NAVITEM: '@@reseller:navigate',
  RECEIVED_ALL_USERS: '@@reseller:allusers:receivedlist',
  RECEIVED_ACTIVE_USERS: '@@reseller:activeusers:receivedlist',
};

/**
 * Actions
 */
export const actions = {
  activity_log: {
    refresh_log: refresh_log,
    set_log_limit: set_log_limit,
  },
  all_users: {
    refresh_all_users: refresh_all_users,
  },
  active_users: {
    fetch: fetch_active_users,
  },
  navigate_to: navigate_to,
};


function navigate_to(url, navItem) {
  return (dispatch, getstate) => {
    dispatch({ type: symbols.SET_ACTIVE_NAVITEM, payload: navItem });
    fetch_appstate(dispatch, getstate, {}, url);
  };
}

function refresh_log() {
  return (dispatch, getstate) => {
    var limit = getstate().$$reseller.activity_log.limit;
    fetch_appstate(dispatch, getstate, { limit: limit });
  };
}

function set_log_limit(limit) {
  return { type: symbols.SET_LIMIT, payload: limit };
}

function fetch_active_users() {
  return (dispatch, getstate) => {
    fetch_appstate(dispatch, getstate);
  };
}

function refresh_all_users() {
  return (dispatch, getstate) => {
    fetch_appstate(dispatch, getstate);
  };
}

function fetch_appstate(dispatch, getstate, params = {}, url = getstate().routing.path) {
  dispatch(enterPage(url, null, params));
}
