import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FlashCloseIcon, FlashErrorIcon, FlashSuccessIcon, FlashWarningIcon } from './icons/index';
import { isLayoutRTL } from 'lib/helpers';

const FlashMessage = ({ type, title, message, onClose }) => {
  const [visible, setVisible] = useState(!!message);

  useEffect(() => {
    setVisible(!!message);
  }, [message]);

  if (!type || type === 'info' || !visible) return null;

  const handleClose = () => {
    setVisible(false);
    setTimeout(onClose, 0);
  };

  let backgroundColorClass, borderColorClass;
  const borderClass = isLayoutRTL() ? '!pr-[10px] border-r-[7px]' : '!pl-[10px] border-l-[7px]';

  switch (type) {
    case 'success':
      backgroundColorClass = 'bg-success';
      borderColorClass = 'border-success';
      break;
    case 'error':
      backgroundColorClass = 'bg-error';
      borderColorClass = 'border-error';
      break;
    case 'warning':
      backgroundColorClass = 'bg-warning';
      borderColorClass = 'border-warning';
      break;
    default:
      backgroundColorClass = 'bg-info';
      borderColorClass = 'border-info';
  }

  const iconMap = {
    success: FlashSuccessIcon,
    error: FlashErrorIcon,
    warning: FlashWarningIcon
  };

  const IconComponent = iconMap[type];

  return (
    <div className="absolute flex justify-center left-0 right-0 bottom-8 z-[999]">
      <div
        className={`flex justify-evenly gap-[17px] directional flash-message flash-${type} shadow-fm bg-white border border-solid ${borderClass} rounded-md p-[17px] max-w-[40vw] min-w-[335px] ${borderColorClass}`}
      >
        <div>
          <IconComponent />
        </div>
        <div className="flex flex-col gap-[0.5rem] bg-white">
          <div className="flex">
            <div className="text-[#333333] text-lg leading-none font-bold">{title}</div>
          </div>
          <div className="text-[#333333] text-lg leading-none tracking-[0.245px]">
            <span dangerouslySetInnerHTML={{ __html: message }}></span>
          </div>
        </div>
        <div className="m-[5px] relative cursor-pointer" onClick={handleClose}>
          <FlashCloseIcon />
        </div>
      </div>
    </div>

  );
};

FlashMessage.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  onClose: PropTypes.func,
  type: PropTypes.oneOf(['success', 'error', 'warning', 'info']),
};

FlashMessage.defaultProps = {
  message: '',
};

export default FlashMessage;
