import React, { Fragment, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {setIsModalDisplay} from '../../actions/undo_redo/undo_redo_cable'
const CLASS_NAMES ={
  CONTAINER : 'modal fade undo-redo-modal-container',
  MODAL_CONTAINER : "modal-dialog",
  MODAL_CONTENT : "modal-content",
  MODAL_HEADER : "modal-header",
  CLOSE_BUTTON : "close-button",
  MODAL_BODY : "modal-body",
  WARNING_ICON : "warn-icon",
  MODAL_BODY_HEADER : "modal-body-header",
  MODAL_BODY_SUBHEADER : "modal-body-subheader",
  MODAL_FOOTER : "modal-footer",
  MODAL_BACKDROP : "modal-backdrop fade",
}

const UndoRedoModalNotification = () => {
  const [display, setDisplay] = useState('block')
  const dispatch = useDispatch()
  const i18n = useSelector((state) => state.$$i18n.getIn(['system', 'undoRedo', 'modal']).toJS());
  const refreshPage = ()=>{
    window.location.reload()
  }
  const closeModal = ()=>{
    dispatch(setIsModalDisplay(false))
    setDisplay('none')
  }
  return (
    <Fragment>
      <div className={CLASS_NAMES.CONTAINER} tabIndex="-1" style={{display: display}}>
        <div className={CLASS_NAMES.MODAL_CONTAINER}>
          <div className={CLASS_NAMES.MODAL_CONTENT}>
            <div className={CLASS_NAMES.MODAL_HEADER}>
              <div className={CLASS_NAMES.CLOSE_BUTTON} onClick={closeModal}></div>
            </div>
            <div className={CLASS_NAMES.MODAL_BODY}>
              <div className={CLASS_NAMES.WARNING_ICON}></div>
              <div className={CLASS_NAMES.MODAL_BODY_HEADER}>
                {i18n.header}
              </div>
              <div className={CLASS_NAMES.MODAL_BODY_SUBHEADER}>
                {i18n.subHeader}
              </div>
            </div>
            <div className={CLASS_NAMES.MODAL_FOOTER}>
              <button type="button" className="nm-btn" onClick={refreshPage}>
                {i18n.refreshBtnLabel}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className={CLASS_NAMES.MODAL_BACKDROP} style={{display: display}}></div>
    </Fragment>
  )
}
export default UndoRedoModalNotification
