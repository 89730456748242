import React from 'react';

export default function ArrowDown() {
  return (
    <svg className="arrow-down-svg" width="11" height="11" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.857 5.095 5.692 9.26 1.527 5.095a.631.631 0 0 0-.892.892l4.282 4.283a1.094 1.094 0 0 0 1.545 0l4.282-4.283a.631.631 0 0 0-.887-.892Z" fill="currentColor" />
      <path d="M4.92 6.157a1.094 1.094 0 0 0 1.544 0l4.282-4.283a.631.631 0 0 0-.89-.892L5.693 5.146 1.527.982a.631.631 0 1 0-.892.892L4.92 6.157Z" fill="currentColor" />
    </svg>
  );
}
