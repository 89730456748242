import React from 'react';
import cx from 'classnames';


export default function SchoolLogo({ className, link, image, alt = 'school logo' }) {
  const classNames = cx('school-logo', className)

  return (
    <a href={link} className={classNames}>
      <img src={image} alt={alt} />
    </a>
  )
}
