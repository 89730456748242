import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import actions from '../actions/quiz';

import QuizDetailedResults from './quiz_detailed_results';

function QuizResultStatus(props){
  const numberOfErrors = props.total - props.correct;
  const retries = props.retries;
  const limitedRetries = (typeof retries === 'number');

  const TestResult = (props.correct / props.total) * 100 >= props.passingMarks
    ? props.i18n.studentPassedTheTest
    : props.i18n.studentFailedTheTest;

  const resultsHtml = props.i18n.results
    .replace('%{errors}', `<span class="error">${numberOfErrors}</span>`)
    .replace('%{number_of_tries}', `<span class="retry">${retries}</span>`)
    .replace('%{limited_retries}', `display-${limitedRetries}-for-result`);

  return (
    <div>
      <h5
        className={`result-message-failure`}
        dangerouslySetInnerHTML={{__html: TestResult}}>
      </h5>
      <p
        className={`result-score result-message-failure`}
        dangerouslySetInnerHTML={{__html: resultsHtml}}>
      </p>
    </div>
  )
}

function PassingGradeForDesktop(props) {
  const passingGrade = props.passingMarks;
  const obtainedMarks = parseInt((props.correct / props.total) * 100)
  const backgroundClass = obtainedMarks >= passingGrade ? 'passing-success' : 'passing-mistake';
  const highestMarks = obtainedMarks === 100;

  return (
    <div className={`passing-grade-container `}>
      <div className={`grade-info-container ${backgroundClass}`}>
        {highestMarks && (
          <>
            <img src="/images/celebration-icon-left.svg" className="celebration-icon-left" alt="celebration-icon-left"/>
            <img src="/images/celebration-icon-right.svg" className="celebration-icon-right" alt="celebration-icon-right"/>
            <img src="/images/celebration_flag.svg" className="celebration-flag" alt="celebration-flag"/>
          </>
        )}

        <p
          className={`grade-label`}
          dangerouslySetInnerHTML={{
            __html: props.i18n.obtainedScore
          }}>
        </p>
        <p className="grade-value">{obtainedMarks}</p>

      </div>

      <p
        className={`passing-score-label`}
        dangerouslySetInnerHTML={{
          __html: props.i18n.passingScore
            .replace('%{passing_grade}', `${passingGrade}`)
        }}>
      </p>
    </div>
  );
}

function FailedSubheader(props) {
  const canRetry = !(typeof props.retries === 'number' && props.retries <= 0);
  const isGradeable = props.isGradeable

  return (
    <div>
      {isGradeable && <PassingGradeForDesktop {...props} />}
      <div className="results">
        <QuizResultStatus {...props} />

        {canRetry && (
          <p className={`try-again-result-btn`}>
            <a
              className="success "
              aria-label={props.i18n.tryAgainAriaLabel}
              dangerouslySetInnerHTML={{__html: props.i18n.tryAgain}}
              href='#'
              onClick={props.tryAgain}>
            </a>
          </p>
        )}
      </div>
    </div>
  );
}

function PassSubheader(props) {
  const obtainedMarks = (props.correct / props.total) * 100
  const highestMarks = obtainedMarks === 100;
  const isGradeable = props.isGradeable;

  return (
    <div>
      {isGradeable && <PassingGradeForDesktop {...props} />}
      <div className="results">
        {highestMarks ? (
          <h5
            className={`result-message-success`}
            dangerouslySetInnerHTML={{__html: props.i18n.passedTitle}}
          ></h5>
        ) : (
          <QuizResultStatus {...props} />
        )}

        <p className={`try-again-result-btn`}>
          <a
            className="success"
            aria-label={props.i18n.tryAgainAriaLabel}
            dangerouslySetInnerHTML={{ __html: props.i18n.tryAgain }}
            href='#'
            onClick={props.tryAgain}>
          </a>
        </p>
      </div>
    </div>

  );
}

function FailedTestHeader(props) {
  return (
    <div className="page-lable quiz-header" style={props.style}>
      <h2 style={props.style}>{props.children}</h2>
    </div>
  );
}

function PassTestHeader(props) {
  return (
    <div className='st-look-saccess'>
      <FailedTestHeader style={props.style}>{props.children}</FailedTestHeader>
    </div>
  );
}

class QuizResults extends React.Component {
  static propTypes = {
    quiz: PropTypes.object.isRequired,
  }

  resetSubmission = (e) => {
    e.preventDefault();
    this.props.dispatch(actions.resetSubmission());
  };

  render() {
    const { quiz, i18n, sidebarBgColor, textColor } = this.props;
    const userColors = {
      '--label-bg-color': sidebarBgColor,
      color: textColor,
    };

    let Header, Subheader;

    if (quiz.get('result') === 'passed') {
      Header = PassTestHeader;
      Subheader = PassSubheader;
    } else {
      Header = FailedTestHeader;
      Subheader = FailedSubheader;
    }

    return (
      <div className='st-look-mistakes content-inner'>
        <Header i18n={i18n} style={userColors}>
          {quiz.get('name')}
        </Header>

        <Subheader
          style={userColors}
          correct={quiz.get('correct')}
          i18n={i18n}
          retries={quiz.get('retries')}
          total={quiz.get('total')}
          tryAgain={this.resetSubmission}
          canRetry={quiz.get('result') !== 'passed'}
          briefQuiz={quiz.get('questions')}
          passingMarks={quiz.get('passing_marks')}
          isGradeable={quiz.get('gradeable')}
        />

        {quiz.get('questions') && <QuizDetailedResults quiz={quiz} />}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    i18n: state.$$i18n.getIn(['students', 'lesson', 'quiz']).toJS(),
    sidebarBgColor: state.$$theme.getIn(['settings', 'background_color']),
    textColor: state.$$theme.getIn(['settings', 'text_color']),
  };
}

export default connect(mapStateToProps)(QuizResults);
