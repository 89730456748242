import Immutable from 'immutable';
import { symbols } from '../../actions/teacher/edit_lesson';
import { symbols as navSymbols } from '../../actions/ajax';

export const initialState = Immutable.Map({
  detailedQuizResults: true,
  isEditing: false,
  linksPanelOpen: false,
  sectionNames: Immutable.Map({}),
  selectContentPopupVisible: false,
  showSpinner: false,
  validFileForUpload: true,
  videourl: null
});

export default function editLessonReducer(state = initialState, action) {
  switch (action.type) {
    case symbols.START_EDIT:
      return state.set('isEditing', true);

    case symbols.CANCEL_EDIT:
      return cancelEdit(state, action);

    case symbols.SET_IFRAME_SRC:
      return setIframeSrc(state, action);

    case symbols.START_SELECT_CONTENT_TYPE:
      return startSelectContentType(state, action);

    case symbols.END_SELECT_CONTENT_TYPE:
      return endSelectContentType(state, action);

    case symbols.SET_VIDEO_URL:
      return setVideoUrl(state, action);

    case symbols.SET_VIDEO_DURATION:
      return setVideoDuration(state, action);

    case symbols.SET_AUDIO_DURATION:
      return setAudioDuration(state, action);

    case symbols.SET_FILE_FOR_UPLOAD:
      return setFileForUpload(state, action);

    case symbols.SET_FILE_FOR_AUDIO_UPLOAD:
      return setFileForAudioUpload(state, action);

    case symbols.SET_FILE_FOR_AUDIO_THUMBNAIL_UPLOAD:
      return setFileForAudioThumbnailUpload(state, action);

    case symbols.SET_DOWNLOADABLE_PDF:
      return setDownloadablePDF(state, action);

    case symbols.SET_PASSWORD_PROTECTED_PDF:
      return setPasswordProtectedPDF(state, action);

    case symbols.SET_PRINTABLE_PDF:
      return setPrintablePDF(state, action);

    case symbols.REPORT_SELECT_CONTENT_ERROR:
      return reportSelectContentError(state, action);

    case symbols.CLEAR_SELECT_CONTENT_ERROR:
      return clearSelectContentError(state, action);

    case symbols.SHOW_SPINNER:
      return showSpinner(state, action);

    case symbols.FILE_UPLOAD_VALIDITY:
      return fileUploadValidity(state, action);

    case symbols.TOGGLE_LINKS_PANEL:
      return toggleLinksPanel(state, action);

    case symbols.SHOW_PUBLISH_BUNDLE_POPUP:
      return showPublishBundlePopup(state, action);

    case symbols.HIDE_PUBLISH_BUNDLE_POPUP:
      return hidePublishBundlePopup(state, action);

    case symbols.PUBLISH_BUNDLE:
      return publishBundle(state, action);

    case symbols.SHOW_PUBLISH_BUNDLE_MODAL:
      return showPublishBundleModal(state, action);

    case symbols.HIDE_PUBLISH_BUNDLE_MODAL:
      return hidePublishBundleModal(state, action);

    case symbols.SHOW_PUBLISH_ERRORS:
      return showPublishErrors(state, action);

    case symbols.SELECT_QUIZ:
      return selectQuiz(state, action);

    case symbols.TOGGLE_DETAILED_QUIZ_RESULTS:
      return toggleDetailedQuizResults(state, action);

    case symbols.RESET_DETAILED_QUIZ_RESULTS:
      return resetDetailedQuizResults(state, action);

    case navSymbols.APPSTATE_MERGE:
      return initialState;

    default:
      return state;
  }
}

function setIframeSrc(state, action) {
  return state.set('iframesrc', action.payload.src);
}

function startSelectContentType(state, action) {
  return state.set('selectContentPopupVisible', true);
}

function endSelectContentType(state, action) {
  return state.set('selectContentPopupVisible', false)
              .set('validFileForUpload', true)
              .set('showSpinner', false)
              .delete('quizPreview')
              .delete('quiz');
}

function setVideoUrl(state, action) {
  return state.set('videourl', action.payload.url);
}

function setVideoDuration(state, action) {
  return state.set('duration', action.payload.duration);
}

function setAudioDuration(state, action) {
  return state.set('audioDuration', action.payload.audioDuration);
}

function setFileForUpload(state, action) {
  return state.set('attachment', action.payload.file);
}

function setFileForAudioUpload(state, action) {
  return state.set('audioAttachment', action.payload.file);
}

function setFileForAudioThumbnailUpload(state, action) {
  return state.set('audioThumbnail', action.payload.file);
}

function setDownloadablePDF(state, action) {
  return state.set('pdfdownloadable', action.payload);
}

function setPasswordProtectedPDF(state, action) {
  return state
      .set("pdfprintable", !action.payload)
      .set("pdfpasswordprotected", action.payload);
}

function setPrintablePDF(state, action) {
  return state.set('pdfprintable', action.payload);
}

function reportSelectContentError(state, action) {
  return state.set('error', action.payload.error).set('showSpinner', false);
}

function clearSelectContentError(state, action) {
  return state.delete('error')
              .set('validFileForUpload', true)
              .set('showSpinner', false);
}

function toggleLinksPanel(state, action) {
  return state.update('linksPanelOpen', open => !open);
}

function showSpinner(state, action) {
  return state.set('showSpinner', true);
}

function fileUploadValidity(state, action) {
  return state.set('validFileForUpload', action.payload)
              .set('showSpinner', false);
}

function showPublishBundlePopup(state, action) {
  return state.set('editStatusDialogVisible', true);
}

function showPublishErrors(state, action) {
  return state.set('errors', action.payload.val);
}

function hidePublishBundlePopup(state, action) {
  return state.set('editStatusDialogVisible', false);
}

function showPublishBundleModal(state, action) {
  return state.set('hideModal', false);
}

function hidePublishBundleModal(state, action) {
  return state.set('hideModal', true);
}

function publishBundle(state, action) {
  return state.set('editStatusDialogVisible', false);
}

function selectQuiz(state, { payload: { quiz, quizForUser } }) {
  return state.set('quiz', quiz).set('quizPreview', Immutable.fromJS(quizForUser));
}

function cancelEdit(state, action) {
  return state.set('isEditing', false);
}

function toggleDetailedQuizResults(state, action) {
  return state.update('detailedQuizResults', val => !val);
}

function resetDetailedQuizResults(state, action) {
  return state.set('detailedQuizResults', action.payload);
}
