import React, { PropTypes } from 'react';
import { connect } from 'react-redux';
import Icon from '../helpers/icon';
import ColoredSpan from '../helpers/text';

function SectionHeader(props) {
  const cls = props.first ? 'toc-header first' : 'toc-header';
  const containsLabel = props.courseLocale.contains.replace('%{lessons_count}', props.lessonsCount);
  const sectionAriaLabel = `${props.courseLocale.chapter} ${props.index} ${props.initialName} ${containsLabel}`;

  return (
    <li className={cls}>
      <a href={props.firstLessonHref} className='bright' aria-label={sectionAriaLabel} >
        <Icon name='chapter' />
        <ColoredSpan className='chapter-index'>{props.index}</ColoredSpan>
        <ColoredSpan className='caption'>{props.initialName}</ColoredSpan>
      </a>
    </li>
  );
}

SectionHeader.propTypes = {
  index: PropTypes.number.isRequired,
  initialName: PropTypes.string.isRequired,
  first: PropTypes.bool,
  firstLessonHref: PropTypes.string.isRequired,
  preview: PropTypes.bool.isRequired,
};

SectionHeader.defaultProps = {
  first: false,
};

function mapStateToProps(state) {
  return {
    preview: state.$$layout.get('preview'),
    courseLocale: state.$$i18n.toJS().students.course
  };
}

export default connect(mapStateToProps)(SectionHeader);
