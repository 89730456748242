import Immutable from 'immutable';
import { symbols } from '../actions/contact_form';
import { symbols as actionTypes } from '../actions/ajax';

export const initialState = Immutable.Map({
  submitting: false,
});

export default function contactFormReducer(state = initialState, action) {
  switch (action.type) {
    case symbols.SUBMIT_START:
      return clear(state).set('submitting', true);

    case symbols.HANDLE_RESULT:
      return clear(state).set('result', action.payload);

    case symbols.HANDLE_ERROR:
      return clear(state).set('error', 'שגיאה בעת שמירת המידע');

    case actionTypes.APPSTATE_MERGE:
      return clear(state);

    default:
      return state;
  }
}

function clear(state) {
  return state.set('submitting', false).
               set('error', null).
               set('waitText', null).
               set('result', null);
}
