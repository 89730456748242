import {createUndoMiddleware} from 'redux-undo-redo';
import { symbols as teacherSymbols } from '../actions/teacher/edit_lesson';
import { symbols as undoSymbols, undoName } from '../actions/undo_redo/undo_redo';

export default createUndoMiddleware({
  revertingActions: {
    [undoSymbols.UNDO_CHANGE_SECTION_NAME]: {
      action: ({payload}) => ({ type: teacherSymbols.END_EDIT_BUNDLE_TOC }),
      createArgs: (state, action) => ({undoName: undoName.rename_section, undoData: action.payload})
    },
    [undoSymbols.UNDO_REORDER_SECTION]: {
      action: ({payload}) => ({ type: teacherSymbols.END_EDIT_BUNDLE_TOC }),
      createArgs: (state, action) => ({undoName: undoName.sections_order_change, undoData: action.payload})
    },
    [undoSymbols.UNDO_REMOVE_SECTION]: {
      action: ({payload}) => ({ type: teacherSymbols.END_EDIT_BUNDLE_TOC }),
      createArgs: (state, action) => ({undoName: undoName.remove_section, undoData: action.payload})
    },
	  [undoSymbols.UNDO_CHANGE_LESSON_NAME]: {
      action: ({payload}) => ({ type: teacherSymbols.END_EDIT_BUNDLE_TOC }),
      createArgs: (state, action) => ({undoName: undoName.rename_lesson, undoData: action.payload})
    },
    [undoSymbols.UNDO_TOGGLE_FREE]: {
      action: ({payload}) => ({ type: teacherSymbols.END_EDIT_BUNDLE_TOC }),
      createArgs: (state, action) => ({undoName: undoName.change_free, undoData: action.payload})
    },
    [undoSymbols.UNDO_CHANGE_THEME]: {
      action: ({payload}) => ({ type: teacherSymbols.END_EDIT_BUNDLE_TOC }),
      createArgs: (state, action) => ({undoName: undoName.change_theme, undoData: action.payload})
    },
    [undoSymbols.UNDO_CHANGE_BUNDLE_NAME]: {
      action: ({payload}) => ({ type: teacherSymbols.END_EDIT_BUNDLE_TOC }),
      createArgs: (state, action) => ({undoName: undoName.rename_bundle, undoData: action.payload})
    },
    [undoSymbols.UNDO_CHANGE_LESSON_NOTES]: {
      action: ({payload}) => ({ type: teacherSymbols.END_EDIT_BUNDLE_TOC }),
      createArgs: (state, action) => ({undoName: undoName.lesson_notes, undoData: action.payload})
    },
    [undoSymbols.UNDO_CREATE_NEW_LESSON]: {
      action: ({payload}) => ({ type: teacherSymbols.END_EDIT_BUNDLE_TOC }),
      createArgs: (state, action) => ({undoName: undoName.add_lesson, undoData: action.payload})
    },
    [undoSymbols.UNDO_REMOVE_LESSON]: {
      action: ({payload}) => ({ type: teacherSymbols.END_EDIT_BUNDLE_TOC }),
      createArgs: (state, action) => ({undoName: undoName.remove_lesson, undoData: action.payload})
    },
    [undoSymbols.UNDO_REORDER_LESSON]: {
      action: ({payload}) => ({ type: teacherSymbols.END_EDIT_BUNDLE_TOC }),
      createArgs: (state, action) => ({undoName: undoName.lessons_order_change, undoData: action.payload})
    },
    [undoSymbols.UNDO_CREATE_NEW_SECTION]: {
      action: ({payload}) => ({ type: teacherSymbols.END_EDIT_BUNDLE_TOC }),
      createArgs: (state, action) => ({undoName: undoName.add_section, undoData: action.payload})
    },
    [undoSymbols.UNDO_DRIP_LESSON]: {
      action: ({payload}) => ({ type: teacherSymbols.END_EDIT_BUNDLE_TOC }),
      createArgs: (state, action) => ({undoName: undoName.drip_lesson, undoData: action.payload})
    },
    [undoSymbols.UNDO_TOGGLE_VIDEO_AUTOPLAY_LESSON]: {
      action: ({payload}) => ({ type: teacherSymbols.END_EDIT_BUNDLE_TOC }),
      createArgs: (state, action) => ({undoName: undoName.toggle_video_autoplay_lesson, undoData: action.payload})
    },
    [undoSymbols.UNDO_TOGGLE_AUDIO_AUTOPLAY_LESSON]: {
      action: ({payload}) => ({ type: teacherSymbols.END_EDIT_BUNDLE_TOC }),
      createArgs: (state, action) => ({undoName: undoName.toggle_audio_autoplay_lesson, undoData: action.payload})
    },
    [undoSymbols.UNDO_TOGGLE_DOWNLOADABLE_PDF]: {
      action: ({payload}) => ({ type: teacherSymbols.END_EDIT_BUNDLE_TOC }),
      createArgs: (state, action) => ({undoName: undoName.toggle_downloadable_pdf, undoData: action.payload})
    },
    [undoSymbols.UNDO_TOGGLE_PRINTABLE_PDF]: {
      action: ({payload}) => ({ type: teacherSymbols.END_EDIT_BUNDLE_TOC }),
      createArgs: (state, action) => ({undoName: undoName.toggle_printable_pdf, undoData: action.payload})
    },
    [undoSymbols.UNDO_FACEBOOK_FEED_LESSON]: {
      action: ({payload}) => ({ type: teacherSymbols.END_EDIT_BUNDLE_TOC }),
      createArgs: (state, action) => ({undoName: undoName.facebook_feed_lesson, undoData: action.payload})
    },
    [undoSymbols.UNDO_UPDATE_CONTENT_LESSON]: {
      action: ({payload}) => ({ type: teacherSymbols.END_EDIT_BUNDLE_TOC }),
      createArgs: (state, action) => ({undoName: undoName.update_lesson_content, undoData: action.payload})
    },
    [undoSymbols.UNDO_RENAME_BUNDLE]: {
      action: ({payload}) => ({ type: teacherSymbols.END_EDIT_BUNDLE_TOC }),
      createArgs: (state, action) => ({undoName: undoName.rename_bundle, undoData: action.payload})
    },
    [undoSymbols.UNDO_CHANGE_LOGO_BUNDLE]: {
      action: ({payload}) => ({ type: teacherSymbols.END_EDIT_BUNDLE_TOC }),
      createArgs: (state, action) => ({undoName: undoName.change_logo_bundle, undoData: action.payload})
    },
    [undoSymbols.UNDO_CHANGE_INSTRUCTOR_INFO_BUNDLE]: {
      action: ({payload}) => ({ type: teacherSymbols.END_EDIT_BUNDLE_TOC }),
      createArgs: (state, action) => ({undoName: undoName.change_instructor_info, undoData: action.payload})
    },
  }
})
