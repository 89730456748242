const NOT_SUPPORTED_ENV = !['production', 'staging', 'staging_mahesh', 'staging_idan', 'development'].includes(window.ENV)

export class StudentTracker {
  static UNIQUE_SESSION_ID = new Date().getTime() + "_" + Math.random();

  static pageView(args = {}, codes = null) {
    if (NOT_SUPPORTED_ENV || typeof window.gtag !== 'function') return;

    const { pathname, search } = window.location;
    let props = {
      page_path: pathname + search,
      ...args
    }

    if (codes && codes.size > 0) {
      props = {...props, send_to: codes.toJS()};
    }

    window.gtag('event', 'page_view', props);
  }

  static track(event = '', args = {}) {
    if (NOT_SUPPORTED_ENV) return;

    if (typeof window.gtag === 'function') {
      window.gtag('event', event, args);
    }

    if (typeof window.fbq === 'function') {
      if (event == 'view_cart') {
        window.fbq('track', 'InitiateCheckout');
      } else if (event == 'purchase') {
        window.fbq('track', 'Purchase', { currency: args['currency'], value: args['value'] })
      }
    }
  }

  static async viewedVideoLength(args = {}) {
    if (NOT_SUPPORTED_ENV || args.viewedLength === 0) return;
  
    const VIDEO_DURATION_ENDPOINT = 'https://schooler.roni-5a2.workers.dev/';
    const eventProperties = {
      courseId: args.courseId,
      lessonId: args.lessonId,
      loadedLength: args.loadedLength,
      studentId: args.studentId,
      teacherId: args.teacherId,
      ts: new Date().getTime(),
      uniqueSessionId: StudentTracker.UNIQUE_SESSION_ID,
      videoId: args.videoId,
      videoLength: args.videoLength,
      viewedLength: args.viewedLength,
    };
  
    try {
      await fetch(VIDEO_DURATION_ENDPOINT, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          "dataSource": "schooler",
          "database": "schooler",
          "collection": "schooler",
          "document": eventProperties
        })
      });
    } catch (e) {
      console.log(e);
    }
  }
};
