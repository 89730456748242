import $ from 'jquery';

export const symbols = {
  START_SEARCH: '@@bundletoc.start-search',
  CLEAR_SEARCH: '@@bundletoc.clear-results',
  MARK_UNREAD: '@@bundletoc.mark-unread',
  MARK_READ: '@@bundletoc.mark-read',
  RECEIVED_SEARCH_RESULTS: '@@bundletoc.received-search-results',
  CREATE_NEW_MODULE: '@@bundletoc.create-new-module',
  SAVE_MODULE: '@@bundletoc.save-module',
  CANCEL_NEW_MODULE: '@@bundletoc.cancel-module',
  ADD_LESSON_TO_NEW_MODULE: '@@bundletoc.add-lesson-to-new-module',
  START_EDIT_LESSONS: '@@bundletoc.start-edit-lessons',
  END_EDIT_LESSONS: '@@bundletoc.end-edit-lessons',
  CANCEL_EDIT_LESSONS: '@@bundletoc.cancel-edit-lessons',
  PUSH_LESSONS_TO_SERVER: '@@bundletoc.push-to-server',
  START_DRAG_CHAPTER: '@@bundletoc.start-drag-chapter',
  END_DRAG_CHAPTER: '@@bundletoc.end-drag-chapter',
  MARK_COMPLETE: '@@bundletoc.mark_complete',
  PARTIAL_COMPLETE: '@@bundletoc.partial_complete',
};

export default {
  addLessonToModule,
  cancelEditLessons,
  cancelNewModule,
  clearSearch: clearSearchResults,
  createNewModule,
  endDragChapter,
  endEditLessons,
  markComplete,
  markRead,
  markUnread,
  partialComplete,
  saveModule,
  startDragChapter,
  startEditLessons,
  startSearch,
};

export function startDragChapter() {
  return {
    type: symbols.START_DRAG_CHAPTER,
    payload: {},
    meta: {
      modal: {
        cancel: endDragChapter(),
        closedWithTypes: [symbols.END_DRAG_CHAPTER],
      },
    },
  };
}

export function endDragChapter() {
  return { type: symbols.END_DRAG_CHAPTER, payload: {} };
}


export function createNewModule() {
  return { type: symbols.CREATE_NEW_MODULE, payload: {} };
}

export function saveModule(moduleId) {
  return { type: symbols.SAVE_MODULE, payload: { id: moduleId } };
}

export function cancelNewModule(moduleId) {
  return { type: symbols.CANCEL_NEW_MODULE, payload: { id: moduleId } };
}

export function addLessonToModule(moduleId, lessonName) {
  return { type: symbols.ADD_LESSON_TO_NEW_MODULE, payload: { id: moduleId, lessonName } };
}

export function startEditLessons() {
  return { type: symbols.START_EDIT_LESSONS };
}

export function endEditLessons() {
  return { type: symbols.END_EDIT_LESSONS };
}

export function cancelEditLessons() {
  return { type: symbols.CANCEL_EDIT_LESSONS };
}

export function startSearch(keyword) {
  return (dispatch, getstate) => {
    const url = getstate().$$bundletoc.get('searchPath');
    $.get(url, { q: keyword }, function(results) {
      dispatch(receivedSearchResults(results));
    });
  };
}

export function receivedSearchResults(results) {
  return { type: symbols.RECEIVED_SEARCH_RESULTS, payload: results };
}

export function clearSearchResults() {
  return { type: symbols.CLEAR_SEARCH };
}

export function markUnread(lessonId) {
  return (dispatch, getstate) => {
    const lessons = getstate().$$bundletoc.get('lessons');
    const idx = lessons.findIndex(lsn => lsn.get('lesson_id') === lessonId);
    const url = lessons.getIn([idx, 'unwatch_url']);

    dispatch({ type: symbols.MARK_UNREAD, payload: lessonId });
    $.post(url);
  };
}

export function markRead(lessonId) {
  return { type: symbols.MARK_READ, payload: lessonId };
}

export function markComplete(params) {
  return (dispatch, getstate) => {
    if (!getstate().$$layout.get('edit')) {
      dispatch({ type: symbols.MARK_COMPLETE, payload: params });
      $.post(params.slug + '/lesson_in_user_logs',params);
    }
  };
}

export function partialComplete(params) {
  return (dispatch) => {
    dispatch({ type: symbols.PARTIAL_COMPLETE, payload: params });
    $.post(params.slug + '/lesson_in_user_logs',params);
  };
}
