import React, { PropTypes } from 'react';
import {Howl} from 'howler';
import SeekBar from '../seek_bar';
import VolumeControl from '../volume_control';
import { connect } from 'react-redux';
import { navigateToNextLesson } from '../../actions/ajax';
import { navigateToPreviousLesson } from '../../actions/ajax';
import {getLessonIndexById} from "../bundletoc/selectors/lesson";
import actions from '../../actions/bundletoc';
import { StudentTracker } from '../../services/StudentTracker';
import { merge } from 'lodash';
import classNames from 'classnames';

class AudioTab extends React.Component {
  static displayName = 'AudioTab';

  static propTypes = {
    pdfurl: React.PropTypes.string,
    isTeacher: React.PropTypes.bool,
    dispatch: React.PropTypes.func.isRequired,
    sounds: React.PropTypes.object,
    interval: React.PropTypes.object,
    forwardBtnImg: React.PropTypes.string,
    reverseBtnImg: React.PropTypes.string,
    playBtnImg: React.PropTypes.string,
    pauseBtnImg: React.PropTypes.string,
    previousBtnImg: React.PropTypes.string,
    nextBtnImg: React.PropTypes.string,
    muteBtnImg: React.PropTypes.string,
    unmuteBtnImg: React.PropTypes.string,
    jumpSpan: React.PropTypes.number,
    maxVolume: React.PropTypes.number,
    lessonWatched: React.PropTypes.bool,
    audioCompletion: PropTypes.number,
  };

  state = {
    isPlaying: false,
    setMute: true,
    seekPosition:  this.props.seekStart,
    volume: 1.0,
    playBackSpeed: 1,
    previousVolume: 0.5,
    playCounter: 0,
  };

  static defaultProps = {
    isTeacher: false,
    jumpSpan: 10,
    maxVolume: 100,
    delayTime: 200,
    forwardBtnImg: "/images/forward-icon.svg",
    reverseBtnImg: "/images/reverse-icon.svg",
    playBtnImg: "/images/play-icon.svg",
    pauseBtnImg: "/images/stop-icon.svg",
    previousBtnImg: "/images/previous-icon.svg",
    nextBtnImg: "/images/next-icon.svg",
    muteBtnImg: "/images/mute-icon.png",
    unmuteBtnImg: "/images/unmute-icon.svg",
  }

  static tid = 6;
  static tabName = 'אודיו';
  isWatched = this.props.lessonWatched;
  duration = this.props.audioDuration;
  counter = 1;
  lastLocationThreshold = 5000;
  seekControl = 0;
  sounds = null;

  componentDidMount(){
    let howlOptions = {
      src: [this.props.pdfurl],
      html5: true,
      onend: () => {
        this.endAudio();
      },
    };

    if (this.props.audioAutoplay === 'true' && !(window.location.pathname.split('/').includes("edit"))) {
      howlOptions.autoplay = true;
    }
    this.sounds = new Howl(howlOptions);

    if (this.props.audioAutoplay === 'true' && !(window.location.pathname.split('/').includes("edit"))) {
        this.sounds.once('play', () => {
          if (this.state.playCounter == 0) {
            this.playPauseToggle();
          }
        });
    }

    this.sounds.seek(this.props.seekStart);
  };

  componentWillUnmount(){
    this.sounds.stop();
    this.sendLastLocation();
  };

  navigateToNextLesson = () => {
    this.props.dispatch(navigateToNextLesson());
  };

  navigateToPreviousLesson = (e) => {
    this.props.dispatch(navigateToPreviousLesson());
  };

  handleChange = (e) => {
    this.setState({playBackSpeed: e.target.value});
    this.sounds.rate(parseFloat(e.target.value));
  };

  endAudio = () => {
    this.setState({seekPosition: 0.1});
    this.playBtnObj.click();
    if (this.props.moveToNextLesson) {
      this.navigateToNextLesson();
    }
  };

  seekAudio = () => {
    const percentCompleted = (this.state.seekPosition / this.props.audioDuration) * 100;
    if (percentCompleted >= this.props.audioCompletion && !this.isWatched ) {
      this.sendCompletion();
    }
    return this.sounds.seek();
  };

  trackLessonComplete = (props) => {
    let itemProps = {
      'item_brand': props.teacherName,
      'item_brand_id': props.teacherId,
      'item_id': props.bundleid,
      'item_name': props.bundleName,
      'item_category': props.schoolName,
      'item_category2': props.bundleType,
    }
    StudentTracker.track(`lesson_${props.lessonSeq}_complete`, merge(itemProps, {
      'lesson_index_number': props.lessonSeq,
      'lesson_name': props.lessonName,
      'lesson_type': props.lessonType,
      'episode_index_number': props.sectionSeq,
      'episode_name': props.sectionName,
    }));

    if(props.watchedLessons + 1 == props.totalLessons){
      StudentTracker.track('finish_course', itemProps);
    }
  }

  playSound = () => {
    this.sounds.play();
  };

  pauseSound = () => {
    this.sounds.pause();
  };

  playPauseToggle = (e) => {

    const {isPlaying, playCounter} = this.state;
    this.setState({playCounter: 1})
    if (isPlaying) {
      this.pauseSound();
      this.sendLastLocation();
      clearInterval(this.interval);
    }
    else {
      this.sounds.seek(this.state.seekPosition);
      this.playSound();
      this.seekAudio();
      this.interval   = setInterval(
        () => this.setState({ seekPosition:  this.seekAudio() } ),
        this.props.delayTime,
      );
    }
    this.setState({ isPlaying: !isPlaying });

  };

  muteUnmuteToggle = (e) => {
    const {setMute} = this.state;
    this.setState({ previousVolume: this.state.volume });

    if (setMute) {
      this.setState({ volume: 0.0 });
      this.sounds.mute(this.state.setMute);
    }
    else {
      this.sounds.mute(this.state.setMute);
      this.setState({ volume: this.state.previousVolume });
    }
    this.setState({ setMute: !setMute });

  };

  forwardAudio = () => {
    let forwardTo = this.state.seekPosition + this.props.jumpSpan;

    if (forwardTo >= this.props.audioDuration) {
      forwardTo = this.props.audioDuration;
    }

    this.setState({seekPosition: forwardTo});
    this.sounds.seek(forwardTo);


  };

  backwardAudio = () => {
    let backwardTo = this.state.seekPosition - this.props.jumpSpan;

    if (backwardTo < 0) {
      backwardTo = 0
    }

    this.setState({seekPosition: backwardTo});
    this.sounds.seek(backwardTo);


  };

  getDragPosition = (dragPosition) => {

    if(this.state.isPlaying){
      this.playPauseToggle();
      this.seekControl = 1;
    }

    const params = this.makeParams();
    params.seekPosition = dragPosition
    const percentCompleted = (params.seekPosition / this.props.audioDuration) * 100;
    params.type = (percentCompleted >= this.props.audioCompletion) ? 'audio_completed' : 'audio_partially_complete';

    if (!(window.location.pathname.split('/').includes("edit"))) {
      if (params.type == 'audio_completed') {
        this.props.dispatch(actions.markComplete(params));

        if (this.viewableLesson()) {
          this.props.dispatch(actions.markRead(params.id));
        }

      } else {
        this.props.dispatch(actions.partialComplete(params));
      }
    };

    this.setState({seekPosition: dragPosition});
    this.sounds.seek(dragPosition);

  };

  startSound = () => {
    if (this.seekControl == 1 ) {
      this.seekControl = 0;
      this.playPauseToggle();
    }
  };

  getVolumePosition = (volumePosition) => {
    const {setMute} = this.state;
    if (volumePosition === 0) {
      this.setState({setMute: false});
    } else {
      if (!setMute) {
        this.sounds.mute(this.state.setMute);
      }
      this.setState({setMute: true});
    }
    this.sounds.volume([volumePosition / 100]);
    this.setState({volume: volumePosition / 100});
  };

  sendCompletion = () => {
    const params = this.makeParams()
    this.isWatched = true;
    if (!(window.location.pathname.split('/').includes("edit"))) {
      this.props.dispatch(actions.markComplete(params));
      this.trackLessonComplete(this.props);
    }

    if (this.viewableLesson()) {
      this.props.dispatch(actions.markRead(params.id));
    }
  };

  makeParams = () => {
    const percentCompleted = (this.state.seekPosition / this.props.audioDuration) * 100;
    const audioCompletionType = (percentCompleted >= this.props.audioCompletion) ? 'audio_completed' : 'audio_partially_complete';

    return {
      duration: this.props.audioDuration,
      id: this.props.lessonId,
      slug: window.location.pathname.split('/').pop(),
      type: audioCompletionType,
      seekPosition: this.state.seekPosition,
    }
  }

  viewableLesson = () => {
    return this.props.lessonId && (this.props.free || this.props.purchased) && this.props.dripNotActive;
  }

  sendLastLocation = () => {
    const params = this.makeParams();
    if (!(window.location.pathname.split('/').includes("edit"))) {
      this.props.dispatch(actions.partialComplete(params));
    }
  };

  handleEnterKeyPress(action, e) {
    if (e.key === 'Enter') {
      action(e);
    }
  }

  render() {
    const audioThumbnail = this.props.audioThumbnail;
    const audioTranslations = this.props.audioTranslations;

    const muteUnmuteText = this.state.setMute ? audioTranslations.unmute : audioTranslations.mute;
    const muteUnmuteAriaLabel = this.state.setMute ? audioTranslations.unmuteAriaLabel : audioTranslations.muteAriaLabel;

    return (
      <div className='audioTab'>
        <div className='audio-image'>
          <img
            src={audioThumbnail}
          />
        </div>

        <SeekBar
          value={this.state.seekPosition}
          max={this.props.audioDuration}
          sendDragPosition={this.getDragPosition}
          check={this.startSound}
        />

        <div className='audio-player'>
          <div className='volume-btn'>
            <VolumeControl
              value={this.state.volume}
              max={this.props.maxVolume}
              sendVolumePosition={this.getVolumePosition}
            />
            <span className='nm-tooltiped '
              data-toggle="tooltip"
              aria-label={muteUnmuteAriaLabel}
              tabIndex="0"
              role="button"
              onClick={(e) => this.muteUnmuteToggle(e)}
              onKeyDown={(e) => this.handleEnterKeyPress(this.muteUnmuteToggle, e)}
            >
              <img className='mute-unmute-btn' src={this.state.setMute ? this.props.unmuteBtnImg : this.props.muteBtnImg} />
              <span className="nm-tooltip">
                <span className="tooltip-inner">
                  {muteUnmuteText} </span>
              </span>
            </span>
          </div>

          <div className='player-buttons'>
            <span className='nm-tooltiped '
              data-toggle="tooltip"
              tabIndex="0"
              role="button"
              aria-label={audioTranslations.nextAriaLabel}
              onClick={(e) => this.navigateToNextLesson(e)}
              onKeyDown={(e) => this.handleEnterKeyPress(this.navigateToNextLesson, e)}
            >
              <img className={classNames('next-btn', { 'next-btn-disable': !this.props.nextChapter })} src={this.props.nextBtnImg} />
              <span className="nm-tooltip">
                <span className="tooltip-inner">{audioTranslations.next}</span>
              </span>
            </span>
            <span className='nm-tooltiped '
              data-toggle="tooltip"
              tabIndex="0"
              role="button"
              aria-label={audioTranslations.skipForwardAriaLabel}
              onClick={(e) => this.forwardAudio(e)}
              onKeyDown={(e) => this.handleEnterKeyPress(this.forwardAudio, e)}
            >
              <img className='forward-btn' src={this.props.forwardBtnImg} />
              <span className="nm-tooltip">
                <span className="tooltip-inner">{audioTranslations.skipForward}</span>
              </span>
            </span>
            <span className='nm-tooltiped '
              data-toggle="tooltip"
              tabIndex="0"
              role="button"
              aria-label={this.state.isPlaying ? audioTranslations.pauseAriaLabel : audioTranslations.playAriaLabel}
              onClick={(e) => this.playPauseToggle(e)}
              onKeyDown={(e) => this.handleEnterKeyPress(this.playPauseToggle, e)}
            >
              <img className='play-pause-btn'
                src={this.state.isPlaying ? this.props.pauseBtnImg : this.props.playBtnImg}
                ref={(img) => (this.playBtnObj = img)}
              />
              <span className="nm-tooltip">
                {this.state.isPlaying
                  ? <span className="tooltip-inner">{audioTranslations.pause}</span>
                  : <span className="tooltip-inner">{audioTranslations.play}</span>
                }
              </span>
            </span>
            <span className='nm-tooltiped '
              data-toggle="tooltip"
              tabIndex="0"
              role="button"
              aria-label={audioTranslations.skipBackAriaLabel}
              onClick={(e) => this.backwardAudio(e)}
              onKeyDown={(e) => this.handleEnterKeyPress(this.backwardAudio, e)}
            >
              <img className='reverse-btn'
                src={this.props.reverseBtnImg}
              />
              <span className="nm-tooltip">
                <span className="tooltip-inner">{audioTranslations.skipBack}</span>
              </span>
            </span>
            <span className='nm-tooltiped '
              data-toggle="tooltip"
              tabIndex="0"
              role="button"
              aria-label={audioTranslations.previousAriaLabel}
              onClick={(e) => this.navigateToPreviousLesson(e)}
              onKeyDown={(e) => this.handleEnterKeyPress(this.navigateToPreviousLesson, e)}
            >
              <img className={classNames('previous-btn', {'previous-btn-disable': !this.props.previousChapter})} src={this.props.previousBtnImg} />
              <span className="nm-tooltip">
                <span className="tooltip-inner">{audioTranslations.previous}</span>
              </span>
            </span>
          </div>

          <div className='playback-btn nm-tooltiped' >
            <select className="playback-speed"
              data-toggle="tooltip"
              aria-label={audioTranslations.speedAriaLabel}
              value={this.state.playBackSpeed}
              onChange={(e) => this.handleChange(e)}
            >
              <option value="0.75">0.75x</option>
              <option value="1">1x</option>
              <option value="1.25">1.25x</option>
              <option value="1.5">1.5x</option>
              <option value="1.75">1.75x</option>
              <option value="2">2x</option>
            </select>

            <span className="nm-tooltip">
              <span className="tooltip-inner">{audioTranslations.speed}</span>
            </span>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {

  const lessonId   = state.$$lesson.get('id');
  const lessonIdx  = lessonId ? state.$$bundletoc.get('lessons').findIndex((l) => l.get('lesson_id') === lessonId) : -1;
  const lessonData = lessonId ? state.$$bundletoc.getIn(['lessons', lessonIdx]) : Immutable.Map();
  const editLessonData = state.teacher.editLesson.get('lessonNameData');
  const lessonName = (editLessonData ? editLessonData.name : lessonData.get('lesson_name')) || '';
  const lessonWatched = state.$$lesson.get('lessonWatched');
  const audioAutoplay = state.$$lesson.get('audioAutoplay') || 'true';
  const audioCompletion = state.$$lesson.get('audioCompletion');
  const seekStart = state.$$lesson.get('seekStart');
  const audioDuration =  state.teacher.editLesson.get('audioDuration') ? state.teacher.editLesson.get('audioDuration') : state.$$lesson.get('audioDuration') ;
  const previousChapter = state.$$lesson.get('prev');
  const nextChapter = state.$$lesson.get('next');
  const watchedLessons = state.$$bundletoc.get('lessons').filter((lesson) => lesson.get('watched') == true).size;
  const totalLessons = state.$$bundletoc.get('lessons').size;
  const moveToNextLesson =  state.$$lesson.get('moveToNextLesson')

  return {
    dripNotActive: !state.$$lesson.getIn(['drip', 'active']),
    free: state.$$lesson.get('free') || false,
    purchased: state.$$layout.getIn(['default_bundle_props', 'owned']),
    lessonName,
    lessonId: lessonId,
    lessonIndex: getLessonIndexById(state, lessonId),
    lessonWatched,
    audioCompletion,
    seekStart,
    audioDuration,
    previousChapter,
    nextChapter,
    audioTranslations: {
      ...state.$$i18n.getIn(['students', 'lesson', 'audio']).toJS()
    },
    watchedLessons,
    totalLessons,
    lessonType: lessonData.get('type'),
    lessonSeq: lessonData.get('lesson_seq'),
    sectionSeq: lessonData.get('section_seq'),
    sectionName: lessonData.get('section_name'),
    audioAutoplay,
    moveToNextLesson,
  };
}

export default connect(mapStateToProps)(AudioTab);
