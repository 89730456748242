import EditAccount from '../containers/edit_account';
import Lesson from '../containers/teacher/lesson';
import ShoppingCart from '../containers/shoppingcart';
import Login from '../containers/login';
import JoinCourse from '../containers/join_course';
import JoinSchool from '../containers/join_school';
import SelectNewPassword from '../containers/reset_password';
import AfterJoin from '../containers/after_join';
import ResetPassword from '../containers/password_new';
import Bundles from '../containers/bundles';
import AfterPay from '../containers/after_pay';

export default function routes(router, initialState) {
  const customDomain = initialState.$$layout.get('customDomain');
  const explicitSchool = initialState.$$school.get('explicit');

  for (const prefix of ['/s/:school', '']) {
    router.get(`${prefix}/:bundleid/:lessonid`, respondWith(Lesson));
    router.get(`${prefix}/:bundleid/:lessonid/edit`, respondWith(Lesson));
    router.get(`${prefix}/:bundleid/`, respondWith(Lesson));

    router.get(`${prefix}/`, respondWith(Bundles));
    router.get(`${prefix}/bundles`, respondWith(Bundles));
    router.get(`${prefix}/:bundleid/afterpay`, respondWith(AfterPay));

    router.get(`${prefix}/user/edit`, respondWith(EditAccount));
    router.get(`${prefix}/login`, respondWith(Login));

    if (explicitSchool) {
      router.get(`${prefix}/user/signup`, respondWith(JoinSchool, false));
    } else {
      router.get(`${prefix}/user/signup`, respondWith(JoinCourse, false));
    }
    router.get(`${prefix}/user`, respondWith(AfterJoin, false));
    router.get(`${prefix}/afterjoin`, respondWith(AfterJoin, false));
    router.get(`${prefix}/quickjoin`, respondWith(AfterJoin, false));
    router.get(`${prefix}/reset/edit`, respondWith(SelectNewPassword));
    router.get(`${prefix}/reset/new`, respondWith(ResetPassword));

    router.get(`${prefix}/reset`, respondWith(ResetPassword));
    router.get(`${prefix}/shoppingcart`, respondWith(ShoppingCart));
  }
}

function respondWith(component, lazyLoad = true) {
  return function (req, res, next) {
    res.component = component;
    res.props = Object.assign({}, { routerParams: req.params }, res.props);
    req.lazyLoad = lazyLoad;
    next();
  };
}

