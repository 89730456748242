import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isVooPlayer, VOO_PLAYER_URL, VOO_PLAYER_DOMAINS } from '../helpers/video_helper';
import { callPlayer, getSDK, randomString } from 'react-player/lib/utils';

const SDK_GLOBAL = 'vooAPI';
const PLAYER_ID_PREFIX = 'voo-player-';

export default class VooPlayer extends Component {
    static displayName = 'VooPlayer'
    static canPlay = (url) => isVooPlayer(url);
    static loopOnEnded = true;
    callPlayer = callPlayer
    playerID = this.props.config.playerId || `${PLAYER_ID_PREFIX}${randomString()}`;
    static propTypes = {
      config: PropTypes.object,
      playing: PropTypes.bool,
      onError: PropTypes.func,
      onEnded: PropTypes.func,
      onProgress: PropTypes.func,
      onMount: PropTypes.func,
      url: PropTypes.string,
    };
    componentDidMount() {
      if (this.props.onMount) {
        this.props.onMount(this);
      }
    }
   
    load(url, isReady) {
      const { playing, onError, onEnded, onProgress, lessonId } = this.props;
      window.lessonId = lessonId;
      getSDK(VOO_PLAYER_URL, SDK_GLOBAL).then(() => {
          window.addEventListener('message', e => {
            if (e.data && e.data.data &&VOO_PLAYER_DOMAINS.some(domain => e.origin.indexOf(domain) !== -1)) {
              const { data: { videoId, name, method } } = e.data;
              //NOTE:playerSettings event should fire also
              if (name === 'videoRatio') {
                if (playing) {
                  window.vooAPI(videoId, 'play');
                }
                //listen to clip ended event
                window.vooAPI(videoId, 'onEnded', null, (...args) => onEnded(...args));
              } else if (method === 'onEnded') {
                onProgress ( {'lessonId': window.lessonId} );
                onEnded(e.data.data)
              }
            }
          })
      }, onError);
    }
    unbind() {
    }

    play() {
      this.callPlayer('play');
    }

    pause() {
      this.callPlayer('pause');
    }

    stop() {
      this.unbind();
      this.callPlayer('remove');
    }

    seekTo(seconds) {
      this.callPlayer('time', seconds);
    }

    setVolume(fraction) {
      this.callPlayer('volume', fraction);
    }

    mute = () => {
      this.callPlayer('mute');
    }

    unmute = () => {
      this.callPlayer('unmute');
    }

    setPlaybackRate(rate) {
      this.callPlayer('playbackRate', rate);
    }

    getDuration() {
      return this.callPlayer('duration');
    }

    getCurrentTime() {
      return this.callPlayer('time');
    }

    getSecondsLoaded() {
      return null;
    }
    render() {
      const { url, config } = this.props;
      const playerId = config.playerId;
      const className = `vooplayer_container_${playerId}`;
      const style = {
        width: '100%',
        height: '100%',
      };
      return (
        <div id={playerId} key={playerId} className={className} style={style} >
          <iframe
            allow='autoplay'
            className='video-player-container spotlightr'
            src={url}
            data-playerid={playerId}
            allowfullscreen='true'
            allowtransparency='true'
            style={{ maxWidth: '100%' }}
            name='vooplayerframe'
            frameborder='0'
            scrolling='no'
          >
          </iframe>
        </div>
      );
    }
}
