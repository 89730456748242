export default class BaseFileValidator {
  static FILE_NAME_ALLOWED_CHARS = "a-zÀ-ÿа-яא-תء-ي0-9_-";
  static FILE_NAME_REGEX = new RegExp(
    `^[${BaseFileValidator.FILE_NAME_ALLOWED_CHARS}]+(?=\\.[a-z0-9]+$)`,
    "i"
  );
  static FILE_NAME_SANITIZED_REGEX = new RegExp(
    `[^${BaseFileValidator.FILE_NAME_ALLOWED_CHARS}](?=.*\\.[a-z0-9]+$)`,
    "gi"
  );
  static DEBUG = false;
  static FILE_TYPE = "";
  static MAX_FILE_SIZE = 5; // MB
  static MEGA_BYTE_IN_BYTES = 1048576; // 1MB = 1024 * 1024
  static MIME_TYPES = [];
  static REPLACE_CHAR_REGEX = /[\s\t\(\)]+/g;
  static SEPARATOR = "_";

  constructor(i18n = {}, userRules = []) {
    this.file = null;
    this.i18n = { ...i18n };
    this.rules = [
      ...this.defaultRules,
      ...(this.typeSpecificRules || []),
      ...userRules,
    ];
  }

  get defaultRules() {
    return [
      "isFileNameValid:skip",
      "isFileSizeValid:exitOnError",
      "isFileMimeTypeValid:exitOnError",
    ];
  }

  get mimeType() {
    return this.constructor.MIME_TYPES.join(", ");
  }

  get sanitizedFilename() {
    return BaseFileValidator.sanitizeFilename(this.file.name, true);
  }

  static sanitizeFilename(filename = "", withOutExtension = false) {
    try {
      filename = filename
        .trim()
        .replace(
          BaseFileValidator.FILE_NAME_SANITIZED_REGEX,
          BaseFileValidator.SEPARATOR
        );

      if (withOutExtension) {
        filename = filename.split(".").slice(0, -1).join(".");
      }
    } catch (error) {
      console.warn(error);
    } finally {
      return filename;
    }
  }

  async validate() {
    const errors = [];
    const suggestions = [];

    for (const rule of this.rules) {
      const [ruleName, ...flags] = rule.split(":");

      if (flags.includes("skip")) continue;

      const message = await this.ruleValidation(ruleName);

      if (
        typeof message !== "string" ||
        (!this.constructor.DEBUG && flags.includes("silent"))
      ) {
        continue;
      }

      if (flags.includes("exitOnSuggestion")) {
        suggestions.push(message);
        break;
      }

      if (flags.includes("exitOnError")) {
        errors.push(message);
        break;
      }

      if (flags.includes("required")) {
        errors.push(message);
        continue;
      }

      suggestions.push(message);
    }

    return {
      errors: errors,
      isValid: errors.length === 0,
      suggestions: suggestions,
    };
  }

  async ruleValidation(ruleName) {
    let message = true;

    try {
      const unvalidRule = this[ruleName]?.apply(this);

      if (unvalidRule) {
        message = unvalidRule;
      }
    } catch (error) {
      message = error.message;
      console.warn(ruleName, error);
    } finally {
      return message;
    }
  }

  isFileNameValid() {
    const validFilename = BaseFileValidator.FILE_NAME_REGEX.test(this.file.name);

    if (!validFilename) {
      return this.i18n.isFileNameValid.replace(
        "%{sanitizedFilename}",
        this.sanitizedFilename
      );
    }
  }

  isFileMimeTypeValid() {
    if (!this.constructor.MIME_TYPES.includes(this.file.type)) {
      return this.i18n.isFileMimeTypeValid.replace(
        "%{acceptedFormats}",
        this.mimeType
      );
    }
  }

  isFileSizeValid() {
    const fileSize = this.file.size / this.constructor.MEGA_BYTE_IN_BYTES;
    const validFileSize =
      this.constructor.MAX_FILE_SIZE === 0 ||
      fileSize <= this.constructor.MAX_FILE_SIZE;

    if (!validFileSize) {
      return this.i18n.isFileSizeValid.replace(
        "%{maxFileSize}",
        this.constructor.MAX_FILE_SIZE
      );
    }
  }
}
