// This file is our manifest of all reducers for the app.
// See also /client/app/bundles/HelloWorld/store/helloWorldStore.jsx
// A real world app will likely have many reducers and it helps to organize them in one file.
// `https://github.com/shakacode/react_on_rails/tree/master/docs/additional_reading/generated_client_code.md`
// import { $$initialState as $$helloWorldState } from './helloWorldReducer';

import bundleTocReducer, { initialState as $$bundletoc } from './bundletoc';
import { initialState as $$bundleIndex } from './bundle_index';
import layoutReducer, { initialState as $$layout } from './layout';
import lessonReducer, { initialState as $$lesson } from './lesson';
import attachedFilesReducer, { initialState as $$attachedFiles } from './lesson/attached_files';
import blogReducer, { initialState as $$blog } from './blog_reducer';
import contactFormReducer, { initialState as $$contactForm } from './contactform';
import navReducer, { initialState as $$nav } from './nav';
import accountReducer, { initialState as $$account } from './account';
import reseller, { initialState as $$reseller } from './reseller';
import cartReducer, { initialState as $$cart } from './cart';
import themeReducer, { initialState as $$theme } from './theme';
import schoolReducer, { initialState as $$school } from './school';
import {undoHistoryReducerCreator} from 'redux-undo-redo';
import undoRedoCableReducer from './undo_redo/undo_redo_cable';
import undoRedoSplashReducer from './undo_redo/undo_redo_splash';

// Teacher Reducers
import teacherReducer from './teacher/index';

const undoHistoryReducer = undoHistoryReducerCreator({
  undoHistoryLimit: 50
})

import Immutable from 'immutable';
import { reducer as formReducer } from 'redux-form';

export const initialStates = {
  $$bundletoc,
  $$bundleIndex,
  $$about: Immutable.Map({ text: '' }),
  $$cart,
  $$account,
  $$layout,
  $$lesson,
  $$attachedFiles,
  $$i18n: Immutable.Map({}),
  $$contactForm,
  $$blog,
  $$nav,
  $$reseller,
  $$newsletter: Immutable.Map({ intro_text: '', items: Immutable.List.of() }),
  $$meta: Immutable.Map({ timestamp: 0 }),
  teacher: teacherReducer(undefined, { type: '@@init' }),
  $$afterjoin: Immutable.Map({}),
  $$theme,
  $$school,
};

function nullReducer(key) {
  return (state = initialStates[key], action) => state;
}

export default {
  $$account: accountReducer,
  $$about: nullReducer('$$about'),
  $$blog: blogReducer,
  $$bundletoc: bundleTocReducer,
  $$bundleIndex: nullReducer('$$bundleIndex'),
  $$cart: cartReducer,
  $$contactForm: contactFormReducer,
  $$i18n: nullReducer('$$i18n'),
  $$layout: layoutReducer,
  $$lesson: lessonReducer,
  $$attachedFiles: attachedFilesReducer,
  $$nav: navReducer,
  $$reseller: reseller,
  $$newsletter: nullReducer('$$newsletter'),
  $$meta: nullReducer('$$meta'),
  $$afterjoin: nullReducer('$$afterjoin'),
  $$theme: themeReducer,
  form: formReducer,
  teacher: teacherReducer,
  $$school: schoolReducer,
  undoHistory: undoHistoryReducer,
  undoRedoCableCarChannel: undoRedoCableReducer,
  undoRedoSplash: undoRedoSplashReducer,
};
