import { useSelector, useDispatch } from "react-redux";
import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useEffect } from "react";

import { showFilesPanel } from "app/actions/layout";
import { STATUS_TYPES } from "app/reducers/lesson/attached_files";
import attachedFilesActions from "app/actions/lesson/attached_files";
import EditableFilesList from "app/components/lesson/attached_files/editable_files_list";
import ViewableFilesList from "app/components/lesson/attached_files/viewable_files_list";

function AttachedFiles({ className, editable, enableDropdown, userColors }) {
  const dispatch = useDispatch();
  const [lessonId, status, files] = useSelector((state) => [
    state.$$lesson.get("id"),
    state.$$attachedFiles.get("status"),
    state.$$attachedFiles.get("files")
  ]);

  useEffect(() => {
    if (lessonId) {
      dispatch(attachedFilesActions.listFiles());
    }
  }, [lessonId, dispatch, editable]);

  useEffect(() => {
    if (!editable && files.length > 0) {
      dispatch(showFilesPanel());
    }
  }, [files, dispatch, editable]);

  return (
    <div
      className={classNames("attached-files", className, {
        [`status-${status}`]: true,
        "editable-box": editable
      })}>
      {editable ? (
        <EditableFilesList
          files={files}
          onDelete={(file) => dispatch(attachedFilesActions.deleteFile(file))}
          onDrop={(files) => dispatch(attachedFilesActions.uploadFiles(files))}
          onRename={(file, newBasename) => dispatch(attachedFilesActions.renameFile(file, newBasename))}
          isProcessing={status === STATUS_TYPES.BUSY}
        />
      ) : (
        <ViewableFilesList
          enableDropdown={enableDropdown}
          files={files}
          userColors={userColors}
        />
      )}
    </div>
  );
}

AttachedFiles.propTypes = {
  className: PropTypes.string,
  editable: PropTypes.bool,
  enableDropdown: PropTypes.bool,
  userColors: PropTypes.object
};

AttachedFiles.defaultProps = {
  className: "",
  editable: false,
  enableDropdown: false,
  userColors: { color: "" }
};

export default AttachedFiles;
