import React from 'react';
import Slider from 'react-rangeslider';

class SeekBar extends React.Component {
  constructor (props, context) {
    super(props, context)
    this.state = {
      value: this.props.value,
    }
  }

  formatTime = (totalSeconds) => {
    totalSeconds = parseInt(totalSeconds);
    let hours = Math.floor(totalSeconds / 3600);
    totalSeconds %= 3600;
    let minutes = Math.floor(totalSeconds / 60);
    let seconds = totalSeconds % 60;

    minutes = String(minutes).padStart(2, "0");
    hours = String(hours).padStart(2, "0");
    seconds = String(seconds).padStart(2, "0");
    return(hours + ":" + minutes + ":" + seconds);
  }

  handleSeek = (value) => {
    this.setState({
      value: value
    });
    this.props.sendDragPosition(value);
  };

  handleEvent = () => {
    this.props.check();
  }

  render () {
    return (
      <div className='slider'>
        <Slider
          min={0}
          max={parseInt(this.props.max)}
          tooltip={false}
          value={parseInt(this.props.value)}
          onChange={this.handleSeek }
          onChangeComplete={ this.handleEvent }
        />
        <div className='seek-time'>{this.formatTime(this.props.value)}
          <span className='duration-time'>{this.formatTime(this.props.max)}</span>
        </div>
      </div>
    )
  }
};

export default SeekBar;
