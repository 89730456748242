import React from 'react';
import { useSelector } from 'react-redux';
import SiteLogo from '../components/svg/site_logo';
import Links from '../constants/links';

export default function FullpageError(props) {
  const storeData = useSelector(({ $$i18n }) => ({
    i18nCredit: $$i18n.getIn(['system', 'credit']),
    i18nCreditAriaLabel: $$i18n.getIn(['system', 'creditAriaLabel'])
  }));

  return (
    <div className={`general-message ${props.className}`}>
      <SiteLogo />

      <p dangerouslySetInnerHTML={{ __html: props.text }}></p>

      <a href={Links.SCHOOLER_HOMEPAGE_URL} target="_blank" aria-label={storeData.i18nCreditAriaLabel} className="text-xs sub">
        {storeData.i18nCredit}
      </a>
    </div>
  );
}

