import Immutable from 'immutable';
import { symbols as cartSymbols } from '../actions/cart';
import { symbols as navSymbols } from '../actions/ajax';
import cartActions from '../actions/cart';
import $ from 'jquery';
import { connect } from 'react-redux';

export const initialState = Immutable.Map({
  items: Immutable.List(),
  serverError: null,
});

export default function cartReducer(state = initialState, action) {
  switch (action.type) {
    case navSymbols.APPSTATE_MERGE:
      return state.set('notification', null);

    case cartSymbols.AJAX_START:
      return state.set('notification', null);

    case cartSymbols.ERROR:
      return state.set('notification', action.payload);

    case cartSymbols.AJAX_END:
      return (action.payload && action.payload.cart) ? state.merge(action.payload.cart) : state;

    default:
      return state;
  }
  return state;
}
