import React from 'react';
import { connect } from 'react-redux';

const FBID = 'facebook-jssdk'

class FBComments extends React.Component {
  render() {
    const { href, enabled } = this.props;
    if (!enabled) {
      return false;
    }
    
    return (
      <div className="fb-comments-container">
        <div className="fb-comments" ref={(el) => { this.el = el; } } data-href={href} ></div>
      </div>
    )
  }

  addAdminMetaTag() {
    const { fbid } = this.props;
    if (!fbid) {
      return this.removeMetaTag();
    }
    
    let meta = document.querySelector('meta[property="fb:admins"]');
    if (!meta) {
      meta = document.createElement('meta');
      document.head.appendChild(meta);
      meta.setAttribute('property', 'fb:admins');
    }

    meta.setAttribute('content', fbid);
  }

  parse() {
    if (typeof FB !== 'undefined') {
      FB.XFBML.parse();
      return true;
    }
    return false;
  }

  removeMetaTag() {
    const meta = document.querySelector('meta[property="fb:admins"]');
    if (meta) {
      meta.parentElement.removeChild(meta);
    }
  }

  componentDidMount() {
    this.addAdminMetaTag();
    if (this.parse()) {
      return;
    }
    
    
    (function(d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s); js.id = id;
      js.src = 'https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v2.12';
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', FBID));
  }

  componentWillUnmount() {
    if (this.el) {
      this.el.innerHTML = '';
    }
    this.removeMetaTag();
  }

  componentDidUpdate() {
    this.addAdminMetaTag();
    this.parse();
  }
}

function mapStateToProps(state) {
  let hrefWithoutParams = state.$$layout.get('requestUrl');
  if (hrefWithoutParams.indexOf('?') >= 0) {
    const idx = hrefWithoutParams.indexOf('?');
    hrefWithoutParams = hrefWithoutParams.substr(0, idx);
  }

  return {
    href: hrefWithoutParams,
    fbid: state.$$lesson.getIn(['comments', 'fbid']),
    enabled: state.$$lesson.get('comments'),
  };
}

export default connect(mapStateToProps)(FBComments);
