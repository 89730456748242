import React from 'react';

export default function LoginFormLogo({ fillColor }) {
  return (
    <svg
      width="86"
      height="88"
      viewBox="0 0 86 88"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Group">
        <ellipse
          id="Oval"
          opacity="0.5"
          cx="45.5612"
          cy="40.4682"
          rx="40.4389"
          ry="40.2104"
          fill={fillColor}
          fillOpacity="0.5"
        />
        <g id="Group 9">
          <path
            id="XMLID_1122_"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M56.0953 63.8238V62.8044C56.0953 56.9556 51.2597 52.2027 45.3092 52.2027C43.2479 52.2027 41.3293 52.7761 39.6958 53.7572C38.0883 52.8016 36.39 52.0115 34.6398 51.4126C39.0995 48.6348 42.0682 43.7289 42.0682 38.1605C42.0682 29.5338 34.9121 22.5 26.1225 22.5C17.3329 22.5 10.1767 29.5338 10.1767 38.1732C10.1767 43.7417 13.1455 48.6475 17.6181 51.4254C7.37652 54.9041 0 64.4737 0 75.6998V85.83C0 86.8876 0.868589 87.7414 1.9446 87.7414H35.8585H50.3004H54.7729C57.275 87.7414 59.3103 85.7408 59.3103 83.2815V68.0925C59.3103 66.0792 57.9491 64.3845 56.0953 63.8238ZM52.2061 62.8044V63.6326H38.4254V62.8044C38.4254 59.0708 41.5108 56.0254 45.3222 56.0254C49.1337 56.0254 52.2061 59.0708 52.2061 62.8044ZM26.1225 26.3227C19.4719 26.3227 14.0659 31.6363 14.0659 38.1732C14.0659 44.7101 19.4719 50.0237 26.1225 50.0237C32.773 50.0237 38.179 44.7101 38.179 38.1732C38.179 31.6363 32.773 26.3227 26.1225 26.3227ZM3.88916 75.6998C3.88916 63.6581 13.8585 53.8464 26.1224 53.8464C29.8431 53.8464 33.4471 54.7512 36.6751 56.4586C35.3269 58.2298 34.5361 60.4216 34.5361 62.7916V63.811C32.6822 64.3717 31.321 66.0665 31.321 68.0798V83.2815C31.321 83.4981 31.334 83.7148 31.3729 83.9186H3.88916V75.6998ZM54.773 83.9187C55.136 83.9187 55.4212 83.6383 55.4212 83.2815V68.0925C55.4212 67.7357 55.136 67.4554 54.773 67.4554H35.8585C35.4955 67.4554 35.2103 67.7357 35.2103 68.0925V83.2815C35.2103 83.6383 35.4955 83.9187 35.8585 83.9187H50.3004H54.773Z"
            fill="#333333"
          />
          <path
            id="XMLID_1129_"
            d="M45.9655 71.4297C45.1451 71.4297 44.4828 72.24 44.4828 73.2438V77.0293C44.4828 78.0332 45.1451 78.8435 45.9655 78.8435C46.786 78.8435 47.4483 78.0332 47.4483 77.0293V73.2438C47.4483 72.24 46.786 71.4297 45.9655 71.4297Z"
            fill="#333333"
          />
        </g>
      </g>
    </svg>
  );
}
