import React from 'react';

export default function (props) {
  return (
    <div className="toc-widget">
      <div className="row toc-content">
        <div className="col-xs-12">
          {props.children}
        </div>
      </div>
    </div>
  );
}
