import React, { Suspense } from 'react';
import { useSelector } from 'react-redux';
import Immutable from 'immutable';
import { importBaseComponents } from '../../MainApp/components/helpers/school';

export default function Bundles() {
  const storeData = useSelector(state => {
    return {
      altColor: state.$$school.getIn(['template_values', 'secondaryColor', 'value']),
      bundles: state.$$school.get('bundles') || Immutable.Map(),
      eventProps: state.$$layout.get('event_props').toJS(),
      ga4DisabledEventsCodes: state.$$layout.get('ga4_disabled_events_codes'),
      i18n: state.$$i18n,
      schoolDescription: state.$$school.get('description'),
      schoolName: state.$$school.get('name'),
      selectCourseText: state.$$school.get('select_course_text'),
      templateName: state.$$school.get('template_name'),
      templateValues: state.$$school.get('template_values'),
      textColor: state.$$school.getIn(['template_values', 'textColor', 'value']),
    };
  });

  const [BundleContainer, School] = importBaseComponents(storeData.templateName);

  return (
    <Suspense fallback="Loading...">
      <School>
        <BundleContainer {...storeData} />
      </School>
    </Suspense>
  );
}
