/**
 * Add to action:
 * meta: {
 *  modal: {
 *    cancel: { type: ..., payload: ... }
 *    closedWithTypes: [ type, type, type, ... ]
 *  }
 * }
 */

export default function modal({ getState, dispatch }) {
  let activeModal;

  return next => action => {
    if (action.meta && action.meta.modal) {
      if (activeModal) {
        dispatch(activeModal.cancel);
      }
      activeModal = action.meta.modal;
    } else if (activeModal &&
               activeModal.closedWithTypes &&
               (activeModal.closedWithTypes.includes(action.type))) {
      activeModal = undefined;
    }

    return next(action);
  };
}

