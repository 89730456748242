import React from "react"

const RefreshIcon = ({ width = 22, height = 18, color = '#354751', ...rest }) => {
  return (
    <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg" {...rest} viewBox="0 0 25 25">
     <g
        stroke={color}
        strokeWidth={1.5}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M17.5 9a8 8 0 10-8 8M17.5 12l-3-3M20.5 9l-3 3M17.5 9v3" />
      </g>
    </svg>
  )
}
RefreshIcon.propTypes = {
  width: React.PropTypes.number,
  height: React.PropTypes.number,
};

export default RefreshIcon;