import React from 'react';
import { connect } from 'react-redux';
import LoginForm from '../components/forms/login_form';
import SchoolLoginForm from '../components/forms/school_login_form';
import OfferToBuy from '../components/offer_to_buy';
import MultiLayout from '../components/multi_layout';
import { StudentTracker } from '../services/StudentTracker';
import LocalStorageManager from '../../../lib/localStorageManager';
import SniperLinks from '../components/sniper_links';
import { AfterJoinLogo, AfterJoinSchoolLogo, CoursePaymentLogo, AfterJoinBackgroundImage } from '../components/svg/join_bundle';

function UserExists({ implicitSchool, i18n }) {
  const commonProps = {
    embeddedForm: true,
    userExistsWelcomeTitle: i18n.existingStudent.title,
    userExistsLoginPrompt: i18n.existingStudent.content
  };

  const FormComponent = implicitSchool ? LoginForm : SchoolLoginForm;

  return (
    <div>
      <FormComponent {...commonProps} />
    </div>
  );
}

function ThanksForJoiningSchool(props) {
  return (
    <div>
      <div className="lg:block hidden text-center">
        <AfterJoinBackgroundImage className="max-w-full" />
      </div>
      <div className="flex items-center justify-center flex-col text-center rtl lg:py-0 py-32 px-4 lg:w-1/2 lg:mx-auto">
        <div className="lg:block hidden"><AfterJoinSchoolLogo /></div>
        <div className="lg:hidden"><AfterJoinSchoolLogo width={76} height={72}
        /></div>
        <span className="text-3xl text-[28px] lg:text-4xl lg:text-[40px] font-bold mt-3 text-[#333]" dangerouslySetInnerHTML={{
          __html: props.i18n.school.title
        }}></span>
        {props.wantCourse ? (
          <span className='text-lg leading-[23px] sm:leading-7 font-normal mt-[3px] lg:mt-2 text-[#333]' dangerouslySetInnerHTML={{__html: props.i18n.school.message}}></span>
        ) : (
          <span className="text-lg leading-[23px] sm:leading-7 font-normal mt-[3px] lg:mt-2 text-[#333]" dangerouslySetInnerHTML={{
            __html: props.i18n.school.content.replace('%{school_name}', props.schoolName)
          }}></span>
        )}
        <SniperLinks {...props} />
      </div>
    </div>
  );
}

function ThanksForJoining(props) {
  return (
    <div>
      <div className="lg:block hidden text-center">
        <AfterJoinBackgroundImage className="max-w-full" />
      </div>
      <div className="flex items-center justify-center flex-col text-center rtl lg:py-0 py-32 px-4 lg:w-1/2 lg:mx-auto">
        <div className="lg:block hidden"><AfterJoinLogo /></div>
        <div className="lg:hidden"><AfterJoinLogo width={79} height={74}
        /></div>
        <span className="text-3xl text-[28px] lg:text-4xl lg:text-[40px] font-bold mt-3 text-[#333]" dangerouslySetInnerHTML={{
          __html: props.i18n.course.title
        }}></span>
        <span className="mt-2 text-2xl text-[23px] lg:text-3xl lg:text-[27px] text-[#333] font-bold">{props.bundleName}</span>
        <span className="text-lg leading-[23px] sm:leading-7 font-normal mt-[3px] lg:mt-2 text-[#333]" dangerouslySetInnerHTML={{
          __html: props.i18n.course.content
        }}></span>
        <SniperLinks {...props} />
      </div>
    </div>
  );
}

function ThanksForCoursePayment(props) {
  return (
    <>
      <div>
        <div className="lg:block hidden text-center">
          <AfterJoinBackgroundImage className="max-w-full" />
        </div>
        <div className="flex items-center justify-center flex-col text-center rtl lg:py-0 py-32 px-4 lg:w-1/2 lg:mx-auto">
          <div className="lg:block hidden"><AfterJoinLogo /></div>
          <div className="lg:hidden"><AfterJoinLogo width={79} height={74}
          /></div>
          <span className="text-3xl text-[28px] lg:text-4xl lg:text-[40px] font-bold mt-3 text-[#333]" dangerouslySetInnerHTML={{
            __html: props.i18n.coursePayment.title
          }}></span>
          <span className="mt-2 text-2xl text-[23px] lg:text-3xl lg:text-[27px] text-[#333] font-bold">{props.bundleName}</span>
          <span className="text-lg leading-[23px] sm:leading-7 font-normal mt-[3px] lg:mt-2 text-[#333]" dangerouslySetInnerHTML={{
            __html: props.i18n.coursePayment.content
          }}></span>
          <SniperLinks {...props} />
        </div>
      </div>
    </>
  );
}


class AfterJoin extends React.Component {
  static propTypes = {
    error: React.PropTypes.bool.isRequired,
  };

  componentDidMount() {
    if(this.props.purchaseDone === true){
      this.purchaseEvent(this.props);
    }
    if (this.props.customThankyouPage) {
      window.location.href = this.props.customThankyouPage;
    }
    if (!this.props.error){
      if(this.props.newSignUp === true){
        this.trackSignUp(this.props);
      }
    }
  }

  trackSignUp = (props) => {
    StudentTracker.track('sign_up', {
      'item_id': props.bundleId,
      'item_name': props.bundleName,
      'method': 'email',
      'item_brand': props.teacherName,
      'item_brand_id': props.teacherId,
      'item_category': props.schoolName,
      'item_category2': props.bundlePolicy
    });
  }

  purchaseEvent = (props) => {
    if(LocalStorageManager.get('finalPrice') === null){
      return
    }
    StudentTracker.track('purchase', {
      'transaction_id': props.paymentId,
      'value': LocalStorageManager.get('finalPrice'),
      'currency': 'ILS',
      'items': this.itemProps(props)
    });    
    LocalStorageManager.remove('couponName');
    LocalStorageManager.remove('couponDiscount');
    LocalStorageManager.remove('finalPrice');
  }

  itemProps = (props) => {
    let couponName = LocalStorageManager.get('couponName');
    let couponDiscount = LocalStorageManager.get('couponDiscount');
    let finalPrice = LocalStorageManager.get('finalPrice');
    let items = [
      {
        'item_id': `${props.bundleId}`,
        'item_name': props.bundleName,
        'item_brand': props.teacherName,
        'item_brand_id': props.teacherId,
        'item_category': props.schoolName,
        'item_category2': props.bundlePolicy,
        'coupon': couponName || props.couponName,
        'discount': couponDiscount || props.couponDiscount,   
        'price': finalPrice || props.discountedPrice,
        'currency': "ILS",
        'quantity': 1
      }
    ];
    return items;
  }

  render() {
    const props = this.props;

    if (props.error) {
      return (
        <MultiLayout hideBottomPanel={true} internalScroll={false}>
          <UserExists {...props} />
        </MultiLayout>
      );
    } else if (props.waitingPayment) {
      return (
        <MultiLayout hideBottomPanel={true} internalScroll={false}>
          <OfferToBuy {...props} />
        </MultiLayout>);
    } else if (props.customThankyouPage) {
      return <p dangerouslySetInnerHTML={{__html: props.i18n.waiting}}></p>;
    } else if (props.bundlePrice > 0) {
      return <ThanksForCoursePayment {...props} />;
    } else if (props.schoolName) {
      return <ThanksForJoiningSchool {...props} />;
    } else {
      return <ThanksForJoining {...props} />;
    }
  }
}

function mapStateToProps(state) {
  return {
    bundleId: state.$$afterjoin.get('bundle_id'),
    bundleName: state.$$afterjoin.get('bundle_name'),
    bundlePrice: state.$$afterjoin.get('bundle_price') || 0,
    bundlePolicy: state.$$afterjoin.get('bundle_policy'),
    customThankyouPage: state.$$afterjoin.get('custom_thankyou_url'),
    error: state.$$afterjoin.get('error'),
    implicitSchool: state.$$afterjoin.get('implicit_school') || !state.$$school.get('explicit'),
    schoolName: state.$$afterjoin.get('school_name'),
    waitingPayment: state.$$afterjoin.get('waiting_payment'),
    wantCourse: state.$$afterjoin.get('want_course'),
    teacherId: state.$$afterjoin.get('teacher_id'),
    teacherName: state.$$afterjoin.get('teacher_name'),
    i18n: state.$$i18n.getIn(['students', 'thankYou']).toJS(),
    couponName: state.$$afterjoin.get('coupon_name'),
    couponDiscount: state.$$afterjoin.get('coupon_discount'),
    purchaseDone: state.$$afterjoin.get('purchase_done'),
    paymentId: state.$$afterjoin.get('payment_id'),
    newSignUp: state.$$afterjoin.get('new_sign_up'),
    gmail_link: state.$$afterjoin.getIn(['sniper_links', 'gmail']),
    outlook_link: state.$$afterjoin.getIn(['sniper_links', 'outlook']),
    discountedPrice: state.$$afterjoin.get('discounted_price') || state.$$afterjoin.get('bundle_price') || 0,
  };
}

export default connect(mapStateToProps)(AfterJoin);
