import React from "react";

export default function SignupFormLogo({ width = 114, height = 91, fillColor }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 114 91"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Group">
        <ellipse
          id="Oval"
          opacity="0.5"
          cx="72.9482"
          cy="40.4682"
          rx="40.4389"
          ry="40.2104"
          fill={fillColor}
          fillOpacity="0.5"
        />
        <g id="Group 9">
          <g id="Group 290">
            <path
              id="Vector 24"
              d="M72.5498 56.1914V70.6652C61.4129 82.5074 35.3451 80.6653 27.2676 70.6652V56.8544"
              stroke="#333333"
              strokeWidth="3.75"
            />
            <path
              id="Rectangle 91"
              d="M48.9116 30.0756C48.9416 30.0635 48.975 30.0634 49.0051 30.0755L92.4734 47.5064C92.504 47.5187 92.5179 47.5293 92.5225 47.5331C92.5275 47.5373 92.5307 47.541 92.5336 47.5455C92.5404 47.5562 92.5519 47.5825 92.5519 47.6223C92.5519 47.662 92.5406 47.6883 92.5337 47.6991C92.5309 47.7036 92.5277 47.7073 92.5227 47.7115C92.5181 47.7153 92.5043 47.726 92.4737 47.7383L70.887 56.4487L49.0053 65.2782C48.9752 65.2904 48.9415 65.2903 48.9114 65.278L48.2037 67.0143L48.9114 65.278L27.1438 56.4057L5.87879 47.7382C5.84855 47.7258 5.83481 47.7152 5.83019 47.7113C5.82514 47.7071 5.82197 47.7033 5.81908 47.6988C5.81219 47.6879 5.80093 47.6617 5.80097 47.6223C5.80101 47.5828 5.81234 47.5566 5.81924 47.5458C5.82215 47.5413 5.82532 47.5375 5.83038 47.5333C5.83501 47.5294 5.84878 47.5188 5.87904 47.5065L48.9116 30.0756Z"
              stroke="#333333"
              strokeWidth="3.75"
            />
            <path
              id="Vector 23"
              d="M89.0972 49.5195L89.0972 81.3964"
              stroke="#333333"
              strokeWidth="3.75"
            />
            <circle
              id="Ellipse 30"
              cx="89.0971"
              cy="84.8845"
              r="4.14227"
              stroke="#333333"
              strokeWidth="3.75"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
