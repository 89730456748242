import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

function ActionIcon({ className, name, onClick, tooltipText }) {
  return (
    <span
      aria-label={tooltipText}
      className={classNames("nm-tooltiped", className)}
      data-placement="center"
      data-toggle="tooltip"
      role="button"
      tabIndex="0"
      onClick={(event) => {
        event.stopPropagation();
        event.preventDefault();
        onClick(event);
      }}
    >
      <i className={`sch-${name}`} />
      <span className="nm-tooltip">
        <span
          className="tooltip-inner"
          dangerouslySetInnerHTML={{ __html: tooltipText }}
        />
      </span>
    </span>
  );
}

ActionIcon.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  tooltipText: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

ActionIcon.defaultProps = {
  className: "",
  name: "",
  tooltipText: "",
  onClick: () => {},
};

export default ActionIcon;
