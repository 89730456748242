import React from "react";

export default function arrowToCourseYoga({bgColor}) {
  return (
    <svg
      width="33"
      height="33"
      viewBox="0 0 33 33"
      xmlns="http://www.w3.org/2000/svg"
      style={{ backgroundColor: bgColor }}
    >
      <g id="scroll 1">
        <g id="Group">
          <path
            id="Vector"
            d="M20.6648 16.0136L16.5002 20.1782L12.3355 16.0136C12.2165 15.8986 12.0571 15.835 11.8916 15.8364C11.7262 15.8379 11.5679 15.9043 11.4509 16.0212C11.3339 16.1382 11.2676 16.2965 11.2661 16.462C11.2647 16.6274 11.3283 16.7868 11.4432 16.9058L15.7253 21.1885C15.9303 21.393 16.208 21.5079 16.4976 21.5079C16.7872 21.5079 17.065 21.393 17.27 21.1885L21.552 16.9058C21.6557 16.7854 21.7102 16.6303 21.7045 16.4714C21.6989 16.3126 21.6334 16.1618 21.5214 16.0491C21.4093 15.9364 21.2589 15.8701 21.1001 15.8635C20.9413 15.8569 20.7858 15.9105 20.6648 16.0136Z"
            fill="#ffffff"
          />
          <path
            id="Vector_2"
            d="M15.7277 17.0751C15.9327 17.2796 16.2105 17.3945 16.5 17.3945C16.7896 17.3945 17.0674 17.2796 17.2724 17.0751L21.5544 12.7924C21.6621 12.6723 21.7197 12.5156 21.7154 12.3544C21.7111 12.1933 21.6453 12.0398 21.5314 11.9256C21.4175 11.8115 21.2643 11.7452 21.1031 11.7405C20.9419 11.7357 20.7851 11.7929 20.6647 11.9002L16.5 16.0648L12.3354 11.9002C12.2772 11.8399 12.2075 11.7918 12.1306 11.7587C12.0536 11.7257 11.9708 11.7083 11.887 11.7075C11.8032 11.7068 11.7201 11.7228 11.6426 11.7545C11.565 11.7862 11.4946 11.8331 11.4353 11.8923C11.3761 11.9516 11.3292 12.022 11.2975 12.0996C11.2657 12.1771 11.2498 12.2602 11.2505 12.344C11.2512 12.4278 11.2686 12.5106 11.3017 12.5876C11.3348 12.6646 11.3829 12.7342 11.4431 12.7924L15.7277 17.0751Z"
            fill="#ffffff"
          />
        </g>
      </g>
    </svg>
  );
}
