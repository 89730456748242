import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const FlashMessageBar = ({ type, message, onClose }) => {
  const [visible, setVisible] = useState(!!message);

  useEffect(() => {
    setVisible(!!message);
  }, [message]);

  if (!type || type !== 'info' || !visible) return null;

  const handleClose = () => {
    setVisible(false);
    setTimeout(onClose, 0);
  };

  return (
    <div className="w-full py-2.5 bg-cyan-400 justify-center items-center inline-flex flash-info-bar top-0 relative z-50">
      <div className="text-center text-zinc-800 text-sm font-normal font-['Assistant'] leading-normal px-12 directional">
        {message}
      </div>
      <button className="flash-close !text-base border-none absolute fm-close !bg-transparent cursor-pointer" onClick={handleClose}>
        &times;
      </button>
    </div>
  );
};

FlashMessageBar.propTypes = {
  type: PropTypes.string,
  message: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};

FlashMessageBar.defaultProps = {
  type: 'info',
  message: '',
};

export default FlashMessageBar;
