// import { pushPath } from 'redux-simple-router';
import $ from 'jquery';

export const symbols = {
  SELECT_TAB: '@@lesson.select-tab',
  SHOW_SIDEBAR: '@@lesson.show-sidebar'
};

export default {
  selectTab,
  showSidebar
};

function selectTab(tabIndex) {
  return { type: symbols.SELECT_TAB, payload: tabIndex };
}

function showSidebar(val) {
  return { type: symbols.SHOW_SIDEBAR, payload: val };
}
