import * as React from 'react';

const PptIcon = ({ width = 26, height = 23, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 1000 1000" {...rest}>
      <path d="M963.42 160.14H561.789V67.456a30.814 30.814 0 00-11.183-23.789c-7.045-5.809-16.375-8.218-25.396-6.55L30.896 129.802c-14.644 2.719-25.21 15.447-25.21 30.338v679.682c0 14.829 10.566 27.619 25.21 30.339l494.315 92.685c1.853.37 3.83.555 5.684.555 7.105 0 14.15-2.471 19.712-7.105 7.104-5.87 11.183-14.645 11.183-23.789v-92.684h401.63c17.055 0 30.895-13.841 30.895-30.895V191.034c0-17.053-13.84-30.894-30.895-30.894zM500 895.248L67.475 814.18V185.782L500 104.715v790.533zm432.526-117.215H561.789v-61.789h278.053c17.054 0 30.894-13.841 30.894-30.896 0-17.054-13.84-30.895-30.894-30.895H561.789V221.929h370.736v556.104zM314.633 345.508h-92.684c-17.054 0-30.895 13.84-30.895 30.894v308.946c0 17.055 13.841 30.896 30.895 30.896s30.895-13.841 30.895-30.896v-92.683h61.79c68.154 0 123.578-55.426 123.578-123.58s-55.426-123.577-123.579-123.577zm0 185.368h-61.79V407.297h61.79c34.046 0 61.79 27.743 61.79 61.789-.001 34.047-27.745 61.79-61.79 61.79zm401.63 61.79c85.208 0 154.473-69.328 154.473-154.475 0-85.145-69.265-154.473-154.473-154.473s-154.474 69.328-154.474 154.473c0 85.147 69.266 154.475 154.474 154.475zm87.388-185.369h-56.493v-56.493c26.292 9.325 47.167 30.201 56.493 56.493zm-118.284-56.492v87.387c0 17.055 13.842 30.895 30.896 30.895h87.388c-12.754 35.96-47.115 61.79-87.388 61.79-51.1 0-92.684-41.584-92.684-92.685 0-40.273 25.829-74.634 61.788-87.387z" />
    </svg>
  );
};

PptIcon.propTypes = {
  width: React.PropTypes.number,
  height: React.PropTypes.number,
};
export default PptIcon;
