'use strict';

exports.__esModule = true;

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { 'default': obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError('Cannot call a class as a function'); } }

var _deepEqual = require('deep-equal');

var _deepEqual2 = _interopRequireDefault(_deepEqual);

function intersects(array1, array2) {
  return !!(array1 && array2 && array1.some(function (item) {
    return ~array2.indexOf(item);
  }));
}

var LazyCache = (function () {
  function LazyCache(component, calculators) {
    var _this = this;

    _classCallCheck(this, LazyCache);

    this.component = component;
    this.allProps = [];
    this.cache = Object.keys(calculators).reduce(function (accumulator, key) {
      var _extends2;

      var calculator = calculators[key];
      var fn = calculator.fn;
      var paramNames = calculator.params;
      paramNames.forEach(function (param) {
        if (! ~_this.allProps.indexOf(param)) {
          _this.allProps.push(param);
        }
      });
      return _extends({}, accumulator, (_extends2 = {}, _extends2[key] = {
        value: undefined,
        props: paramNames,
        fn: fn
      }, _extends2));
    }, {});
  }

  LazyCache.prototype.get = function get(key) {
    var component = this.component;
    var _cache$key = this.cache[key];
    var value = _cache$key.value;
    var fn = _cache$key.fn;
    var props = _cache$key.props;

    if (value !== undefined) {
      return value;
    }
    var params = props.map(function (prop) {
      return component.props[prop];
    });
    var result = fn.apply(undefined, params);
    this.cache[key].value = result;
    return result;
  };

  LazyCache.prototype.componentWillReceiveProps = function componentWillReceiveProps(nextProps) {
    var _this2 = this;

    var component = this.component;

    var diffProps = [];
    this.allProps.forEach(function (prop) {
      if (!_deepEqual2['default'](component.props[prop], nextProps[prop])) {
        diffProps.push(prop);
      }
    });
    if (diffProps.length) {
      Object.keys(this.cache).forEach(function (key) {
        if (intersects(diffProps, _this2.cache[key].props)) {
          delete _this2.cache[key].value; // uncache value
        }
      });
    }
  };

  return LazyCache;
})();

exports['default'] = LazyCache;
module.exports = exports['default'];