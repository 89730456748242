
export const symbols = {
  UNDO_INIT_CABLE_CHANNEL: '@@undo-init-cable-channel',
  UNDO_CABLE_SEND: '@@undo-cable-send',
  UNDO_CABLE_RECEIVE: '@@undo-cable-receive',
  UNDO_CABLE_MODAL_DISPLAY_STATE: '@@undo-cable-modal-display-state',
  UNDO_MODAL_DISPLAY_STATE: '@@undo-modal-display-state',
};

export function initializeUndoRedoCableCar(channel) {
  return (dispatch, getstate) => {
    dispatch({ type: symbols.UNDO_INIT_CABLE_CHANNEL, payload: channel });
  }
}
export function sendUndoRedoCableCarMessage(action) {
  return (dispatch, getstate) => {
    dispatch({ type: symbols.UNDO_CABLE_SEND, payload: {action} });
  }
}

export function setIsModalDisplay(isModalDisplay) {
  return (dispatch, getstate) => {
    dispatch({ type: symbols.UNDO_MODAL_DISPLAY_STATE, payload: { isModalDisplay } });
  }
}

