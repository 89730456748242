import { each } from 'lodash';

export const symbols = {
  REPLACE_VIDEO: '@@lesson-video-player.replace-video',
  SET_ACTIVE_MARK: '@@lesson-video-player.set-active-mark',
  REPLACE_VIDEO_END: '@@lesson-video-player.replace-video-end',
  SET_INTERVALS: '@@lesson-video-player.set-interval',
  SET_WISTIA_API: '@@lesson-video-player.set-wistia-api',
  WATCH_VIDEO_PART: '@@lesson-video-player.watch-video-part',
  PLAYING_INTRO: '@@lesson-video-player.playing-intro',
};

export default {
  initPlayer,
  seek,
  removeVideo,
};

function seek(toSecond) {
  return (dispatch, getstate) => {
    const wistia = getstate().$$lesson.get('$$player').get('wistia');
    if (wistia) {
      wistia.time(toSecond);
    } else {
      console.warn('seek called before wistia API initialised');
    }
  };
}

function removeVideo() {
  return (dispatch, getstate) => {
    const player = getstate().$$lesson.getIn(['$$player', 'wistia']);
    if (player) {
      player.remove();
    }
  };
}

let gEventBound = false;

function initPlayer() {
  return (dispatch, getstate) => {
    if (gEventBound) return;

    addWistiaAction('_all', (video) => {
      const $$lesson = getstate().$$lesson;
      const email = getstate().$$account.get('email');
      if (email) {
        video.email(email);
      }

      dispatch({ type: symbols.SET_WISTIA_API, payload: video });

      function dispatchPlayingIntro(within) {
        const playing = (video.state() === 'playing');
        dispatch({ type: symbols.PLAYING_INTRO, payload: within && playing });
      }

      function handlePlay() {
        if (video.time() <= 14) {
          dispatch({ type: symbols.PLAYING_INTRO, payload: true });
        }
      }

      try {
        video.unbind('betweentimes', 0, 15, dispatchPlayingIntro);
        video.unbind('play', handlePlay);
      } catch (e) {
        // ignore
      }
      video.bind('betweentimes', 0, 15, dispatchPlayingIntro);
      video.bind('play', handlePlay);

      // no need to unbind when creating a new player
      rebindIntervals(video, $$lesson.getIn(['video', 'marks']), [], dispatch, getstate);
      gEventBound = true;
    });
  };
}

function rebindIntervals(video, $$marks, prevIntervals, dispatch, getstate) {
  unbindIntervals(video, prevIntervals);

  const intervals = createIntervalsForMarks(video, $$marks.toJS(), dispatch, getstate);
  const watched = getstate().$$lesson.get('watched_markers');
  dispatch({ type: symbols.SET_INTERVALS, payload: { intervals, watched } });
  bindIntervals(video, intervals);

  return intervals;
}

function setActiveMark(markId, dispatch, getstate, within) {
  if (within) {
    dispatch({ type: symbols.SET_ACTIVE_MARK, payload: markId });
  } else {
    const url = getstate().$$layout.getIn(['urls', 'mark_part_as_watched']);
    const token = getstate().$$layout.get('authenticityToken');
    markWatchedVideoPart(url, token, markId);
    dispatch({ type: symbols.WATCH_VIDEO_PART, payload: markId });
  }
}

function bindIntervals(video, intervals) {
  each(intervals, function (t) {
    video.bind('betweentimes', t.start, t.end, t.callback);
  }, this);
}

function unbindIntervals(video, intervals) {
  each(intervals, (t) => video.unbind('betweentimes', t.start, t.end, t.callback));
}

function createIntervalsForMarks(video, marks, dispatch, getstate) {
  const lastIndex = marks.length - 1;
  // iterate without the last video mark
  const res = [];

  for (let i = 0; i < marks.length; i++) {
    res.push({
      id: marks[i].id,
      start: marks[i].atSecond,
      end: i < lastIndex ? marks[i + 1].atSecond : video.duration(),
      callback: setActiveMark.bind(null, marks[i].id, dispatch, getstate),
    });
  }

  return res;
}


function addWistiaAction(id, callback) {
  if (typeof window === 'undefined') { return; }

  window._wq = window._wq || [];
  window._wq.push({
    [id]: (video) => {
      return callback.call(null, video);
    },
  });
}

function markWatchedVideoPart(url, token, markerId) {
  const xhr = new XMLHttpRequest();
  xhr.open('POST', url);
  xhr.setRequestHeader('Content-Type', 'application/json');
  xhr.setRequestHeader('Accept', 'application/json');
  xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
  xhr.send(JSON.stringify({ marker_id: markerId, authenticity_token: token }));
}
