import React, { useEffect, useRef, useState, useMemo } from 'react';
import cx from 'classnames';
import { bindActionCreators } from 'redux';
import { actions as UndoActionCreators } from 'redux-undo-redo';
import { useSelector, useDispatch } from 'react-redux';
import undoActions, { undoName } from '../../actions/undo_redo/undo_redo';
import {setDisplaySplash} from '../../actions/undo_redo/undo_redo_splash';
import {UndoIcon, RedoIcon} from '../icons/index';
import {ConditionalComponent} from '../common';

const CLASS_NAMES ={
  CONTAINER : 'undo-redo-container',
  REDO_CONTAINER : 'nm-tooltiped redo-container',
  REDO_ICON_CONTAINER : 'redo-icon-container',
  PIPE_CONTAINER : 'pipe-container',
  DROPDOWN_CONTAINER : 'container-dropdown',
  UNDO_CONTAINER : 'nm-tooltiped undo-container',
  UNDO_ICON_CONTAINER : 'undo-icon-container',
  UNDO_REDO_TRIANGLE : 'undo-redo-triangle',
  DROPDOWN_UNDO_CONTAINER : 'dropdown-undo-menu',
  DROPDOWN_TITLE : 'dropdown-undo-title',
  TOOLTIP_CONTAINER : 'nm-tooltip',
  TOOLTIP_INNER_TITLE : 'tooltip-inner',
}
const UndoRedo = () => {
  const refDropDownContainer = useRef(null)
  const refActionList = useRef(null)
  const refActionButton = useRef(null)
  const [selectedUndoActionIndex, setSelectedUndoActionIndex] = useState(-1)
  const [isUndoDropDownHidden, setHideUndoDropDown] = useState(true)
  const [isUndoButtonEnable, setIsUndoButtonEnable] = useState(true)
  const [isRedoButtonEnable, setIsRedoButtonEnable] = useState(true)
  const dispatch = useDispatch()

  const { onUndo, onRedo } = useMemo(() => bindActionCreators({ onUndo: UndoActionCreators.undo, onRedo: UndoActionCreators.redo }, dispatch))

  const {isCanUndo, isCanRedo,  undoList, redoList, i18n, isDisplayUndoList} =
  useSelector(({$$theme, undoHistory, $$i18n, $$layout}) => ({
    isCanUndo: undoHistory.undoQueue.length > 0,
    isCanRedo: undoHistory.redoQueue.length > 0,
    bgColor: $$theme.getIn(['settings', 'background_color']),
    fgColor: $$theme.getIn(['settings', 'text_color']),
    undoList: undoHistory.undoQueue,
    redoList: undoHistory.redoQueue,
    i18n: $$i18n.getIn(['system', 'undoRedo']).toJS(),
    isDisplayUndoList: $$layout.get('isDisplayUndoList')
  }))

  useEffect(() => {
    const documentClickListener = (e) => {
      if (e.target !== refActionButton.current) {
        setHideUndoDropDown(true);
      }
    };
    document.addEventListener('click', documentClickListener);
    return () => {
      document.removeEventListener('click', documentClickListener)
    }
  })

  const handleOnUndo = async () => {
    if (isCanUndo && isUndoButtonEnable && undoList.length) {
      const [firstItem] = undoList
      const {undoName, undoData} = firstItem.args

      const intervalId = displaySplash(true)

      try {
        setIsUndoButtonEnable(false)
        await dispatch(undoActions.handleOnUndoRedo(undoName, undoData))
        onUndo()
      } finally {
        displaySplash(false, intervalId)
        setIsUndoButtonEnable(true)
        window.MixpanelService.track('Editor update', {'Action': 'Undo'})
      }
    }
  };

  const handleOnRedo = async () => {
    if (isCanRedo && isRedoButtonEnable && redoList.length) {
      const [firstItem] = redoList
      const {undoName, undoData} = firstItem.args

      const intervalId = displaySplash(true)

      try {
        setIsRedoButtonEnable(false)
        await dispatch(undoActions.handleOnUndoRedo(undoName, undoData, false))
        onRedo()
      } finally {
        displaySplash(false, intervalId)
        setIsRedoButtonEnable(true)
        window.MixpanelService.track('Editor update', {'Action': 'Redo'})
      }
    }
  };

  const handleUndoListClick = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    setHideUndoDropDown(!isUndoDropDownHidden)
    setSelectedUndoActionIndex(-1)
    const intervalId = displaySplash(true)

    const idx = parseInt(e.target.dataset.index)

    const promises = [];
    for(let i = 0; i <= idx; i++) {
      const {undoName, undoData} = undoList[i].args

      const promise = await Promise.all([dispatch(undoActions.handleOnUndoRedo(undoName, undoData))])
                                   .then(() => onUndo())
      promises.push(promise);
    }
    await Promise.all(promises)
      .then(() => displaySplash(false, intervalId))
      .catch(() => displaySplash(false, intervalId))
  };
  const actionButtonClick = (e) => {
    e.preventDefault();
    if (isCanUndo) {
      setHideUndoDropDown(!isUndoDropDownHidden)
    }
  }
  const onHoverStart = (e) => {
    e.preventDefault();
    setSelectedUndoActionIndex(+e.target.dataset.index)
  }
  const onHoverEnd = (e) => {
    e.preventDefault();
    setSelectedUndoActionIndex(-1)
  }
  const displayUndoDescription = (data) => {
    let actionName = data.undoName
    switch (data.undoName) {
      case undoName.facebook_feed_lesson:
        actionName = data.undoData.OldValue ? 'remove_facebook_feed' : 'add_facebook_feed'
        break
      case undoName.change_free:
        actionName = data.undoData.OldValue ? 'close_lock' : 'open_lock'
        break
      default:
        actionName = data.undoName
        break
    }
    return i18n.actions[actionName];
  }

  const undoDropDown = () => {
      return undoList.slice().map((undo, idx)=>{
         const description = displayUndoDescription(undo.args)
        return (<li key={idx}
          title={description}
          data-index={idx}
          onMouseEnter={onHoverStart}
          onMouseLeave={onHoverEnd}
          style={{ backgroundColor: idx <= selectedUndoActionIndex ? '#1f311d' : 'white', color: idx <= selectedUndoActionIndex ? 'white' : '' }}
          onClick={handleUndoListClick}>
          {description}
        </li>)
      })
  };
  const getTooltip = (name)=>{
    return (
    <div className={CLASS_NAMES.TOOLTIP_CONTAINER}>
      <div className={CLASS_NAMES.TOOLTIP_INNER_TITLE}>{name}</div>
    </div>)
  }

  const displaySplash = (isDisplaySplash, intervalId)=>{
    if(intervalId){
      clearTimeout(intervalId)
    }
    return setTimeout(() => dispatch(setDisplaySplash(isDisplaySplash)), 1000)
  }

  return (
    <div className={CLASS_NAMES.CONTAINER} data-testname='undo_redo_container'>
      <div className={CLASS_NAMES.REDO_CONTAINER} data-testname='redo_container'>
        <div className={CLASS_NAMES.REDO_ICON_CONTAINER} disabled={!isCanRedo} onClick={handleOnRedo}>
          <RedoIcon color='white' opacity={isCanRedo && isRedoButtonEnable ? 1 : 0.3} />
        </div>
        <ConditionalComponent isRender={isCanRedo}>
          {getTooltip(i18n.dropDown.redo)}
        </ConditionalComponent>
      </div>
      <div className={CLASS_NAMES.PIPE_CONTAINER}></div>
      <div ref={refDropDownContainer} className={CLASS_NAMES.DROPDOWN_CONTAINER} data-testname='undo_redo_drop_down_container'>
        <div className={CLASS_NAMES.UNDO_CONTAINER} disabled={!isCanUndo} data-testname='undo_container'>
          <div className={CLASS_NAMES.UNDO_ICON_CONTAINER} disabled={!isCanUndo} onClick={handleOnUndo}>
            <UndoIcon color='white' opacity={isCanUndo && isUndoButtonEnable ? 1 : 0.3}/>
          </div>
          <ConditionalComponent isRender={isCanUndo}>
            {getTooltip(i18n.dropDown.undo)}
          </ConditionalComponent>

          <ConditionalComponent isRender={isDisplayUndoList}>
           <div  ref={refActionButton} className={CLASS_NAMES.UNDO_REDO_TRIANGLE} disabled={!isCanUndo} onClick={actionButtonClick} data-testname='undo_action_button'></div>
          </ConditionalComponent>

        </div>
        <ConditionalComponent isRender={isDisplayUndoList}>
          <div ref={refActionList} className={cx(CLASS_NAMES.DROPDOWN_UNDO_CONTAINER, { "nm-hidden": isUndoDropDownHidden })} data-testname='undo_drop_down_container'>
              <div className={CLASS_NAMES.DROPDOWN_TITLE}>{i18n.dropDown.title}</div>
              <ul>
                {isCanUndo ? undoDropDown() : ''}
              </ul>
          </div>
        </ConditionalComponent>

      </div>
    </div>
  )
}

export default UndoRedo;
