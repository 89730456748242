import './patchReact';
import React from 'react';
import routes from './routes';
import { Provider } from 'react-redux';
import cablecar from 'redux-cablecar';
import createStore from '../store';
import * as NavActions from '../actions/ajax';
import * as UndoRedoCableCar from '../actions/undo_redo/undo_redo_cable';
import UIView from '../containers/uiview';
import './monkeypatch';

export default function (router, composedStore) {
  const MainApp = (props, railsContext) => {
    const store = createStore(props, composedStore);
    const res = { props: props };

    routes(router, store.getState());

    if (props.account.session_token && props.account.signedIn) {
      const session_args = {
        params: { session_token: props.account.session_token },
        connected: function () { console.log('CONNECTED-Student', props.account.session_token); },
        disconnected: function () { console.log('Failed-to-connect-Student-Channel'); },
        wsURL: props.layout.urls.action_cable_url,
      };
      cablecar.connect(store, 'StudentAccessChannel', session_args);
    }


    const session_args = {
      params: { school_id: props.layout.school_id },
      connected: function () { console.log('CONNECTED-Cart', props.layout.school_id); },
      disconnected: function () { console.log('Failed-to-connect-Cart-Channel'); },
      wsURL: props.layout.urls.action_cable_url,
    };

    try {
      cablecar.connect(store, 'SchoolChannel', session_args);
    } catch (err) {
      console.log(err.name);
    }

    if (!railsContext.serverSide) {
      if (window.livepreview) {
        const connectChannel = (store, channelName) => {
          const channelOptions = {
            params: {},
            connected: function () {
              if (channelName === "SchoolPreviewChannel") {
                document.dispatchEvent(new CustomEvent("REACT_READY"));
              }
              console.log(`${channelName} CONNECTED`);
            },
            wsURL: props.layout.urls.action_cable_url
          };

          cablecar.connect(store, channelName, channelOptions);
        };

        connectChannel(store, "SchoolPreviewChannel");
        connectChannel(store, "CourseSignupPreviewChannel");

        window.addEventListener("message", (ev) => {
          Array.isArray(ev.data) && ev.data.length > 1 && store.dispatch({ type: ev.data[0], payload: ev.data[1] });
        }, false);
      }
      router.use(function (req, res, next) {
        if (req.init) {
          return next();
        }
        store.dispatch(NavActions.fetchStateForNextPage(req, res));

        if (res.component && req.lazyLoad) {
          store.dispatch(NavActions.updateView(res.component, res.props.routerParams));
        }

        if (window.GT) {
          window.GT.r_event();
        }

        return next();
      });
    }

    const undoRedoArgs = {
      params: { school_id: props.layout.school_id, bundle_id: props.layout.default_bundle_props ? props.layout.default_bundle_props.id : -1 },
      connected: function () {
        const teacherName = props[props.school ? 'school' : 'bundletoc'].teacher_name;
        console.log('CONNECTED-Undo-Redo', teacherName);
      },
      wsURL: props.layout.urls.action_cable_url,
    };

    try {
      const undoRedoChannel = cablecar.connect(store, 'UndoRedoChannel', undoRedoArgs);
      store.dispatch(UndoRedoCableCar.initializeUndoRedoCableCar(undoRedoChannel));
    } catch (err) {
      console.log(err.name);
    }

    router.handle({ url: railsContext.href, method: 'get', init: true }, res, function () { });
    return (<Provider store={store}>
      <UIView component={res.component} />
    </Provider>);
  };

  return MainApp;
}
