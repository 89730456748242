import Immutable from 'immutable';
import { symbols } from '../actions/registrations';
import { symbols as actionTypes } from '../actions/ajax';

export const initialState = Immutable.Map({
  signupSubmitting: false,
  signupError: null,
  signupResult: null,
});

export default function accountReducer(state = initialState, action) {
  switch (action.type) {
    case symbols.SUBMIT_START:
      return state.merge({
        signupSubmitting: true,
        signupError: false,
        signupResult: null,
      });

    case symbols.HANDLE_RESULT:
      return state.merge({
        signupSubmitting: false,
        signupResult: signupResultText(action.payload),
        signupError: null,
      });

    case symbols.HANDLE_ERROR:
      return state.merge({
        signupSubmitting: false,
        signupResult: null,
        signupError: 'שגיאה בעת יצירת המשתמש',
      });

    case actionTypes.APPSTATE_MERGE:
      return state.merge({
        signupError: null,
        signupResult: null,
      });

    default:
      return state;
  }
}

function signupResultText(result) {
  if (result.intro_video) {
    return 'החשבון נוצר בהצלחה. להמשך עבור לתיבת המייל שלך ולחץ על הקישור שנשלח. בינתיים מוזמן לצפות בפרק הראשון בקורס';
  } else {
    return 'החשבון נוצר בהצלחה. להמשך עבור לתיבת המייל שלך ולחץ על הקישור ששלחנו';
  }
}

