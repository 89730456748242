import React from 'react';
import $s from 'scriptjs';
import $ from 'jquery';

export default class CKEditor extends React.Component {
  static propTypes = {
    text: React.PropTypes.string,
    onBlur: React.PropTypes.func,
  };
  $scrollWrapperElement;
  $editorWrapperElement;
  UNSAFE_componentWillMount() {
    //get scroll element of Scrollbars component
    this.$scrollWrapperElement = $('.edit-lesson-root >:eq(1) :first');
    $s('/vendor/ckeditor-4.5.6/ckeditor.js', () => {
      CKEDITOR.focusManager._.blurDelay = 70;
      this.editor = CKEDITOR.inline(this.refs.el, { startupFocus: true });
      this.$editorWrapperElement = $(this.refs.el).parent();
      if (this.props.onBlur) {
        this.editor.on('blur', (ev) => {
          setTimeout(this.props.onBlur, 0);
        });
      }
      const self = this;
      CKEDITOR.on('instanceReady', (e) => {
        const $editorDomElement = $('.cke');
        self.$scrollWrapperElement.on('scroll', () => self.toFixToolbarPosition($editorDomElement));
        e.editor.on('focus', () => self.toFixToolbarPosition($editorDomElement));
      });
    });
  }

  componentWillUnmount() {
    try {
      this.editor.destroy();
      this.$scrollWrapperElement.off('scroll');
    } catch (err) {
      console.log('error while trying to destroy the editor. Trying the hard way');
      const editorLeftovers = document.querySelector('.cke_textarea_inline');
      if (editorLeftovers) {
        editorLeftovers.parentElement.removeChild(editorLeftovers);
      }
    }
  }

  getData() {
    return this.editor.getData();
  }

  toFixToolbarPosition = ($editorDomElement) => {
    $editorDomElement.css({top: `${this.$editorWrapperElement.offset().top - 43}px`});
  }

  render() {
    const style = { visibility: 'hidden' };
    return <textarea style={style} contentEditable={true} ref="el" defaultValue={this.props.text}></textarea>;
  }
}
