import React, { PropTypes } from 'react';
import { connect } from 'react-redux';
import { isFunction } from 'lodash';

function Icon(props, context) {
  const attrs = {}

  if (props.title.length) {
    attrs.title = props.title;
  }

  if (isFunction(props.onClick)) {
    attrs.onClick = props.onClick;
  }

  return (
    <span
      {...attrs}
      className={`sch-${props.name} ${props.className} colored-item`}
      style={{ color: props.fgColor }}
      aria-hidden="true"
    >
    </span>
  );
}

Icon.propTypes = {
  className: PropTypes.string,
  fgColor: PropTypes.string,
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  title: PropTypes.string,
};

Icon.defaultProps = {
  className: '',
  title: '',
};

function mapStateToProps(state) {
  return {
    fgColor: state.$$theme.getIn(['settings', 'text_color']),
  };
}

export default connect(mapStateToProps)(Icon);
