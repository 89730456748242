import { compose, createStore, applyMiddleware, combineReducers } from 'redux';
import { extend, isEmpty } from 'lodash';

// See https://github.com/gaearon/redux-thunk and http://redux.js.org/docs/advanced/AsyncActions.html
// This is not actually used for this simple example, but you'd probably want to use this once your app has
// asynchronous actions.
import thunkMiddleware from 'redux-thunk';

import reducers from '../reducers';
import { initialStates } from '../reducers';
import { symbols as actionTypes } from '../actions/ajax';
import undoRedoMiddleware from '../middlewares/undoRedoMiddleware';
import cableChannelMiddleware from '../middlewares/cableCarUndoRedoMiddleware';


export default (props, composedStore) => {
  // This is how we get initial props Rails into redux.
  // const { name } = props;

  // Redux expects to initialize the store using an Object, not an Immutable.Map
  const initialState = mergeServerData({}, props);

  const combinedReducer = combineReducers(reducers);
  const reducer = (state, action) => {
    let result = state;
    if (action.type === actionTypes.APPSTATE_MERGE) {
      if (action.meta && action.meta.previewWindowId && action.meta.previewWindowId !== window.name) {
        // ignore preview message to another window
        return combinedReducer(result, action);
      } else {
        document.dispatchEvent(new CustomEvent('REACT_APPSTATE_MERGE'));
      }
      result = mergeServerData(state, action.payload);
    } else if (action.type === actionTypes.STUDENT_LIMIT) {
      window.location.href = action.payload.logout_url;
      return
    } else if (action.type === actionTypes.POLICY_CHANGE) {
      if(action.payload.reload == true){
        window.location.reload();
      } else {
        if(window.location.href.includes("/shoppingcart")){
          window.location.href = action.payload.url;
        } else {
          window.location.reload();
        }
      }
      return
    }
    return combinedReducer(result, action);
  };
  const composeEnhancers = (process.env.NODE_ENV !== 'production' &&
  typeof window !== 'undefined' &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;
  const storeCreator = composedStore(createStore);
  const store = storeCreator(reducer, initialState, composeEnhancers(applyMiddleware(thunkMiddleware, undoRedoMiddleware, cableChannelMiddleware)));

  return store;
};

function mergeServerData(state, props) {
  const _state = !isEmpty(state) ? state : initialStates;
  const res = { ..._state };

  if (props) {
    if (props.timestamp < _state.$$meta.get('timestamp')) {
      console.log('ignoring old server response');
      return res;
    }

    res.$$bundletoc = _state.$$bundletoc.merge(props.bundletoc);
    res.$$bundleIndex = _state.$$bundleIndex.merge(props.bundle_index);
    res.$$layout = _state.$$layout.merge(props.layout);
    res.$$about = _state.$$about.merge(props.about);
    res.$$lesson = _state.$$lesson.merge(props.lesson);
    res.$$i18n = _state.$$i18n.merge(props.i18n);
    res.$$contactForm = _state.$$contactForm.merge(props.contactform);
    res.$$blog = _state.$$blog.merge(props.blog);
    res.$$account = _state.$$account.merge(props.account);
    res.$$reseller = extend({}, _state.$$reseller, props.reseller);
    res.$$cart = _state.$$cart.merge(props.cart);
    res.$$afterjoin = _state.$$afterjoin.merge(props.afterjoin);
    res.$$meta = _state.$$meta.merge(props.meta);
    res.$$theme = _state.$$theme.merge(props.theme);
    res.$$school = _state.$$school.merge(props.school);
  }

  return res;
}
