import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { markComplete, markRead } from "app/actions/bundletoc";
import { merge } from "lodash";
import { StudentTracker } from "app/services/StudentTracker";
import PropTypes from "prop-types";
import qs from "query-string";
import React, { Component } from "react";

class PdfTab extends Component {
  static displayName = "PdfTab";
  static tabName = "קובץ";
  static tid = 3;

  static propTypes = {
    changeContentTypes: PropTypes.func,
    isTeacher: PropTypes.bool,
    lessonWatched: PropTypes.bool,
    pdfdownloadable: PropTypes.bool,
    pdfpasswordprotected: PropTypes.bool,
    pdfprintable: PropTypes.bool,
    pdfurl: PropTypes.string,
    pdfViewerUrl: PropTypes.string,
    srcCompletion: PropTypes.number
  };

  static defaultProps = {
    changeContentType: () => {},
    isTeacher: false,
    pdfdownloadable: true,
    pdfpasswordprotected: false,
    pdfprintable: true,
    pdfViewerUrl: ""
  };

  state = {
    isWatched: this.props.lessonWatched,
    interval: null
  };

  componentDidMount() {
    this.setState({ interval: this.timedOutMarkAsComplete() });
  }

  componentWillUnmount() {
    clearTimeout(this.state.interval);
  }

  timedOutMarkAsComplete = () => {
    const params = {
      slug: window.location.pathname.split("/").pop(),
      type: "pdf_completed",
      id: this.props.lessonId
    };

    return setTimeout(() => {
      if (!this.state.isWatched) {
        this.setState({ isWatched: true });
        if (!window.location.pathname.split("/").includes("edit")) {
          this.props.markComplete(params);
          this.trackLessonComplete();
        }
        if (this.viewableLesson()) {
          this.props.markRead(this.props.lessonId);
        }
      }
    }, this.props.srcCompletion);
  };

  viewableLesson = () => {
    return this.props.lessonId && (this.props.free || this.props.purchased) && this.props.dripNotActive;
  };

  trackLessonComplete = () => {
    const {
      bundleid,
      bundleName,
      bundleType,
      lessonName,
      lessonSeq,
      lessonType,
      schoolName,
      sectionName,
      sectionSeq,
      teacherId,
      teacherName,
      totalLessons,
      watchedLessons
    } = this.props;

    let itemProps = {
      item_brand_id: teacherId,
      item_brand: teacherName,
      item_category: schoolName,
      item_category2: bundleType,
      item_id: bundleid,
      item_name: bundleName
    };

    StudentTracker.track(
      `lesson_${lessonSeq}_complete`,
      merge(itemProps, {
        episode_index_number: sectionSeq,
        episode_name: sectionName,
        lesson_index_number: lessonSeq,
        lesson_name: lessonName,
        lesson_type: lessonType
      })
    );

    if (watchedLessons + 1 === totalLessons) {
      StudentTracker.track("finish_course", itemProps);
    }
  };

  render() {
    const { pdfdownloadable, pdfpasswordprotected, pdfprintable, pdfurl, pdfViewerUrl } = this.props;
    const pdfViewerLink = qs.parseUrl(pdfViewerUrl);

    pdfViewerLink.query = {
      ...pdfViewerLink.query,
      ...{
        downloadable: pdfdownloadable,
        passwordprotected: pdfpasswordprotected,
        printable: pdfprintable,
        url: encodeURIComponent(pdfurl)
      }
    };

    const iframeSrc = qs.stringifyUrl(pdfViewerLink);

    return (
      <div className="pdftab">
        <iframe
          style={{ width: "100%", height: "400px" }}
          src={iframeSrc}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const lessonId = state.$$lesson.get("id");
  const lessonIdx = lessonId ? state.$$bundletoc.get("lessons").findIndex((lesson) => lesson.get("lesson_id") === lessonId) : -1;
  const lessonData = lessonId ? state.$$bundletoc.getIn(["lessons", lessonIdx]) : Immutable.Map();

  return {
    dripNotActive: !state.$$lesson.getIn(["drip", "active"]),
    free: state.$$lesson.get("free") || false,
    lessonId,
    lessonName: state.$$lesson.get("name"),
    lessonSeq: lessonData.get("lesson_seq"),
    lessonType: lessonData.get("type"),
    lessonWatched: state.$$lesson.get("lessonWatched"),
    pdfViewerUrl: state.$$layout.getIn(["urls", "pdf_viewer"]),
    purchased: state.$$layout.getIn(["default_bundle_props", "owned"]),
    sectionName: lessonData.get("section_name"),
    sectionSeq: lessonData.get("section_seq"),
    srcCompletion: state.$$lesson.get("srcCompletion"),
    totalLessons: state.$$bundletoc.get("lessons").size,
    watchedLessons: state.$$bundletoc.get("lessons").filter((lesson) => lesson.get("watched") == true).size
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ markComplete, markRead }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(PdfTab);
