'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = createReactElement;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/**
 * Logic to either call the generatorFunction or call React.createElement to get the
 * React.Component
 * @param options
 * @param options.componentObj
 * @param options.props
 * @param options.domNodeId
 * @param options.trace
 * @param options.location
 * @returns {Element}
 */
function createReactElement(_ref) {
  var componentObj = _ref.componentObj,
      props = _ref.props,
      railsContext = _ref.railsContext,
      domNodeId = _ref.domNodeId,
      trace = _ref.trace;
  var name = componentObj.name,
      component = componentObj.component,
      generatorFunction = componentObj.generatorFunction;


  if (trace) {
    if (railsContext && railsContext.serverSide) {
      console.log('RENDERED ' + name + ' to dom node with id: ' + domNodeId + ' with railsContext:', railsContext);
    } else {
      console.log('RENDERED ' + name + ' to dom node with id: ' + domNodeId + ' with props, railsContext:', props, railsContext);
    }
  }

  if (generatorFunction) {
    return component(props, railsContext);
  }

  return _react2.default.createElement(component, props);
} /* eslint-disable react/prop-types */