import React from 'react';
import PropTypes from 'prop-types';

const FlashError = ({ width = 19, height = 18, fill = '#EB5757' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 19 18" fill="none">
    <path d="M9.03229 0C4.04011 0 0 4.02528 0 9C0 13.9744 4.03973 18 9.03229 18C14.0245 18 18.0646 13.9747 18.0646 9C18.0646 4.02564 14.0248 0 9.03229 0Z" fill={fill} />
    <path d="M11.6464 6.14645L5.64645 12.1464C5.45118 12.3417 5.45118 12.6583 5.64645 12.8536C5.84171 13.0488 6.15829 13.0488 6.35355 12.8536L12.3536 6.85355C12.5488 6.65829 12.5488 6.34171 12.3536 6.14645C12.1583 5.95118 11.8417 5.95118 11.6464 6.14645Z" fill="white" stroke="white" strokeWidth="0.5" />
    <path d="M5.64645 6.85355L11.6464 12.8536C11.8417 13.0488 12.1583 13.0488 12.3536 12.8536C12.5488 12.6583 12.5488 12.3417 12.3536 12.1464L6.35355 6.14645C6.15829 5.95118 5.84171 5.95118 5.64645 6.14645C5.45118 6.34171 5.45118 6.65829 5.64645 6.85355Z" fill="white" stroke="white" strokeWidth="0.5" />
  </svg>
);

FlashError.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string,
};

export default FlashError;
