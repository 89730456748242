import * as React from 'react';

const Mp3Icon = ({ width = 26, height = 23, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 1000 1000" {...rest}>
      <path d="M902.151 220.537v123.738h-61.874v-92.804H716.539c-34.171 0-61.865-27.7-61.865-61.869V65.863H190.653c-17.086 0-30.936 13.85-30.936 30.936v804.298c0 17.086 13.85 30.936 30.936 30.936h69.602v61.87h-69.602c-51.254 0-92.804-41.548-92.804-92.806V96.799c0-51.253 41.55-92.804 92.804-92.804h494.955a30.936 30.936 0 0121.965 8.97l185.605 185.609a30.94 30.94 0 018.973 21.963zm-4.431 344.091v242.876c0 70.853-57.643 128.495-128.495 128.495S640.73 878.356 640.73 807.504s57.643-128.495 128.495-128.495c24.32 0 47.084 6.793 66.495 18.579v-95.833l-289.977 49.437V867.51c0 70.853-57.643 128.495-128.495 128.495S288.753 938.362 288.753 867.51s57.643-128.495 128.495-128.495c24.321 0 47.085 6.793 66.495 18.579V625.535 505.018c0-15.11 10.895-28.02 25.791-30.559l351.976-60.003a30.998 30.998 0 0136.21 30.558v119.614zM483.743 867.51c0-36.665-29.829-66.495-66.495-66.495-36.665 0-66.495 29.83-66.495 66.495s29.83 66.495 66.495 66.495c36.666 0 66.495-29.83 66.495-66.495zM835.72 538.861v-57.114L545.743 531.18v57.118l289.977-49.437zm0 268.643c0-36.665-29.83-66.495-66.495-66.495s-66.495 29.83-66.495 66.495 29.83 66.495 66.495 66.495 66.495-29.83 66.495-66.495z" />
    </svg>
  );
};

Mp3Icon.propTypes = {
  width: React.PropTypes.number,
  height: React.PropTypes.number,
};
export default Mp3Icon;
