import React from 'react';
import { GmailLogo, OutlookLogo } from './svg/join_bundle'; // Adjust the import path as necessary

const SniperLinks = ({ gmail_link, outlook_link, i18n }) => {
  // Check if both links exist; if not, render nothing.
  if (!gmail_link || !outlook_link) {
    return null;
  }

  return (
    <div className='flex ltr:lg:flex-row rtl:lg:flex-row-reverse flex-col my-7'>
      <div className='flex items-center ltr:lg:mr-7'>
        <GmailLogo />
        <a
          href={gmail_link}
          target="_blank"
          rel="noopener noreferrer"
          className='rtl:mr-3 ltr:ml-3 cursor-pointer !text-[18px]'>
          <span className="text-[#0047FF] underline font-normal">
            {i18n.sniperText.gmail}
          </span>
        </a>
      </div>
      <div className='flex items-center lg:mt-0 mt-7 rtl:lg:ml-7'>
        <OutlookLogo />
        <a
          href={outlook_link}
          target="_blank"
          rel="noopener noreferrer"
          className='rtl:mr-3 ltr:ml-3 cursor-pointer !text-[18px]'>
          <span className="text-[#0047FF] underline font-normal">
            {i18n.sniperText.outlook}
          </span>
        </a>
      </div>
    </div>
  );
};

export default SniperLinks;
