import { findWhere, extend } from 'lodash';

export const symbols = {
  RECEIVED_QUICKLINKS: '@@reseller:group:on_quicklinks',
  QUICKLINK_ERROR: '@@reseller:group:on_qlerror',
  EDIT_QUICKLINK: '@@reseller:group:edit_quicklink',
  CANCEL_QUICKLINK_EDIT: '@@reseller:group:cancel_edit_quicklink',
  REORDER: '@@reseller:group:reorder',
};

export default {
  addQuicklink: add_quicklink,
  editQuicklink: edit_quicklink,
  saveQuicklink: save_quicklink,
  removeQuicklink: remove_quicklink,
  reorderLinks: set_links_order,
  cancelQuicklinkedit: cancel_quicklink_edit,
};


function add_quicklink(text, link_text, href) {
  return function(dispatch, getstate) {
    var url = getstate().$$reseller.group.add_quicklink_url;
    var params = { text: text, link_text: link_text, href: href };
    modify_quicklink('post', url, params, dispatch, getstate);
  };
}

function edit_quicklink(id) {
  return { type: symbols.EDIT_QUICKLINK, payload: id };
}

function save_quicklink(item) {
  return function(dispatch, getstate) {
    modify_quicklink('put', item.edit_url, item, dispatch, getstate);
  };
}

function modify_quicklink(requestType, url, params, dispatch, getstate) {
  var state = getstate();
  var active_group_id = findWhere(state.$$reseller.group.all, { active: true }).id;
  $.ajax({
    type: requestType,
    success: function(links) {
      dispatch({ type: symbols.RECEIVED_QUICKLINKS, payload: links });
    },
    dataType: 'json',
    error: function(err) {
      dispatch({ type: symbols.QUICKLINK_ERROR, payload: err});
    },
    data: extend({}, params, {group_id: active_group_id}),
    url: url
  });
}

function remove_quicklink(item) {
  return function(dispatch, getstate) {
    modify_quicklink('delete', item.edit_url, {}, dispatch, getstate);
  };
}

function cancel_quicklink_edit(id) {
  return { type: symbols.CANCEL_QUICKLINK_EDIT, payload: id };
}

function set_links_order(order) {
  return function(dispatch, getstate) {
    dispatch({ type: symbols.REORDER, payload: order });

    var url = getstate().$$reseller.group.reorder_url;
    var params = { sortorder: order };
    modify_quicklink('post', url, params, dispatch, getstate);
  };
}
