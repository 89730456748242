import React, { PropTypes } from 'react';
import { VideoProps } from '../../constants/lesson';
import { getPlayer, Providers, fixPlayerDimensions, isPlayerShallFixDimensions } from '../../components/helpers/video_providers';
import { navigateToNextLesson } from '../../actions/ajax';
import { mobileAndTabletcheck, isIOSDevice } from 'lib/helpers';
import { connect } from 'react-redux';
import actions from '../../actions/bundletoc';
import { StudentTracker } from '../../services/StudentTracker';
import { noop, merge, debounce } from 'lodash';

class VideoTab extends React.Component {
  static displayName = 'VideoTab';

  static propTypes = {
    item: VideoProps.isRequired,
    isTeacher: PropTypes.bool,
    changeContentType: PropTypes.func,
    videoCompletion: PropTypes.number,
    bunnyVideoCompletion: PropTypes.number,
    dispatch: PropTypes.func.isRequired,
    lessonWatched: React.PropTypes.bool,
  };

  state = {
    seekPosition: this.props.seekStart,
    videoProgress: 0
  };

  static defaultProps = {
    isTeacher: false,
    delayTime: 1,
    changeContentType: function () { },
  };

  static tid = 2;
  static tabName = 'וידאו';
  playerContainerRef = null;
  videoResponsiveRef = null;
  counter = 0;
  lastLocationThreshold = 5;
  avoidLoopThreshold = 1;
  isWatched = this.props.lessonWatched;

  constructor(props) {
    super(props);

    this.videoWatchLengthEvent = debounce(this.videoWatchLengthEvent, 5000, {leading: true, trailing: false, maxWait: 20000});
  }

  componentDidMount(){
    if(!this.videoResponsiveRef){
      this.videoResponsiveRef = this.playerContainerRef.closest('.video-responsive')
    }

    if(isPlayerShallFixDimensions(this.props.item.get('type'))) {
      window.addEventListener('resize', this.onWindowResize);
      this.onWindowResize();
    }
    this.removeVimeoBlackBackground(this.props.item.get('type'))
  }

  componentDidUpdate(prevProps){
    const {item} = this.props;
    //hide html5 players first incorrect dimensions
    if (item && item.get('videoId') && isPlayerShallFixDimensions(item.get('type')) && prevProps.item.get('videoId') !== item.get('videoId')) {
      Object.assign(this.videoResponsiveRef.style, { left: '-99999em', position: 'absolute' })
    }
    this.removeVimeoBlackBackground(item.get('type'))
  }

  componentWillUnmount(){
    window.removeEventListener('resize', this.onWindowResize);
    //remove vooplayer set max-width
    Object.assign(this.videoResponsiveRef.style, { maxWidth: '' })
    this.sendLastLocation();
  }

  onWindowResize = () => {
    const {item} = this.props;
    fixPlayerDimensions(this.videoResponsiveRef, item.get('videoId'), item.get('type'));
  }

  navigateToNextLesson = () => {
    this.props.dispatch(navigateToNextLesson());
  };

  removeVimeoBlackBackground = (type) => {
    if(type === Providers.VIMEO){
      //vimeo workaround of black background.remove this patch when upgrade react-player to new version
      const playerWrapper = this.playerContainerRef.firstChild.lastChild.firstChild
      playerWrapper && Object.assign(playerWrapper.style, { backgroundColor: 'transparent' })
    }
  };

  handleProgress = (e) => {
    const { duration, type } = this.props.item.toJS();
    const { bunnyVideoCompletion, videoCompletion } = this.props;
    const percentCompleted = (e.playedSeconds / duration) * 100;
    const videoCompletionPercentage = type === Providers.BUNNY_PLAYER ? bunnyVideoCompletion : videoCompletion;

    this.setState({ seekPosition: e.playedSeconds });
    this.videoWatchEvent(this.props, percentCompleted);
    let loadedSeconds = 0

    if (type === Providers.BUNNY_PLAYER){
      loadedSeconds = e.playedSeconds;
    } else {
      loadedSeconds = e.loadedSeconds;
    }

    this.videoWatchLengthEvent(e.playedSeconds, loadedSeconds);

    if ( e.playedSeconds >= duration - this.avoidLoopThreshold){
      this.setState({seekPosition: 0.1});
    }

    if ( (percentCompleted >= videoCompletionPercentage && !this.isWatched )
         || type === Providers.VOO_PLAYER || type === Providers.WHIST_PLAYER ) {
      this.sendCompletion(e);
    }

  };


  videoWatchEvent = (props, percentCompleted) => {
    let progress = parseInt(percentCompleted);
    if([5,10,25,50,75,90].includes(progress)) {
      if(progress !== this.state.videoProgress) {
        this.setState({videoProgress: progress});

        StudentTracker.track(`video_watch_${progress}%`, {
          'video_id': props.lessonId,
          'video_name': props.lessonName,
          'item_id': props.bundleId,
          'item_name': props.bundleName,
          'item_brand': props.teacherName,
          'item_brand_id': props.teacherId,
          'item_category': props.schoolName,
          'item_category2': props.bundleType,
        });

        // if(progress == 90){
        //   this.trackLessonComplete(props);
        // }
      }
    }
  }

  videoWatchLengthEvent = (watchedInSeconds, loadedInSeconds) => {
    StudentTracker.viewedVideoLength({
      courseId: this.props.bundleId,
      lessonId: this.props.lessonId,
      loadedLength: loadedInSeconds,
      studentId: this.props.studentId,
      teacherId: this.props.teacherId,
      videoId: this.props.item.get('videoId'),
      videoLength: this.props.item.get('duration') || 0,
      viewedLength: watchedInSeconds,
    });
  }

  trackLessonComplete = (props) => {
    let itemProps = {
      'item_brand': props.teacherName,
      'item_brand_id': props.teacherId,
      'item_id': props.bundleId,
      'item_name': props.bundleName,
      'item_category': props.schoolName,
      'item_category2': props.bundleType,
    }
    StudentTracker.track(`lesson_${props.lessonSeq}_complete`, merge(itemProps, {
      'lesson_index_number': props.lessonSeq,
      'lesson_name': props.lessonName,
      'lesson_type': props.lessonType,
      'episode_index_number': props.sectionSeq,
      'episode_name': props.sectionName,
    }));

    if(props.watchedLessons + 1 == props.totalLessons){
      StudentTracker.track('finish_course', itemProps);
    }
  }

  trackStart = () => {
    if(this.props.seekStart === 0){
      StudentTracker.track('watch_start', {
        'video_id': this.props.lessonId,
        'video_name': this.props.lessonName,
        'item_id': this.props.bundleId,
        'item_name': this.props.bundleName,
        'item_brand': this.props.teacherName,
        'item_brand_id': this.props.teacherId,
        'item_category': this.props.schoolName,
        'item_category2': this.props.bundleType,
      });
    }
  }

  trackPause = () => {
    this.sendLastLocation();
  }

  customSeek = () => {
    if (this.props.seekStart && this.player && this.player.seekTo) {
      this.player.seekTo(this.props.seekStart);
      console.log(`VideoTab customSeek to ${this.props.seekStart}`);
    }
  };

  endLesson = () => {
    const { type } = this.props.item.toJS();
    const { navigateToNextLessonWhenFinished } = this.props;
    if ( (type === Providers.VOO_PLAYER || type === Providers.WHIST_PLAYER ) && !this.isWatched ) {
      this.sendCompletion();
    }
    this.setState({seekPosition: 0.1});
    if (navigateToNextLessonWhenFinished) {
      this.navigateToNextLesson();
    } else {
      noop();
    }
  };

  sendCompletion = (e) => {
    this.isWatched = true;
    const params = this.makeParams(e);
    this.props.dispatch(actions.markComplete(params));

    this.trackLessonComplete(this.props);

    if (this.viewableLesson() && (params.id != undefined)) {
      this.props.dispatch(actions.markRead(params.id));
    }
  };

  logTypeInParams = () => {
    const { type , duration } = this.props.item.toJS();
    const { bunnyVideoCompletion, videoCompletion } = this.props;
    const percentCompleted = (this.state.seekPosition / duration) * 100;
    const videoCompletionPercentage = type === Providers.BUNNY_PLAYER ? bunnyVideoCompletion : videoCompletion;

    if ((type === Providers.VOO_PLAYER || type === Providers.WHIST_PLAYER || percentCompleted >= videoCompletionPercentage) && this.isWatched) {
      return 'video_completed'
    } else {
      return 'video_partially_complete'
    }
  };

  sendLessonId = (e) => {
    const { type } = this.props.item.toJS();
    if (e.hasOwnProperty('lessonId') && e.lessonId != undefined
    && (type === Providers.VOO_PLAYER ||  type === Providers.WHIST_PLAYER )) {
    return e.lessonId
    } else {
     return this.props.lessonId
    }
  };

  makeParams = (e = {}) => {
    const { videoId, duration } = this.props.item.toJS();
    const params = {
      duration: duration,
      id: this.sendLessonId(e),
      slug: window.location.pathname.split('/').pop(),
      type: this.logTypeInParams(),
      videoId: videoId,
      seekPosition: this.state.seekPosition,
    }

    return params
  }

  viewableLesson = () => {
    return this.props.lessonId && (this.props.free || this.props.purchased) && this.props.dripNotActive;
  }

  sendLastLocation = () => {
    const { duration } = this.props.item.toJS();
    const params = this.makeParams();

    if (params.seekPosition >= duration - this.avoidLoopThreshold) {
      params.seekPosition = 0.1
    }

    if (!(window.location.pathname.split('/').includes("edit"))) {
      this.props.dispatch(actions.partialComplete(params));
    }
  };

  isVimeoOnIosDevice = (type) => {
    return (type === Providers.VIMEO && mobileAndTabletcheck() && isIOSDevice()) ? 1 : 0;
  }

  ref = player => {
    this.player = player;
  }

  render() {
    if (!this.props.item) return false;
    const { videoId, type, autoplay, videoUrl } = this.props.item.toJS();
    const { isTeacher } = this.props;

    // Check for iOS Vimeo bug and enable inline playback as a workaround
    // This prevents the video from automatically entering fullscreen mode on iOS 17 mobile devices.
    // Discussion reference: https://github.com/cookpete/react-player/discussions/1785
    const isInlinePlaybackEnabledForVimeo = this.isVimeoOnIosDevice(type);


    const Player = getPlayer(type, videoId, videoUrl,
      {
        lessonId: this.props.lessonId,
        playing: !isTeacher && !mobileAndTabletcheck() && autoplay,
        onEnded: this.endLesson,
        onProgress: this.handleProgress,
        onReady: this.customSeek,
        onStart: this.trackStart,
        onPause: this.trackPause,
        seekStart: this.props.seekStart,
        ref: this.ref,
        ...isPlayerShallFixDimensions(type) && { width: null, height: null }
      },
      isInlinePlaybackEnabledForVimeo
    );

    console.log('VideoTab render Player:', Player);

    return (
      <div ref={node => this.playerContainerRef = node}>
        <div>
          <div
            className='teacher__dimmer teacher__dimmer_video_tab'
            onClick={this.props.changeContentType}
          />
          {Player}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const lessonId = state.$$lesson.get('id');
  const lessonIdx  = lessonId ? state.$$bundletoc.get('lessons').findIndex((l) => l.get('lesson_id') === lessonId) : -1;
  const lessonData = lessonId ? state.$$bundletoc.getIn(['lessons', lessonIdx]) : Immutable.Map();
  const videoCompletion = state.$$lesson.get('videoCompletion');
  const bunnyVideoCompletion = state.$$lesson.get('bunnyVideoCompletion');
  const lessonWatched = state.$$lesson.get('lessonWatched');
  const seekStart = state.$$lesson.get('seekStart');
  const watchedLessons = state.$$bundletoc.get('lessons').filter((lesson) => lesson.get('watched') == true).size;
  const totalLessons = state.$$bundletoc.get('lessons').size;

  return {
    dripNotActive: !state.$$lesson.getIn(['drip', 'active']),
    free: state.$$lesson.get('free') || false,
    purchased: state.$$layout.getIn(['default_bundle_props', 'owned']),
    lessonId,
    lessonName: state.$$lesson.get('name'),
    lessonType: lessonData.get('type'),
    lessonSeq: lessonData.get('lesson_seq'),
    sectionSeq: lessonData.get('section_seq'),
    sectionName: lessonData.get('section_name'),
    videoCompletion,
    bunnyVideoCompletion,
    lessonWatched,
    watchedLessons,
    totalLessons,
    navigateToNextLessonWhenFinished: state.$$bundletoc.get('move_to_next_lesson_after_video_ends'),
    seekStart,
    teacherId: state.$$layout.get('storageapi_id'),
    studentId: state.$$layout.get('student_id'),
    teacherName: state.$$bundletoc.get('teacher_name'),
    bundleId: state.$$bundletoc.get('bundleid'),
    bundleName: state.$$bundletoc.get('name'),
    bundleType: state.$$bundletoc.get('course_type'),
    schoolName: state.$$school.get('name')
  };
}

export default connect(mapStateToProps)(VideoTab);
