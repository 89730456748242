import $ from 'jquery';
import { symbols } from './ajax';
import { reportError } from './layout';

export default {
  submitResults,
  resetSubmission,
};

function submitResults(formData, submitUrl) {
  return async (dispatch, getstate) => {
    try {
      const appstate = await $.ajax({
        url: submitUrl,
        data: formData,
        type: 'POST',
        dataType: 'json',
      });
      dispatch({ type: symbols.APPSTATE_MERGE, payload: appstate });
    } catch (e) {
      dispatch(reportError(getstate().$$i18n.getIn(['quizzes', 'quiz_incomplete'])));
    }
  };
}

function resetSubmission() {
  return async (dispatch, getstate) => {
    try {
      const appstate = await $.ajax({
        url: getstate().$$lesson.getIn(['quiz', 'resetUrl']),
        type: 'POST',
        dataType: 'json',
      });
      dispatch({ type: symbols.APPSTATE_MERGE, payload: appstate });
    } catch (e) {
      dispatch(reportError(getstate().$$i18n.getIn(['errors', 'unknown'])));
    }
  };
}
