import Immutable from 'immutable';
import { symbols } from '../actions/theme';

export const initialState = Immutable.fromJS({
  settings: {
    background_color: '#2e4b4f',
    text_color: '#c1c9cb',
  },
  edit: false,
});

export default function navReducer(state = initialState, action) {
  switch (action.type) {
    case symbols.START_EDIT:
      return startEdit(state, action);

    case symbols.COMMIT:
      return commit(state, action);

    case symbols.CANCEL:
      return cancel(state, action);

    case symbols.SET:
      return set(state, action);

    default:
      return state;
  }
}

function startEdit(state, action) {
  return state.set('oldSettings', state.get('settings')).set('edit', true);
}

function commit(state, action) {
  return state.delete('oldSettings').set('edit', false);
}

function cancel(state, action) {
  return state.set('settings', state.get('oldSettings')).set('edit', false);
}

function set(state, { payload: { options } }) {
  return state.update('settings', (settings) => settings.merge(Immutable.fromJS(options)));
}

