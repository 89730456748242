import * as React from 'react';

const WordIcon = ({ width = 26, height = 23, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 1000 1000" {...rest}>
      <path d="M966.455 157.914h-404.26v-93.29c0-9.267-4.104-18.037-11.257-23.944-7.091-5.847-16.482-8.271-25.563-6.593l-497.551 93.29a31.01 31.01 0 00-25.375 30.537v684.133c0 14.928 10.635 27.803 25.375 30.539l497.551 93.291c1.865.375 3.857.559 5.723.559 7.152 0 14.241-2.486 19.84-7.15 7.153-5.908 11.257-14.74 11.257-23.945v-93.293h404.261c17.164 0 31.097-13.93 31.097-31.096v-621.94c-.001-17.166-13.934-31.098-31.098-31.098zM500 897.836L64.643 816.24V183.725L500 102.125v795.711zm435.359-117.981H562.195V717.66h279.872c17.166 0 31.098-13.93 31.098-31.098 0-17.164-13.932-31.096-31.098-31.096H562.195v-62.193h279.872c17.166 0 31.098-13.932 31.098-31.098s-13.932-31.098-31.098-31.098H562.195v-62.194h279.872c17.166 0 31.098-13.932 31.098-31.098 0-17.166-13.932-31.097-31.098-31.097H562.195v-62.194h279.872c17.166 0 31.098-13.931 31.098-31.097 0-17.165-13.932-31.097-31.098-31.097H562.195v-62.194h373.164v559.749zm-497.74-400.841l-31.096 279.875c-1.616 14.242-12.813 25.623-27.055 27.363-1.306.25-2.55.311-3.857.311-12.811 0-24.504-7.959-29.107-20.15L282.32 495.254l-64.184 171.159c-4.976 13.311-18.036 21.396-32.464 19.965-14.119-1.494-25.438-12.439-27.428-26.557l-31.098-217.68c-2.425-16.978 9.391-32.713 26.37-35.139 16.856-2.736 32.776 9.329 35.201 26.37l13.871 97.271 50.563-134.899c9.08-24.255 49.133-24.255 58.275 0l47.641 127 16.73-150.633c1.865-17.041 17.85-29.107 34.331-27.428 17.106 1.928 29.358 17.289 27.491 34.331z" />
    </svg>
  );
};

WordIcon.propTypes = {
  width: React.PropTypes.number,
  height: React.PropTypes.number,
};
export default WordIcon;
