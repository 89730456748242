import React from 'react';
import Quiz from '../quiz';
import QuizResults from '../quiz_results.jsx';
import actions from "../../actions/bundletoc";
import {connect} from "react-redux";
import { StudentTracker } from '../../services/StudentTracker';
import { merge } from 'lodash';

class QuizTab extends React.Component {
  static propTypes = {
    quiz: React.PropTypes.object,
    changeContentType: React.PropTypes.func,
    isTeacher: React.PropTypes.bool,
  };

  state = {
    quizPassed: false
  };

  static defaultProps = {
    isTeacher: false,
    changeContentType: () => {},
  };

  static tid = 5;
  static tabName = 'מבחן';

  componentDidUpdate(prevProps){
    const { quiz } = this.props;
    if (!quiz) { return false; }
     if( quiz.get('solved')){
       if((quiz.get('blocking') && quiz.get('result') === 'passed') || (quiz.get('blocking') === false))
       {
         if (this.viewableLesson()) {
           this.props.dispatch(actions.markRead(this.props.lessonId));
           if(this.state.quizPassed === false){
             this.trackLessonComplete(this.props);
           }
         }
       }
     }
  }

  trackLessonComplete = (props) => {
    let itemProps = {
      'item_brand': props.teacherName, 
      'item_brand_id': props.teacherId,
      'item_id': props.bundleid, 
      'item_name': props.bundleName, 
      'item_category': props.schoolName,  
      'item_category2': props.bundleType,
    }
    StudentTracker.track(`lesson_${props.lessonSeq}_complete`, merge(itemProps, {
      'lesson_index_number': props.lessonSeq,
      'lesson_name': props.lessonName,
      'lesson_type': props.lessonType,
      'episode_index_number': props.sectionSeq,
      'episode_name': props.sectionName,
    }));

    if(props.watchedLessons + 1 == props.totalLessons){
      StudentTracker.track('finish_course', itemProps);
    }
    this.setState({quizPassed: true});
  }

  viewableLesson = () => {
    return this.props.lessonId && (this.props.free || this.props.purchased) && this.props.dripNotActive;
  }

  render() {
    const { quiz } = this.props;
    let QuizComponent = false;

    if (quiz && quiz.get('solved')) {
      QuizComponent = <QuizResults quiz={quiz} />;
    } else if (quiz){
      QuizComponent = <Quiz quiz={quiz} />;
    }

    return (
      <div className='quiztab'>
        {QuizComponent}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const lessonId = state.$$lesson.get('id');
  const lessonIdx  = lessonId ? state.$$bundletoc.get('lessons').findIndex((l) => l.get('lesson_id') === lessonId) : -1;
  const lessonData = lessonId ? state.$$bundletoc.getIn(['lessons', lessonIdx]) : Immutable.Map();
  const watchedLessons = state.$$bundletoc.get('lessons').filter((lesson) => lesson.get('watched') == true).size;
  const totalLessons = state.$$bundletoc.get('lessons').size;

  return {
    dripNotActive: !state.$$lesson.getIn(['drip', 'active']),
    free: state.$$lesson.get('free') || false,
    purchased: state.$$layout.getIn(['default_bundle_props', 'owned']),
    lessonId,
    watchedLessons,
    totalLessons,
    lessonName: state.$$lesson.get('name'),
    lessonType: lessonData.get('type'),
    lessonSeq: lessonData.get('lesson_seq'),
    sectionSeq: lessonData.get('section_seq'),
    sectionName: lessonData.get('section_name'),
  };
}

export default connect(mapStateToProps)(QuizTab);
