export function getFlashMessage(state) {
  const flashType = state.$$layout.getIn(['flash', 'type']);
  const jsError   = state.$$layout.get('jserror');
  const jsWarning = state.$$layout.get('jswarning');

  const errorHeader   = state.$$i18n.getIn(['general', 'error_header']);
  const warningHeader = state.$$i18n.getIn(['general', 'warning_header']);

  return {
    msgType: flashType || (jsError ? 'error' : (jsWarning ? 'warning' : undefined)),
    headerTitle: flashType ? state.$$layout.getIn(['flash', 'header']) : (jsError ? errorHeader : (jsWarning ? warningHeader : undefined)),
    textMessage: flashType ? state.$$layout.getIn(['flash', 'message']) : (jsError || jsWarning || undefined)
  };
}
