import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';

const VideoMarkProps = ImmutablePropTypes.contains({
  atSecond: React.PropTypes.number,
  text: React.PropTypes.string,
});

export const VideoProps = ImmutablePropTypes.contains({
  id: React.PropTypes.number.isRequired,
  duration: React.PropTypes.number.isRequire,
  notes: React.PropTypes.string,
  videoId: React.PropTypes.string.isRequired,
  marks: ImmutablePropTypes.listOf(VideoMarkProps),
});
