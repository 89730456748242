import React from "react";
import Links from "../../../constants/links";

import SchoolerLogo from "../../../components/logo";

const Footer = ({ i18n, schoolData }) => {
  const previewSchoolStatementPath = schoolData.get('preview_school_statement')
  const hasA11yStatement = schoolData.get('has_a11y_statement')
  const a11yStatement = i18n.getIn(["system", "a11yStatement"])
  const creditStatement = i18n.getIn(["system", "credit"])
  const creditAriaLabel = i18n.getIn(['system', 'creditAriaLabel'])

  if (hasA11yStatement) {
    return (
      <footer className="static-footer credits a11y-school-footer">
        <SchoolerLogo />
        <div className="links-container">
          <a href={Links.SCHOOLER_HOMEPAGE_URL} aria-label={creditAriaLabel} target="_blank" className="top-link">
            {creditStatement}
          </a>
          <a href={previewSchoolStatementPath} className="bottom-link" target="_blank">
            {a11yStatement}
          </a>
        </div>
      </footer>
    );
  } else {
    return (
      <footer className="footer-wrapper">
        <a href={Links.SCHOOLER_HOMEPAGE_URL} aria-label={creditAriaLabel} target="_blank">
          <SchoolerLogo />
          <div className="logo-description">{creditStatement}</div>
        </a>
      </footer>
    );
  }
};
export default React.memo(Footer);
