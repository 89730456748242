import React, { useCallback, useLayoutEffect } from 'react';
import cx from 'classnames';

import { durationString } from '../../../components/helpers/school';
import ConditionalComponent from '../../../components/common/ConditionalComponent';
import Clock from '../../../components/svg/clock';

import CourseProgressBar from './CourseProgressBar';
import CoursePrice from './CoursePrice';
import { StudentTracker } from '../../../services/StudentTracker';


export default function CourseBox({ templateName, altColor, course, i18n, textColor, schoolName, layoutLocale }) {
  const randomNameId = `school-course-name-${Math.floor(Math.random() * 10000)}`;
  const displayProgressBar = course.is_course_progress_display && course.owned && !!course.user_watch_lessons
  const addMarginToDetails = ['green', 'purple'].includes(templateName) && !displayProgressBar
  const progressBarDirection = layoutLocale == 'en' ? 'ltr' : 'rtl'

  const onVisitCourse = useCallback((e, bundle, schoolName) => {
    e.preventDefault();

    StudentTracker.track('choose_course', {
      'item_id': bundle.id,
      'item_name': bundle.name,
      'item_brand': bundle.author,
      'item_brand_id': bundle.author_id,
      'item_category': schoolName,
      'item_category2': bundle.bundle_policy,
    });

    window.location.href = bundle.href;
  }, []);

  return (
    <div
      className={cx('school-course-box', {
        'is-draft': course.draft,
        'is-owned': course.owned
      })}
      tabIndex="0"
      onClick={(e) => onVisitCourse(e, course, schoolName)}
    >
      <div className={cx('school-course-thumbnail', {'is-draft': course.draft})} style={{ backgroundImage: `url('${course.img}')` }}>
        <ConditionalComponent isRender={course.draft}>
          <div className="school-course-draft" style={{ color: textColor, backgroundColor: altColor }}>
            {i18n.getIn(['students', 'school', 'draft'])}
          </div>
        </ConditionalComponent>

        <ConditionalComponent isRender={course.owned}>
          <div className="school-course-owned" style={{ color: textColor, backgroundColor: altColor }} />
        </ConditionalComponent>

      </div>

      <div className='school-course-info'>
        <h4 className='school-course-name' id={randomNameId}>
          {course.name}
        </h4>

        <ConditionalComponent isRender={course.duration !== 0 && course.is_course_duration_display}>
          <div className='school-course-duration'>
            <Clock />
            {durationString(course.duration, i18n.getIn(['students', 'course', 'duration']).toJS())}
          </div>
        </ConditionalComponent>

        <ConditionalComponent isRender={course.is_course_progress_display && course.owned && !!course.user_watch_lessons}>
          <CourseProgressBar
            amount={!!course.user_watch_lessons && course.user_watch_lessons.lessons_count}
            color={altColor}
            i18n={i18n}
            watchedAmount={!!course.user_watch_lessons && course.user_watch_lessons.watched_lessons_count}
            progressBarDirection={progressBarDirection}
          />
        </ConditionalComponent>

        <div className={cx('school-course-details', {'detail-margin-top': addMarginToDetails})} style={{position: 'relative'}}>
          <ConditionalComponent isRender={!!course.avatar_url.length}>
            <div
              className='school-course-avatar school-course-avatar-center'
              style={{ backgroundImage: `url('${course.avatar_url}')` }}
            >
            </div>
          </ConditionalComponent>

          <div className='school-course-author'>
            {course.author}
          </div>

          <CoursePrice
            backgroundColor={altColor}
            color={textColor}
            i18n={i18n}
            isActive={course.is_active}
            isOwned={course.owned}
            policy={course.policy}
            price={course.price}
            currencyType={course.currency_type}
            priceAfterDiscount={course.price_after_discount}
          />
        </div>
      </div>
    </div>
  );
}
