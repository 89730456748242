import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import ProgressBar from "@ramonak/react-progress-bar";

import { hexAndAlphaToRGBA } from '../../../components/helpers/school';


export default function CourseProgressBar({
  amount,
  color,
  i18n,
  watchedAmount,
  progressBarDirection,
}) {

  const getProgressPercentage = useCallback(() => {
    return useMemo(() => amount ? parseInt(watchedAmount / (amount / 100)) : 0);
  })

  const getProgressStats = useCallback(() => {
    return useMemo(() => `(${`${watchedAmount}/${amount}`})`)
  })

  return (
    <div className='school-course-progress'>
      <span
        className='school-course-progress-label'
        dangerouslySetInnerHTML={{ __html: i18n.getIn(['students', 'school', 'progress', 'label']) }}>
      </span>

      <span className="school-course-progress-stats">
        {getProgressStats()}
      </span>

      <span className="school-course-progress-percentage">
        {`${getProgressPercentage()}%`}
      </span>

      <ProgressBar
        animateOnRender={true}
        baseBgColor={hexAndAlphaToRGBA(color, 0.3)}
        bgColor={color}
        borderRadius={'10px'}
        className="school-course-progress-bar"
        completed={getProgressPercentage()}
        dir={progressBarDirection}
        height={'10px'}
        isLabelVisible={false}
        maxCompleted={100}
      />
    </div>
  );
}

CourseProgressBar.propTypes = {
  bgColor: PropTypes.string,
  baseBgColor: PropTypes.string,
}

CourseProgressBar.defaultProps = {
  baseBgColor: "#E7E7E7",
}

