import mixpanel from 'mixpanel-browser';

let enableDebugging = ['development', 'staging', 'staging_mahesh'].includes(window.ENV)

mixpanel.init(window.MIXPANEL_API_KEY, {debug: enableDebugging});

let env_check = ['production', 'staging', 'staging_mahesh'].includes(window.ENV)

let actions = {
  identify: (id) => {
    if (env_check) mixpanel.identify(id);
  },
  alias: (id) => {
    if (env_check) mixpanel.alias(id);
  },
  track: (name, props) => {
    if (env_check) mixpanel.track(name, props);
  },
  time_event: (name, props) => {
    if (env_check){
      mixpanel.track(name, props);
      mixpanel.time_event(name);
    }
  },
  register: (props) => {
    if (env_check) mixpanel.register(props);
  },
  unregister: (props) => {
    if (env_check) mixpanel.unregister(props);
  },
  reset: (props) => {
    if (env_check) mixpanel.reset(props);
  },
  people: {
    set: (props) => {
      if (env_check) mixpanel.people.set(props);
    },
  },
  superIncrement: (prop) => {
    if (env_check) superIncrement(prop, 1)
  },
};

//wait until Mixpanel is loaded
//alternatively you may use the loaded callback in the init() or set_config(): https://github.com/mixpanel/mixpanel-js/blob/8b2e1f7b/src/mixpanel-core.js#L95 
function waitUntilLoad(func) {
 if (mixpanel.__loaded) func();
 else setTimeout(function() {
 waitUntilLoad(func);
 }, 10);
}

//a wrapper which acts a superIncrement method accepting a 'property' and 'amount'
function superIncrement(property, amount) {
 waitUntilLoad(function() {
   increment(property, amount);
 });
}

//wrapper for the incremental event logic
function increment(property, value) {
 waitUntilLoad(function() {
   incrementLogic(property, value);
 });
}

//the actual increment logic
function incrementLogic(property, value) {
 var prop = {};
 prop[property] = 0;
 mixpanel.register_once(prop);
 prop[property] = mixpanel.get_property(property) + value;
 mixpanel.register(prop);
}
export let Mixpanel = actions;