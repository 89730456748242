import React, { useEffect, useRef } from 'react';

const BunnyPlayer = ({ url, ...videoProps }) => {
  const iframeRef = useRef(null);
  const playerRef = useRef(null);
  const {onEnded, onProgress, lessonId, onPause, seekStart, playing} = videoProps;

  useEffect(() => {
    const scriptUrl = '//assets.mediadelivery.net/playerjs/player-0.1.0.min.js';

    const loadScript = (src, callback) => {
      const script = document.createElement('script');
      script.src = src;
      script.async = true;
      script.onload = callback;
      document.body.appendChild(script);
    };

    const initializePlayer = () => {
      if (iframeRef.current) {
        playerRef.current = new window.playerjs.Player(iframeRef.current);

        playerRef.current.on('ready', () => {
          setTimeout(() => {
            playerRef.current.setCurrentTime(parseInt(seekStart));
          }, 500)

          playerRef.current.on('play', () => {
            playerRef.current.on('timeupdate', (time) => {
              onProgress({lessonId, playedSeconds: time.seconds});
            });
          });

          playerRef.current.on('pause', () => {
            onPause();
          });

          playerRef.current.on('ended', () => {
            onEnded();
          });

          if (playing) {
            playerRef.current.play();
          } else {
            playerRef.current.pause();
          }
        });
      }
    };

    // Check if playerjs is already loaded
    if (window.playerjs) {
      initializePlayer();
    } else {
      // Load the script if not loaded yet
      loadScript(scriptUrl, initializePlayer);
    }

    // Cleanup on component unmount
    return () => {
      if (playerRef.current) {
        playerRef.current.off('ready');
        playerRef.current.off('play');
        playerRef.current.off('pause');
        playerRef.current.off('ended');
      }
    };
  }, [onEnded, onProgress, onPause, lessonId]);

  let updatedUrl = url;

  if (url.includes('iframe.mediadelivery.net/play')) {
    updatedUrl = url.replace(/\/play\//, '/embed/');
  } else if (url.includes('iframe.mediadelivery.net/embed')) {
    const urlParts = url.split('?');
    if (urlParts.length > 1) {
      updatedUrl = urlParts[0];
    }
  }

  const modifiedUrl = playing
    ? `${updatedUrl}?autoplay=true&muted=true&preload=true`
    : `${updatedUrl}?autoplay=false`;


  return (
    <iframe
      ref={iframeRef}
      src={modifiedUrl}
      width="640"
      height="360"
      allowFullScreen
      frameBorder="0"
      scrolling="no"
      {...videoProps}
    ></iframe>
  );
};

export default BunnyPlayer;
