import $ from 'jquery';
import { noop } from 'lodash';
import { List } from 'immutable';

let currentlyLoading = undefined;

export const symbols = {
  APPSTATE_MERGE: '@@ajax.appstate-merge',
  APPSTATE_CLEAR_PROP: '@@ajax.appstate-clear-prop',
  AJAX_START: '@@ajax.start',
  AJAX_CANCEL: '@@ajax.cancel',
  PAGE_TRANSITION_START: '@@ajax.page-transition-start',
  PAGE_TRANSITION_END: '@@ajax.page-transition-end',
  SHOW_SPINNER: '@@ajax.show-spinner',
  UPDATE_VIEW: '@@ajax.update-view',
  STUDENT_LIMIT: '@@ajax.student-limit',
  POLICY_CHANGE: '@@ajax.policy_change'
};

export function updateView(component, routerParams = {}) {
  return { type: symbols.UPDATE_VIEW, payload: { component, routerParams } };
}

export function navigateToNextLesson() {
  return (dispatch, getstate) => {
    let nextChapter = getstate().$$lesson.get('next');
    if (!nextChapter) { return; }

    dispatch(enterPage(nextChapter, function () {
      setLocationToActiveLesson(getstate());
    }));
  };
}

export function navigateToPreviousLesson() {
  return (dispatch, getstate) => {
    let previousChapter = getstate().$$lesson.get('prev');
    if (!previousChapter) { return; }

    dispatch(enterPage(previousChapter, function () {
      setLocationToActiveLesson(getstate());
    }));
  };
}

export function logout() {
  return (dispatch, getstate) => {
    const logoutUrl = getstate().$$layout.getIn(['urls', 'logout']);
    const rootUrl = getstate().$$layout.getIn(['urls', 'root']);
    $.ajax({
      url: logoutUrl,
      type: 'DELETE',
      success: function (result) {
        window.location.reload();
      },
    });
  };
}

export function fetchStateForNextPage(req, res) {
  const successReporterGen = function (dispatch, getstate) {
    return function () {
      if (res.component) {
        if (res.mergeAction) {
          res.mergeAction.payload.nextView = res.component;
          dispatch(res.mergeAction);
        } else {
          dispatch(updateView(res.component, res.props.routerParams));
        }
      } else {
        window.location.reload();
      }
    };
  };

  const errorReporterGen = function (dispatch, getstate) {
    return function (err) {
      window.location.reload();
    };
  };
  return doFetchStateForNextPage(req, res, successReporterGen, errorReporterGen);
}

function doFetchStateForNextPage(req, res, successReporterGen, errorReporterGen) {
  const url = req.url;

  if (url === currentlyLoading) {
    return noop;
  }

  return (dispatch, getstate) => {
    const successReporter = successReporterGen(dispatch, getstate);
    const errorReporter = errorReporterGen(dispatch, getstate);
    let type = '';
    let transitionValue = false;
    let filteredLessons = List(); // Assuming List is the appropriate data structure.

    if (res.props.routerParams && res.props.routerParams.lessonid && !getstate().$$bundletoc.isEmpty()) {
      filteredLessons = getstate().$$bundletoc.get('lessons').filter(
        lesson => lesson.get('lesson_slug') === res.props.routerParams.lessonid
      );
    }

    if (filteredLessons.size === 1) {
      const lesson = filteredLessons.first();
      type = lesson.get('type');
    }

    if (type === 'video' || type === 'audio' || type === 'pdf') {
      transitionValue = true;
    }

    dispatch({
      type: symbols.PAGE_TRANSITION_START,
      payload: {
        ...res.props,
        transitionValue: transitionValue
      }
    });

    const activeRequest = getstate().$$nav.get('loading');
    if (activeRequest) {
      activeRequest.abort();
      dispatch({ type: symbols.AJAX_CANCEL });
    }

    currentlyLoading = url;
    const req = $.getJSON(url, (appstate, _success, xhr) => {
      const loginPageHeader = xhr.getResponseHeader('LoginPage');
      if (loginPageHeader && loginPageHeader !== '') {
        window.navigateTo(loginPageHeader);
      }

      if (currentlyLoading !== appstate.layout.lastLoadedUrl) {
        return window.navigateTo(appstate.layout.lastLoadedUrl);
      }

      currentlyLoading = undefined;
      res.mergeAction = { type: symbols.APPSTATE_MERGE, payload: appstate };
      successReporter();

      document.title = getstate().$$layout.get('title');
    });

    req.fail(function (jqxhr, textStatus, error) {
      if (error !== 'abort') {
        errorReporter(null, `${textStatus}, ${error}`);
      }
    });

    dispatch({ type: symbols.AJAX_START, payload: req });
    setTimeout(function () {
      if (getstate().$$nav.get('loading')) {
        // if still loading, show a spinner
        dispatch({ type: symbols.SHOW_SPINNER });
      }
    }, 500);
    // return req;
  };
}

export function setLocationToActiveLesson(state) {
  const currentLessonId = state.$$lesson.get('id');
  const currentLesson = state.$$bundletoc.get('lessons').find((l) => l.get('lesson_id') === currentLessonId);
  const currentBundle = state.$$bundletoc.get('name');
  history.pushState(null, currentLesson.get('lesson_name'), currentLesson.get('path'));
  window.pushRouterHistory(currentLesson.get('path'));
  document.title = currentBundle + '|' + currentLesson.get('lesson_name');
}

export function enterPage(url, callback = null, params = null) {
  if (url === currentlyLoading) {
    return noop;
  }
  //do not go to last saved lesson, go to requested lesson
  return (dispatch, getstate) => {
    // onEnter is called even on first page load
    // but we already have all the data for that,
    // so no need to send an AJAX request
    if (!params && (getstate().$$layout.get('lastLoadedUrl') === url)) {
      return typeof callback === 'function' && callback.call();
    }

    const nextLessonId = getstate().$$lesson.get('nextLessonSlug');
    const previousLessonId = getstate().$$lesson.get('previousLessonSlug');
    let type = '';

    if (url && url.split('/').includes(nextLessonId)) {
      type = getstate().$$lesson.get('nextLessontype');
    } else if (url && url.split('/').includes(previousLessonId)) {
      type = getstate().$$lesson.get('previousLessontype');
    }

    let transitionValue = false;

    if (type === 'video' || type === 'audio' || type === 'pdf') {
      transitionValue = true;
    }

    dispatch({
      type: symbols.PAGE_TRANSITION_START,
      payload: {
        transitionValue: transitionValue
      }
    });

    const activeRequest = getstate().$$nav.get('loading');
    if (activeRequest) {
      activeRequest.abort();
      dispatch({ type: symbols.AJAX_CANCEL });
    }

    currentlyLoading = url;
    const req = $.getJSON(url, params, (appstate, _success, xhr) => {
      const loginPageHeader = xhr.getResponseHeader('LoginPage');
      if (loginPageHeader && loginPageHeader !== '') {
        window.navigateTo(loginPageHeader);
      }

      currentlyLoading = undefined;
      dispatch({ type: symbols.APPSTATE_MERGE, payload: appstate });
      if (typeof callback === 'function') {
        callback.call();
      }
      document.title = getstate().$$layout.get('title');
      dispatch({ type: symbols.PAGE_TRANSITION_END });
    });

    req.fail(function (jqxhr, textStatus, error) {
      if (typeof callback === 'function') {
        callback.call(null, `${textStatus}, ${error}`);
      }
    });

    dispatch({ type: symbols.AJAX_START, payload: req });
    setTimeout(function () {
      if (getstate().$$nav.get('loading')) {
        // if still loading, show a spinner
        dispatch({ type: symbols.SHOW_SPINNER });
      }
    }, 500);
    // return req;
  };
}
