import $ from 'jquery';

export default {
  submitContactForm,
};

function submitContactForm(data) {
  return submitForm((getstate) => getstate().$$contactForm.get('submitUrl'), 'contact', data);
}

export const symbols = {
  SUBMIT_START: '@@contactform.submit-start',
  HANDLE_RESULT: '@@contactform.submit-end',
  HANDLE_ERROR: '@@contactform.submit-error',
  SET_SIGNUP_REASON: '@@form-set-signup-reason',
};

export function submitForm(aUrl, data) {
  return (dispatch, getstate) => {
    const url = (typeof aUrl === 'function') ? aUrl(getstate) : aUrl;

    dispatch({ type: symbols.SUBMIT_START });

    return new Promise((resolve, reject) => {
      $.ajax({
        method: 'POST',
        url,
        data,
        dataType: 'json',
        success: (result) => {
          dispatch({ type: symbols.HANDLE_RESULT, payload: result.text });

          resolve();
        },
        error: (xhr, err) => {
          dispatch({ type: symbols.HANDLE_ERROR, payload: err });
          if (xhr.responseJSON) {
            reject(xhr.responseJSON.errors);
          } else {
            reject(xhr.responseText);
          }
        },
      });
    });
  };
}

