import React from 'react';

import { format } from '../../../../../lib/helpers'
import ConditionalComponent from '../../../components/common/ConditionalComponent';
import SchoolLogo from '../common/SchoolLogo';


export default function SchoolButtonsBar({
  altColor,
  i18n,
  signedIn,
  signedInUserName,
  signinPath,
  signoutPath,
  signupPath,
  textColor,
  homepagePath,
  logo,
}) {
  const generalI18n = i18n.getIn(['students', 'general']).toJS();

  const LogInOutButton = () => {
    const text = (signedIn ? generalI18n.logout : generalI18n.login);
    const testName = (signedIn ? 'signout-button' : 'signin-button');
    const href = (signedIn ? signoutPath : signinPath);
    const leftButtonAriaLabel = signedIn ? generalI18n.logoutAriaLabel : generalI18n.loginAriaLabel;

    return (
      <a
        href={href}
        className='school-button secondary-btn'
        aria-label={leftButtonAriaLabel}
        style={{
          backgroundColor: textColor,
          borderColor: altColor,
          color: altColor,
        }}
        data-testname={testName}
      >
        {text}
      </a>
    )
  }

  return (
    <div className='school-grid school-buttons-bar'>
      <SchoolLogo link={homepagePath} image={logo} />

      <ConditionalComponent isRender={signedIn}>
        <div className="school-welcome" style={{ color: altColor }}>
          {generalI18n.welcomeFormat.replace("%{user_name}", signedInUserName)}
        </div>
      </ConditionalComponent>

      <LogInOutButton />

      <ConditionalComponent isRender={!signedIn}>
        <a
          className='school-button'
          href={signupPath}
          aria-label={generalI18n.registrationAriaLabel}
          style={{
            backgroundColor: altColor,
            color: textColor,
          }}
        >
          {generalI18n.registration}
        </a>
      </ConditionalComponent>

    </div>
  )
}
