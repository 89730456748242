import React from 'react';

export default function MagnifyingGlass({ ...props }) {
  return (
    <svg className="magnifying-glass-svg" width="35" height="34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="14.858" cy="14.35" r="11.057" stroke="currentColor" />
      <path d="M22.508 21.965a.5.5 0 0 0-.712.703l.712-.703Zm7.255 8.781a.5.5 0 1 0 .712-.702l-.712.702Zm-7.967-8.078 7.967 8.078.712-.702-7.967-8.079-.712.703Z" fill="currentColor" />
    </svg>
  );
}
