export default function userNameHelper(customDomain) {
  let prefix;
  if (customDomain) {
    prefix = 'd_school__user';
  } else {
    prefix = 'r_school_user';
  }
  return function (name) {
    return `${prefix}[${name}]`;
  };
}

