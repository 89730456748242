import React, { useState } from 'react';
import { connect } from 'react-redux';
import Lesson from './lesson';
import { AboutInstructor, AboutBundle, AboutAudience } from '../components/svg/join_bundle';
import SignupFormLogo from '../components/svg/signup_form_logo';
import { ChevronIcon } from '../components/icons/index';
import { isLayoutRTL } from 'lib/helpers';

const JoinBundleLayout = (props) => {
  const { description, audience, instructor, i18n, price, bundleName, bundleImage, Content, MobileContent, isShoppingCart } = props;
  const [expandedSections, setExpandedSections] = useState(['instructor', 'bundle', 'audience']);

  const toggleSection = (section) => {
    setExpandedSections((prev) =>
      prev.includes(section) ? prev.filter((s) => s !== section) : [...prev, section]
    );
  };

  const Section = ({ IconComponent, title, content, sectionClass }) => (
    <section className={`${sectionClass} text-[#333]`}>
      <div className="flex items-center justify-end mb-3 flex-row-reverse">
        <span className="text-lg font-semibold ltr:ml-2 rtl:mr-2 text-[#333333]" dangerouslySetInnerHTML={{ __html: title }}></span>
        <IconComponent />
      </div>
      <p className='text-md font-normal text-[#333]' dangerouslySetInnerHTML={{ __html: content }}></p>
    </section>
  );

  const CourseDescription = () => (
    <div className="lg:block hidden">
      <Section
        IconComponent={AboutBundle}
        title={i18n.about}
        content={description}
        sectionClass="mt-8"
      />
      <Section
        IconComponent={AboutInstructor}
        title={i18n.aboutInstructor}
        content={instructor}
        sectionClass="mt-11"
      />
      <Section
        IconComponent={AboutAudience}
        title={i18n.audience}
        content={audience}
        sectionClass="mt-11"
      />
    </div>
  );

  const CollapsibleSection = ({ IconComponent, title, content, sectionName, sectionClass }) => (
    <div className={sectionClass}>
      <button
        className="flex items-center justify-between !bg-white border-none w-full !font-normal px-5 py-3 mb-2"
        onClick={() => toggleSection(sectionName)}
      >
        <div className="flex items-center justify-end">
          <IconComponent />
          <span className="text-lg font-bold font-[Assistant] mr-2 text-[#333333]" dangerouslySetInnerHTML={{ __html: title }}></span>
        </div>
        <span className={`!static transition-transform duration-300 ${expandedSections.includes(sectionName) ? 'rotate-0' : 'rotate-180'}`}>
          <ChevronIcon />
        </span>
      </button>
      {expandedSections.includes(sectionName) && (
        <p className="!my-2.5 px-5 text-[#333]" dangerouslySetInnerHTML={{ __html: content }}></p>
      )}
    </div>
  );

  const CourseDescriptionMobile = () => (
    <div className="lg:hidden mt-4">
      <CollapsibleSection
        IconComponent={AboutBundle}
        title={i18n.about}
        content={description}
        sectionName="bundle"
      />
      <CollapsibleSection
        IconComponent={AboutInstructor}
        title={i18n.aboutInstructor}
        content={instructor}
        sectionName="instructor"
      />
      <CollapsibleSection
        IconComponent={AboutAudience}
        title={i18n.audience}
        content={audience}
        sectionName="audience"
      />
    </div>
  );

  return (
    <Lesson hideBottomPanel={true} internalScroll={false} hideCourseImage={true}>
      <div className="course-registration mt-16 directional min-[1500px]:w-[1136px] min-[1500px]:mx-auto">
        <div className="grid lg:grid-cols-2 lg:gap-[75px] pb-12">
          <div className={`lg:order-1 order-2 rtl:lg:mr-[5rem] ltr:lg:ml-[5rem] rtl:text-right ltr:text-left ${isShoppingCart && 'lg:block hidden'}`}>
            <div className="lg:px-0 px-5">
              <div className="relative lg:block hidden">
                <img src={bundleImage} className="w-full h-[257px] object-cover" alt="course poster" style={{ boxShadow: '0px 4px 8px 0px rgba(94, 94, 94, 0.08)' }} />
                <span className="absolute bottom-[5px] ltr:right-0 rtl:left-0 course-img w-[150px] h-[130px]"
                  style={{
                    background:
                      isLayoutRTL()
                        ? 'linear-gradient(to bottom left, transparent 0%, transparent 50%, #03D6F3 51%, #03D6F3 100%)'
                        : 'linear-gradient(to bottom right, transparent 0%, transparent 50%, #03D6F3 51%, #03D6F3 100%)',
                  }}
                >
                  <div className="relative">
                    <span className="absolute top-20 ltr:right-4 rtl:left-4 text-[22px] font-bold text-[#333] tracking-[0.4px]">
                       {price > 0 ? i18n.paid : i18n.free}
                    </span>
                  </div>
                </span>
              </div>
              <h3 className="font-bold !mt-3 text-2xl text-[#333] lg:block hidden">{bundleName}</h3>
            </div>
            <CourseDescription />
            <CourseDescriptionMobile />
          </div>
          <div className="lg:order-2 order-1 rtl:lg:pl-9 ltr:lg:pr-9">
            <div className={`lg:bg-white lg:shadow-custom-lg lg:py-9 ${isShoppingCart ? 'lg:px-5' : 'lg:px-12'} px-5 lg:mb-0 mb-4`}>
              <div className={`text-center mb-2 lg:hidden ${isShoppingCart && 'hidden'}`}>
                <SignupFormLogo fillColor={'#03D6F3'} width={75} height={60} />
              </div>

              {price > 0 &&
                <>
                  <h3 className={`!mt-0 text-lg text-[#333] !text-[22px] font-bold text-center lg:hidden ${isShoppingCart && 'hidden'}`}>{bundleName}</h3>
                  <div className='lg:hidden mt-2'> <MobileContent /></div>
                </>
              }
              <Content />
            </div>
          </div>
        </div>
      </div>
    </Lesson>
  );
}

function mapStateToProps(state) {
  return {
    newUserButtonIcon: state.$$layout.getIn(['default_bundle_props', 'paid_for']) ? 'cart' : 'registration',
    instructor: state.$$layout.getIn(['default_bundle_props', 'instructor']).replace(/\n/g, '<br />'),
    audience: state.$$layout.getIn(['default_bundle_props', 'audience']).replace(/\n/g, '<br />'),
    description: state.$$layout.getIn(['default_bundle_props', 'description']).replace(/\n/g, '<br />'),
    price: state.$$layout.getIn(['default_bundle_props', 'price']),
    bundleName: state.$$layout.getIn(['default_bundle_props', 'name']),
    bundleImage: state.$$bundletoc.get('image'),
    i18n: {
      ...state.$$i18n.getIn(['students', 'course']).toJS(),
      ...state.$$i18n.getIn(['students', 'joinCourse']).toJS(),
    },
  };
}

export default connect(mapStateToProps)(JoinBundleLayout);
