import * as React from 'react';

const PdfIcon = ({ width = 26, height = 23, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 1000 1000" {...rest}>
      <path d="M653.066 547.049v94.068c0 13.545-8.434 21.652-22.5 21.652h-54.994V525.396h54.994c13.872 0 22.5 8.336 22.5 21.653zm-293.444-21.653v43.273h54.994c13.871 0 22.467-8.303 22.467-21.621 0-13.316-8.596-21.652-22.467-21.652h-54.994zm644.878-41.22v219.848c0 60.768-49.461 108.369-112.602 108.369H645.291v48.572c0 42.536-35.848 77.143-79.91 77.143H75.605c-44.171 0-80.106-34.606-80.106-77.143v-721.9c0-42.554 35.936-77.175 80.106-77.175h365.734l203.952 228.788v85.128h246.607c63.141 0 112.602 47.602 112.602 108.37zm-555.191-219.59c0 7.681 9.917 17.121 17.984 17.121h88.719L449.309 161.648v102.938zm133.908 547.806H336.74c-63.131 0-112.584-47.602-112.584-108.369V484.176c0-60.768 49.453-108.369 112.584-108.369h246.477v-34.273H467.293c-44.036 0-79.861-34.519-79.861-76.947V121.913H75.605c-11.128 0-18.016 8.903-18.016 17.152v721.899c0 10.575 9.352 17.121 18.016 17.121H565.38c10.669 0 17.836-8.853 17.836-17.121v-48.572zM414.616 474.375H306.532v239.416h53.09v-94.1h54.994c41.662 0 75.59-32.592 75.59-72.643 0-40.081-33.928-72.673-75.59-72.673zm291.556 72.674c0-40.082-33.928-72.674-75.605-72.674H522.498v239.416h108.068c41.678 0 75.605-32.592 75.605-72.674v-94.068zm215.953-72.674H738.418v239.416h52.943v-94.1h98.072V568.67h-98.072v-43.273h130.764v-51.022z" />
    </svg>
  );
};

PdfIcon.propTypes = {
  width: React.PropTypes.number,
  height: React.PropTypes.number,
};

export default PdfIcon;
