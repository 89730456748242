import React from 'react';

export default function SchoolerLogo({ color = '#FFFFFF', width = '56', height = '30' }) {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      viewBox="50 -19 124 62"
      style={{ enableBackground: "new 50 -19 124 62" }}
      width={width}
      height={height}
    >
      <g id="schooler">
        <g>
          <path fill={color} d="M64.4,30.3H62c0-1.1-0.2-1.8-0.7-2.1s-1.6-0.4-3.5-0.4c-1.8,0-2.9,0.1-3.3,0.4c-0.5,0.3-0.7,1-0.7,2
            c0,1.6,0.8,2.5,2.4,2.6l1.9,0.1l2.4,0.1c2.9,0.1,4.4,1.7,4.4,4.5c0,1.8-0.5,3-1.4,3.7s-2.7,1-5.3,1c-2.6,0-4.5-0.3-5.5-0.9
            c-1-0.6-1.5-1.8-1.5-3.5v-0.9h2.4v0.7c0,1,0.3,1.7,0.8,2.1c0.5,0.3,1.6,0.5,3.2,0.5c1.9,0,3.2-0.2,3.8-0.5
            c0.6-0.4,0.9-1.1,0.9-2.3c0-1.7-0.8-2.5-2.3-2.5c-3.6,0-5.9-0.3-7.1-0.9c-1.1-0.6-1.7-1.8-1.7-3.7c0-1.8,0.4-3,1.3-3.6
            s2.7-0.9,5.3-0.9c4.3,0,6.4,1.3,6.4,3.8v0.7H64.4z"/>
          <path fill={color} d="M79.3,36.3h2.4v0.9c0,3.4-2.3,5.1-6.8,5.1c-2.9,0-4.8-0.5-5.7-1.6s-1.4-3.3-1.4-6.6c0-3.1,0.5-5.3,1.4-6.4
            c0.9-1.1,2.8-1.7,5.4-1.7s4.4,0.4,5.4,1.1c1,0.8,1.4,2.2,1.4,4.3H79V31c0-1.2-0.3-2.1-0.9-2.5s-1.8-0.6-3.7-0.6
            c-1.8,0-2.9,0.4-3.4,1.2s-0.8,2.5-0.8,5.2c0,2.6,0.3,4.3,0.9,5s1.9,1.1,4.1,1.1c1.8,0,2.9-0.2,3.4-0.7
            C79.1,39.1,79.3,38,79.3,36.3z"/>
          <path fill={color} d="M87.4,19.3v8.8h0.1c0.6-1.5,2.3-2.2,5-2.2c4,0,6.1,1.8,6.1,5.3v0.6V42h-2.4V31.8v-0.4c0-2.4-1.2-3.6-3.7-3.6
            c-2,0-3.3,0.4-4,1.1c-0.7,0.7-1,2.1-1,4.2V42H85V19.3H87.4z"/>
          <path fill={color} d="M109.4,25.9c3,0,4.9,0.5,5.8,1.5s1.3,3.2,1.3,6.6c0,3.4-0.4,5.6-1.3,6.6s-2.8,1.5-5.8,1.5s-4.9-0.5-5.8-1.5
            s-1.3-3.2-1.3-6.6c0-3.4,0.4-5.6,1.3-6.6S106.4,25.9,109.4,25.9z M109.4,27.8c-2.1,0-3.4,0.3-3.9,1s-0.8,2.4-0.8,5.2
            s0.2,4.6,0.8,5.2s1.8,1,3.9,1s3.4-0.3,3.9-1s0.8-2.4,0.8-5.2s-0.2-4.6-0.8-5.2C112.8,28.1,111.5,27.8,109.4,27.8z"/>
          <path fill={color} d="M126.9,25.9c3,0,4.9,0.5,5.8,1.5c0.9,1,1.3,3.2,1.3,6.6c0,3.4-0.4,5.6-1.3,6.6c-0.9,1-2.8,1.5-5.8,1.5
            c-3,0-4.9-0.5-5.8-1.5s-1.3-3.2-1.3-6.6c0-3.4,0.4-5.6,1.3-6.6S123.9,25.9,126.9,25.9z M126.9,27.8c-2.1,0-3.4,0.3-3.9,1
            s-0.8,2.4-0.8,5.2s0.2,4.6,0.8,5.2s1.8,1,3.9,1s3.4-0.3,3.9-1s0.8-2.4,0.8-5.2s-0.2-4.6-0.8-5.2C130.3,28.1,129,27.8,126.9,27.8z"
          />
          <path fill={color} d="M140,19.3V42h-2.4V19.3H140z"/>
          <path fill={color} d="M155.4,37.2h2.4v0.6c0,1.7-0.5,2.8-1.5,3.5s-2.8,1-5.4,1c-3,0-4.9-0.5-5.8-1.6c-0.9-1.1-1.4-3.3-1.4-6.7
            c0-3.2,0.5-5.3,1.4-6.4c0.9-1.1,2.8-1.6,5.5-1.6c3,0,4.9,0.5,5.8,1.4c0.9,0.9,1.4,2.9,1.4,6v1.2h-11.6c0,2.5,0.3,4.1,0.8,4.8
            s1.8,1,3.8,1c1.9,0,3.1-0.2,3.7-0.5s0.9-1,0.9-2.1V37.2z M155.4,32.7V32c0-1.7-0.3-2.8-0.9-3.3s-1.8-0.8-3.7-0.8
            c-1.9,0-3.1,0.3-3.7,0.9c-0.6,0.6-0.9,1.9-0.9,4h9.2V32.7z"/>
          <path fill={color} d="M161.7,26.1h2.4l-0.2,1.8h0.1c0.9-1.5,2.5-2.2,4.6-2.2c3,0,4.5,1.5,4.5,4.5v1.1h-2.3v-0.4
            c0-0.4,0.1-0.7,0.1-0.8c0-1.6-0.9-2.4-2.7-2.4c-2.6,0-3.9,1.6-3.9,4.8V42h-2.4V26.1H161.7z"/>
        </g>
      </g>
      <g id="Shape_128_1_" enableBackground="new">
        <g id="Shape_128">
          <g>
            <path fill={color} d="M131-18l-18.8,9.1l0.5,1.3l18.8-9.1L131-18z M132.1-15.1L113.2-6l8.8,23.7l17.6-12.5L132.1-15.1z"/>
          </g>
        </g>
      </g>
      <g id="Shape_128_copy_1_" enableBackground="new">
        <g id="Shape_128_copy">
          <g>
            <path fill={color} d="M110.7-5.1l-20.3,5L98,20.2l21.6-1.6L110.7-5.1z M109.6-8L89.3-3l0.5,1.3l20.3-5L109.6-8z"/>
          </g>
        </g>
      </g>
    </svg>
  );
}

