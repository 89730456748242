import React, { PropTypes } from 'react';
import actions from '../../actions/bundletoc';
import { connect } from 'react-redux';
import { StudentTracker } from '../../services/StudentTracker';
import { merge } from 'lodash';

class IFrameTab extends React.Component {
  static propTypes = {
    iframesrc: React.PropTypes.string,
    changeContentType: React.PropTypes.func,
    isTeacher: React.PropTypes.bool,
    dispatch: PropTypes.func.isRequired,
    srcCompletion: PropTypes.number,
    lessonWatched: React.PropTypes.bool,
  }

  static defaultProps = {
    isTeacher: false,
    changeContentType: function () { },
  }

  static tid = 4;
  static tabName = 'קובץ';
  isWatched = this.props.lessonWatched;

  componentDidMount(){
    const params = {slug: window.location.pathname.split('/').pop(), type: 'iframe_completed', id: this.props.lessonId}
    this.interval =  setTimeout(() => {
      if (this.isWatched == false) {
        this.isWatched = true;
        if (!(window.location.pathname.split('/').includes("edit"))) {
          this.props.dispatch(actions.markComplete(params));
          this.trackLessonComplete(this.props);
        }
        if (this.viewableLesson()) {
          this.props.dispatch(actions.markRead(this.props.lessonId));
        }
      }
    }, this.props.srcCompletion);
  }

  trackLessonComplete = (props) => {
    let itemProps = {
      'item_brand': props.teacherName, 
      'item_brand_id': props.teacherId,
      'item_id': props.bundleid, 
      'item_name': props.bundleName, 
      'item_category': props.schoolName,  
      'item_category2': props.bundleType,
    }
    StudentTracker.track(`lesson_${props.lessonSeq}_complete`, merge(itemProps, {
      'lesson_index_number': props.lessonSeq,
      'lesson_name': props.lessonName,
      'lesson_type': props.lessonType,
      'episode_index_number': props.sectionSeq,
      'episode_name': props.sectionName,
    }));

    if(props.watchedLessons + 1 == props.totalLessons){
      StudentTracker.track('finish_course', itemProps);
    }
  }

  viewableLesson = () => {
    return this.props.lessonId && (this.props.free || this.props.purchased) && this.props.dripNotActive;
  }

  componentWillUnmount(){
    clearTimeout(this.interval);
  }

  render() {
    return (<div className='iframetab'>
      {this.props.isTeacher &&
        <div className='teacher__dimmer' onClick={this.props.changeContentType}></div>
      }
        <iframe
          src={this.props.iframesrc}
          style={{ width: '100%', height: '100vh' }}
          allowFullScreen="true"
          webkitallowfullscreen="true"
          mozallowfullscreen="true"
        />
    </div>);
  }
}

function mapStateToProps(state) {
  const lessonId = state.$$lesson.get('id');
  const lessonIdx  = lessonId ? state.$$bundletoc.get('lessons').findIndex((l) => l.get('lesson_id') === lessonId) : -1;
  const lessonData = lessonId ? state.$$bundletoc.getIn(['lessons', lessonIdx]) : Immutable.Map();
  const srcCompletion = state.$$lesson.get('srcCompletion');
  const lessonWatched = state.$$lesson.get('lessonWatched');
  const watchedLessons = state.$$bundletoc.get('lessons').filter((lesson) => lesson.get('watched') == true).size;
  const totalLessons = state.$$bundletoc.get('lessons').size;

  return {
    dripNotActive: !state.$$lesson.getIn(['drip', 'active']),
    free: state.$$lesson.get('free') || false,
    purchased: state.$$layout.getIn(['default_bundle_props', 'owned']),
    lessonId,
    srcCompletion,
    lessonWatched,
    totalLessons,
    watchedLessons,
    lessonName: state.$$lesson.get('name'),
    lessonType: lessonData.get('type'),
    lessonSeq: lessonData.get('lesson_seq'),
    sectionSeq: lessonData.get('section_seq'),
    sectionName: lessonData.get('section_name'),
  };
}

export default connect(mapStateToProps)(IFrameTab);
