import { symbols } from '../actions/group';
import { extend, map, indexOf, sortBy } from 'lodash';

const initialState = {
  quicklinks: [],
  addQuicklinkUrl: '',
  error: null,
};

export default function group(state = initialState, action) {
  switch (action.type) {
    case symbols.RECEIVED_QUICKLINKS:
      return extend({}, state, { quicklinks: action.payload, error: undefined });

    case symbols.QUICKLINK_ERROR:
      return extend({}, state, { error: action.payload });

    case symbols.EDIT_QUICKLINK:
      return extend({}, state, {
        quicklinks: map(state.quicklinks, (link) => {
          return link.id === action.payload ? extend({}, link, { edit: true }) : link;
        })
      });

    case symbols.REORDER:
      const ql = state.quicklinks;
      const qll = map(ql.slice(0, -1), (item) => {
        return extend({}, item, { order: indexOf(action.payload, String(item.id)) });
      });

      const result = extend({}, state, { quicklinks: sortBy(qll, 'order').concat(ql.slice(-1)) });
      return result;

    case symbols.CANCEL_QUICKLINK_EDIT:
      return extend({}, state, {
        quicklinks: map(state.quicklinks, (link) => {
          return link.id === action.payload ? extend({}, link, { edit: false }) : link;
        })
      });

    default:
      return state;
  }
}
