import { invert } from 'lodash';
import * as UndoRedoCableCar from '../actions/undo_redo/undo_redo_cable';
import { symbols } from '../actions/undo_redo/undo_redo';

const invertedSymbols = invert(symbols)

const cableChannelMiddleware = (store) => (next) => (action) => {

  if(action.type && invertedSymbols[action.type]){
    store.dispatch(UndoRedoCableCar.sendUndoRedoCableCarMessage(action))
  }
  next(action)
}

export default cableChannelMiddleware
