import React from 'react';
import { updateTinymceEditorAnchor, getTextWithColor } from 'app/components/helpers/school';

export default function TinyMceEditorContent ({ className = '', text = '', color = '' }) {
  let content = updateTinymceEditorAnchor(text);
      content = getTextWithColor(content, color);

  return (
    <div
      className={className}
      dangerouslySetInnerHTML={{ __html: content }}>
    </div>
  );
}
