import React from 'react';
import { connect } from 'react-redux';
import Lesson from '../containers/lesson';
import { importSchoolTemplates } from '../../MainApp/components/helpers/school';

class MultiLayout extends React.Component {
  render() {
    const { isExplicitSchool, templateName } = this.props;
    const School = importSchoolTemplates(templateName);

    if (isExplicitSchool) {
      return (
        <School {...this.props}>
          {this.props.children}
        </School>
      );
    } else {
      return (
        <Lesson {...this.props}>
          {this.props.children}
        </Lesson>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    isExplicitSchool: state.$$school.get('explicit'),
    templateName: state.$$school.get('template_name'),
  };
}

export default connect(mapStateToProps)(MultiLayout);
