const CACHED_RESPONSES = new Map();

function clear() {
  return CACHED_RESPONSES.clear();
}

function get(cachedKey = "") {
  return CACHED_RESPONSES.get(cachedKey) || {};
}

function has(cachedKey = "") {
  return CACHED_RESPONSES.has(cachedKey);
}

function key(...args) {
  return args.map((arg) => JSON.stringify(arg)).join("|");
}

function set(cachedKey = "", response = {}) {
  return CACHED_RESPONSES.set(cachedKey, response);
}

export default {
  clear,
  get,
  has,
  key,
  set,
};
